define('presentation/account/activity/notation/facades/addNotationFacade',['persistence/webSocket/webSocketApp',
        'common/authentication/employeeAuthenticationStore',
        'common/collections/collectionSorter'],
function() {
    var _promiseFactory = null;
    var _webSocketNotationsApp = null;
    var SorterConstructor = require('common/collections/collectionSorter');
    var _sorter = new SorterConstructor();
    var _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');
    var _employeeId = _employeeAuthenticationStore.getEmployeeId();

    var _saveNotations = function(accountNumber, notations) {
        return _promiseFactory.defer(function(deferredObject) {
            var notationsData = notations.map(function(notation) {
                return {
                    "status": notation.status(),
                    "notationEventId": notation.notationEventId(),
                    "comment": notation.comment(),
                    "startOnDate": notation.startOn(),
                    "dueOnDate": notation.dueOn()
                };
            });
            var params = {
                "account_number": accountNumber,
                "employee_id": _employeeId,
                "notations": notationsData
            };

            _webSocketNotationsApp.send("save_notations", params, function(notationCategoriesEventsList) {
                deferredObject.resolve();
            });
        });
    };

    var _getNotationCategoriesEventsList = function(accountNumber) {
        return _promiseFactory.defer(function(deferredObject) {
            var params = {
                "account_number": accountNumber,
                "employee_id": _employeeId
            };

            _webSocketNotationsApp.send("get_notation_categories_events_list", params, function(notationCategoriesEventsList) {
                _sorter.sort(notationCategoriesEventsList, "notationCategoryName");

                notationCategoriesEventsList.forEach(function(notationCategory) {
                    _sorter.sort(notationCategory.notationEvents, "notationEventName");
                });
                deferredObject.resolve(notationCategoriesEventsList);
            });
        });
    };

    var _init = function(promiseFactory) {
        _promiseFactory = promiseFactory;

        var WebSocketNotationsAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketNotationsApp = new WebSocketNotationsAppConstructor();
        _webSocketNotationsApp.init("notations");
    };

    var _init2 = function(promiseFactory, webSocketApp) {
        _promiseFactory = promiseFactory;
        _webSocketNotationsApp = webSocketApp;
    };

    return function() {
        var self = this;

        self.init = _init;
        self.init2 = _init2;

        self.getNotationCategoriesEventsList = _getNotationCategoriesEventsList;
        self.saveNotations = _saveNotations;
    };
});

