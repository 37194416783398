define('presentation/navigation/viewModels/mainNavigationViewModel',['businessServices/pathProviders/urlFormatter',
    'common/promises/promiseFactory',
    'presentation/navigation/facades/mainNavigationFacade',
    'presentation/navigation/viewModels/recentlyViewedAccountsViewModel'
], function() {
    return function(){
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _facade = null;
        let _urlFormatter = null;
        let _uiAnimationsConfig = null;
        let _hideUserProfileOptionsTimeout = null;
        let _router = null;

        self.isEmployeeProfileOptionsOpen = ko.observable(false);
        self.isEmployeeHistoryGirdOpen = ko.observable(false);
        self.historyGridViewModel = ko.observable();

        self.userAvatar = ko.observable();
        self.showCrmSettings = ko.observable(false);
        self.showCrmMarketing = ko.observable(false);
        self.showCrmRelease = ko.observable(false);
        self.showCrmDeveloper = ko.observable(false);
        self.historyUrl = ko.observable("");
        self.reportsUrl = ko.observable("");
        self.accountLookupUrl = ko.observable("");
        self.lobbyTicketingDefaultUrl = ko.observable("");
        self.settingsUrl = ko.observable("");
        self.marketingUrl = ko.observable("");
        self.releaseUrl = ko.observable("");
        self.developerUrl = ko.observable("");
        self.environmentLabel = ko.observable("");

        self.environmentLabelIsVisible = ko.computed(function () {
            return self.environmentLabel() !== "";
        });

        self.logoutEmployee = () => {
            return _promiseFactory.defer((promise) => {
                _facade.logoutEmployee()
                    .done(() => {
                        _router.navigate("/login");
                        promise.resolve();
                    })
                    .fail((error) => {
                        promise.reject(error);
                    });
            });
        };

        self.toggleEmployeeHistoryGrid = () => {
            return _promiseFactory.defer((promise) => {
                if(self.isEmployeeHistoryGirdOpen() === true) {
                    self.isEmployeeHistoryGirdOpen(false);
                    promise.resolve();
                } else {
                    self.historyGridViewModel().loadHistoryGrid()
                        .done(() => {
                            self.isEmployeeHistoryGirdOpen(true);
                            promise.resolve();
                        })
                        .fail((error) => {
                            promise.reject(error);
                        });
                }
            });
        };

        self.toggleUserProfileOptions = () => {
            if (self.isEmployeeProfileOptionsOpen() === true) {
                self.isEmployeeProfileOptionsOpen(false);
            } else {
                self.isEmployeeProfileOptionsOpen(true);
            }
        };

        self.hideUserProfileOptions = () => {
            _hideUserProfileOptionsTimeout = setTimeout(() => {
                self.isEmployeeProfileOptionsOpen(false);
            }, _uiAnimationsConfig.employeeProfile_hideEmployeeProfileOptions);
        };

        self.resetHideUserProfileOptions = () => {
            clearTimeout(_hideUserProfileOptionsTimeout);
        };

        self.setRouter = (router) => {
            _router = router;
        };

        self.activate = function() {
            const FacadeConstructor = require('presentation/navigation/facades/mainNavigationFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            _urlFormatter = require('common/url/urlFormatter');

            _uiAnimationsConfig = require('settings/uiAnimationsConfiguration');

            return _initialize();
        };

        const _initialize = () => {
            _promiseFactory.defer((promise) => {
                _facade.hasAccessToCrmSettings()
                    .done((hasCrmSettingsPermission) => {
                        self.showCrmSettings(hasCrmSettingsPermission);
                        promise.resolve();
                    })
                    .fail((error) => {
                        promise.reject(error);
                    });
            });

            _promiseFactory.defer((promise) => {
                _facade.hasAccessToCrmMarketing()
                    .done((hasCrmMarketingPermission) => {
                        self.showCrmMarketing(hasCrmMarketingPermission);
                        promise.resolve();
                    })
                    .fail((error) => {
                        promise.reject(error);
                    });
            });

            _promiseFactory.defer((promise) => {
                _facade.hasAccessToCrmRelease()
                    .done((hasCrmReleasePermission) => {
                        self.showCrmRelease(hasCrmReleasePermission);
                        promise.resolve();
                    })
                    .fail((error) => {
                        promise.reject(error);
                    });
            });

            _promiseFactory.defer((promise) => {
                _facade.hasAccessToCrmDeveloper()
                    .done((hasCrmDeveloperPermission) => {
                        self.showCrmDeveloper(hasCrmDeveloperPermission);
                        promise.resolve();
                    })
                    .fail((error) => {
                        promise.reject(error);
                    });
            });

            _facade.getLobbyTicketingUrl()
                .done((result) => {
                    self.lobbyTicketingDefaultUrl(result.ticketsUrl);
                })
                .fail((error) => {
                    throw new Error(error);
                });

            _facade.getEnvironmentLabel()
                .done((result) => {
                    self.environmentLabel(result);
                })
                .fail((error) => {
                    throw new Error(error);
                });

            _promiseFactory.defer((promise) => {
                const HistoryGridViewModelConstructor = require('presentation/navigation/viewModels/recentlyViewedAccountsViewModel');
                const historyGrid = new HistoryGridViewModelConstructor();
                historyGrid.activate();
                self.historyGridViewModel(historyGrid);
                promise.resolve();
            });

            let historyUrl = _urlFormatter.buildUrl(["history"]);
            let reportsUrl = _urlFormatter.buildUrl(["reports"]);
            let accountLookupUrl = _urlFormatter.buildUrl(["accountLookup"]);
            let settingsUrl = _urlFormatter.buildUrl(["settings"]);
            let marketingUrl = _urlFormatter.buildUrl(["marketing"]);
            let releaseUrl = _urlFormatter.buildUrl(["release"]);
            let developerUrl = _urlFormatter.buildUrl(["developer"]);

            self.historyUrl(historyUrl);
            self.reportsUrl(reportsUrl);
            self.accountLookupUrl(accountLookupUrl);
            self.settingsUrl(settingsUrl);
            self.marketingUrl(marketingUrl);
            self.releaseUrl(releaseUrl);
            self.developerUrl(developerUrl);

            return _promiseFactory.wait();
        };
    };
});

