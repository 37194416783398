define('presentation/settings/viewModels/addEmployeeViewModel',['common/promises/promiseFactory',
        'plugins/router',
        'presentation/settings/validators/addEmployeeValidator',
        'presentation/settings/facades/addEmployeeFacade'], function() {
    return function(){
        var self = this;

        var PASSWORD_TOOLTIP = "Passwords must contain 8 to 32 characters, <br/>at least 1 uppercase letter, and at least 1 number.";
        const ROLE_AGENT = "CS.Agent";
        const ROLE_DEVELOPER = "CS.Developer";
        const ROLE_LEAD = "CS.Lead";
        const ROLE_MARKETING = "CS.Marketing";
        const ROLE_NUMBER_SERVICES = "CS.NumberServices";
        const ROLE_RELEASE = "CS.Release";

        var _facade = null;
        var _validator = null;
        var _router = null;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _employeeId = null;

        var _populateForm = function(employee) {
            self.firstName(employee.firstName);
            self.lastName(employee.lastName);
            self.emailAddress(employee.emailAddress);
            self.employeeNumber(employee.employeeNumber);
            self.isActive(employee.isEnabled);
            _populateRoles(employee);
        };

        const _populateRoles = function(employee){
             employee.roles.map(function(role){
               switch (role.roleId){
                   case ROLE_AGENT:
                       self.isRoleAgent(true);
                       break;
                   case ROLE_DEVELOPER:
                       self.isRoleDeveloper(true);
                       break;
                   case ROLE_LEAD:
                       self.isRoleLead(true);
                       break;
                   case ROLE_MARKETING:
                       self.isRoleMarketing(true);
                       break;
                   case ROLE_NUMBER_SERVICES:
                       self.isRoleNumberServices(true);
                       break;
                   case ROLE_RELEASE:
                       self.isRoleRelease(true);
                       break;
                   default:
                       break;
               }
            });
        };

        self.formTitle = "";
        self.saveButtonTitle = "";
        self.firstName = ko.observable('');
        self.lastName = ko.observable('');
        self.emailAddress = ko.observable('');
        self.password = ko.observable('');
        self.passwordTooltip = PASSWORD_TOOLTIP;
        self.confirmPassword = ko.observable('');
        self.employeeNumber = ko.observable('');
        self.isActive = ko.observable(true);
        self.CURRENT_MODE_EDIT = "edit";
        self.CURRENT_MODE_NEW = "new";
        self.currentMode = self.CURRENT_MODE_NEW;
        self.originalEmployee = null;
        self.isRoleAgent = ko.observable(false);
        self.isRoleDeveloper = ko.observable(false);
        self.isRoleLead = ko.observable(false);
        self.isRoleMarketing = ko.observable(false);
        self.isRoleNumberServices = ko.observable(false);
        self.isRoleRelease = ko.observable(false);

        self.employeeRoles = ko.computed(function() {
           let employeeRoles = [];
           if (self.isRoleAgent()) {
               employeeRoles.push({roleId: ROLE_AGENT});
           }
            if (self.isRoleDeveloper()) {
                employeeRoles.push({roleId: ROLE_DEVELOPER});
            }
            if (self.isRoleLead()) {
                employeeRoles.push({roleId: ROLE_LEAD});
            }
            if (self.isRoleMarketing()) {
                employeeRoles.push({roleId: ROLE_MARKETING});
            }
            if (self.isRoleNumberServices()) {
                employeeRoles.push({roleId: ROLE_NUMBER_SERVICES});
            }
            if (self.isRoleRelease()) {
                employeeRoles.push({roleId: ROLE_RELEASE});
            }
           return employeeRoles;
        });

        self.saveButtonClick = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid) {
                            if (self.currentMode === self.CURRENT_MODE_NEW) {
                                _facade.addEmployee(
                                        self.firstName(),
                                        self.lastName(),
                                        self.emailAddress(),
                                        self.password(),
                                        self.employeeNumber(),
                                        self.isActive(),
                                        self.employeeRoles()
                                    ).done(function(addEmployeeResult) {
                                        _router.navigate("/settings/employeeManagement");
                                        deferredObject.resolve();
                                    });
                            } else {
                                _facade.updateEmployee(
                                        _employeeId,
                                        self.firstName(),
                                        self.lastName(),
                                        self.emailAddress(),
                                        self.password(),
                                        self.employeeNumber(),
                                        self.isActive(),
                                        self.employeeRoles()
                                    ).done(function(addEmployeeResult) {
                                        _router.navigate("/settings/employeeManagement");
                                        deferredObject.resolve();
                                    });
                            }
                        } else {
                            deferredObject.resolve();
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.cancelForm = function () {
            _router.navigate("/settings/employeeManagement");
        };

        self.activate = function(employeeId) {
            var Facade = require('presentation/settings/facades/addEmployeeFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            var ValidatorConstructor = require('presentation/settings/validators/addEmployeeValidator');
            _validator = new ValidatorConstructor();

            _router = require('plugins/router');

            _employeeId = employeeId;

            return _initialize(employeeId);
        };

        var _initialize = function() {
            if (_employeeId) {
                self.formTitle = "Edit Employee";
                self.saveButtonTitle = "Save";
                _facade.getEmployee(_employeeId)
                    .done(function (employee) {
                        self.currentMode = self.CURRENT_MODE_EDIT;
                        self.originalEmployee = employee;
                        _populateForm(employee);
                    });
            } else {
                self.currentMode = self.CURRENT_MODE_NEW;
                self.formTitle = "New Employee";
                self.saveButtonTitle = "Create";
            }
            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();
        };
    };
});

