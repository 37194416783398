define('entities/employeeEntity',[],function() {
    return function(){
        var self = this;

        self.employeeId = null;
        self.firstName = null;
        self.lastName = null;
        self.emailAddress = null;
        self.password = null;
        self.employeeNumber = null;
        self.isEnabled = null;
        self.createdDateTime = null;
        self.modifiedDateTime = null;
        self.roles = null;
    };
});

