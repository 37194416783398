define('presentation/marketing/validators/addEmailRecipientValidator',['common/specifications/validEmailAddressSpecification',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'constants/validationMessageEnumerations'], function() {
    return function() {
        var self = this;

        var _addEmailRecipientViewModel = null;
        var _addEmailRecipientFacade = null;
        var _commonValidator = null;

        var ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        var _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        self.registerViewModel = function(addEmailRecipientViewModel, addEmailRecipientFacade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.marketing.addEmailRecipient;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("emailAddress")
                .addValidation(_validateStringRequired, validationMessageBase.emailAddressRequired)
                .addValidation(_validateEmailAddressFormatting, validationMessageBase.emailAddressInvalid);

            _commonValidator = new CommonValidatorConstructor(addEmailRecipientViewModel, validationRules);
            _addEmailRecipientViewModel = addEmailRecipientViewModel;
            _addEmailRecipientFacade = addEmailRecipientFacade;
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateStringRequired = function(value) {
            return _commonValidator.isStringWithValue(value);
        };

        var _validateEmailAddressFormatting = function(emailAddress) {
            return _validEmailAddressSpecification.isSatisfiedBy(emailAddress.trim());
        };
    };
});

