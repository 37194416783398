define('externalDependencies/clientApi',['businessServices/events/eventManager',
    'externalDependencies/clientResult',
    'externalDependencies/configuration/clientApiConfiguration',
    'common/promises/promiseFactory'
], function() {

    var ClientResultConstructor = require('externalDependencies/clientResult');
    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = new PromiseFactoryConstructor();
    var _eventManager = null;
    var _apiUrl = null;
    var _authenticatedEmployee = null;

    return function() {
        var self = this;

        var _updateConfigurationValues = function(clientConfiguration) {
            _apiUrl = clientConfiguration.apiUrl;
        };

        self.init = function() {
            _eventManager = require('businessServices/events/eventManager');
            _authenticatedEmployee = require('businessServices/authentication/authenticatedEmployee');

            var clientConfiguration = require('externalDependencies/configuration/clientApiConfiguration');
            _updateConfigurationValues(clientConfiguration);
        };

        self.setHasTimeout = function(hasTimeout) {
            _promiseFactory.setHasTimeout(hasTimeout);
        };

        self.unauthenticatedCall = function(url, method, document) {
            var jsonCall = "";
            if(document){
                jsonCall = JSON.stringify(document);
            }
            return self.call(url, method, jsonCall, 'application/json');
        };

        self.call = function(url, method, requestData, contentType) {
            return _promiseFactory.defer(function(promise) {

                var actualContentType = contentType || 'application/json';
                var fullUrl = _apiUrl + url;

                var errorHandler = function(jQueryRequest, textStatus, exceptionThrown) {
                    var jQueryResponse;
                    var requestStatus;
                    var exceptionMessage;

                    if (jQueryRequest.status === 401) {
                        _eventManager.publishAuthenticationExpired();
                        promise.reject(new Error("Not Authorized"));
                        return;
                    }

                    if (jQueryRequest.responseText) {
                        try {
                            jQueryResponse = JSON.parse(jQueryRequest.responseText);
                        }
                        catch (exception) {
                            jQueryResponse = "[Error in parsing response: " + exception + "]";
                        }
                    } else {
                        jQueryResponse = "[undefined]";
                    }

                    if (textStatus) {
                        requestStatus = textStatus;
                    }
                    else {
                        requestStatus = "[undefined]";
                    }

                    if (exceptionThrown) {
                        exceptionMessage = exceptionThrown;
                    }
                    else {
                        exceptionMessage = "[undefined]";
                    }

                    var result = new ClientResultConstructor();
                    result.success = false;
                    result.data = jQueryResponse;
                    result.errorMessage = "JQueryResponse: {" + jQueryResponse + "}, RequestStatus: {" + requestStatus + "}, ExceptionMessage: {" + exceptionMessage + "}";
                    promise.resolve(result);
                };

                var performRequest = function() {
                    var ajaxSettings = {};
                    ajaxSettings.type = method;
                    ajaxSettings.contentType = actualContentType;
                    ajaxSettings.data = JSON.stringify(requestData);
                    ajaxSettings.cache = false;
                    ajaxSettings.async = true;
                    ajaxSettings.error = errorHandler;
                    ajaxSettings.beforeSend = (jQueryRequest) => {
                        if (_authenticatedEmployee.isAuthenticated()) {
                            let apiToken = _authenticatedEmployee.apiToken();
                            if (apiToken) {
                                jQueryRequest.setRequestHeader('X-Employee-Authentication', apiToken);
                            }
                        }
                    };
                    ajaxSettings.success = (jsonResult) => {
                        var dataResult;
                        try {
                            dataResult = JSON.parse(jsonResult);
                        }
                        catch (e) {
                            dataResult = jsonResult;
                        }

                        var result = new ClientResultConstructor();
                        result.success = true;
                        result.data = dataResult;
                        result.errorMessage = "";
                        promise.resolve(result);
                    };

                    $.ajax(fullUrl, ajaxSettings);

                };

                performRequest();
            });
        };
    };
});

