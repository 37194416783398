define('presentation/account/activity/notation/presentationObjects/addNotationPresentationObject',[],
function() {
    return function() {
        var self = this;

        self.status = ko.observable("");
        self.startOn = ko.observable("");
        self.startOnIsVisible = null;
        self.dueOn = ko.observable("");
        self.dueOnIsVisible = null;
        self.notationEventId = ko.observable("");
        self.comment = ko.observable("");
        self.isChanged = null;
        self.isRemovable = null;
    };
});

