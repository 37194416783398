define('presentation/developer/facades/smsVerificationFacade',[
        'externalDependencies/clientApi'
    ],
    function() {
        const ClientApiConstructor = require('externalDependencies/clientApi');
        const _clientApi = new ClientApiConstructor();
        
        const _getSmsVerificationNumber = () => {
            return _clientApi.call("smsVerification/getSmsVerificationNumber", "GET", {}, 'application/json');
        }
        ;
        const _setSmsVerificationNumber = (smsVerificationNumber) => {
            let params = {
                smsVerificationNumber: smsVerificationNumber
            };
            return _clientApi.call("smsVerification/setSmsVerificationNumber", "POST", params, 'application/json');
        };
        
        const _init = () => {
            _clientApi.init();
        };
        
        return function() {
            let self = this;
            self.getSmsVerificationNumber = _getSmsVerificationNumber;
            self.setSmsVerificationNumber = _setSmsVerificationNumber;
            self.init = _init;
        };
    });
