define('businessServices/country/timeZones',['constants/timeZoneEnumerations'], function() {
    return function() {
        var self = this;

        var timeZoneEnumerations = require('constants/timeZoneEnumerations');

        var _getTimeZones = function() {
            return [
                {
                    key : timeZoneEnumerations.newfoundland,
                    name : "America/St_Johns"
                },
                {
                    key : timeZoneEnumerations.atlantic,
                    name : "America/Halifax"
                },
                {
                    key : timeZoneEnumerations.eastern,
                    name : "America/New_York"
                },
                {
                    key : timeZoneEnumerations.central,
                    name : "America/Chicago"
                },
                {
                    key : timeZoneEnumerations.mountain,
                    name : "America/Denver"
                },
                {
                    key : timeZoneEnumerations.arizona,
                    name : "America/Phoenix"
                },
                {
                    key : timeZoneEnumerations.pacific,
                    name : "America/Los_Angeles"
                },
                {
                    key : timeZoneEnumerations.alaska,
                    name : "America/Anchorage"
                },
                {
                    key : timeZoneEnumerations.hawaii_aleutian,
                    name : "America/Adak"
                }
            ];
        };

        self.getTimeZoneName = function(requestedTimeZone) {
            var timeZones = _getTimeZones();
            var matchedTimeZone = timeZones.find(function(timeZoneItem) {
                if (timeZoneItem.key === requestedTimeZone) {
                    return true;
                } else {
                    return false;
                }
            });

            if (matchedTimeZone !== undefined && matchedTimeZone.name !== undefined) {
                return matchedTimeZone.name;
            } else {
                throw new Error("Invalid timezone specified");
            }
        };
    };
});

