define('persistence/webSocket/webSocketEmployeeAuthentication',[
    'common/authentication/employeeAuthenticationStore',
    'persistence/webSocket/webSocketApp',
    'common/storage/workSessionIdStorage',
    'common/url/url',
    'constants/statusConstants'
], function() {

    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');
        var _statusConstants = require('constants/statusConstants');

        var _webSocketApp = null;
        var _workSessionIdStorage = require('common/storage/workSessionIdStorage');

        var _validateTokenCredentials = function(employeeId, reconnectToken) {
            return _promiseFactory.defer(function(promise) {
                var workSessionId = _workSessionIdStorage.getWorkSessionId();
                self.loginToken(employeeId, reconnectToken, workSessionId)
                    .done(function (result) {
                        switch (result.status) {
                            case _statusConstants.success:
                                _workSessionIdStorage.setWorkSessionId(result.workSessionId);
                                promise.resolve({status: _statusConstants.success});
                                break;

                            case _statusConstants.invalidToken:
                                _workSessionIdStorage.deleteWorkSessionId();
                                promise.resolve({status: _statusConstants.failed});
                                break;

                            default:
                                var authenticationError = new Error();
                                authenticationError.message = "Invalid response message";
                                authenticationError.status = result.status;
                                _workSessionIdStorage.deleteWorkSessionId();
                                promise.reject(authenticationError);
                        }
                    })
                    .fail(function (error) {
                        promise.reject(error);
                    });
            });
        };

        self.configureAuthentication = function() {
            return _promiseFactory.defer(function(promise) {
                var employeeId = _employeeAuthenticationStore.getEmployeeId();
                var reconnectToken = _employeeAuthenticationStore.getReconnectionToken();

                if (employeeId === null || reconnectToken === null) {
                    promise.resolve(false);
                } else {
                    _validateTokenCredentials(employeeId, reconnectToken)
                        .done(function(result) {
                            if (result.status === _statusConstants.failed) {
                                _webSocketApp.doNothingOnReconnect();
                                _employeeAuthenticationStore.resetEmployeeAuthentication();
                                promise.resolve(false);
                            } else {
                                _webSocketApp.onReconnect(self.configureAuthentication);
                                promise.resolve(true);
                            }
                        })
                        .fail(function(error) {
                            promise.reject(error);
                        });
                }
            });
        };

        self.loginEmployee = function(username, password, workSessionId) {
            return _promiseFactory.defer(function(deferredObject) {
                var usernameLowerCase = username.toLowerCase();

                var webSocketParams = {
                    emailAddress: usernameLowerCase,
                    password: password,
                    workSessionId : workSessionId
                };

                _webSocketApp.send("login_employee", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.loginToken = function(employeeId, reconnectionToken, workSessionId) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "employeeId": employeeId,
                    "reconnectionToken": reconnectionToken,
                    "workSessionId" : workSessionId
                };

                _webSocketApp.send("login_token", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.validateUrlNonce = function(urlFragment) {
            var urlParts = urlFragment.split("/");

            var validateUrlNonce = {
                "employee_id": _employeeAuthenticationStore.getEmployeeId(),
                "nonce_id": urlParts[1],
                "signature": urlParts[3],
                "url": _reconstructUrl(urlFragment),
                "workSessionId": _workSessionIdStorage.getWorkSessionId(),
                "reconnectionToken": _employeeAuthenticationStore.getReconnectionToken()
            };

            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("validate_url_nonce", validateUrlNonce, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.validateClientUiUrlNonce = function (urlFragment) {
            var urlParts = urlFragment.split("/");

            var validateClientUiUrlNonce = {
                "employee_id": _employeeAuthenticationStore.getEmployeeId(),
                "nonce_id": urlParts[1],
                "signature": urlParts[3],
                "url": _reconstructUrl(urlFragment),
                "reconnection_token": _employeeAuthenticationStore.getReconnectionToken()
            };

            return _promiseFactory.defer(function (deferredObject) {
                _webSocketApp.send("validate_client_ui_url_nonce", validateClientUiUrlNonce, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        var _reconstructUrl = function(urlFragment) {
            var urlFragmentWithoutSignature = urlFragment.substring(0, urlFragment.lastIndexOf("/"));

            var UrlConstructor = require('common/url/url');
            var urlBuilder = new UrlConstructor();

            return urlBuilder.getBaseURL() + "/" + urlFragmentWithoutSignature;
        };

        self.logout = function() {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {
                    "reconnection_token": _employeeAuthenticationStore.getReconnectionToken()
                };
                _webSocketApp.send("logout", webSocketParams, function(result) {
                    promise.resolve(result);
                });
            });
        };

        self.setWorkSessionId = function(workSessionId) {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {
                    "workSessionId" : workSessionId
                };
                _webSocketApp.send("set_work_session_id", webSocketParams, function(result) {
                    promise.resolve(result);
                });
            });
        };

        self.retrievePermissions = function() {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {};

                _webSocketApp.send("get_permissions", webSocketParams, function(result) {
                    promise.resolve(result);
                });
            });
        };

        self.init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("authentication_employee");
        };

        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});

