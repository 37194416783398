define('presentation/account/viewModels/resetPasswordModalViewModel',['presentation/common/modal',
    'presentation/account/facades/usersFacade',
    'common/promises/promiseFactory',
], function () {
    return function (emailAddress, accountNumber, userId, onLoadCompleteDeferredObject, onModalCloseCompleteDeferredObject) {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _facade =  require('presentation/account/facades/usersFacade');

        var ModalServiceConstructor = require('presentation/common/modal');
        var _modalService = new ModalServiceConstructor();

        var _onModalCloseCompleteDeferredObject = onModalCloseCompleteDeferredObject;

        self.emailAddress = ko.observable();
        self.accountNumber = accountNumber;
        self.userId = userId;

        var _sendResetPassword = function () {
            return _promiseFactory.defer(function (deferredObject) {
                var accountNumber = self.accountNumber;
                var userId = self.userId;

                _facade.sendResetPassword(accountNumber, userId)
                    .done(function () {
                        deferredObject.resolve();
                        _onModalCloseCompleteDeferredObject.resolve("done");
                        _modalService.closeModal(self);
                    })
                    .fail(function (error) {
                        deferredObject.reject(error);
                        _onModalCloseCompleteDeferredObject.reject(error);
                    });

            });
        };

        self.closeModal = function () {
            _onModalCloseCompleteDeferredObject.resolve("cancel");
            _modalService.closeModal(self);
        };

        self.cancel = function () {
            _modalService.closeModal(self);
        };

        self.sendResetPassword = function () {
            return _sendResetPassword();
        };

        self.activate = function () {
            return _initialize();
        };

        self.activate2 = function (facade, modalService) {
            _facade = facade;
            _modalService = modalService;

            return _initialize();
        };

        var _initialize = function () {
            self.emailAddress(emailAddress);

            onLoadCompleteDeferredObject.resolve();

            return _promiseFactory.wait();
        };
    };
});
