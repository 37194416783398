define('presentation/settings/validators/addBlacklistPhoneNumberValidator',['presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'common/converters/phoneNumberFormatter',
    'presentation/common/validation/validPhoneNumberSpecification',
    'constants/validationMessageEnumerations',
    'constants/countryEnumerations',
    'common/promises/promiseFactory'], function() {
    return function() {
        var self = this;

        var _addBlacklistPhoneNumberViewModel;
        var _addBlacklistPhoneNumberFacade;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');

        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        var _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        var ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
        var _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();

        var _countryEnumerations = require('constants/countryEnumerations');

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');

        var _commonValidator = null;

        self.registerViewModel = function(addBlacklistPhoneNumberViewModel, addNotationEventFacade) {

            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.settings.addBlacklistPhoneNumber;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("phoneNumber")
                .addValidation(_validatePhoneNumberRequired, validationMessageBase.phoneNumberRequired)
                .addValidation(_validatePhoneNumberLength, validationMessageBase.phoneNumberInvalid);
            _addBlacklistPhoneNumberViewModel = addBlacklistPhoneNumberViewModel;
            _addBlacklistPhoneNumberFacade = addNotationEventFacade;
            _commonValidator = new CommonValidatorConstructor(_addBlacklistPhoneNumberViewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validatePhoneNumberRequired = function (phoneNumber) {
            return _commonValidator.isStringWithValue(phoneNumber);
        };

        var _validatePhoneNumberLength = function (phoneNumber) {
            if (phoneNumber) {
                var cleanPhoneNumber = _phoneNumberFormatter.clean(phoneNumber);
                return _validPhoneNumberSpecification.isSatisfiedBy(cleanPhoneNumber, _countryEnumerations.usa);
            }
        };

    };
});

