define('presentation/common/tagEditor/viewModels/tagEditorViewModel',[],
function() {
    return function() {
        var self = this;

        self.allowTagValidator = null;
        self.removeTagValidator = null;
        self.value = ko.observableArray([]);
        self.tagItemValueProperty = null;
        self.tagItemTextProperty = null;

        self.activate = function(settings) {
            return _initialize(settings);
        };

        self.activate2 = function(settings) {
            return _initialize(settings);
        };

        var _initialize = function(settings) {
            if (settings.value) {
                if (typeof settings.value === "function") {
                    self.value = settings.value;
                } else {
                    self.value(settings.value);
                }
            }

            if (settings.allowTagValidator && typeof settings.allowTagValidator === "function") {
                self.allowTagValidator = settings.allowTagValidator;
            }

            if (settings.removeTagValidator && typeof settings.removeTagValidator === "function") {
                self.removeTagValidator = settings.removeTagValidator;
            }

            if (settings.tagItemValueProperty) {
                self.tagItemValueProperty = settings.tagItemValueProperty;
            }

            if (settings.tagItemTextProperty) {
                self.tagItemTextProperty = settings.tagItemTextProperty;
            }
        };
    };
});

