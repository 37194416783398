define('businessServices/authentication/authenticatedEmployee',['common/authentication/employeeAuthenticationStore'],
    function() {
        var _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');

        function isAuthenticated() {
            if (_employeeAuthenticationStore.getEmployeeId() === undefined) {
                return false;
            } else {
                return true;
            }
        }

        function apiToken() {
            return _employeeAuthenticationStore.getReconnectionToken();
        }

        return {
            isAuthenticated : isAuthenticated,
            apiToken: apiToken
        };
    }
);

