define('presentation/forgotPassword/viewModels/forgotPasswordViewModel',[
    'common/promises/promiseFactory',
    'presentation/forgotPassword/facades/forgotPasswordFacade',
    'presentation/forgotPassword/validators/forgotPasswordValidator'
], function() {

    return function() {
        var self = this;

        var _facade = null;
        var _validator = null;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.emailAddress = ko.observable('');
        self.showConfirmationMessage = ko.observable(false);

        self.sendEmail = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid === true) {
                            var forgotPasswordEmail = self.emailAddress().trim();
                            _facade.sendPasswordResetEmail(forgotPasswordEmail)
                                .done(function() {
                                    self.showConfirmationMessage(true);
                                    deferredObject.resolve();
                                })
                                .fail(function(error) {
                                    deferredObject.reject(error);
                                });
                        } else {
                            deferredObject.resolve();
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.activate = function() {
            var Facade = require('presentation/forgotPassword/facades/forgotPasswordFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            var ForgotPasswordValidatorConstructor = require('presentation/forgotPassword/validators/forgotPasswordValidator');
            _validator = new ForgotPasswordValidatorConstructor();

            return _initialize();
        };

        self.activate2 = function(facade, validator) {
            _facade = facade;
            _validator = validator;

            return _initialize();
        };

        var _initialize = function() {
            _validator.registerViewModel(self, _facade);
            return _promiseFactory.wait();
        };
    };
});

