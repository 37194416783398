define('presentation/developer/validators/fraudSettingsValidator',[
    'constants/validationMessageEnumerations',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
], function() {
    return function() {
        let self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        let _commonValidator = null;
        let _viewModel = null;
        let _facade = null;

        self.registerViewModel = function(viewModel, facade) {
            const validationMessageBase = _validationMessageEnumerations.webcrm.presentation.developer.fraudSettings;
            let validationRules = new ValidationRulesConstructor();

            validationRules.field("emailFraudScore")
                .addValidation(_validateNumber, validationMessageBase.required);

            validationRules.field("ipFraudScore")
                .addValidation(_validateNumber, validationMessageBase.required);

            validationRules.field("paymentMethodFraudScore")
                .addValidation(_validateNumber, validationMessageBase.required);

            validationRules.field("phoneNumberFraudScore")
                .addValidation(_validateNumber, validationMessageBase.required);

            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        const _validateNumber = function(value) {
            return _commonValidator.isNumberBetweenValues(value, 0, 101);
        };
    };
});

