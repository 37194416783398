define('presentation/account/activity/notation/presentationObjects/updateNotationPresentationObject',[],
function() {
    return function() {
        var self = this;

        self.notationEventId = ko.observable("");
        self.comment = ko.observable("");
        self.isChanged = null;
        self.isRemovable = null;
    };
});

