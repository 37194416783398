define('presentation/release/viewModels/addFeatureToAccountModalViewModel',[
    'presentation/common/modal',
    'presentation/release/facades/addFeatureToAccountModalFacade',
    'presentation/release/presentationObjects/accountPresentationObject',
    'presentation/release/validators/addFeatureToAccountModalValidator'
], function () {
    return function (featureToggle, onLoadCompletePromise, onModalCloseCompletePromise) {
        let self = this;

        let _modalService = null;
        let _onLoadCompletePromise = null;
        let _onModalCloseCompletePromise = null;
        let _facade = null;
        let _validator = null;

        const AccountPresentationObject = require('presentation/release/presentationObjects/accountPresentationObject');
        let _featureToggle = featureToggle;

        self.featureToggle = _featureToggle;
        self.modalHeaderText = "Add Feature to Accounts";
        self.accounts = ko.observableArray([new AccountPresentationObject()]);
        self.isLastAccount = ko.pureComputed(function() {
            return self.accounts().length === 1;
        });
        self.saveButtonText = "Save";

        self.closeModal = () => {
            _onModalCloseCompletePromise.resolve('cancel');
            _modalService.closeModal(self);
        };

        self.saveButton = () => {
            self.accounts.remove(function(account) {
                return account.accountId().trim() === "";
            });

            if (self.accounts().length === 0) {
                self.closeModal();
            } else {
                _validator.validate()
                    .fail(_onModalCloseCompletePromise.reject)
                    .done((isValid) => {
                        if (isValid) {
                            let accountIdsToAdd = self.accounts().map((account) => {
                                return account.accountId();
                            });
                            _facade.addAccountsToFeature(_featureToggle, accountIdsToAdd)
                                .fail(_onModalCloseCompletePromise.reject)
                                .done(function (result) {
                                    _modalService.closeModal(self);
                                    _onModalCloseCompletePromise.resolve(result);
                                });
                        }
                    });
            }
        };

        self.addAccount = function () {
            self.accounts.push(new AccountPresentationObject());
        };

        self.removeAccount = function (account) {
            self.accounts.remove(account);
        };

        self.activate = function () {
            const ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            const FacadeConstructor = require('presentation/release/facades/addFeatureToAccountModalFacade');
            _facade = new FacadeConstructor();

            const ValidatorConstructor = require('presentation/release/validators/addFeatureToAccountModalValidator');
            _validator = new ValidatorConstructor();

            _onLoadCompletePromise = onLoadCompletePromise;
            _onModalCloseCompletePromise = onModalCloseCompletePromise;

            return _initialize();
        };

        const _initialize = function () {
            _validator.registerViewModel(self, _facade);
        };
    };
});

