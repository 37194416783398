define('presentation/account/presentationObjects/userPresentationObject',[],function() {
    return function(){
        var self = this;
        self.userId = null;
        self.userAvatar = ko.observable(null);
        self.hasAvatar = false;
        self.firstName = null;
        self.lastName = null;
        self.fullName = null;
        self.emailAddress = null;
        self.emailAddressDescription = null;
        self.verificationQuestion = null;
        self.verificationAnswer = null;
        self.timeZone = null;
        self.otherContactInfoEmailAddresses = [];
        self.otherContactInfoPhoneNumbers = [];
        self.userType = null;
        self.userGroups = ko.observableArray([]);
        self.devices = ko.observableArray([]);
        self.createdDateTime = null;
        self.status = ko.observable(null);
        self.inviteStatus = ko.observable(null);
        self.isActiveStatus = ko.pureComputed(() => self.status() === "active");
        self.isDeactivatedStatus = ko.pureComputed(() => self.status() === "deactivated");
        self.isExpiredStatus = ko.pureComputed(() => self.status() === "expired");
        self.isInviteExpired = ko.pureComputed(() => self.inviteStatus() === "expired");
        self.isInvitedStatus = ko.pureComputed(() => self.status() === "invited");
        self.inviteExpirationDateTime = null;
        self.inviteSentDateTime = null;
        self.badges = [];

        self.showResetPassword = ko.pureComputed(() => self.isActiveStatus());
        self.showResetVerificationCode = ko.pureComputed(() => self.isActiveStatus());
    };
});

