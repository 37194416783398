define('constants/statusConstants',[], function() {
    return {
        alreadyLoggedIn: "alreadyLoggedIn",
        employeeInactive:"employee_inactive",
        failed: "failed",
        ignored: "ignored",
        invalidEmployeeName: "invalid_employee_name",
        invalidPassword: "invalid_password",
        invalidToken: "invalid_token",
        success: "success"
    };
});

