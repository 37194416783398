define('presentation/account/activity/email/viewModels/sendToViewModel',['presentation/account/activity/email/facades/sendToFacade',
        'common/promises/promiseFactory'],
function() {
    return function(parentViewModel, accountNumber) {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _facade = null;
        var _accountNumber = accountNumber;
        var _parentViewModel = parentViewModel;

        self.sendToContactsList = ko.observableArray([]);
        self.sendToContactsSelected = ko.observableArray([]);

        self.cancelForm = function() {
            _parentViewModel.closeModal();
        };

        self.submitForm = function() {
            _parentViewModel.goToNextSection();
        };

        self.activate = function() {
            var Facade = require('presentation/account/activity/email/facades/sendToFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            return _initialize();
        };

        self.activate2 = function(facade) {
            _facade = facade;
            return _initialize();
        };

        var _initialize = function() {
            _facade.getSendToContacts(_accountNumber)
                .done(function(results) {
                    self.sendToContactsList(results);
                })
                .fail(function(error) {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

