define('presentation/shell/viewModels/templateContentViewModel',['businessServices/router/crmRouter',
        'common/promises/promiseFactory'], function() {

    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.router = null;

        self.activate = function() {
            self.router = require('businessServices/router/crmRouter');

            return _initialize();
        };

        self.activate2 = function(router) {
            self.router = router;

            return _initialize();
        };

        var _initialize = function () {
            return _promiseFactory.wait();
        };
    };
});

