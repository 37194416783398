define('presentation/account/facades/activityFacade',['persistence/webSocket/webSocketApp',
        'common/authentication/employeeAuthenticationStore',
        'common/converters/passedTimeSpanFormatter',
        'common/converters/phoneNumberFormatter'],
function() {
    var COLUMNS_PER_ROW = 5;

    var _promiseFactory = null;
    var _webSocketNotationsApp = null;
    var _webSocketActivityApp = null;

    var _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');
    var _employeeId = _employeeAuthenticationStore.getEmployeeId();

    var PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
    var _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();

    var PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
    var _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

    var _getNotations = function(accountNumber, rowsPerPage, currentPage, sortBy, sortAsc, filter, showDetails) {
        return _promiseFactory.deferIndefinitely(function(deferredObject) {
            var params = {
                "account_number": accountNumber,
                "employee_id": _employeeId,
                "rows_per_page": rowsPerPage,
                "current_page": currentPage,
                "sort_by": sortBy,
                "sort_asc": sortAsc,
                "filter": filter
            };

            _webSocketNotationsApp.send("get_notations", params, function(result) {

                result.results.forEach(function(notation) {
                    var unfilteredMetaDataList = notation.metaData;
                    notation.metaData = [];
                    notation.hasStoredDocument = false;
                    unfilteredMetaDataList.forEach(function(metaDataItem) {
                        if (metaDataItem.dataType === "storedDocument") {
                            notation.storedDocument = metaDataItem.value;
                            notation.hasStoredDocument = true;
                        } else {
                            notation.metaData.push(metaDataItem);
                        }
                    });

                    if (notation.startOnDate !== null) {
                        notation.metaData.unshift({
                            "label": "Start On",
                            "value": _passedTimeSpanFormatter.formatDateTime(notation.startOnDate)
                        });
                    }

                    notation.workSessionIdDisplay = notation.workSessionId.substring(0, 6) + "...";

                    var metaData = [];
                    var loopTo = notation.metaData.length;
                    var itemsOnIncompleteRow = notation.metaData.length % COLUMNS_PER_ROW;
                    if (itemsOnIncompleteRow) {
                        loopTo += (COLUMNS_PER_ROW - itemsOnIncompleteRow);
                    }
                    var rowIndex = 0;

                    for (var i=0; i < loopTo; i++) {
                        if (i > 0 && i % COLUMNS_PER_ROW === 0) {
                            rowIndex++;
                        }

                        var cellValue;

                        if (i <= notation.metaData.length - 1) {
                            var metaDataItem = notation.metaData[i];
                            if (metaDataItem.dataType === "phoneNumber") {
                                cellValue = {
                                    label: metaDataItem.label,
                                    value: _phoneNumberFormatter.toDashed(metaDataItem.value)
                                };
                            } else {
                                cellValue = {
                                    label: metaDataItem.label,
                                    value: metaDataItem.value
                                };
                            }
                        } else {
                            cellValue = {
                                label: "",
                                value: ""
                            };
                        }

                        if (metaData[rowIndex] === undefined) {
                            metaData[rowIndex] = [];
                        }

                        metaData[rowIndex].push(cellValue);
                    }

                    notation.metaData = metaData;
                    notation.isDetailsVisible = ko.observable(showDetails);

                    if (notation.createdDateTime !== null) {
                        notation.createdDateTime = _passedTimeSpanFormatter.formatDateTime(notation.createdDateTime);
                    }

                    if (notation.dueByDate !== null) {
                        notation.dueByDate = _passedTimeSpanFormatter.formatDateTime(notation.dueByDate);
                    }
                });

                var formattedResult = {};
                formattedResult.totalResults = result.totalResults;
                formattedResult.results = result.results;
                deferredObject.resolve(formattedResult);
            });
        });
    };

    var _getLobbyTicketingUrl = function() {
        return _promiseFactory.defer(function (deferredObject) {
            var webSocketParams = {
                "employee_id": _employeeId
            };
            _webSocketActivityApp.send("get_lobby_ticketing_url", webSocketParams, function (result) {
                deferredObject.resolve(result);
            });
        });
    };

    var _init = function(promiseFactory) {
        _promiseFactory = promiseFactory;

        var WebSocketNotationsAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketNotationsApp = new WebSocketNotationsAppConstructor();
        _webSocketNotationsApp.init("notations");

        var WebSocketActivityAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketActivityApp = new WebSocketActivityAppConstructor();
        _webSocketActivityApp.init("crm_activity");
    };

    var _init2 = function(promiseFactory, webSocketApp) {
        _promiseFactory = promiseFactory;
        _webSocketNotationsApp = webSocketApp;
        _webSocketActivityApp = webSocketApp;
    };

    return function() {
        var self = this;

        self.init = _init;
        self.init2 = _init2;

        self.getNotations = _getNotations;
        self.getLobbyTicketingUrl = _getLobbyTicketingUrl;
    };
});

