define('persistence/fileStores/fileManagement/fileReader',['common/promises/promiseFactory',
        'persistence/fileStores/fileManagement/fileReaderResult'],

function() {
    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.readFile = function(fileHandle) {
            var FileReaderResultConstructor = require('persistence/fileStores/fileManagement/fileReaderResult');

            var promise = _promiseFactory.defer(function(deferredObject) {
                var fileInfo = new FileReaderResultConstructor();
                fileInfo.fileSize = fileHandle.size;
                fileInfo.fileName = fileHandle.name;
                fileInfo.fileEncodingType = fileHandle.type;

                var reader = new FileReader();
                reader.readAsArrayBuffer(fileHandle);
                reader.onabort = function() {
                    var abortError = new Error("Received Abort when reading file");
                    deferredObject.reject(abortError);
                };
                reader.onerror = function(error) {
                    deferredObject.reject(error);
                };
                reader.onloadend = function() {
                    fileInfo.fileContent = reader.result;
                    deferredObject.resolve(fileInfo);
                };
            });

            return promise.promise();
        };
    };
});

