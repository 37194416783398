define('presentation/settings/facades/crmOptionsFacade',['common/promises/promiseFactory','persistence/webSocket/webSocketApp'], function () {
    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = null;
    var _webSocketApp = null;

    function _getCrmOptions() {
        return _promiseFactory.defer(function (promise) {
            _webSocketApp.send("get_crm_options", {}, function(result) {
                promise.resolve(result.crmOptions);
            });
        });
    }

    function _saveCrmOptions(environmentLabel) {
        return _promiseFactory.defer(function (promise) {
            var parameters = {
                environmentLabel: environmentLabel
            };
            _webSocketApp.send("save_crm_options", parameters, function (result) {
                if(result.status === "success") {
                    promise.resolve();
                } else {
                    var error = new Error();
                    error.reason = result.reason;
                    promise.reject(error);
                }
            });
        });
    }

    function _init() {
        _promiseFactory = new PromiseFactoryConstructor();

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("crm_options");
    }

    function _init2(promiseFactory, webSocketApp) {
        _promiseFactory = promiseFactory;
        _webSocketApp = webSocketApp;
    }

    return function () {
        var self = this;
        self.getCrmOptions = _getCrmOptions;
        self.saveCrmOptions = _saveCrmOptions;
        self.init = _init;
        self.init2 = _init2;
    };
});
