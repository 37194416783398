define('presentation/settings/validators/emailNotificationsValidator',['common/promises/promiseFactory',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'common/specifications/validEmailAddressSpecification',
    'constants/validationMessageEnumerations'], function() {
    return function() {
        var self = this;

        var _potentialCustomerViewModel = null;
        var _potentialCustomerFacade = null;
        var _commonValidator = null;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        var _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        self.registerViewModel = function(potentialCustomerViewModel, potentialCustomerFacade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.settings.emailNotifications;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("activationEmail")
                .addValidation(_validateEmailAddress, validationMessageBase.emailInvalid);

            _commonValidator = new CommonValidatorConstructor(potentialCustomerViewModel, validationRules);
            _potentialCustomerViewModel = potentialCustomerViewModel;
            _potentialCustomerFacade = potentialCustomerFacade;
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateEmailAddress = function(value) {
            if (_commonValidator.isStringWithValue(value)) {
                // If we provided something, make sure it's an email address
                if ((_validEmailAddressSpecification.isSatisfiedBy(value) === true) &&
                    (_validEmailAddressSpecification.isTrestaEmail(value) === true)) {
                    return true;
                } else {
                    // We provided something, but it wasn't a valid email address
                    return false;
                }
            } else {
                // We didn't provide anything, and that's okay
                return true;
            }
        };
    };
});

