define('businessServices/country/countryCode',[],function() {
    return function() {
        var self = this;
        
        self.defaultCountryAbbreviation = "us";

        self.countryCodes = [
            {
                name: "United States of America",
                countryAbbreviation: "us",
                countryCode: "1",
                hasStates: true,
                hasProvinces: false,
                maxDigits: 10,
                minDigits: 10
            },
            {
                name: "Canada",
                countryAbbreviation: "ca",
                countryCode: "1",
                hasStates: false,
                hasProvinces: true,
                maxDigits: 10,
                minDigits: 10
            }
        ];

        self.findByAbbreviation = (countryAbbreviation) => {
            const countryAbbreviationLowercase = countryAbbreviation.toLowerCase();
            return self.countryCodes.find(country => country.countryAbbreviation === countryAbbreviationLowercase);
        };

        self.getDefaultCountry = () => self.findByAbbreviation(self.defaultCountryAbbreviation);

        self.getCountryName = (countryAbbreviation) => {
            const country = self.findByAbbreviation(countryAbbreviation);
            return country ? country.name : null;
        };
    };
});

