define('presentation/reports/facades/reportsFacade',['presentation/common/dateTimeValue',
        'common/collections/collectionSorter',
        'persistence/webSocket/webSocketApp',
        'presentation/reports/presentationObjects/reportsPresentationObject'],
    function() {
        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        var SorterConstructor = require('common/collections/collectionSorter');
        var ReportsPresentationObjectConstructor = require('presentation/reports/presentationObjects/reportsPresentationObject');
        var _sorter = new SorterConstructor();
        var _promiseFactory = null;
        var _webSocketApp = null;

        var _getNotationReports = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("get_all_notation_reports", {}, function(reports) {
                    var date = new Date();
                    var openItemReport = {};
                    openItemReport.notationReportId = "openItems";
                    openItemReport.reportName = "Agent - Active Items";
                    openItemReport.createdDateTime = date.toISOString();
                    openItemReport.modifiedDateTime = date.toISOString();
                    var notationReports = reports.notationReports;
                    notationReports.push(openItemReport);
                    var notationReportPresentationObjects = _formatNotationReports(notationReports);
                    _sorter.sort(notationReportPresentationObjects, "reportName", true);
                    deferredObject.resolve(notationReportPresentationObjects);
                });
            });
        };

        var _getNotationReportCounts = function(promise) {
            _webSocketApp.send("get_notation_report_item_counts", {}, function (notationReportCountHash) {
                promise.resolve(notationReportCountHash);
            });
        };

        var _formatNotationReports = function(notationReportsList) {
            return notationReportsList.map(function(notationReport){
                var notationReportPresentationObject = new ReportsPresentationObjectConstructor();
                notationReportPresentationObject.notationReportId = notationReport.notationReportId;
                notationReportPresentationObject.reportName = notationReport.reportName;
                var modifiedDateTime = new DateTimeValueConstructor(notationReport.modifiedDateTime);
                notationReportPresentationObject.modifiedDateTimeDisplayValue = modifiedDateTime.displayValue;
                notationReportPresentationObject.modifiedDateTime = modifiedDateTime;
                var createdDateTime = new DateTimeValueConstructor(notationReport.createdDateTime);
                notationReportPresentationObject.createdDateTimeDisplayValue = createdDateTime.displayValue;
                notationReportPresentationObject.createdDateTime = createdDateTime;
                return notationReportPresentationObject;
            });
        };

        var _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("notation_report");
        };

        var _init2 = function() {

        };

        return function() {
            var self = this;
            self.init = _init;
            self.init2 = _init2;
            self.getNotationReports = _getNotationReports;
            self.getNotationReportCounts = _getNotationReportCounts;
        };
    });

