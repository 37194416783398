define('presentation/marketing/viewModels/emailNotificationsViewModel',['common/promises/promiseFactory',
        'constants/marketingContactFormTypeEnumerations',
        'common/collections/enumerationKeyValueConverter',
        'presentation/marketing/facades/emailNotificationsFacade',
        'presentation/promptDialog/viewModels/promptDialogViewModel',
        'plugins/router'],
    function() {
        return function() {
            var self = this;

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();

            var MARKETING_CONTACT_FORM_TYPE_ENUMERATION = require('constants/marketingContactFormTypeEnumerations');
            var _keyValueConverter = require('common/collections/enumerationKeyValueConverter');
            var _formTypeOptions = _keyValueConverter.convertToKeyValues(MARKETING_CONTACT_FORM_TYPE_ENUMERATION);

            var _facade = null;
            var _router = null;
            var _promptDialog = null;

            var _getMarketingEmailNotifications = function() {
                return _promiseFactory.defer(function(deferredObject) {
                    _facade.getEmailNotifications()
                        .done(function(emailNotifications) {
                            self.emailNotifications(emailNotifications);
                            deferredObject.resolve();
                        })
                        .fail(deferredObject.reject);
                });
            };

            var _getFormTypeList = function() {
                var types = [];
                _formTypeOptions.forEach(function(item){
                    types.push({value: item.key, title: item.value});
                });

                return types;
            };

            self.formTypeList = _getFormTypeList();
            self.formType = ko.observable().extend({observeState: true});
            self.showContactUs = ko.pureComputed(function() {
                return self.formType() === "contactUs";
            });
            self.showDemoRequest = ko.pureComputed(function() {
                return self.formType() === "demoRequest";
            });

            self.emailNotifications = ko.observableArray([]);
            self.contactFormRecipients = ko.pureComputed(function() {
                return self.emailNotifications().filter(function(recipient) {
                    return recipient.formType === MARKETING_CONTACT_FORM_TYPE_ENUMERATION.contactUs;
                });
            });
            self.demoRequestRecipients = ko.pureComputed(function() {
                return self.emailNotifications().filter(function (recipient) {
                    return recipient.formType === MARKETING_CONTACT_FORM_TYPE_ENUMERATION.demoRequest;
                });
            });
            
            self.formType = ko.observable("contactUs");

            self.addRecipient = function () {
                _router.navigate("marketing/emailNotifications/addRecipient");
            };

            var _showRemoveRecipientDialog  = function(recipient) {
                return _promptDialog.masterClear()
                    .setTitle('Remove Email Recipient')
                    .addPrimaryButton('Confirm', 'confirm')
                    .addButton('Cancel', 'cancel')
                    .addLine("The following email recipient will be removed.")
                    .addEmptyLine()
                    .addCenteredLine(recipient)
                    .showDialog();
            };


            self.removeRecipient = function (recipient) {
                return _promiseFactory.deferIndefinitely(function (promise) {
                    _showRemoveRecipientDialog(recipient.emailAddress)
                        .fail(promise.reject)
                        .done(function() {
                            if (_promptDialog.userSelection !== "confirm") {
                                _promptDialog.complete();
                                promise.resolve();

                            } else {
                                _facade.removeEmailRecipient(recipient)
                                    .done(function() {
                                        _promptDialog.complete();
                                        _getMarketingEmailNotifications();
                                        promise.resolve();
                                    });
                            }
                        });
                });
            };

            self.activate = function() {
                var Facade = require('presentation/marketing/facades/emailNotificationsFacade');
                _facade = new Facade();
                _facade.init(_promiseFactory);

                var PromptsDialogViewModelConstructor = require('presentation/promptDialog/viewModels/promptDialogViewModel');
                _promptDialog = new PromptsDialogViewModelConstructor();

                _router = require('plugins/router');

                return _initialize();
            };

            var _initialize = function() {
                _promptDialog.activate();
                _getMarketingEmailNotifications();
                
                return _promiseFactory.wait();
            };
        };
});
