define('businessServices/converters/timeZoneDateTimeFormatter',['businessServices/country/timeZones',
        'moment-timezone'],
function() {
    return function() {
        var self = this;

        var TimeZonesConstructor = require('businessServices/country/timeZones');
        var _timeZones = new TimeZonesConstructor();

        var _moment = require('moment-timezone');

        var _formatDate = function(dateTime, timeZone) {
            var timeZoneName = _timeZones.getTimeZoneName(timeZone);
            return _moment(dateTime).tz(timeZoneName).format("MM/DD/YYYY");
        };

        var _formatTime = function(dateTime, timeZone) {
            var timeZoneName = _timeZones.getTimeZoneName(timeZone);
            return _moment(dateTime).tz(timeZoneName).format("hh:mm a z");
        };

        var _formatDateTime = function(dateTime, timeZone) {
            var timeZoneName = _timeZones.getTimeZoneName(timeZone);
            return _moment(dateTime).tz(timeZoneName).format("MM/DD/YYYY hh:mm a z");
        };

        var _formatDateNow = function(timeZone) {
            var timeZoneName = _timeZones.getTimeZoneName(timeZone);
            return _moment().tz(timeZoneName).format("MM/DD/YYYY");
        };

        var _formatTimeNow = function(timeZone) {
            var timeZoneName = _timeZones.getTimeZoneName(timeZone);
            return _moment().tz(timeZoneName).format("hh:mm a z");
        };

        var _formatDateTimeNow = function(timeZone) {
            var timeZoneName = _timeZones.getTimeZoneName(timeZone);
            return _moment().tz(timeZoneName).format("MM/DD/YYYY hh:mm a z");
        };

        self.formatDate = _formatDate;
        self.formatTime = _formatTime;
        self.formatDateTime = _formatDateTime;
        self.formateDateNow = _formatDateNow;
        self.formatTimeNow = _formatTimeNow;
        self.formatDateTimeNow = _formatDateTimeNow;
    };
});

