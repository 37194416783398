define('businessServices/authentication/employeeAuthorization',[
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketEmployeeAuthentication',
    'businessServices/events/eventManager'], function() {

    function authenticationChanged(isAuthenticated) {
        _permissionIds = null;
    }

    function retrievePermissions() {
        return _promiseFactory.defer(function(promise) {
            var EmployeeAuthenticationConstructor = require('persistence/webSocket/webSocketEmployeeAuthentication');
            var employeeAuthentication = new EmployeeAuthenticationConstructor();
            employeeAuthentication.init();
            employeeAuthentication.retrievePermissions()
                .done(function(result) {
                    if (result.status === "success") {
                        _permissionIds = {};
                        result.permissionIds.forEach(function(permissionId) {
                            _permissionIds[permissionId] = true;
                        });

                        promise.resolve();
                    } else {
                        var error = new Error("Unknown result from retrievePermissions");
                        error.result = result;
                        promise.reject(error);
                    }
                })
                .fail(function(error) {
                    promise.reject(error);
                });
        });
    }

    function hasPermission(permissionId) {
        return _promiseFactory.defer(function(promise) {
            if (_permissionIds === null) {
                retrievePermissions()
                    .done(function() {
                        hasPermission(permissionId)
                            .done(function(result) {
                                promise.resolve(result);
                            })
                            .fail(function(error) {
                                promise.reject(error);
                            });
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            } else {
                if (_permissionIds[permissionId] === true) {
                    promise.resolve(true);
                } else {
                    promise.resolve(false);
                }
            }
        });
    }

    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = new PromiseFactoryConstructor();

    var _eventManager = require('businessServices/events/eventManager');
    _eventManager.subscribeEmployeeAuthenticated(authenticationChanged);

    var _permissionIds = null;

    return {
        hasPermission : hasPermission
    };
});
