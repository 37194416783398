define('presentation/settings/facades/emailNotificationsFacade',['common/promises/promiseFactory',
        'persistence/webSocket/webSocketApp'],
    function() {
        var _promiseFactory = null;
        var _webSocketApp = null;

        var _saveEmailNotifications = function(activationEmail) {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {
                    "activationEmail": activationEmail
                };
                _webSocketApp.send("save_email_notifications", params, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        var _getEmailNotifications = function() {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {};
                _webSocketApp.send("get_email_notifications", params, function(emailNotifications) {
                    deferredObject.resolve(emailNotifications);
                });
            });
        };

        var _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("crm_email_notifications");
        };

        return function() {
            var self = this;
            self.getEmailNotifications = _getEmailNotifications;
            self.saveEmailNotifications = _saveEmailNotifications;
            self.init = _init;
        };
    });

