define('presentation/account/sidebar/viewModels/editAccountStatusModalViewModel',['presentation/common/modal',
    'presentation/account/sidebar/facades/accountSidebarFacade',
    'constants/accountStatusEnumerations',
    'common/collections/enumerationKeyValueConverter',
    'presentation/account/sidebar/validators/editAccountStatusModalViewModelValidator',
    'common/promises/promiseFactory',
    'presentation/account/sidebar/presentationObjects/statusChangedPresentationObject'
], function () {
    return function (accountNumber, accountStatus, billingStatus, fraudStatus, reasonForCancellation, cancellationComment, onLoadCompleteDeferredObject, onModalCloseCompleteDeferredObject) {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _facade = null;
        var _modalService = null;

        var _onModalCloseCompleteDeferredObject = onModalCloseCompleteDeferredObject;

        var _accountStatusEnumerations = require('constants/accountStatusEnumerations');

        var _keyValueConverter = require('common/collections/enumerationKeyValueConverter');

        var _validator = null;

        var StatusChangedPresentationObjectConstructor = require('presentation/account/sidebar/presentationObjects/statusChangedPresentationObject');

        var _processChangeStatus = function (statusName, status, changeText) {
            var statusChangedObject = self.accountChanges().find(function (statusChangedObject) {
                return statusChangedObject.statusName === statusName;
            });

            if(statusChangedObject) {
                self.accountChanges.remove(statusChangedObject);
            }
            statusChangedObject = new StatusChangedPresentationObjectConstructor();
            statusChangedObject.statusName = statusName;
            statusChangedObject.toStatus = status;
            if(changeText !== undefined) {
                statusChangedObject.statusChangeText = changeText;
            } else {
                statusChangedObject.statusChangeText = statusName + " changed to " + status + ".";
            }
            self.accountChanges.push(statusChangedObject);
        };

        var _onBillingStatusChanged = function (billingStatus) {
            var statusName = "Billing Status";
            _processChangeStatus(statusName, billingStatus);
        };

        var _onFraudStatusChanged = function (fraudStatus) {
            var statusName = "Fraud Status";
            _processChangeStatus(statusName, fraudStatus);
            switch(fraudStatus) {
                case _accountStatusEnumerations.fraudStatus.creditCardFraud:
                    _processChangeStatus("Do Not Reactivate Flag", "on", "Do Not Reactivate Flag will be turned on.");
                    _processChangeStatus("Do Not Auto Charge", "on", "Do Not Auto Charge Flag will be turned on.");
                    break;
                case _accountStatusEnumerations.fraudStatus.abusiveCustomer:
                case _accountStatusEnumerations.fraudStatus.other:
                    _processChangeStatus("Do Not Auto Charge", "off");
                    _processChangeStatus("Do Not Reactivate Flag", "on", "Do Not Reactivate Flag will be turned on.");
                    break;
                default:
                    _processChangeStatus("Do Not Auto Charge", "off");
                    _processChangeStatus("Do Not Reactivate Flag", "off");
                    break;
            }
        };

        var _saveChanges = function () {
            return _promiseFactory.defer(function (deferredObject) {
                var statusData = {
                    billingStatus: self.billingStatus(),
                    fraudStatus: self.fraudStatus(),
                };

                _facade.saveStatusData(statusData, self.accountNumber)
                    .done(function (result) {
                        deferredObject.resolve();
                        _modalService.closeModal(self);
                        _onModalCloseCompleteDeferredObject.resolve(result);
                    })
                    .fail(function (error) {
                        deferredObject.reject(error);
                        _onModalCloseCompleteDeferredObject.reject(error);
                    });

            });
        };

        self.initialAccountStatus = accountStatus;
        self.initialBillingStatus = billingStatus;
        self.accountStatus = ko.observable();
        self.billingStatus = ko.observable();
        self.fraudStatus = ko.observable();
        self.reasonForCancellation = ko.observable();
        self.cancellationComment = ko.observable();
        self.isEditing = ko.observable(true);
        self.isConfirming = ko.observable(false);
        self.modalHeaderText = ko.observable("Edit Account Status");
        self.accountStatusIsEnabled = ko.observable(true);

        self.accountChanges = ko.observableArray([]);
        self.accountNumber = accountNumber;
        self.billingStatuses = _keyValueConverter.convertToKeyValues(_accountStatusEnumerations.billingStatus);
        self.fraudStatuses = _keyValueConverter.convertToKeyValues(_accountStatusEnumerations.fraudStatus);

        self.filteredFraudStatuses = ko.computed(function () {
            if(self.accountStatus() === _accountStatusEnumerations.status.active) {
                return self.fraudStatuses.filter(function(fraudStatus){
                    return (fraudStatus.value === _accountStatusEnumerations.fraudStatus.ok || fraudStatus.value === _accountStatusEnumerations.fraudStatus.fraudReview);
                });
            } else {
                return self.fraudStatuses;
            }
        });

        self.filteredBillingStatuses = ko.computed(function () {
            if(self.accountStatus() === _accountStatusEnumerations.status.active) {
                return self.billingStatuses.filter(function(billingStatus) {
                    return (billingStatus.value === _accountStatusEnumerations.billingStatus.ok || billingStatus.value === _accountStatusEnumerations.billingStatus.ccDeclined);
                });
            } else {
                return self.billingStatuses;
            }
        });

        self.billingStatusIsEnabled = ko.computed(function () {
            if(self.accountStatus()) {
                if(self.accountStatus() === _accountStatusEnumerations.status.closed) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        });

        self.showChangeConfirmation = ko.computed(function () {
            return self.accountChanges().length > 0;
        });

        self.submitButtonText = ko.computed(function () {
            if(self.accountChanges().length > 0) {
                return "Save";
            } else {
                return "OK";
            }
        });

        self.accountStatusStyle = ko.pureComputed(function () {
            switch (self.accountStatus()) {
                case undefined:
                    return "";
                case _accountStatusEnumerations.status.active:
                    return "status-success";
                case _accountStatusEnumerations.status.closed:
                    return "status-error";
                default:
                    throw new Error("Invalid account status.");
            }
        });

        self.closeModal = function () {
            _onModalCloseCompleteDeferredObject.resolve({clicked: "cancel", showCancellationModal: false});
            _modalService.closeModal(self);
        };

        self.goBack = function () {
            self.isEditing(true);
            self.isConfirming(false);
            self.modalHeaderText("Edit Account Status");
        };

        self.confirmClose = function () {
            _validator.validate()
                .done(function (isValid) {
                    if (isValid === true) {
                        _saveChanges();
                    }
                });
        };

        self.submit = function () {
            _validator.validate()
                .done(function (isValid) {
                    if (isValid === true) {
                        if(self.accountChanges().length > 0) {
                            if (self.accountStatus() === _accountStatusEnumerations.status.closed && self.initialAccountStatus === _accountStatusEnumerations.status.active) {
                                self.isEditing(false);
                                self.isConfirming(true);
                                self.modalHeaderText("Confirm Changes");
                            }
                            else {
                                _saveChanges();
                            }
                        } else {
                            self.closeModal();
                        }
                    }
                });
        };

        self.activate = function () {
            var FacadeConstructor = require('presentation/account/sidebar/facades/accountSidebarFacade');
            _facade = new FacadeConstructor();

            var ValidatorConstructor = require('presentation/account/sidebar/validators/editAccountStatusModalViewModelValidator');
            _validator = new ValidatorConstructor();

            var ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            return _initialize();
        };

        self.activate2 = function (facade, validator, modalService) {
            _facade = facade;
            _validator = validator;
            _modalService = modalService;

            return _initialize();
        };

        var _initialize = function () {
            self.accountStatus(accountStatus);
            self.billingStatus(billingStatus);
            self.fraudStatus(fraudStatus);

            _validator.registerViewModel(self, _facade);

            self.billingStatus.subscribe(_onBillingStatusChanged);
            self.fraudStatus.subscribe(_onFraudStatusChanged);

            onLoadCompleteDeferredObject.resolve();
        };
    };
})
;

