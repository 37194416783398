define('presentation/account/presentationObjects/userOtherContactInfoEmailAddressesPresentationObject',[],function() {
    return function(){
        var self = this;
        self.otherContactInfoEmailAddressId = ko.observable("");
        self.type = ko.observable("");
        self.typeOrder = ko.observable("");
        self.emailAddress = null;
    };
});

