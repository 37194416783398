define('presentation/settings/validators/addNotationReportValidator',['common/promises/promiseFactory',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
        'constants/validationMessageEnumerations'], function() {
    return function() {
        var self = this;

        var _viewModel = null;
        var _facade = null;
        var _commonValidator = null;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        self.registerViewModel = function(viewModel, facade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.settings.addNotationReport;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("reportName")
                                .addValidation(_validateStringRequired, validationMessageBase.reportNameRequired)
                                .addValidation(_validateReportNameNotReserved, validationMessageBase.reportNameIsReserved)
                                .addValidation(_validateReportNameUnique, validationMessageBase.reportNameUnique);
            validationRules.field("selectedNotationCategoriesAndEvents")
                                .addValidation(_validateCategoryEventSelection, validationMessageBase.categoryEventRequired);

            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
            _viewModel = viewModel;
            _facade = facade;
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateStringRequired = function(value) {
            return _commonValidator.isStringWithValue(value);
        };

        var _validateReportNameNotReserved = function(value) {
            return value.toLowerCase().trim() !== "agent - active items";
        };

        var _validateReportNameUnique = function(reportName) {
            return _promiseFactory.defer(function(deferredResult) {
                var reportId = _viewModel.notationReportId;
                _facade.isReportNameUnique(reportId, reportName)
                    .done(function(isUnique) {
                        deferredResult.resolve(isUnique);
                    })
                    .fail(function(error) {
                        error.reportId = reportId;
                        error.reportName = reportName;
                        deferredResult.reject(error);
                    });
            });
        };

        var _validateCategoryEventSelection = function(selectedCategoryEventList) {
            return _promiseFactory.defer(function(deferredResult) {
                deferredResult.resolve(selectedCategoryEventList.length > 0);
            });
        };

    };
});

