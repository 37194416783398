define('businessServices/browserSupport/browserType',[],
    function() {
        const self = this;

        // From: http://stackoverflow.com/a/9851769
        const _isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
        const _isFirefox = typeof InstallTrigger !== 'undefined';
        const _isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0;
        const _isIE = /*@cc_on!@*/false || !!document.documentMode;
        const _isEdge = !_isIE && !!window.StyleMedia;
        const _isChrome = !!window.chrome && !!window.chrome.webstore;

        self.isMobile = ko.observable(window.innerWidth <= 960);
        self.windowHeight = ko.observable(window.innerHeight);
        self.windowWidth = ko.observable(window.innerWidth);
        window.onresize = () => {
            self.isMobile(window.innerWidth <= 960);
            self.windowHeight(window.innerHeight);
            self.windowWidth(window.innerWidth);
        };

        return {
            isOpera: _isOpera,
            isFirefox: _isFirefox,
            isSafari: _isSafari,
            isIE: _isIE,
            isEdge: _isEdge,
            isChrome: _isChrome,
            isMobile: self.isMobile,
            windowHeight: self.windowHeight,
            windowWidth: self.windowWidth,
        };
    });

