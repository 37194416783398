define('presentation/common/widgetConfiguration',['plugins/widget'], function() {
    return {
        configureWidgets: function() {
            var widget = require('plugins/widget');

            widget.registerKind('tagEditor');
            widget.mapKind('tagEditor',
                            'presentation/common/tagEditor/views/tagEditor',
                            'presentation/common/tagEditor/viewModels/tagEditorViewModel');

            widget.registerKind('wysiwygEditor');
            widget.mapKind('wysiwygEditor',
                            'presentation/common/wysiwygEditor/views/wysiwygEditor',
                            'presentation/common/wysiwygEditor/viewModels/wysiwygEditorViewModel');

            widget.registerKind('attachmentSelector');
            widget.mapKind('attachmentSelector',
                            'presentation/common/attachmentSelector/views/attachmentSelector',
                            'presentation/common/attachmentSelector/viewModels/attachmentSelectorViewModel');

            widget.registerKind('toggleSwitch');
            widget.mapKind('toggleSwitch',
                            'presentation/common/toggleSwitch/views/toggleSwitch',
                            'presentation/common/toggleSwitch/viewModels/toggleSwitchViewModel');

            widget.registerKind('crmDataGrid');
            widget.mapKind('crmDataGrid',
                           'presentation/common/crmDataGrid/views/crmDataGrid',
                           'presentation/common/crmDataGrid/viewModels/crmDataGridViewModel');

           widget.registerKind('dateTimePicker');
           widget.mapKind('dateTimePicker',
                          'presentation/common/dateTimePicker/views/dateTimePicker',
                          'presentation/common/dateTimePicker/viewModels/dateTimePickerViewModel');

          widget.registerKind('clockDisplay');
          widget.mapKind('clockDisplay',
                         'presentation/common/clockDisplay/views/clockDisplay',
                         'presentation/common/clockDisplay/viewModels/clockDisplayViewModel');

            widget.registerKind('optionsBox');
            widget.mapKind('optionsBox',
                'presentation/common/optionsBox/views/optionsBox',
                'presentation/common/optionsBox/viewModels/optionsBoxViewModel');
        }
    };
});

