define('presentation/marketing/facades/addEmailRecipientFacade',['common/promises/promiseFactory',
        'externalDependencies/clientApi'
    ],
    function() {
        var _promiseFactory = null;
        var _clientApi = null;

        var _addEmailRecipient = function(emailAddress, formTypes) {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {"emailAddress": emailAddress, "formTypes": formTypes};
                _clientApi.call("marketingRecipient/addRecipient", "POST", params, 'application/json')
                    .fail(deferredObject.reject)
                    .done(function(result) {
                        deferredObject.resolve(result);
                    });
            });
        };

        var _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();
        };

        return function() {
            var self = this;
            self.addEmailRecipient = _addEmailRecipient;
            self.init = _init;
        };
    });

