define('persistence/webSocket/webSocketEvent',['persistence/webSocket/webSocketApp'], function() {

    return function() {
        var self = this;
        var _webSocketApp = null;

        self.postEvent = function(eventData) {
            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var promiseFactory = new PromiseFactoryConstructor();
            return promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("post_event", eventData, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("event");
        };

        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});
