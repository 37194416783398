/**
 * @module resetPasswordViewModel
 */
define(
    'presentation/accountLookup/viewModels/accountLookupViewModel',[
        'presentation/common/window/windowControl',
        'common/promises/promiseFactory',
        'presentation/accountLookup/facades/accountLookupFacade'
    ], function() {
        return function() {
            var self = this;
            var _facade = null;

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();
            var _windowControl = null;

            self.searchCriteria = ko.observable("");

            self.showSearchResult = ko.observable(false);
            self.showSearchNotFound = ko.observable(false);
            self.foundAccounts = ko.observable([]);

            var _setAccountFound = function(foundAccounts) {
                self.showSearchResult(true);
                self.showSearchNotFound(false);
                self.foundAccounts(foundAccounts);
            };

            var _setAccountNotFound = function () {
                self.showSearchResult(false);
                self.showSearchNotFound(true);
                self.foundAccounts([]);
            };

            self.searchForAccount = function() {
                return _promiseFactory.defer(function(promise) {
                    var searchCriteria = self.searchCriteria();
                    if (searchCriteria.length === 0) {
                        _setAccountNotFound();
                        promise.resolve();
                        return;
                    }

                    _facade.lookup(searchCriteria)
                        .fail(function(error) {
                            promise.reject(error);
                        })
                        .done(function(accountNumberResult) {
                            if (accountNumberResult.success === true) {
                                _setAccountFound(accountNumberResult.accounts);
                                promise.resolve();
                            } else {
                                _setAccountNotFound();
                            }
                            promise.resolve();
                        });
                });
            };

            self.openAccount = function(foundAccount) {
                var url = foundAccount.url;
                _windowControl.openNewWindow(url);
            };


            self.activate = function() {
                _facade = require('presentation/accountLookup/facades/accountLookupFacade');
                _facade.init();
                _windowControl = require('presentation/common/window/windowControl');

                return _initialize();
            };

            self.activate2 = function(accountNumber, facade) {
                _facade = facade;

                return _initialize();
            };

            var _initialize = function() {
                return _promiseFactory.wait();
            };
        };
    });

