define('presentation/settings/validators/addNotationCategoryViewModelValidator',['presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'constants/validationMessageEnumerations',
    'common/promises/promiseFactory'], function() {
    return function() {
        var self = this;

        var _addNotationCategoryViewModel;
        var _addNotationCategoryFacade;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
 

        var _commonValidator = null;
 

        self.registerViewModel = function(addNotationCategoryViewModel, addNotationCategoryFacade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.settings.addNotationCategory;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("description")
                .addValidation(_validateNotationCategoryDescriptionRequired, validationMessageBase.notationCategoryDescriptionRequired)
                .addValidation(_validateNotationCategoryDescriptionUnique, validationMessageBase.notationCategoryDescriptionUnique);
            _addNotationCategoryViewModel = addNotationCategoryViewModel;
            _addNotationCategoryFacade = addNotationCategoryFacade;
            _commonValidator = new CommonValidatorConstructor(_addNotationCategoryViewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateNotationCategoryDescriptionRequired = function(description) {
            return _commonValidator.isStringWithValue(description);
        };

        var _validateNotationCategoryDescriptionUnique = function(description) {
            return _promiseFactory.defer(function(deferredResult) {
                var notationCategoryId = _addNotationCategoryViewModel.notationCategoryId;
                _addNotationCategoryFacade.isNotationCategoryDescriptionUnique(notationCategoryId, description)
                    .done(function(isUnique) {
                        deferredResult.resolve(isUnique);
                    })
                    .fail(function(error) {
                        error.notationCategoryId = notationCategoryId;
                        error.description = description;
                        deferredResult.reject(error);
                    });
            });
        };

    };
}); 

