define('presentation/settings/facades/addNotationReportFacade',['persistence/webSocket/webSocketApp',
        'common/collections/collectionSorter',
        'entities/notationReportEntity'],
function() {
    var _promiseFactory = null;
    var _webSocketApp = null;
    var SorterConstructor = require('common/collections/collectionSorter');
    var NotationReportEntityConstructor = require('entities/notationReportEntity');
    var _sorter = new SorterConstructor();

    var _getNotationCategoryEventList = function() {
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {};
            _webSocketApp.send("get_notation_categories_events_list", webSocketParams, function(notationCategoryEventList) {
                var filteredNotationCategoryEventList = notationCategoryEventList.filter(function (notation) {
                    return notation.notationEvents.length !== 0;
                });

                _sorter.sort(filteredNotationCategoryEventList, "notationCategoryName");
                filteredNotationCategoryEventList.forEach(function(notationCategory) {
                    _sorter.sort(notationCategory.notationEvents, "notationEventName");
                });

                deferredObject.resolve(filteredNotationCategoryEventList);
            });
        });
    };

    var _getNotationReport = function(notationReportId) {
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {notationReportId: notationReportId};
            _webSocketApp.send("get_notation_report_by_id", webSocketParams, function(notationReport) {
                var notationReportObject = {};
                notationReportObject.reportName = notationReport.reportName;
                notationReportObject.categories = [];
                notationReportObject.events = notationReport.events;
                deferredObject.resolve(notationReportObject);
            });
        });
    };

    var _isReportNameUnique = function(reportId, reportName) {
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {};
            _webSocketApp.send("get_all_notation_reports", webSocketParams, function(result) {
                var notationReports = result.notationReports;
                var hasDuplicate = notationReports.some(function(notationReport) {
                    if (notationReport.reportName.toLowerCase().trim() === reportName.toLowerCase().trim()) {
                        if (notationReport.notationReportId !== reportId) {
                            return true;
                        }
                    }
                    return false;
                });
                var isUnique = !hasDuplicate;
                deferredObject.resolve(isUnique);
            });
        });
    };

    var _saveNotationReport = function(reportId, reportName, events, eventItemIds) {
        return _promiseFactory.defer(function(deferredObject) {
            var notationReportEntity  = new NotationReportEntityConstructor();
            notationReportEntity.notationReportId = reportId;
            notationReportEntity.reportName = reportName;
            notationReportEntity.events = events.map(function(event) {
                var eventObject = {};
                var eventItem = eventItemIds.find(function(eventItem) {
                   return eventItem.notationEventId === event;
                });
                if(eventItem !== undefined) {
                    eventObject.itemId = eventItem.itemId;
                } else {
                    eventObject.itemId = null;
                }
                eventObject.eventNotationId = event;
                return eventObject;
            });

            var webSocketParams = {
                notation_report_entity: notationReportEntity
            };
            _webSocketApp.send("save_notation_report", webSocketParams, function() {
                deferredObject.resolve(true);
            });
        });
    };

    var _init = function(promiseFactory) {
        _promiseFactory = promiseFactory;

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("notation_report_management");
    };

    var _init2 = function(promiseFactory, webSocketApp) {
        _promiseFactory = promiseFactory;
        _webSocketApp = webSocketApp;
    };

    return {
        init: _init,
        init2: _init2,
        getNotationCategoryEventList: _getNotationCategoryEventList,
        getNotationReport: _getNotationReport,
        isReportNameUnique: _isReportNameUnique,
        saveNotationReport: _saveNotationReport
    };
});

