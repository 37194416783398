define('presentation/settings/viewModels/addNotationCategoryViewModel',['common/promises/promiseFactory',
    'plugins/router',
    'presentation/settings/facades/addNotationCategoryFacade',
    'presentation/settings/validators/addNotationCategoryViewModelValidator'
], function () {
    return function(){
        var self = this;
        var _router = null;
        var _facade = null;
        var _validator = null;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();


        self.notationCategoryId = null;
        self.isNewNotationCategory = true;
        self.description = ko.observable('');
        self.isActive = ko.observable(true);
        
         
        self.formTitle = "Add Notation Category";
        self.saveButtonTitle = "Save";
        
        
        self.cancelForm = function () { 
            return _promiseFactory.defer(function (deferredObject) {
                _router.navigate("/settings/notationCategory");
                deferredObject.resolve();
            });
        };
        self.saveNotationCategory = function() {
            return _promiseFactory.defer(function (deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid) {
                            if (self.notationCategoryId) {
                                _facade.updateNotationCategory(self.notationCategoryId, self.description(), self.isActive())
                                    .done(function (updateResult) {
                                        deferredObject.resolve(updateResult);
                                        _router.navigate('/settings/notationCategory');
                                    })
                                    .fail(function (error) {
                                        error.notationCategoryId = self.notationCategoryId();
                                        error.description = self.description();
                                        error.isActive = self.isActive();
                                        deferredObject.reject(error);
                                    });
                            }
                            else {
                                _facade.addNotationCategory(self.description(), self.isActive())
                                    .done(function (addResult) {
                                        deferredObject.resolve(addResult);
                                        _router.navigate('/settings/notationCategory');
                                    })
                                    .fail(function (error) {
                                        error.description = self.description();
                                        error.isActive = self.isActive();
                                        deferredObject.reject(error);
                                    });
                            }
                        } else {    
                            deferredObject.resolve();
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };
     

        self.activate = function(notationCategoryId) {
            
            _facade =  require('presentation/settings/facades/addNotationCategoryFacade');
            _facade.init(_promiseFactory);
            _router = require('plugins/router');
            self.notationCategoryId = notationCategoryId;

            var ValidatorConstructor = require('presentation/settings/validators/addNotationCategoryViewModelValidator');
            _validator = new ValidatorConstructor();
            return _initialize();
        };

        self.activate2 = function (router, facade, notationCategoryId, validator) {
            _facade = facade;
            _router = router;

            self.notationCategoryId = notationCategoryId;
            _validator = validator;
            return _initialize();
        };

        var _initialize = function() {
            if (self.notationCategoryId) {
                
                self.formTitle = "Edit Notation Category";
                self.isNewNotationCategory = false;
                self.saveButtonTitle = "Save"; 
                _facade.findNotationCategory(self.notationCategoryId).done(function(notationCategory) {
                    if(notationCategory) {
                        self.description(notationCategory.description);
                        self.isActive(notationCategory.isActive);

                        _validator.registerViewModel(self, _facade);
                    }
                });
            
            } else {
                self.formTitle = "New Notation Category";
                self.saveButtonTitle = "Create";
                _validator.registerViewModel(self, _facade);
            }
            return _promiseFactory.wait();
        };
    };
});
 
