define('presentation/settings/viewModels/emailNotificationsViewModel',['common/promises/promiseFactory',
    'presentation/settings/validators/emailNotificationsValidator',
    'presentation/settings/facades/emailNotificationsFacade'], function() {
    return function(){
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _facade = null;
        var _validator = null;

        var _getEmailNotifications= function() {
            return _promiseFactory.defer(function(deferredObject) {
                _facade.getEmailNotifications()
                    .done(function(emailNotifications) {
                        self.activationEmail(emailNotifications.activationEmail);

                        deferredObject.resolve();
                    })
                    .fail(deferredObject.reject);
            });
        };

        self.activationEmail = ko.observable('');
        self.isSuccessfulSave = ko.observable(false);

        self.saveEmailNotifications = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid !== true) {
                            self.isSuccessfulSave(false);
                            deferredObject.resolve();
                            return;
                        }

                        var activationEmail = self.activationEmail();

                        _facade.saveEmailNotifications(activationEmail)
                            .done(function() {
                                self.isSuccessfulSave(true);
                                deferredObject.resolve();
                            })
                            .fail(function(error) {
                                deferredObject.reject(error);
                            });
                    })
                    .fail(deferredObject.reject);
            });
        };

        self.activate = function() {
            var Facade = require('presentation/settings/facades/emailNotificationsFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            var ValidatorConstructor = require('presentation/settings/validators/emailNotificationsValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        self.activate2 = function() {
            return _initialize();
        };

        var _initialize = function() {
            _getEmailNotifications()
                .done(function() {
                    _validator.registerViewModel(self, _facade);
                });
            return _promiseFactory.wait();
        };
    };
});

