define('presentation/settings/viewModels/blacklistPhoneNumbersViewModel',['common/promises/promiseFactory',
    'presentation/settings/facades/blacklistPhoneNumbersFacade',
    'presentation/promptDialog/viewModels/promptDialogViewModel',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'plugins/router'], function() {
    return function(){
        var self = this;
        var _facade = null;
        var _router = null;

        var PromptsDialogViewModelConstructor = require('presentation/promptDialog/viewModels/promptDialogViewModel');
        var FilterConstructor = require('common/collections/collectionFilter');
        var SorterConstructor = require('common/collections/collectionSorter');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _availableBlacklistedPhoneNumbers = null;

        var _blacklistPhoneNumbersFilterOnChange = function() {
            _updateDisplayedBlacklistPhoneNumbers();
        };

        var SORT_OPTIONS = [
            { "value": "phoneNumber", "displayName":"Phone Number", "isAscending" : true},
            { "value": "addedDateTime.sortValue", "displayName":"Recently Added", "isAscending" : false}
        ];

        self.displayBlacklistPhoneNumbers = ko.observableArray();
        self.sortOptions = ko.observableArray(SORT_OPTIONS);
        self.selectedSort = ko.observable(SORT_OPTIONS[0]);
        self.isActive = ko.observable(true);
        self.blacklistPhoneNumbersFilter = ko.observable('');
        self.blacklistPhoneNumbersFilter.subscribe(_blacklistPhoneNumbersFilterOnChange);
        self.addBlacklistPhoneNumber = function () {
            _router.navigate("/settings/addBlacklistPhoneNumber");
        };

        var _updateDisplayedBlacklistPhoneNumbers = function() {
            var filteredBlacklistedPhoneNumbers;
            var filterContent = self.blacklistPhoneNumbersFilter().trim().toLowerCase();
            if (filterContent) {
                var blacklistPhoneNumbersFilter = new FilterConstructor();
                blacklistPhoneNumbersFilter.addProperty('phoneNumber');
                blacklistPhoneNumbersFilter.addProperty('formattedPhoneNumber');
                blacklistPhoneNumbersFilter.addProperty('addedDateTime.filterValue');
                blacklistPhoneNumbersFilter.addValue(filterContent);
                filteredBlacklistedPhoneNumbers = blacklistPhoneNumbersFilter.filter(_availableBlacklistedPhoneNumbers);
            }
            else {
                filteredBlacklistedPhoneNumbers = _availableBlacklistedPhoneNumbers;
            }
            self.displayBlacklistPhoneNumbers.removeAll();
            for (var x = 0; x < filteredBlacklistedPhoneNumbers.length; x++) {
                var blacklistedPhoneNumber = filteredBlacklistedPhoneNumbers[x];
                self.displayBlacklistPhoneNumbers.push(blacklistedPhoneNumber);
            }
            _sortDisplayedBlacklistPhoneNumbers();
        };

        var _sortDisplayedBlacklistPhoneNumbers = function() {
            var selectedSort = self.selectedSort();
            var sortField = selectedSort.value;
            var isAscending = selectedSort.isAscending;
            var _sorter = new SorterConstructor();
            if (sortField !== null) {
                _sorter.multiSort(self.displayBlacklistPhoneNumbers, [sortField], isAscending);
            }
        };

        var _refreshBlacklistedPhoneNumbers = function() {
            _facade.getAllBlackListedPhoneNumbers()
                .done(function(blacklistedPhoneNumbers) {
                    _availableBlacklistedPhoneNumbers = blacklistedPhoneNumbers;
                    _updateDisplayedBlacklistPhoneNumbers();
                    if(blacklistedPhoneNumbers.length > 0) {
                        self.showWelcomeState(false);
                    } else {
                        self.showWelcomeState(true);
                    }
                });
        };
        self.showEmptyState = ko.computed(function() {
            return self.displayBlacklistPhoneNumbers().length <= 0;
        });
        self.showWelcomeState = ko.observable(false);

        self.deleteBlacklistPhoneNumber = function(blacklistPhoneNumber) {
            var promptDialog = new PromptsDialogViewModelConstructor();
            promptDialog.activate();
            promptDialog.setTitle('Delete Blacklist Phone Number');
            promptDialog.addRedButton('Delete', 'delete');
            promptDialog.addButton('Cancel', 'cancel');
            promptDialog.addLine("Are you sure you want to delete this phone number from the blacklist?");
            promptDialog.addEmptyLine();
            promptDialog.addCenteredPhoneNumberLine(blacklistPhoneNumber.formattedPhoneNumber);

            promptDialog.showDialog()
                .fail(function(error) {
                    throw error;
                })
                .done(function() {
                    if (promptDialog.userSelection === 'delete') {
                        _facade.removePhoneNumberFromBlacklist(blacklistPhoneNumber.phoneNumber)
                            .fail(function(error) {
                                throw error;
                            })
                            .done(function() {
                                _refreshBlacklistedPhoneNumbers();
                                promptDialog.complete();
                            });
                    } else {
                        promptDialog.complete();
                    }
                });
        };

        self.activate = function() {
            var FacadeConstructor = require('presentation/settings/facades/blacklistPhoneNumbersFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);
            _router = require('plugins/router');
            return _initialize();
        };

        self.activate2 = function(facade, router) {
            _facade = facade;
            _router = router;
            return _initialize();
        };

        var _initialize = function() {
            _refreshBlacklistedPhoneNumbers();
            self.selectedSort.subscribe(_sortDisplayedBlacklistPhoneNumbers);
            return _promiseFactory.wait();
        };
    };
});

