define('presentation/account/facades/accountFacade',['businessServices/authentication/employeeAuthorization',
        'common/promises/promiseFactory',
        'common/authentication/employeeAuthenticationStore',
        'persistence/webSocket/webSocketApp',
        'externalDependencies/clientApi',
        'presentation/account/presentationObjects/affiliatePresentationObject',
    ],
    function() {

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        const AffiliatePresentationObjectConstructor = require('presentation/account/presentationObjects/affiliatePresentationObject');

        var _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');

        var _webSocketCustomerAccountInfo = null;
        var _webSocketNavigation = null;

        let _employeeAuthorization = null;
        let _clientApi = null;

        function init() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketCustomerAccountInfo = new WebSocketAppConstructor();
            _webSocketCustomerAccountInfo.init("crm_account");

            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();

            _webSocketNavigation = new WebSocketAppConstructor();
            _webSocketNavigation.init("crm_navigation");

            _employeeAuthorization = require('businessServices/authentication/employeeAuthorization');
        }

        function getCustomerInformation(accountNumber) {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {
                    "employee_id" : _employeeAuthenticationStore.getEmployeeId(),
                    "customer_account_number" : accountNumber
                };
                _webSocketCustomerAccountInfo.send("get_customer_info", webSocketParams, function(result) {
                    promise.resolve(result);
                });
            });
        }

        function saveMemo(accountNumber, memo) {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {
                    "employee_id" : _employeeAuthenticationStore.getEmployeeId(),
                    "customer_account_number" : accountNumber,
                    "memo" : memo
                };
                _webSocketCustomerAccountInfo.send("save_memo", webSocketParams, function(result) {
                    promise.resolve();
                });
            });
        }

        function sendResetPassword(accountNumber) {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {
                    "employee_id" : _employeeAuthenticationStore.getEmployeeId(),
                    "customer_account_number" : accountNumber
                };
                _webSocketCustomerAccountInfo.send("save_memo", webSocketParams, function(result) {
                    promise.resolve();
                });
            });
        }

        function associateCall(accountNumber, callId, phoneNumber) {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {
                    "employee_id" : _employeeAuthenticationStore.getEmployeeId(),
                    "customer_account_number" : accountNumber,
                    "call_id": callId,
                    "phone_number": phoneNumber
                };
                _webSocketCustomerAccountInfo.send("associate_call", webSocketParams, function(result) {
                    promise.resolve(result);
                });
            });
        }

        var _getSignupForCustomerUrl = function (affiliateCode, patliveAccountNumber) {
            return _promiseFactory.defer(function (promise) {
                var params = {
                    affiliateCode: affiliateCode,
                    patliveAccountNumber: patliveAccountNumber
                };
                _webSocketNavigation.send("signup_for_customer", params, function (result) {
                    promise.resolve(result);
                });
            });
        };

        const _getAffiliates = () => {
            return _promiseFactory.defer((promise) => {
                const buildAffiliatePresentationObject = (affiliate) => {
                    const affiliatePresentationObject = new AffiliatePresentationObjectConstructor();
                    affiliatePresentationObject.affiliateId = affiliate.affiliateId;
                    affiliatePresentationObject.affiliateCode = affiliate.affiliateCode;
                    affiliatePresentationObject.affiliateName = affiliate.affiliateName;
                    affiliatePresentationObject.displayName = affiliate.displayName ? affiliate.displayName : affiliate.affiliateName;
                    return affiliatePresentationObject;
                };

                _clientApi.call("accountContent/getAffiliates", "GET", {}, 'application/json')
                    .done((result) => {
                        const affiliates = result.data;
                        const affiliatePresentationObjects = [];

                        affiliates.forEach((affiliate) => {
                            const affiliatePresentationObject = buildAffiliatePresentationObject(affiliate);
                            affiliatePresentationObjects.push(affiliatePresentationObject);
                        });
                        promise.resolve(affiliatePresentationObjects);
                    });
            });
        };

        const _canEmployeeViewAccountDb = () => {
            return _employeeAuthorization.hasPermission("CRM.Developer");
        };

        return {
            init : init,

            canEmployeeViewAccountDb : _canEmployeeViewAccountDb,
            getAffiliates: _getAffiliates,
            getCustomerInformation : getCustomerInformation,
            getSignupForCustomerUrl : _getSignupForCustomerUrl,
            saveMemo : saveMemo,
            sendResetPassword : sendResetPassword,
            associateCall: associateCall
        };
});

