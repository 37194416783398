define('presentation/account/sidebar/validators/vanityAddValidator',[
    'constants/validationMessageEnumerations',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validPhoneNumberSpecification'
], function() {
    return function() {
        var self = this;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var _commonValidator = null;
        var _viewModel = null;
        var _facade = null;

        var ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
        var _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();
        var PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        var _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        self.registerViewModel = function(viewModel, facade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.sidebar.vanityAdd;
            var validationRules = new ValidationRulesConstructor();

            validationRules.array("phoneNumbers")
                .arrayField("phoneNumber")
                .addValidation(_isPhoneNumberAStringWithValue, validationMessageBase.phoneNumberRequired)
                .addValidation(_isValidPhoneNumber, validationMessageBase.phoneNumberInvalid)
                .addValidation(_isPhoneNumberNotOnThisAccount, validationMessageBase.phoneNumberOnThisAccount)
                .addValidation(_isPhoneNumberNotOnAnotherAccount, validationMessageBase.phoneNumberOnAnotherAccount)
                .addValidation(_isAllowedPhoneNumber, validationMessageBase.restrictedPhoneNumber);

            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };
        var _isPhoneNumberAStringWithValue = function(phoneNumber) {
            return _commonValidator.isStringWithValue(phoneNumber);
        };
        var _isValidPhoneNumber = function(phoneNumber) {
            return _validPhoneNumberSpecification.isSatisfiedBy(_phoneNumberFormatter.toNumbers(phoneNumber), "us");
        };
        var _isAllowedPhoneNumber = function(phoneNumber) {
            return _facade.isAllowedPhoneNumber(_viewModel.accountNumber, _phoneNumberFormatter.toNumbers(phoneNumber));
        };
        var _isPhoneNumberNotOnAnotherAccount = function(phoneNumber) {
            return _facade.isPhoneNumberNotOnAnotherAccount(_viewModel.accountNumber, _phoneNumberFormatter.toNumbers(phoneNumber));
        };
        var _isPhoneNumberNotOnThisAccount = function(phoneNumber) {
            return _facade.isPhoneNumberNotOnThisAccount(_viewModel.accountNumber, _phoneNumberFormatter.toNumbers(phoneNumber));
        };
    };
});

