define('presentation/release/viewModels/releaseViewModel',[
    'common/promises/promiseFactory',
    'presentation/navigation/facades/mainNavigationFacade',
    'presentation/release/viewModels/releaseSidebarViewModel',
    'businessServices/router/crmRouter'
    ], function() {
        const SIDEBAR_CONTENT_ITEMS = [
            {
                selection: "featureToggles",
                iconClass: "icon-nav-gear",
                title: "Manage Feature Toggles",
                permissionId: 'CRM.Release.FeatureToggles',
                viewModelPath: 'presentation/release/viewModels/releaseTogglesViewModel',
                urlParts: ["release", "featureToggles"],
                showInSidebar: true
            },
        ];

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _sidebarViewModel = null;
        let _sidebarPane = ko.observable(null);
        let _contentPane = ko.observable(null);
        let _router = null;
        let _isInitialized = false;

        let Constructor = function() {
            let self = this;

            self.sidebarPane = _sidebarPane;
            self.contentPane = _contentPane;

            self.activate = function(settingsSelection) {
                if (_isInitialized === false) {
                    _router = require('businessServices/router/crmRouter');
                }
                self.router = _router;

                return _initialize(settingsSelection);
            };

            const _initialize = function(settingsSelection) {
                let initializedPromise = _promiseFactory.deferIndefinitely();
                let contentPaneLoadedPromise = _promiseFactory.deferIndefinitely();

                if (_isInitialized === false) {
                    _isInitialized = true;

                    const SidebarViewModelConstructor = require('presentation/release/viewModels/releaseSidebarViewModel');
                    _sidebarViewModel = new SidebarViewModelConstructor();
                    _sidebarViewModel.sidebarConfig = SIDEBAR_CONTENT_ITEMS;
                    _sidebarViewModel.parentSettingsViewModel = self;
                    _sidebarViewModel.activate()
                        .done(() => {
                            _sidebarPane(_sidebarViewModel);
                            initializedPromise.resolve();
                        });
                } else {
                    initializedPromise.resolve();
                }

                initializedPromise.done(() => {
                    if (settingsSelection === null) {
                        contentPaneLoadedPromise.resolve();
                    } else {
                        let pathParts = settingsSelection.split("/");
                        let pathFirstPart = pathParts[0];
                        let pathSubsequentParts = pathParts.slice(1).join("/");

                        _sidebarViewModel.settingSelectionChanged(pathFirstPart);

                        let foundSidebarItem = SIDEBAR_CONTENT_ITEMS.find((item) => {
                            return (item.selection === pathFirstPart);
                        });

                        if (foundSidebarItem === undefined) {
                            _contentPane(null);
                            self.router.navigate('error', { trigger: true, replace: true });
                            contentPaneLoadedPromise.resolve();
                        } else {
                            require([foundSidebarItem.viewModelPath], function(ViewModelConstructor) {
                                let viewModel = new ViewModelConstructor();
                                viewModel.activate(pathSubsequentParts)
                                    .done(() => {
                                        _contentPane(viewModel);
                                        contentPaneLoadedPromise.resolve();
                                    });
                            });
                        }
                    }
                });

                return _promiseFactory.wait();
            };
        };


        let instance = new Constructor();
        return instance;
    });

