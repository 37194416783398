define('presentation/forgotPassword/validators/forgotPasswordValidator',['presentation/common/validation/validationRule',
        'constants/validationMessageEnumerations',
        'common/promises/promiseFactory',
        'common/specifications/validEmailAddressSpecification',
        'presentation/common/validation/commonValidator'], function() {
    return function() {
        var self = this;

        var _forgotPasswordViewModel = null;
        var _forgotPasswordFacade = null;
        var _commonValidator = null;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        var _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        self.registerViewModel = function(forgotPasswordViewModel, forgotPasswordFacade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.forgotPassword;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("emailAddress")
                                .addValidation(_validateEmailAddressRequired, validationMessageBase.emailAddressRequired)
                                .addValidation(_validateEmailAddressValid, validationMessageBase.emailAddressValid)
                                .addValidation(_validateEmailAddressExists, validationMessageBase.emailAddressNotFound);

            _commonValidator = new CommonValidatorConstructor(forgotPasswordViewModel, validationRules);
            _forgotPasswordViewModel = forgotPasswordViewModel;
            _forgotPasswordFacade = forgotPasswordFacade;
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateEmailAddressRequired = function(emailAddress) {
            return _commonValidator.isStringWithValue(emailAddress);
        };

        var _validateEmailAddressValid = function(emailAddress) {
            var trimmedEmailAddress = emailAddress.trim();
            return _validEmailAddressSpecification.isSatisfiedBy(trimmedEmailAddress);
        };

        var _validateEmailAddressExists = function(emailAddress) {
            return _promiseFactory.defer(function(deferredObject) {
                _forgotPasswordFacade.doesEmailAddressExist(emailAddress.trim())
                    .done(function(exists) {
                        deferredObject.resolve(exists);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };
    };
});

