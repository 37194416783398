/**
 Configuration for site navigation.  Configuration is used to associate URLs to viewModules
 and to provide enumerations for navigation items.

 settings = array of Navigation Objects:
 Navigation Object:
 {
     "pageTitle": 'Dashboard'        // Name to show in navigation
     "url": '/'                        // URL
     "isSecure": true                  // true | false - require user to be authenticated in order to view
     "viewModelPath": 'dashboardViewModel'  // Full path to viewModel to load for given URL
     "cssTemplate": TEMPLATE.CONTENT_ONLY
 }
 **/
define('settings/navigationConfiguration',[],function() {
    var TEMPLATE = {
        CONTENT_ONLY : "templateContent",
        HEADER_AND_CONTENT : "templateHeaderContent",
        SIDEBAR_HEADER_AND_CONTENT : "templateHeaderSidebarContent",
        HEADER_SIDEBAR_ACCOUNT_CONTENT: "templateHeaderSidebarAccountContent",
        HEADER_MARKETING_SIDEBAR_AND_CONTENT : "templateHeaderMarketingSidebarContent"
    };

    var routes = [
        {
            "routeId" : "login",
            "pageTitle": "Login",
            "url": "login(/:messageType)",
            "isSecure": false,
            "viewModelPath": "presentation/login/viewModels/loginViewModel",
            "cssTemplate": TEMPLATE.CONTENT_ONLY
        },
        {
            "routeId" : "forgotPassword",
            "pageTitle" : "Forgot Password",
            "url" : "forgotPassword",
            "isSecure" : false,
            "viewModelPath" : "presentation/forgotPassword/viewModels/forgotPasswordViewModel",
            "cssTemplate" : TEMPLATE.CONTENT_ONLY
        },
        {
            "routeId": "resetPassword",
            "pageTitle": "Reset Password",
            "url": "resetPassword/:emailAddress/:expirationDateTime/*signature",
            "isSecure" : false,
            "viewModelPath" : "presentation/forgotPassword/viewModels/resetPasswordViewModel",
            "cssTemplate" : TEMPLATE.CONTENT_ONLY
        },
        {
            "routeId" : "index",
            "pageTitle": "Home",
            "url": "",
            "isSecure": true,
            "viewModelPath": "presentation/shell/viewModels/indexViewModel",
            "cssTemplate": TEMPLATE.HEADER_AND_CONTENT
        },
        {
            "routeId" : "accountUsers",
            "pageTitle": "Account Users",
            "url": "account/:accountNumber/users",
            "isSecure": true,
            "viewModelPath" : "presentation/account/viewModels/usersViewModel",
            "cssTemplate" : TEMPLATE.HEADER_SIDEBAR_ACCOUNT_CONTENT
        },
        {
            "routeId" : "accountBilling",
            "pageTitle": "Account Billing",
            "url": "account/:accountNumber/billing",
            "isSecure": true,
            "viewModelPath" : "presentation/account/viewModels/billingViewModel",
            "cssTemplate" : TEMPLATE.HEADER_SIDEBAR_ACCOUNT_CONTENT
        },
        {
            "routeId" : "accountActivity",
            "pageTitle": "Account Activity",
            "url": "account/:accountNumber/activity",
            "isSecure": true,
            "viewModelPath" : "presentation/account/viewModels/activityViewModel",
            "cssTemplate" : TEMPLATE.HEADER_SIDEBAR_ACCOUNT_CONTENT
        },
        {
            "routeId" : "accountAcceptCall",
            "pageTitle": "Account",
            "url": "account/:accountNumber/:tabSelection/:callId/:phoneNumber",
            "isSecure": true,
            "viewModelPath" : "presentation/account/viewModels/accountViewModel",
            "cssTemplate" : TEMPLATE.HEADER_SIDEBAR_ACCOUNT_CONTENT
        },
        {
            "routeId" : "reports",
            "pageTitle": "Notation Reports",
            "url": "reports",
            "isSecure": true,
            "viewModelPath" : "presentation/reports/viewModels/reportsViewModel",
            "cssTemplate" : TEMPLATE.HEADER_AND_CONTENT
        },
        {
            "routeId" : "executeReport",
            "pageTitle": "Execute Report",
            "url": "reports/execute/:reportId",
            "isSecure": true,
            "viewModelPath" : "presentation/reports/viewModels/executeReportViewModel",
            "cssTemplate" : TEMPLATE.HEADER_AND_CONTENT
        },
        {
            "routeId" : "accountLookup",
            "pageTitle": "Account Lookup",
            "url": "accountLookup",
            "isSecure": true,
            "viewModelPath" : "presentation/accountLookup/viewModels/accountLookupViewModel",
            "cssTemplate" : TEMPLATE.HEADER_AND_CONTENT
        },
        {
            "routeId" : "settings",
            "pageTitle" : "Settings",
            "url" : "settings(/*selection)",
            "isSecure" : true,
            "viewModelPath" : "presentation/settings/viewModels/settingsViewModel",
            "cssTemplate" : TEMPLATE.SIDEBAR_HEADER_AND_CONTENT
        },
        {
            "routeId" : "marketing",
            "pageTitle" : "Marketing",
            "url" : "marketing",
            "isSecure" : true,
            "viewModelPath" : "presentation/marketing/viewModels/indexViewModel",
            "cssTemplate" : TEMPLATE.HEADER_MARKETING_SIDEBAR_AND_CONTENT
        },
        {
            "routeId" : "marketingEmailNotifications",
            "pageTitle" : "Email Notifications",
            "url" : "marketing/emailNotifications",
            "isSecure" : true,
            "viewModelPath" : "presentation/marketing/viewModels/emailNotificationsViewModel",
            "cssTemplate" : TEMPLATE.HEADER_MARKETING_SIDEBAR_AND_CONTENT
        },
        {
            "routeId" : "marketingAddEmailNotifications",
            "pageTitle" : "Add Email Notification Recipient",
            "url" : "marketing/emailNotifications/addRecipient",
            "isSecure" : true,
            "viewModelPath" : "presentation/marketing/viewModels/addEmailRecipientViewModel",
            "cssTemplate" : TEMPLATE.HEADER_MARKETING_SIDEBAR_AND_CONTENT
        },
        {
            "routeId" : "release",
            "pageTitle" : "Release",
            "url" : "release(/*selections)",
            "isSecure" : true,
            "viewModelPath" : "presentation/release/viewModels/releaseViewModel",
            "cssTemplate" : TEMPLATE.SIDEBAR_HEADER_AND_CONTENT
        },
        {
            "routeId" : "developer",
            "pageTitle" : "Developer",
            "url" : "developer(/*selections)",
            "isSecure" : true,
            "viewModelPath" : "presentation/developer/viewModels/developerViewModel",
            "cssTemplate" : TEMPLATE.SIDEBAR_HEADER_AND_CONTENT
        },
        {
            "routeId": "error",
            "pageTitle": "Error",
            "url": "error",
            "isSecure": false,
            "viewModelPath": "presentation/error/viewModels/errorViewModel",
            "cssTemplate": TEMPLATE.CONTENT_ONLY
        }

    ];

    var routesById = {};
    var routeIds = [];
    routes.forEach(function(route) {
        routesById[route.routeId] = route;
        routeIds.push(route.routeId);
    });

    return {
        "loginPageRouteUrl" : "login",
        "landingPageRouteUrl" : "/",
        "errorPageRouteUrl" : "error",
        "routeIds" : routeIds,
        "routesById": routesById,
        "defaultHeaderViewModelPath" : "presentation/navigation/viewModels/mainNavigationViewModel"
    };
});

