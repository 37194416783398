define('presentation/settings/facades/blacklistPhoneNumbersFacade',['persistence/webSocket/webSocketApp',
        'presentation/common/dateTimeValue'], function() {

    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');

        var _webSocketApp = null;
        var _phoneNumberFormatter = null;

        self.getAllBlackListedPhoneNumbers = function() {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {};
                _webSocketApp.send("get_blacklisted_phone_numbers", webSocketParams, function(blacklistPhoneNumbers) {
                    var blacklistPhoneNumbersObjects = blacklistPhoneNumbers.map(function(blacklistPhoneNumber) {
                        var formattedPhoneNumber = _phoneNumberFormatter.toInternational(blacklistPhoneNumber.phoneNumber, blacklistPhoneNumber.countryAbbreviation);
                        var addedDateTime = new DateTimeValueConstructor(blacklistPhoneNumber.addedDateTime);
                        var presentationObject = {
                            "phoneNumber": blacklistPhoneNumber.phoneNumber,
                            "formattedPhoneNumber": formattedPhoneNumber,
                            "addedDateTime": addedDateTime
                        };
                        return presentationObject;
                    });
                    deferredObject.resolve(blacklistPhoneNumbersObjects);
                });
            });
        };

        self.removePhoneNumberFromBlacklist = function(phoneNumber) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {phoneNumber: phoneNumber};
                _webSocketApp.send("remove_blacklist_phone_number", webSocketParams, function () {
                    deferredObject.resolve();
                });
            });
        };

        self.init = function() {
            var PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("blacklist_phone_numbers");
        };

        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});

