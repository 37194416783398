define(
    'presentation/account/viewModels/accountContentViewModel',[
        'presentation/common/window/windowControl',
        'common/promises/promiseFactory',
        'presentation/account/facades/accountFacade',
        'common/converters/accountNumberFormatter',
        'common/converters/phoneNumberFormatter',
        'common/url/urlFormatter',
        'presentation/account/viewModels/usersViewModel',
        'presentation/account/viewModels/billingViewModel',
        'presentation/account/viewModels/activityViewModel',
        'presentation/account/viewModels/accountContentViewModel',
        'presentation/account/sidebar/viewModels/accountSidebarViewModel',
        'presentation/account/activity/email/viewModels/emailViewModel',
        'presentation/common/modal',
        'presentation/promptDialog/viewModels/promptDialogViewModel',
        'businessServices/events/eventManager',
        'businessServices/router/crmRouter',
        'presentation/account/validators/patliveAccountNumberValidator',
        'presentation/account/presentationObjects/affiliatePresentationObject'
    ], function() {
        return function() {
            var self = this;
            var _accountNumber = null;
            var _callId = null;
            var _phoneNumber = null;
            var _facade = null;
            var _promptDialog = null;
            var _eventManager = null;
            var _queryStringArguments = null;
            var _router = null;
            var _validator = null;

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();

            var _urlFormatter = null;
            var _modalService = null;
            var _tabSelection = null;
            const _windowControl = require('presentation/common/window/windowControl');

            var EmailViewModelConstructor = null;

            const AffiliatePresentationObjectConstructor = require('presentation/account/presentationObjects/affiliatePresentationObject');

            var AccountNumberFormatterConstructor = require('common/converters/accountNumberFormatter');
            var _accountNumberFormatter = new AccountNumberFormatterConstructor();

            var PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            var _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            let _customerAccountDb = null;

            const _getAffiliates = () => {
                return _promiseFactory.defer((promise) => {
                    _facade.getAffiliates()
                        .done((affiliates) => {
                            self.affiliates = self.affiliates.concat(affiliates);
                            promise.resolve();
                        });
                });
            };

            var _initializeCustomerInformation = function() {
                return _promiseFactory.defer(function(promise){
                    _facade.getCustomerInformation(_accountNumber)
                        .done(function(customerInformationResult) {
                            self.title(customerInformationResult.title);
                            self.timeZone(customerInformationResult.timeZone);
                            self.memo(customerInformationResult.memo);
                            self.isSignupCustomerButtonVisible(customerInformationResult.isPotentialCustomerAccount);

                            if (customerInformationResult.addressLineOne !== undefined &&
                                customerInformationResult.addressLineOne !== null) {
                                self.fullAddress.push(customerInformationResult.addressLineOne);
                            }
                            if (customerInformationResult.addressLineTwo !== undefined &&
                                customerInformationResult.addressLineTwo !== null &&
                                customerInformationResult.addressLineTwo.length > 0) {
                                self.fullAddress.push(customerInformationResult.addressLineTwo);
                            }
                            if (customerInformationResult.city !== undefined &&
                                customerInformationResult.city !== null &&
                                customerInformationResult.stateProvince !== undefined &&
                                customerInformationResult.postalCode !== undefined) {
                                self.fullAddress.push((
                                    customerInformationResult.city + ', ' +
                                    customerInformationResult.stateProvince + ' ' +
                                    customerInformationResult.postalCode).trim()
                                );

                            } else if (customerInformationResult.postalCode !== undefined) {
                                self.fullAddress.push(customerInformationResult.postalCode);
                            }
                            if(customerInformationResult.defaultContactPhoneNumber !== undefined) {
                                self.fullAddress.push(_phoneNumberFormatter.toInternational(customerInformationResult.defaultContactPhoneNumber));
                            }

                            const matchingAffiliate = self.affiliates.find(affiliate => affiliate.affiliateId !== null && affiliate.affiliateCode === customerInformationResult.affiliateCode);
                            if (matchingAffiliate !== undefined) {
                                self.currentAffiliate("Referred by " + matchingAffiliate.displayName);
                            }
                            if (customerInformationResult.customerAccountDb){
                                _customerAccountDb =  customerInformationResult.customerAccountDb;
                            }

                            promise.resolve();
                        })
                        .fail(function(error){
                            promise.reject(error);
                        });
                });
            };

            var _buildAssociateCallConfirmationModalMessage  = function() {
                _promptDialog.masterClear();
                _promptDialog.setTitle('Associate Call');
                _promptDialog.addPrimaryButton('Associate Call', 'associate');
                _promptDialog.addButton('Cancel', 'cancel');
                _promptDialog.addLine("Are you sure you want to associate this call to this account?");
                _promptDialog.addEmptyLine();
            };

            var _buildAssociateCallErrorModalMessage  = function() {
                _promptDialog.masterClear();
                _promptDialog.setTitle('Associate Phone Number');
                _promptDialog.addButton('Close', 'cancel');
                _promptDialog.addLine("This call has already been associated to an account.");
                _promptDialog.addEmptyLine();
            };

            var _showAssociateCallErrorModalMessage = function() {
                return _promiseFactory.deferIndefinitely(function (deferredObject) {
                    _buildAssociateCallErrorModalMessage();
                    _promptDialog.showDialog()
                        .done(function() {
                            _promptDialog.complete();
                            deferredObject.resolve();
                        })
                        .fail(function(error) {
                            throw error;
                        });
                });
            };

            var _setTabSelection = function() {
                if(_tabSelection === undefined) {
                    var params = _router.activeInstruction().params;
                    _tabSelection = params[1];
                    switch(_tabSelection) {
                        case "users" :
                            self.selectUsersTab();
                            break;
                        case "billing" :
                            self.selectBillingTab();
                            break;
                        case "activity" :
                            self.selectActivityTab();
                            break;
                        default :
                            throw new Error("No tab selected");
                    }
                } else {
                    switch(_tabSelection) {
                        case "users" :
                            self.isUsersSelected(true);
                            break;
                        case "billing" :
                            self.isBillingSelected(true);
                            break;
                        case "activity" :
                            self.isActivitySelected(true);
                            break;
                        default :
                            throw new Error("No tab selected");
                    }
                }
            };

            var _onActiveItemChange = function() {
                var routeId = _router.activeInstruction().config.customSettings.routeId;
                switch (routeId) {
                    case "accountUsers":
                        self.isUsersSelected(true);
                        self.isBillingSelected(false);
                        self.isActivitySelected(false);
                        break;
                    case "accountBilling":
                        self.isBillingSelected(true);
                        self.isUsersSelected(false);
                        self.isActivitySelected(false);
                        break;
                    case "accountActivity":
                        self.isActivitySelected(true);
                        self.isUsersSelected(false);
                        self.isBillingSelected(false);
                        break;
                    default:
                        break;
                }
            };

            self.sendEmail = function() {
                var emailViewModel = new EmailViewModelConstructor(_accountNumber);
                _modalService.showModal(emailViewModel);

            };

            self.canEmployeeViewAccountDb = false;

            self.formattedAccountNumber = ko.observable();
            self.title = ko.observable();
            self.fullAddress = ko.observableArray();
            self.timeZone = ko.observable();
            self.memo = ko.observable();
            self.memoCopy = ko.observable();
            self.usersUrl = ko.observable();
            self.activityUrl = ko.observable();
            self.billingUrl = ko.observable();

            self.selectedTab = ko.observable();
            self.isActivitySelected = ko.observable();
            self.isBillingSelected = ko.observable();
            self.isUsersSelected = ko.observable();
            self.isMemoInEditMode = ko.observable(false);
            self.isAssociateCallButtonVisible = ko.observable(false);
            self.isSignupCustomerButtonVisible = ko.observable(false);
            self.patliveAccountNumber = ko.observable();
            self.currentAffiliate = ko.observable();

            self.affiliates = [];
            self.selectedAffiliate = ko.observable();

            self.selectUsersTab = function() {
                return _promiseFactory.defer(function(deferredObject){
                    _router.navigate("/account/" + _accountNumber + "/users");
                    deferredObject.resolve();
                });
            };
            self.selectBillingTab = function() {
                return _promiseFactory.defer(function(deferredObject){
                    _router.navigate("/account/" + _accountNumber + "/billing");
                    deferredObject.resolve();
                });
            };
            self.selectActivityTab = function() {
                return _promiseFactory.defer(function(deferredObject){
                    _router.navigate("/account/" + _accountNumber + "/activity");
                    deferredObject.resolve();
                });
            };

            self.editMemo = function() {
                return _promiseFactory.defer(function (deferredObject) {
                    self.memoCopy(self.memo());
                    self.isMemoInEditMode(true);
                    deferredObject.resolve();
                });
            };

            self.cancelMemo = function() {
                return _promiseFactory.defer(function (deferredObject) {
                    self.memo(self.memoCopy());
                    self.isMemoInEditMode(false);
                    deferredObject.resolve();
                });
            };

            self.saveMemo = function() {
                return _promiseFactory.defer(function (deferredObject) {
                    self.isMemoInEditMode(false);
                    _facade.saveMemo(_accountNumber, self.memo())
                        .done(function(){
                            self.memoCopy(self.memo());
                            deferredObject.resolve();
                        })
                        .fail(function (error) {
                            throw error;
                        });
                });
            };

            self.launchAssociateCallConfirmation = function() {
                return _promiseFactory.deferIndefinitely(function (deferredObject) {
                    _promptDialog.showDialog()
                        .done(function() {
                            if (_promptDialog.userSelection === 'associate') {
                                _facade.associateCall(_accountNumber, _callId, _phoneNumber)
                                    .done(function(result) {
                                        _eventManager.publishCapturedPhoneNumberListUpdated();
                                        self.isAssociateCallButtonVisible(false);
                                        _promptDialog.complete();
                                        deferredObject.resolve();

                                        if (result.status === "call_already_captured") {
                                            _showAssociateCallErrorModalMessage();
                                        }
                                    })
                                    .fail(function(error) {
                                        error.accountNumber = _accountNumber;
                                        error.phoneNumber = _phoneNumber;
                                        deferredObject.resolve();
                                        throw error;
                                    });
                            } else {
                                _promptDialog.complete();
                                deferredObject.resolve();
                            }
                        })
                        .fail(function(error) {
                            throw error;
                        });
                });
            };

            self.getSignupForCustomerUrl = function() {
                return _promiseFactory.defer(function(promise) {
                    _facade.getSignupForCustomerUrl(self.selectedAffiliate().affiliateCode, self.patliveAccountNumber())
                        .done(function (result) {
                            if (result.status === "success") {
                                promise.resolve(result.url);

                            } else {
                                var err = new Error("Signup customer URL fetch failed");
                                err.result = result;
                                promise.reject(err);
                            }
                        })
                        .fail(function (error) {
                            promise.reject(error);
                        });
                });

            };

            self.navigateToCouchDb = () => {
                _windowControl.openNewWindow(_urlFormatter.buildCouchAccountDbUrl(_customerAccountDb), "_blank");
            };


            self.activate = function(accountNumber, tabSelection, queryStringArguments) {
                _accountNumber = accountNumber;
                _tabSelection = tabSelection;
                if(queryStringArguments !== undefined && queryStringArguments !== null) {
                    _queryStringArguments = queryStringArguments;
                    _callId = _queryStringArguments.callId;
                    _phoneNumber = _queryStringArguments.phoneNumber;
                }
                _facade = require('presentation/account/facades/accountFacade');
                _urlFormatter = require('common/url/urlFormatter');

                var PromptsDialogViewModelConstructor = require('presentation/promptDialog/viewModels/promptDialogViewModel');
                _promptDialog = new PromptsDialogViewModelConstructor();

                var ValidatorConstructor = require('presentation/account/validators/patliveAccountNumberValidator');
                _validator = new ValidatorConstructor();

                _eventManager = require('businessServices/events/eventManager');

                _router = require('businessServices/router/crmRouter');

                return _initialize();
            };

            self.activate2 = function (validator) {
                _validator = validator;

                return _initialize();
            };

            var _initialize = function() {
                _facade.init();
                _validator.registerViewModel(self, _facade);

                const nonAffiliatePresentationObject = new AffiliatePresentationObjectConstructor();
                nonAffiliatePresentationObject.displayName = "Tresta Customer";
                self.affiliates.push(nonAffiliatePresentationObject);
                self.selectedAffiliate(nonAffiliatePresentationObject);

                self.formattedAccountNumber(_accountNumberFormatter.formatAccountNumber(_accountNumber));
                self.usersUrl = _urlFormatter.buildWorkSessionUrl(["account", _accountNumber, "users"]);
                self.activityUrl = _urlFormatter.buildWorkSessionUrl(["account", _accountNumber, "activity"]);
                self.billingUrl = _urlFormatter.buildWorkSessionUrl(["account", _accountNumber, "billing"]);
                _promptDialog.activate();
                _buildAssociateCallConfirmationModalMessage();
                _getAffiliates()
                    .done(() => {
                        _initializeCustomerInformation()
                            .done(function(){
                                _setTabSelection();
                                _facade.canEmployeeViewAccountDb()
                                    .done((canEmployeeViewAccountDb) => {
                                        self.canEmployeeViewAccountDb = canEmployeeViewAccountDb;
                                    });
                            });
                    });

                if (_callId && _phoneNumber) {
                    self.isAssociateCallButtonVisible(true);
                }
                _router.activeItem.subscribe(_onActiveItemChange);

                return _promiseFactory.wait();
            };
        };
    });

