define('businessServices/router/plugins/authenticationRouterPlugin',[
    'settings/navigationConfiguration',
    'businessServices/authentication/authenticatedEmployee',
    'constants/statusConstants'
], function(
    navigationConfiguration,
    authenticatedEmployee,
    statusConstants
) {

    function isRouteSecure(routeId) {
        var routeInfo = navigationConfiguration.routesById[routeId];
        if (routeInfo === undefined) {
            return false;
        }
        return routeInfo.isSecure;
    }

    function authenticationRouterPlugin(routeId) {
        var isAuthenticated = authenticatedEmployee.isAuthenticated();
        if (isAuthenticated === true ) {
            return ({status: statusConstants.success});
        }

        if (isRouteSecure(routeId) === true) {
            return ({status: statusConstants.failed});
        }

        return ({status: statusConstants.ignored});
    }

    return {
        configureAuthorization: authenticationRouterPlugin
    };
});

