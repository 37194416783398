define('businessServices/router/plugins/workSessionRouterPlugin',['common/storage/workSessionIdStorage', 'businessServices/authentication/authenticationProvider', 'common/promises/promiseFactory'], function(workSessionIdStorage, AuthenticationProviderConstructor, PromiseFactoryConstructor) {
    var _workSessionIdStorage = workSessionIdStorage;

    function workSessionRedirect(urlFragment) {
        var parts = urlFragment.split("/");
        if (parts.length >= 2) {
            if (parts[0] === "ws") {
                var workSessionId = parts[1];
                var currentWorkSessionId = _workSessionIdStorage.getWorkSessionId();

                var newParts = parts.slice(2);
                var newPath = newParts.join("/");
                var newRouteInfo = { routeUrl : newPath };
                if (workSessionId !== currentWorkSessionId) {
                    var promiseFactory = new PromiseFactoryConstructor();
                    return promiseFactory.defer(function(promise) {
                        var authenticationProvider = new AuthenticationProviderConstructor();
                        authenticationProvider.init();
                        authenticationProvider.setWorkSessionId(workSessionId)
                            .done(function() {
                                promise.resolve(newRouteInfo);
                            })
                            .fail(function(error) {
                                promise.reject(error);
                            });
                    });

                } else {
                    return newRouteInfo;
                }
            }
        }
        return true;
    }

    return {
        loadUrlRedirect: workSessionRedirect
    };
});

