define('businessServices/pathProviders/urlFormatter',['common/storage/workSessionIdStorage'],
    function() {
        var _workSessionIdStorage = require('common/storage/workSessionIdStorage');

        function buildWorkSessionUrl(pathComponents) {
            var workSessionId = _workSessionIdStorage.getWorkSessionId();

            var path;
            if (workSessionId === undefined) {
                path = pathComponents.join("/");
            } else {
                var sessionPath = ["ws", workSessionId].concat(pathComponents);
                path = sessionPath.join("/");
            }

            return path;
        }

        function buildUrl(pathComponents) {
            var path = pathComponents.join("/");
            return path;
        }

        return {
            buildWorkSessionUrl : buildWorkSessionUrl,
            buildUrl : buildUrl
        };
    });

