define('presentation/developer/facades/developerSidebarFacade',['common/promises/promiseFactory', 'businessServices/authentication/employeeAuthorization'], function() {

    return function(){
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _employeeAuthorization = null;

        self.hasPermission = function(permissionId) {
            return _promiseFactory.defer(function(promise) {
                _employeeAuthorization.hasPermission(permissionId)
                    .done(function(hasPermission) {
                        promise.resolve(hasPermission);
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            });
        };

        self.init = function(promiseFactory) {
            _employeeAuthorization = require('businessServices/authentication/employeeAuthorization');
            _promiseFactory = promiseFactory;
        };
    };
});

