define('presentation/account/sidebar/validators/retirePhoneNumberValidator',[
    'constants/validationMessageEnumerations',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validPhoneNumberSpecification'
], function() {
    return function() {
        var self = this;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var _commonValidator = null;
        var _viewModel = null;
        var _accountNumber = null;

        var ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
        var _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();
        var PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        var _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        self.registerViewModel = function(viewModel, accountNumber) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.sidebar.retireNumber;
            var validationRules = new ValidationRulesConstructor();

            validationRules.field("retirePhoneNumber")
                .addValidation(_isPhoneNumberAStringWithValue, validationMessageBase.phoneNumberRequired)
                .addValidation(_isValidPhoneNumber, validationMessageBase.phoneNumberInvalid)
                .addValidation(_isPhoneNumberOnThisAccount, validationMessageBase.phoneNumberOnThisAccount);

            _viewModel = viewModel;
            _accountNumber = accountNumber;
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };
        var _isPhoneNumberAStringWithValue = function(phoneNumber) {
            return _commonValidator.isStringWithValue(phoneNumber);
        };
        var _isValidPhoneNumber = function(phoneNumber) {
            return _validPhoneNumberSpecification.isSatisfiedBy(_phoneNumberFormatter.toNumbers(phoneNumber), "us");
        };
        var _isPhoneNumberOnThisAccount = function(phoneNumber) {
            var isOnAccount = _viewModel.phoneNumbers().find((number) => number.phoneNumber === _phoneNumberFormatter.toEOneSixFour(phoneNumber));
            return isOnAccount !== undefined;
        };
    };
});

