define('presentation/account/sidebar/presentationObjects/paymentMethodPresentationObject',[],function () {
    return function () {
        var self = this;
        self.paymentMethodId = null;
        self.cardHolderName = null;
        self.cardType = null;
        self.lastFourDigits = null;
        self.expirationDate = null;
        self.isDefault = null;
        self.isExpired = null;
        self.description = null;
        self.defaultIndicatorCss = ko.computed(() => self.isDefault ?
            'payment-method__default-card-indicator--is-default' :
            'payment-method__default-card-indicator--make-default');
        self.cssStyles = null;
    };
});
