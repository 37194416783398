define('presentation/login/viewModels/loginViewModel',['common/promises/promiseFactory',
        'businessServices/router/crmRouter',
        'businessServices/authentication/authenticationProvider',
        'presentation/login/validators/loginViewModelValidator',
        'constants/validationMessageEnumerations',
        'constants/statusConstants',
        'settings/navigationConfiguration'
], function(){

    return function() {
        var self = this;
        var _authenticationProvider = null;
        var _router = null;
        var _validator = null;
        var _messageType = null;
        var _navigationConfiguration = require('settings/navigationConfiguration');
        var _statusConstants = require('constants/statusConstants');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');
        var _validationMessageBase = _validationMessageEnumerations.webcrm.presentation.login;

        self.username = ko.observable('');
        self.password = ko.observable('');
        self.isResetPasswordConfirmationMessageVisible = ko.observable(false);
        self.isNotAuthenticated = ko.observable(true);

        self.onLogInClicked = function() {
            return _promiseFactory.defer(function (deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        self.isResetPasswordConfirmationMessageVisible(false);

                        if(isValid === true ){
                            _authenticationProvider.logIn(self.username(), self.password())
                                .done(function(result) {
                                    switch (result.status) {
                                        case _statusConstants.success:
                                            _router.navigate(_navigationConfiguration.landingPageRouteUrl);
                                            break;
                                        case _statusConstants.invalidEmployeeName:
                                            self.username.isValid(false);
                                            self.username.validationMessage(_validationMessageBase.usernameValid);
                                            break;
                                        case _statusConstants.employeeInactive:
                                            self.username.isValid(false);
                                            self.username.validationMessage(_validationMessageBase.usernameEnabled);
                                            break;
                                        case _statusConstants.invalidPassword:
                                            self.password.isValid(false);
                                            self.password.validationMessage(_validationMessageBase.passwordValid);
                                            break;
                                    }
                                    deferredObject.resolve();
                                })
                                .fail(function(error) {
                                    deferredObject.reject(error);
                                });

                        } else {
                            deferredObject.resolve();
                        }
                    })
                    .fail(function(error) {
                        // Don't log the password, because that could get leaked.
                        error.userName = self.username();
                        deferredObject.reject(error);
                    });
            });

        };

        self.activate = function(messageType) {
            _router = require('businessServices/router/crmRouter');

            var AuthenticationProviderConstructor = require('businessServices/authentication/authenticationProvider');
            _authenticationProvider = new AuthenticationProviderConstructor();
            _authenticationProvider.init();

            var ValidatorConstructor = require('presentation/login/validators/loginViewModelValidator');
            _validator = new ValidatorConstructor();

            if (messageType) {
                _messageType = messageType;
            }

            return _initialize();
        };

        self.activate2 = function(router, authenticationProvider, validator, messageType) {
            _router = router;
            _authenticationProvider = authenticationProvider;
            _validator = validator;

            if (messageType) {
                _messageType = messageType;
            }

           return _initialize();
        };

        var _initialize = function() {
            _validator.registerViewModel(self);

            if (_messageType === "resetPasswordConfirm") {
                self.isResetPasswordConfirmationMessageVisible(true);
            } else {
                self.isResetPasswordConfirmationMessageVisible(false);
            }

            return _promiseFactory.wait();
        };

   };
});

