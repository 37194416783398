define('presentation/common/crmDataGrid/viewModels/crmDataGridViewModel',[],
function() {
    return function() {
        var self = this;
        var _settings = null;
        var _selectRowCallback = null;
        var _sortColumnCallback = null;
        var _currentSortColumn = null;
        var _cellCssFormat = null;

        var _configureColumnSortingClass = function(column) {
            if (column.isSortable === true) {
                return ko.computed(function() {
                    var sortClass = "";

                    switch (column.sortAsc()) {
                        case true:
                            sortClass = "sort-asc";
                            break;
                        case false:
                            sortClass = "sort-desc";
                            break;
                    }

                    return sortClass;
                });
            } else {
                return "not-sortable";
            }
        };

        self.dataSet = ko.observableArray([]);
        self.columns = [];
        self.onGetNextPage = null;
        self.allRowsVisible = ko.observable(false);
        self.hasRowDetails = ko.observable(true);

        self.sortColumn = function(columnToSort) {
            if (columnToSort.isSortable === false) {
                return;
            }

            var sortAsc = true;
            if (_currentSortColumn !== null && _currentSortColumn.key === columnToSort.key && columnToSort.sortAsc() === true) {
                sortAsc = false;
            }

            if (_sortColumnCallback !== null) {

                _sortColumnCallback(columnToSort.key, sortAsc)
                    .done(function() {
                        self.columns.forEach(function(column) {
                            if (column.key === columnToSort.key) {
                                column.sortAsc(sortAsc);
                            } else {
                                column.sortAsc(null);
                            }
                        });

                        _currentSortColumn = columnToSort;
                    });

            }
        };

        self.getColumnText = function(column, row) {
            const columnText = row[column.key];
            if (ko.isObservable(columnText)) {
                return columnText();
            } else {
                return columnText;
            }
        };

        self.selectRow = function(row) {
            if (_selectRowCallback !== null) {
                _selectRowCallback(row);
            }
        };

        self.getSummaryRowCss = function(index) {
            var baseCss = "summary-row";

            if (index() % 2 === 0) {
                return baseCss + " summary-row-even";
            } else {
                return baseCss + " summary-row-odd";
            }
        };

        self.getDetailsRowCss = function(detailRow, index) {
            var baseCss = "details-row";
            if (detailRow.isDetailsVisible()) {
                baseCss += " show";
            }

            if (index() % 2 === 0) {
                return baseCss + " details-row-even";
            } else {
                return baseCss + " details-row-odd";
            }
        };

        self.getColumnCss = function(column, row) {
            if (_cellCssFormat === null) {
                return;
            }

            return _cellCssFormat(column, row);
        };

        self.activate = function(settings) {
            _settings = settings;
            return _initialize();
        };

        self.activate2 = function(settings) {
            _settings = settings;

            return _initialize();
        };

        var _initialize = function() {
            if (_settings.config !== undefined) {
                if (_settings.config.dataSet !== undefined) {
                    if (ko.isObservable(_settings.config.dataSet)) {
                        self.dataSet = _settings.config.dataSet;
                    } else {
                        self.dataSet(_settings.config.dataSet);
                    }
                } else {
                    throw new Error("`dataSet` missing from config.");
                }

                if (_settings.config.allRowsVisible !== undefined) {
                    if (ko.isObservable(_settings.config.allRowsVisible)) {
                        self.allRowsVisible = _settings.config.allRowsVisible;
                    } else {
                        self.allRowsVisible(_settings.config.allRowsVisible);
                    }
                }

                if (_settings.config.hasRowDetails !== undefined) {
                    if (ko.isObservable(_settings.config.hasRowDetails)) {
                        self.hasRowDetails = _settings.config.hasRowDetails;
                    } else {
                        self.hasRowDetails(_settings.config.hasRowDetails);
                    }
                }

                if (_settings.config.selectRowCallback !== undefined) {
                    _selectRowCallback = _settings.config.selectRowCallback;
                }

                if (_settings.config.sortColumnCallback !== undefined) {
                    _sortColumnCallback = _settings.config.sortColumnCallback;
                }

                if (_settings.config.cellCssFormat !== undefined) {
                    _cellCssFormat = _settings.config.cellCssFormat;
                }

                if (_settings.config.onGetNextPage !== undefined) {
                    self.onGetNextPage = _settings.config.onGetNextPage;
                }

                if (_settings.config.columns !== undefined) {
                    self.columns = _settings.config.columns;

                    self.columns.forEach(function(column) {
                        var initialSortedAsc = null;
                        if (ko.isObservable(column.sortAsc)) {
                            initialSortedAsc = column.sortAsc();
                            _currentSortColumn = column;
                        } else {
                            initialSortedAsc = column.sortAsc;
                            _currentSortColumn = column;
                        }
                        
                        column.sortAsc = ko.observable(initialSortedAsc);
                        column.sortingClass = _configureColumnSortingClass(column);
                    });
                } else {
                    throw new Error("`columns` missing from config.");
                }
            } else {
                throw new Error("A `config` object is required.");
            }
        };
    };
});

