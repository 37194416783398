define('presentation/settings/facades/potentialCustomerFacade',['common/promises/promiseFactory',
        'persistence/webSocket/webSocketApp'],
    function() {
        var _promiseFactory = null;
        var _webSocketApp = null;

        var _getPotentialCustomer = function() {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {};
                _webSocketApp.send("get_potential_customer", params, function(potentialCustomer) {
                    deferredObject.resolve(potentialCustomer);
                });
            });
        };

        var _isAccountNumberValid = function(accountNumber) {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {"account_number": accountNumber};
                _webSocketApp.send("validate_account_number", params, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        var _addPotentialCustomer = function(accountNumber) {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {"account_number": accountNumber.trim()};
                _webSocketApp.send("add_potential_customer", params, function(potentialCustomerResult) {
                    deferredObject.resolve(potentialCustomerResult);
                });
            });
        };

        var _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("crm_potential_customer");
        };

        var _init2 = function(promiseFactory) {
            _promiseFactory = promiseFactory;
        };

        return function() {
            var self = this;
            self.isAccountNumberValid = _isAccountNumberValid;
            self.addPotentialCustomer = _addPotentialCustomer;
            self.getPotentialCustomer = _getPotentialCustomer;
            self.init = _init;
            self.init2 = _init2;
        };
    });

