define('businessServices/events/eventManager',['common/uniqueId/uniqueIdGenerator'], function() {

    var UniqueNameGenerator = require('common/uniqueId/uniqueIdGenerator');
    var _uniqueNameGenerator = new UniqueNameGenerator();

    var EventManagerSingleton = function() {
        if (EventManagerSingleton.prototype._singletonInstance) {
            return EventManagerSingleton.prototype._singletonInstance;
        }
        var self = this;
        EventManagerSingleton.prototype._singletonInstance = self;

        var _events = [];

        var _findEvent = function(topicName) {
            for (var x = 0; x < _events.length; x++) {
                var currentEvent = _events[x];
                if (currentEvent.topicName === topicName) {
                    return currentEvent;
                }
            }
            return null;
        };

        var _getOrAddEvent = function(topicName, eventId) {
            var currentEvent = _findEvent(topicName);
            if (currentEvent === null) {
                var newEvent = {
                    topicName : topicName,
                    subscribedCallbacks : [],
                    eventId: eventId

                };
                _events.push(newEvent);
                return newEvent;
            } else {
                return currentEvent;
            }
        };

        var _subscribe = function(topicName, callbackFunction) {
            var uniqueId = _uniqueNameGenerator.generateUniqueIdWithoutHyphens();
            var currentEvent = _getOrAddEvent(topicName, uniqueId);

            currentEvent.subscribedCallbacks.push({"callback": callbackFunction, "id": uniqueId});
            return uniqueId;
        };

        var _publish = function(topicName, eventData) {
            var currentEvent = _findEvent(topicName);
            if (currentEvent === null) {
                return;
            }
            for (var x = 0; x < currentEvent.subscribedCallbacks.length; x++) {
                var currentCallback = currentEvent.subscribedCallbacks[x].callback;
                    currentCallback(eventData);

            }
        };

        var EVENT_NAME_EMPLOYEE_AUTHENTICATED = "EVENT_NAME_EMPLOYEE_AUTHENTICATED";
        var EVENT_NAME_AUTHENTICATION_EXPIRED = "authentication_token_expired";
        var EVENT_NAME_SHELL_UPDATED = "shell_updated";
        var EVENT_CAPTURED_PHONE_NUMBER_LIST_UPDATED = "EVENT_CAPTURED_PHONE_NUMBER_LIST_UPDATED";

        self.unsubscribe = function(callbackFunctionId) {
            for(var x = 0; x < _events.length; x++) {
                for(var i = 0; i < _events[x].subscribedCallbacks.length; i++) {
                    if(_events[x].subscribedCallbacks[i].id === callbackFunctionId) {
                        _events[x].subscribedCallbacks.splice(i, 1);
                        break;
                    }
                }
            }
        };

        self.publishEmployeeAuthenticated = function(isAuthenticated) {
            _publish(EVENT_NAME_EMPLOYEE_AUTHENTICATED, isAuthenticated);
        };

        self.subscribeEmployeeAuthenticated = function(callbackFunction) {
            return _subscribe(EVENT_NAME_EMPLOYEE_AUTHENTICATED, callbackFunction);
        };

        self.publishAuthenticationExpired = function() {
            _publish(EVENT_NAME_AUTHENTICATION_EXPIRED, null);
        };

        self.subscribeAuthenticationExpired = function(callbackFunction) {
            return _subscribe(EVENT_NAME_AUTHENTICATION_EXPIRED, callbackFunction);
        };

        self.publishCapturedPhoneNumberListUpdated = function() {
            _publish(EVENT_CAPTURED_PHONE_NUMBER_LIST_UPDATED, null);
        };

        self.subscribeCapturedPhoneNumberListUpdated = function(callbackFunction) {
            return _subscribe(EVENT_CAPTURED_PHONE_NUMBER_LIST_UPDATED, callbackFunction);
        };
    };

    return new EventManagerSingleton();
});

