define('presentation/settings/viewModels/addNotationReportViewModel',['common/promises/promiseFactory',
        'plugins/router',
        'presentation/settings/facades/addNotationReportFacade',
        'presentation/settings/validators/addNotationReportValidator'],
function() {
    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _facade = null;
        var _validator = null;
        var _router = null;

        self.notationReportId = null;
        self.formTitle = "New Notation Report";
        self.saveButtonTitle = "Create";
        self.reportName = ko.observable("");
        self.notationCategoriesEvents = ko.observableArray([]);
        self.selectedNotationEvents = ko.observableArray([]);
        self.eventItemIds = [];
        self.selectedNotationCategoriesAndEvents = ko.computed(function() {
            return self.selectedNotationEvents();
        });

        self.saveButtonClick = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid === false) {
                            deferredObject.resolve(false);
                            return;
                        }

                        var reportName = self.reportName();
                        var selectedEvents = self.selectedNotationEvents();

                        if(self.notationReportId === "") {
                            self.notationReportId = null;
                        }

                        _facade.saveNotationReport(self.notationReportId, reportName, selectedEvents, self.eventItemIds)
                            .done(function() {
                                _router.navigate("/settings/notationReport");
                                deferredObject.resolve();
                            })
                            .fail(function(error) {
                                deferredObject.reject(error);
                            });
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.cancelForm = function() {
            _router.navigate("/settings/notationReport");
        };

        self.activate = function(notationReportId) {
            self.notationReportId = notationReportId;

            _facade = require('presentation/settings/facades/addNotationReportFacade');
            _facade.init(_promiseFactory);

            var ValidatorConstructor = require('presentation/settings/validators/addNotationReportValidator');
            _validator = new ValidatorConstructor();

            _router = require('plugins/router');

            return _initialize();
        };

        self.activate2 = function(notationReportId, facade, validator, router) {
            self.notationReportId = notationReportId;
            _facade = facade;
            _validator = validator;
            _router = router;

            return _initialize();
        };

        var _initialize = function() {
            _facade.getNotationCategoryEventList()
                .done(function(notationCategoryEventList) {
                    self.notationCategoriesEvents(notationCategoryEventList);
                    if (self.notationReportId) {
                        self.formTitle = "Edit Notation Report";
                        self.saveButtonTitle = "Save";
                       _facade.getNotationReport(self.notationReportId)
                           .done(function (notationReport) {
                               self.reportName(notationReport.reportName);
                               notationReport.events.forEach(function(event) {
                                   self.selectedNotationEvents.push(event.notationEventId);
                               });
                               self.eventItemIds = notationReport.events;
                               _validator.registerViewModel(self, _facade);
                           });
                    } else {
                        _validator.registerViewModel(self, _facade);
                    }
                })
                .fail(function(error) {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

