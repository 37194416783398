define('presentation/release/facades/releaseTogglesFacade',[
        'common/promises/promiseFactory',
        'persistence/webSocket/webSocketApp',
        'presentation/release/presentationObjects/releaseTogglePresentationObject'
    ], function() {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = null;
        let _webSocketApp = null;

        const ReleaseTogglePresentationObject = require('presentation/release/presentationObjects/releaseTogglePresentationObject');

        const _getReleaseToggles = () => {
            return _promiseFactory.defer((promise) => {
                _webSocketApp.send("get_release_toggles", {}, function(result) {
                    let formattedReleaseToggles = _formatReleaseToggles(result);
                    promise.resolve(formattedReleaseToggles);
                });
            });
        };

        const _formatReleaseToggles = (releaseToggles) => {
            let formattedReleaseToggles = [];
            releaseToggles.map((releaseToggle) => {
                let formattedReleaseToggle = new ReleaseTogglePresentationObject();
                formattedReleaseToggle.featureToggleId = releaseToggle.featureToggleId;
                formattedReleaseToggle.name = releaseToggle.name;
                formattedReleaseToggle.description = releaseToggle.description;
                formattedReleaseToggle.isOnForAll(releaseToggle.isOnForAll);
                if (releaseToggle.isOnForAll === false) {
                    formattedReleaseToggle.accounts(releaseToggle.accounts);
                }
                formattedReleaseToggles.push(formattedReleaseToggle);
            });
            return formattedReleaseToggles;
        };

        const _saveFeatures = (releaseToggles) => {
            return _promiseFactory.defer((promise) => {
                let returnReleaseToggles = {
                    releaseToggles: releaseToggles
                        .filter(t => t.isChanged())
                        .map((releaseToggle) => {
                            return {
                                featureToggleId: releaseToggle.featureToggleId,
                                isOnForAll: releaseToggle.isOnForAll()
                            };
                    })
                };
                _webSocketApp.send("save_release_toggles", returnReleaseToggles, function(result) {
                    promise.resolve(result);
                });
            });
        };

        const _removeFeatureFromAccount = function(featureToggleId, accountId) {
            return _promiseFactory.defer(function(promise) {
                let webSocketParams = {
                    feature_toggle_id: featureToggleId,
                    account_id: accountId
                };
                _webSocketApp.send("remove_feature_from_account", webSocketParams, function(result) {
                    promise.resolve(result);
                });
            });
        };


        const _init = function(){
                _promiseFactory = new PromiseFactoryConstructor();

                const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
                _webSocketApp = new WebSocketAppConstructor();
                _webSocketApp.init("release_toggles");
            };

            return function() {
                let self = this;
                self.getReleaseToggles = _getReleaseToggles;
                self.saveFeatures = _saveFeatures;
                self.removeFeatureFromAccount = _removeFeatureFromAccount;

                self.init = _init;
            };
        });


