define('presentation/marketing/presentationObjects/bulletPresentationObject',[],
function() {
    return function() {
        var self = this;

        self.bulletId = null;
        self.bullet = ko.observable("");
    };
});

