define('businessServices/router/crmRouterPluginManager',[
    'businessServices/router/authorizationPlugins/fromPccAuthorizationPlugin',
    'businessServices/router/plugins/fromPccRouterPlugin',
    'businessServices/router/plugins/authenticationRouterPlugin',
    'businessServices/router/plugins/workSessionRouterPlugin',
    'businessServices/router/plugins/redirectRouterPlugin',
    'businessServices/router/plugins/unknownRoutePlugin',
    'businessServices/router/authorizationPlugins/authorizationPlugin',
    'businessServices/router/plugins/fromClientUIRouterPlugin',
    'businessServices/router/authorizationPlugins/fromClientUiAuthorizationPlugin'
], function(
    fromPccAuthorizationPlugin,
    fromPccRouterPlugin,
    authenticationRouterPlugin,
    workSessionRouterPlugin,
    redirectRouterPlugin,
    unknownRoutePlugin,
    authorizationPlugin,
    fromClientUIPlugin,
    fromClientUIAuthorizationPlugin
) {

    // Note: the order of these is important.
    var plugins = [
        fromPccRouterPlugin,
        fromClientUIPlugin,
        authorizationPlugin,
        workSessionRouterPlugin,
        redirectRouterPlugin,
        unknownRoutePlugin
    ];

    // Note: the order of these is important.
    var authorizationPlugins = [
        fromPccAuthorizationPlugin,
        fromClientUIAuthorizationPlugin,
        authenticationRouterPlugin
    ];

    function getPluginConfigurations() {
        var allRouteGuards = [];
        var allLoadUrlRedirects = [];
        var allUnknownRouteRedirects = [];
        var allAuthorizationPlugins = [];

        plugins.forEach(function(plugin) {
            if (plugin.guardRoute) {
                allRouteGuards.push(plugin.guardRoute);
            }
            if (plugin.loadUrlRedirect) {
                allLoadUrlRedirects.push(plugin.loadUrlRedirect);
            }
            if (plugin.unknownRouteRedirect) {
                allUnknownRouteRedirects.push(plugin.unknownRouteRedirect);
            }
        });

        authorizationPlugins.forEach(function(authorizationPlugin) {
            if (authorizationPlugin.configureAuthorization) {
                allAuthorizationPlugins.push(authorizationPlugin.configureAuthorization);
            }
        });

        return {
            routeGuards: allRouteGuards,
            loadUrlRedirects: allLoadUrlRedirects,
            unknownRouteRedirects: allUnknownRouteRedirects,
            authorizationPlugins: allAuthorizationPlugins
        };
    }

    return {
        getPluginConfigurations: getPluginConfigurations
    };
});

