define('presentation/release/presentationObjects/releaseTogglePresentationObject',[], function () {
    return function () {
        let self = this;

        self.featureToggleId = null;
        self.name = null;
        self.description = null;
        self.isOnForAll = ko.observable(false);
        self.isChanged = ko.observable(false);
        self.canAddAccountsToFeature = ko.computed(function() {
            return self.isOnForAll() === false;
        });
        self.accounts = ko.observableArray([]);
    };
});
