define('presentation/navigation/viewModels/recentlyViewedAccountsViewModel',['common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/common/window/windowControl',
    'presentation/navigation/facades/recentlyViewedAccountsFacade'
], function() {
    return function(){
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var SorterConstructor = require('common/collections/collectionSorter');
        var sorter = new SorterConstructor();

        var _facade = null;
        var _windowControl = null;

        var _populateRecentlyViewedAccounts = function(){
            return _promiseFactory.defer(function(promise){
                _facade.getRecentlyViewedAccounts()
                    .done(function(recentlyViewedAccounts){
                        self.recentlyViewedAccounts(recentlyViewedAccounts);
                        promise.resolve();
                    })
                    .fail(function(error){
                        promise.reject(error);
                    });
            });
        };

        var _sortRecentlyViewedAccounts = function(column, sortOrder){
            return _promiseFactory.defer(function(promise){
                var isAscending = sortOrder === "sort-asc";
                sorter.sort(self.recentlyViewedAccounts, column, isAscending);
                promise.resolve();
            });
        };

        var _toggleSortOrder = function(sortOrder) {
            if (sortOrder === "sort-asc") {
                return "sort-desc";
            } else {
                return "sort-asc";
            }
        };

        var _clearSortOrder = function() {
            self.accountHistoryAccountNumberSortOrder("");
            self.accountHistoryNameSortOrder("");
            self.accountHistoryCitySortOrder("");
            self.accountHistoryStateSortOrder("");
            self.accountHistoryDateSortOrder("");
        };

        self.sortAccountHistoryByAccountNumber = function() {
            var sortOrder = _toggleSortOrder(self.accountHistoryAccountNumberSortOrder());
            _clearSortOrder();
            self.accountHistoryAccountNumberSortOrder(sortOrder);
            return _sortRecentlyViewedAccounts("accountNumber", sortOrder);
        };

        self.sortAccountHistoryByName = function() {
            var sortOrder = _toggleSortOrder(self.accountHistoryNameSortOrder());
            _clearSortOrder();
            self.accountHistoryNameSortOrder(sortOrder);
            return _sortRecentlyViewedAccounts("customerName", sortOrder);
        };

        self.sortAccountHistoryByCity = function() {
            var sortOrder = _toggleSortOrder(self.accountHistoryCitySortOrder());
            _clearSortOrder();
            self.accountHistoryCitySortOrder(sortOrder);
            return _sortRecentlyViewedAccounts("city", sortOrder);
        };

        self.sortAccountHistoryByState = function() {
            var sortOrder = _toggleSortOrder(self.accountHistoryStateSortOrder());
            _clearSortOrder();
            self.accountHistoryStateSortOrder(sortOrder);
            return _sortRecentlyViewedAccounts("state", sortOrder);
        };

        self.sortAccountHistoryByDateViewed = function() {
            var sortOrder = _toggleSortOrder(self.accountHistoryDateSortOrder());
            _clearSortOrder();
            self.accountHistoryDateSortOrder(sortOrder);
            return _sortRecentlyViewedAccounts("dateViewed", sortOrder);
        };

        self.recentlyViewedAccounts = ko.observableArray([]);

        self.accountHistoryAccountNumberSortOrder = ko.observable("");
        self.accountHistoryNameSortOrder = ko.observable("");
        self.accountHistoryCitySortOrder = ko.observable("");
        self.accountHistoryStateSortOrder = ko.observable("");
        self.accountHistoryDateSortOrder = ko.observable("");

        self.openAccount = function(currentHistoryItem) {
            return _promiseFactory.defer(function(promise) {
                _windowControl.openNewWindow(currentHistoryItem.accountUrl);
                promise.resolve();
            });
        };

        self.loadHistoryGrid = function(){
            return _promiseFactory.defer(function(promise) {
                _populateRecentlyViewedAccounts()
                    .done(function(){
                        self.accountHistoryDateSortOrder("sort-asc");
                        self.sortAccountHistoryByDateViewed()
                            .done(function(){
                                promise.resolve();
                            })
                            .fail(function(error){
                                promise.reject(error);
                            });
                    })
                    .fail(function(error){
                        promise.reject(error);
                    });
            });
        };

        self.activate = function() {
            var FacadeConstructor = require('presentation/navigation/facades/recentlyViewedAccountsFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            _windowControl = require('presentation/common/window/windowControl');

            return _initialize();
        };

        self.activate2 = function(facade) {
            _facade = facade;

            return _initialize();
        };

        var _initialize = function() {
            return _promiseFactory.wait();
        };
    };
});

