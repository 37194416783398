define('presentation/account/viewModels/billingViewModel',['common/promises/promiseFactory',
        'presentation/common/window/windowControl',
        'presentation/account/facades/billingFacade'
        ],
    function () {
        return function () {
            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();
            var _facade = null;

            var self = this;
            var _accountNumber = null;
            var _enterCreditRequestUrl = null;
            var _windowControl = null;

            var _initializeBillingActions = function() {
                _promiseFactory.defer(function(promise){
                    _facade.getBillingActions(_accountNumber)
                        .done(function(billingActions){
                            self.billingEvents(billingActions);
                            promise.resolve();
                        })
                        .fail(function(error){
                            promise.reject(error);
                        });
                });
                _promiseFactory.defer(function(promise){
                    _facade.getBillingCommunications(_accountNumber)
                        .done(function(billingCommunications){
                            self.billingCommunications(billingCommunications);
                            promise.resolve();
                        })
                        .fail(function(error){
                            promise.reject(error);
                        });
                });
            };

            var _getCreditRequestUrl = function() {
                return _promiseFactory.defer(function(promise) {
                    _facade.getCreditRequestUrl()
                        .fail(promise.reject)
                        .done(function (enterCreditRequestUrl) {
                            _enterCreditRequestUrl = enterCreditRequestUrl;
                            promise.resolve();
                        });
                });
            };

            self.billingEvents = ko.observableArray([]);
            self.billingCommunications = ko.observableArray([]);

            self.enterCreditRequest = function () {
                _windowControl.openNewWindow(_enterCreditRequestUrl);
            };

            self.activate = function (accountNumber, queryStringArguments, isCalledFromSidebar) {
                if (isCalledFromSidebar !== true) {
                    return;
                }

                _facade = require('presentation/account/facades/billingFacade');
                _accountNumber = accountNumber;
                _windowControl = require('presentation/common/window/windowControl');

                return _initialize();
            };

            self.activate2 = function () {

                return _initialize();
            };

            var _initialize = function () {
                _facade.init();
                _getCreditRequestUrl()
                    .done(function() {
                        _initializeBillingActions();
                    });

                return _promiseFactory.wait();
            };

        };
    });

