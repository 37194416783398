define('presentation/shell/viewModels/templateHeaderSidebarContentViewModel',['common/promises/promiseFactory',
        'businessServices/router/crmRouter',
        'settings/navigationConfiguration'
        ],
function(){
    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _navigationConfiguration = null;

        self.router = null;

        self.headerPane = ko.observable();
        self.sidebarPane = ko.observable();
        self.contentPane = ko.observable();

        var _monitoredChild = null;


        var _watchChild = function(childViewModel) {
            _monitoredChild = childViewModel;

            self.sidebarPane(childViewModel.sidebarPane());
            childViewModel.sidebarPane.subscribe(function() {
                self.sidebarPane(childViewModel.sidebarPane());
            });

            self.contentPane(childViewModel.contentPane());
            childViewModel.contentPane.subscribe(function() {
                self.contentPane(childViewModel.contentPane());
            });
        };

        self.activate = function() {
            self.router = require('businessServices/router/crmRouter');
            _navigationConfiguration = require('settings/navigationConfiguration');

            return _initialize();
        };

        self.activate2 = function(router, navigationConfiguration) {
            self.router = router;
            _navigationConfiguration = navigationConfiguration;

            return _initialize();
        };

        var _loadHeader = function() {
            var instruction = self.router.activeInstruction();
            var routeId = instruction.config.customSettings.routeId;

            var route = _navigationConfiguration.routesById[routeId];

            var headerViewModelPath = route.headerViewModelPath;
            if (headerViewModelPath === undefined) {
                headerViewModelPath = _navigationConfiguration.defaultHeaderViewModelPath;
            }

            return _promiseFactory.defer(function(promise) {
                require([headerViewModelPath], function(HeaderViewModelConstructor) {
                    var headerViewModel = new HeaderViewModelConstructor();
                    headerViewModel.activate();
                    headerViewModel.setRouter(self.router);

                    self.headerPane(headerViewModel);
                    promise.resolve();
                });
            });
        };

        var _initialize = function() {
            _promiseFactory.defer(function(promise) {
                self.router.customState.applicationIsReady.done(function() {
                    var activeItem = self.router.activeItem();
                    if (activeItem === null) {
                        self.router.customState.notifyWhenNextNavigationComplete(function() {
                            var activeItem = self.router.activeItem();
                            _watchChild(activeItem);
                        });
                    } else {
                        _watchChild(activeItem);
                    }

                    _loadHeader();

                    promise.resolve();
                });
            });

            return _promiseFactory.wait();
        };
    };
});

