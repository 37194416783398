define('presentation/marketing/viewModels/addEmailRecipientViewModel',['common/promises/promiseFactory',
    'plugins/router',
    'constants/marketingContactFormTypeEnumerations',
    'presentation/marketing/validators/addEmailRecipientValidator',
    'presentation/marketing/facades/addEmailRecipientFacade'], function() {
    return function(){
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _facade = null;
        var _router = null;
        var _validator = null;

        var MARKETING_CONTACT_FORM_TYPE_ENUMERATION = require('constants/marketingContactFormTypeEnumerations');

        self.formTitle = "New Email Recipient";
        self.saveButtonTitle = "Create";
        self.emailAddress = ko.observable('');
        self.isContactForm = ko.observable(false);
        self.isDemoRequest = ko.observable(false);

        self.recipientFormTypes = ko.computed(function() {
            let recipientFormTypes = [];
            if (self.isContactForm()) {
                recipientFormTypes.push({formType: MARKETING_CONTACT_FORM_TYPE_ENUMERATION.contactUs});
            }
            if (self.isDemoRequest()) {
                recipientFormTypes.push({formType: MARKETING_CONTACT_FORM_TYPE_ENUMERATION.demoRequest});
            }
            return recipientFormTypes;
        });

        self.saveButtonClick = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid) {
                            _facade.addEmailRecipient(
                                self.emailAddress().toLowerCase(),
                                self.recipientFormTypes()
                            ).done(function () {
                                _router.navigate("/marketing/emailNotifications");
                                deferredObject.resolve();
                                });
                            }
                        })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.cancelForm = function () {
            _router.navigate("/marketing/emailNotifications");
        };

        self.activate = function() {
            var Facade = require('presentation/marketing/facades/addEmailRecipientFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            var ValidatorConstructor = require('presentation/marketing/validators/addEmailRecipientValidator');
            _validator = new ValidatorConstructor();

            _router = require('plugins/router');

            return _initialize();
        };

        var _initialize = function() {

            _validator.registerViewModel(self, _facade);
            
            return _promiseFactory.wait();
        };
    };
});

