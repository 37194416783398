define('presentation/developer/viewModels/developerViewModel',['common/promises/promiseFactory',
    'presentation/navigation/facades/mainNavigationFacade',
    'presentation/developer/viewModels/developerSidebarViewModel',
    'businessServices/router/crmRouter'
], function() {
    const SIDEBAR_CONTENT_ITEMS = [
        {
            selection : "callMonitoring",
            title : "Call Monitoring",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/callMonitoringViewModel',
            urlParts : ["developer", "callMonitoring"],
            showInSidebar : true,
        },
        {
            selection : "deactivateNumbers",
            title : "Deactivate Numbers",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/deactivateNumbersViewModel',
            urlParts : ["developer", "deactivateNumbers"],
            showInSidebar : true,
        },
        {
            selection : "numberManagement",
            title : "Move Numbers",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/numberManagementViewModel',
            urlParts : ["developer", "numberManagement"],
            showInSidebar : true,
        },
        {
            selection : "operator",
            title : "Operator",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/operatorViewModel',
            urlParts : ["developer", "operator"],
            showInSidebar : true
        },
        {
            selection : "rabbitMq",
            title : "Rabbit MQ",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/rabbitMqViewModel',
            urlParts : ["developer", "rabbitMq"],
            showInSidebar : true,
            childRoutes : ["rabbitMq", "queueDetails"]
        },
        {
            selection : "queueDetails",
            title : "Queue Details",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/queueDetailsViewModel',
            urlParts : ["developer", "queueDetails"],
            showInSidebar : false,
        },
        {
            selection : "reactivateNumbers",
            title : "Reactivate Numbers",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/reactivateNumbersViewModel',
            urlParts : ["developer", "reactivateNumbers"],
            showInSidebar : true,
        },
        {
            selection : "scheduledJobs",
            title : "Scheduled Jobs",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/scheduledJobsViewModel',
            urlParts : ["developer", "scheduledJobs"],
            showInSidebar : true,
        },
        {
            selection : "sqs",
            title : "SQS Queues",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/sqsViewModel',
            urlParts : ["developer", "sqs"],
            showInSidebar : true,
            childRoutes : ["sqs", "sqsQueueDetails"]
        },
        {
            selection : "sqsQueueDetails",
            title : "SQS Queue Details",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/sqsQueueDetailsViewModel',
            urlParts : ["developer", "sqsQueueDetails"],
            showInSidebar : false,
        },
        {
            selection : "smsVerification",
            title : "SMS Verification",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/smsVerificationViewModel',
            urlParts : ["developer", "smsVerification"],
            showInSidebar : true,
        },
        {
            selection : "fraudSettings",
            title : "Fraud Settings",
            iconClass : "icon-nav-gear",
            permissionId : 'CRM.Developer',
            viewModelPath : 'presentation/developer/viewModels/fraudSettingsViewModel',
            urlParts : ["developer", "fraudSettings"],
            showInSidebar : true,
        }
    ];

    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = new PromiseFactoryConstructor();
    var _sidebarViewModel = null;
    var _sidebarPane = ko.observable(null);
    var _contentPane = ko.observable(null);
    var _router = null;
    var _isInitialized = false;

    var Constructor = function() {
        var self = this;

        self.sidebarPane = _sidebarPane;
        self.contentPane = _contentPane;

        self.activate = function(settingsSelection) {
            if (_isInitialized === false) {
                _router = require('businessServices/router/crmRouter');
            }
            self.router = _router;

            return _initialize(settingsSelection);
        };

        var _initialize = function(settingsSelection) {
            var initializedPromise = _promiseFactory.deferIndefinitely();
            var contentPaneLoadedPromise = _promiseFactory.deferIndefinitely();

            if (_isInitialized === false) {
                _isInitialized = true;

                var SidebarViewModelConstructor = require('presentation/developer/viewModels/developerSidebarViewModel');
                _sidebarViewModel = new SidebarViewModelConstructor();
                _sidebarViewModel.sidebarConfig = SIDEBAR_CONTENT_ITEMS;
                _sidebarViewModel.parentSettingsViewModel = self;
                _sidebarViewModel.activate()
                    .done(function () {
                        _sidebarPane(_sidebarViewModel);

                        initializedPromise.resolve();
                    });
            } else {
                initializedPromise.resolve();
            }

            initializedPromise.done(function() {
                if (settingsSelection === null) {
                    contentPaneLoadedPromise.resolve();
                } else {
                    var pathParts = settingsSelection.split("/");
                    var pathFirstPart = pathParts[0];
                    var pathSubsequentParts = pathParts.slice(1).join("/");

                    _sidebarViewModel.toolSelectionChanged(pathFirstPart);

                    var foundSidebarItem = SIDEBAR_CONTENT_ITEMS.find(function(item) {
                        return (item.selection === pathFirstPart);
                    });

                    if (foundSidebarItem === undefined) {
                        _contentPane(null);
                        self.router.navigate('error', { trigger: true, replace: true });
                        contentPaneLoadedPromise.resolve();
                    } else {
                        require([foundSidebarItem.viewModelPath], function(ViewModelConstructor) {
                            var viewModel = new ViewModelConstructor();
                            viewModel.activate(pathSubsequentParts)
                                .done(function() {
                                    _contentPane(viewModel);

                                    contentPaneLoadedPromise.resolve();
                                });
                        });
                    }
                }
            });

            return _promiseFactory.wait();
        };
    };


    var instance = new Constructor();
    return instance;
});

