define('businessServices/router/authorizationPlugins/fromPccAuthorizationPlugin',[
    'businessServices/authentication/authenticationProvider',
    'constants/statusConstants'
], function(
    AuthenticationProviderConstructor,
    statusConstants
) {

    function isFromPcc(urlFragment) {
        var urlParts = urlFragment.split("/");
        return urlParts.length === 4 && urlParts[0].substr(0, 7) === "fromPcc";
    }

    function fromPccAuthorizationPlugin(urlFragment) {
        if (isFromPcc(urlFragment)) {
            var authenticationProvider = new AuthenticationProviderConstructor();
            authenticationProvider.init();
            return authenticationProvider.validateUrlNonce(urlFragment);
        }

        return {status: statusConstants.ignored};
    }

    return {
        configureAuthorization : fromPccAuthorizationPlugin
    };
});

