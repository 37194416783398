define('presentation/settings/facades/addEmployeeFacade',[
    'entities/employeeEntity',
    'persistence/webSocket/webSocketApp'
],
    function() {
        var _promiseFactory = null;
        var _webSocketApp = null;
        var EmployeeEntityConstructor = require('entities/employeeEntity');

        var _getEmployee = function(employeeId) {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {"employee_id": employeeId};
                _webSocketApp.send("get_employee_by_id", params, function(employee) {
                    deferredObject.resolve(employee);
                });
            });
        };

        var _isEmailAddressUnique = function(emailAddress) {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {"email_address": emailAddress};
                _webSocketApp.send("get_employee_by_email", params, function(getEmployeResponse) {
                    var isUnique;
                    if ((typeof getEmployeResponse.status !== 'undefined') &&
                        (getEmployeResponse.status === "error")) {
                        if (getEmployeResponse.reason === "document_not_found") {
                            isUnique = true;
                        } else {
                            throw new Error("error encountered calling get_employee_by_email. reason = " + getEmployeResponse.reason);
                        }
                    } else {
                        isUnique = false;
                    }
                    deferredObject.resolve(isUnique);
                });
            });
        };

        var _isEmployeeNumberUnique = function(employeeNumber) {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {"employee_number": employeeNumber};
                _webSocketApp.send("get_employee_by_number", params, function(getEmployeResponse) {
                    var isUnique;
                    if ((typeof getEmployeResponse.status !== 'undefined') &&
                        (getEmployeResponse.status === "error")) {
                        if (getEmployeResponse.reason === "document_not_found") {
                            isUnique = true;
                        } else {
                            throw new Error("error encountered calling get_employee_by_email. reason = " + getEmployeResponse.reason);
                        }
                    } else {
                        isUnique = false;
                    }
                    deferredObject.resolve(isUnique);
                });
            });
        };

        var _addEmployee = function(firstName, lastName, emailAddress, password, employeeNumber, isActive, employeeRoles) {
            return _promiseFactory.defer(function(deferredObject) {
                var employee = _createEmployeeEntity(firstName, lastName, emailAddress, password, employeeNumber, isActive, employeeRoles);
                var params = {"employee": employee};
                _webSocketApp.send("add_employee", params, function(addEmployeeResult) {
                    deferredObject.resolve(addEmployeeResult);
                });
            });
        };

        var _updateEmployee = function(employeeId, firstName, lastName, emailAddress, password, employeeNumber, isActive, employeeRoles) {
            return _promiseFactory.defer(function(deferredObject) {
                var employee = _createEmployeeEntity(firstName, lastName, emailAddress, password, employeeNumber, isActive, employeeRoles);
                employee.employeeId = employeeId;
                var params = {"employee": employee};
                _webSocketApp.send("update_employee", params, function(updateEmployeeResult) {
                    deferredObject.resolve(updateEmployeeResult);
                });
            });
        };

        var _createEmployeeEntity = function(firstName, lastName, emailAddress, password, employeeNumber, isActive, employeeRoles) {
            var employeeEntity = new EmployeeEntityConstructor();
            employeeEntity.firstName = firstName.trim();
            employeeEntity.lastName = lastName.trim();
            employeeEntity.emailAddress = emailAddress.trim();
            employeeEntity.password = password;
            employeeEntity.employeeNumber = employeeNumber.trim();
            employeeEntity.isEnabled = isActive;
            employeeEntity.roles = employeeRoles;
            return employeeEntity;
        };

        var _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("crm_employee_management");
        };

        return function() {
            var self = this;
            self.isEmailAddressUnique = _isEmailAddressUnique;
            self.isEmployeeNumberUnique = _isEmployeeNumberUnique;
            self.addEmployee = _addEmployee;
            self.updateEmployee = _updateEmployee;
            self.getEmployee = _getEmployee;
            self.init = _init;
        };
    });

