define('presentation/forgotPassword/viewModels/resetPasswordViewModel',['businessServices/router/crmRouter',
        'common/promises/promiseFactory',
        'presentation/forgotPassword/validators/resetPasswordValidator',
        'presentation/forgotPassword/facades/resetPasswordFacade'], function() {
    return function() {
        var self = this;
        var _emailAddress = null;
        var _expirationDateTime = null;
        var _signature = null;
        var _validator = null;
        var _facade = null;
        var _router = null;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _validateLink = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _facade.validateLink(_emailAddress, _expirationDateTime, _signature)
                    .done(function(result) {
                        switch (result) {
                            case "valid":
                                self.isValidLink(true);
                                break;
                            case "invalid":
                                self.isInvalidLink(true);
                                break;
                            default:
                                self.isExpiredLink(true);
                                break;
                        }

                        deferredObject.resolve(result);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.password = ko.observable("");
        self.confirmPassword = ko.observable("");
        self.isValidLink = ko.observable(false);
        self.isInvalidLink = ko.observable(false);
        self.isExpiredLink = ko.observable(false);
        self.forgotPasswordUrl = "forgotPassword";

        self.changePassword = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid === false) {
                            deferredObject.resolve();

                        } else {
                            _facade.resetPassword(_emailAddress, self.password(), _expirationDateTime, _signature)
                                .done(function() {
                                    _router.navigate('login/resetPasswordConfirm');
                                    deferredObject.resolve();
                                })
                                .fail(function(error) {
                                    deferredObject.reject(error);
                                });
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.activate = function(emailAddress, expirationDateTime, signature) {
            _emailAddress = emailAddress;
            _expirationDateTime = expirationDateTime;
            _signature = signature;

            _router = require('businessServices/router/crmRouter');

            var ResetPasswordFacadeConstructor = require('presentation/forgotPassword/facades/resetPasswordFacade');
            _facade = new ResetPasswordFacadeConstructor();
            _facade.init(_promiseFactory);

            var ValidatorConstructor = require('presentation/forgotPassword/validators/resetPasswordValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        self.activate2 = function(emailAddress, expirationDateTime, signature, validator, facade, router) {
            _emailAddress = emailAddress;
            _expirationDateTime = expirationDateTime;
            _signature = signature;

            _validator = validator;
            _facade = facade;
            _router = router;

            return _initialize();
        };

        var _initialize = function() {
            _validateLink()
                .done(function() {
                    _validator.registerViewModel(self);
                })
                .fail(function(error) {
                    self.isValidLink(false);
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

