define('presentation/account/sidebar/presentationObjects/capturedPhoneNumberPresentationObject',[], function () {
    return function () {
        var self = this;

        self.id = null;
        self.phoneNumber = null;
        self.formattedPhoneNumber = null;
    };
});

