define('presentation/account/viewModels/resendInviteModalViewModel',[
    'presentation/common/modal',
    'presentation/account/facades/usersFacade',
    'common/promises/promiseFactory'
], function () {
    return function (emailAddress, onLoadCompleteDeferredObject, onModalCloseCompleteDeferredObject) {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _facade =  require('presentation/account/facades/usersFacade');

        var ModalServiceConstructor = require('presentation/common/modal');
        var _modalService = new ModalServiceConstructor();

        var _onModalCloseCompleteDeferredObject = onModalCloseCompleteDeferredObject;

        self.emailAddress = ko.observable('');

        self.closeModal = function () {
            _onModalCloseCompleteDeferredObject.resolve("cancel");
            _modalService.closeModal(self);
        };

        self.cancel = function () {
            self.closeModal();
        };

        self.resendInvite = function () {
            return _promiseFactory.defer(function (promise) {
                _facade.resendInvite(self.emailAddress())
                    .done(function () {
                        _onModalCloseCompleteDeferredObject.resolve("done");
                        _modalService.closeModal(self);
                        promise.resolve();
                    })
                    .fail(function (error) {
                        _onModalCloseCompleteDeferredObject.reject(error);
                        promise.reject(error);
                    });

            });
        };

        self.activate = function () {
            return _initialize();
        };

        self.activate2 = function (facade, modalService) {
            _facade = facade;
            _modalService = modalService;

            return _initialize();
        };

        var _initialize = function () {
            self.emailAddress(emailAddress);

            onLoadCompleteDeferredObject.resolve();

            return _promiseFactory.wait();
        };
    };
});
