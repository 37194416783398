define('presentation/developer/viewModels/queueDetailsViewModel',['common/promises/promiseFactory',
    'plugins/router',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'presentation/developer/facades/queueDetailsFacade'], function() {
    return function(){
        var self = this;

        var _facade = null;
        var _sorter = null;
        var FilterConstructor = require('common/collections/collectionFilter');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _sortBy = "timestamp";
        var _sortAsc = true;
        var _showAllDetails = false;

        self.queueName = null;
        self.queueMetaData = ko.observable(null);
        self.dataGridConfig = {};
        self.messages = ko.observableArray([]);
        self.messageFilter = ko.observable("");
        self.searchText = ko.observable("");
        self.selectedAll = ko.observable(false);
        self.reprocessThese = ko.observableArray([]);

        self.filteredMessages = ko.computed(function() {
            var searchText = self.messageFilter().trim().toLowerCase();
            var filteredMessages = [];
            if (searchText) {
                var messageFilter = new FilterConstructor();
                messageFilter.addProperty('queue');
                messageFilter.addValue(searchText);
                filteredMessages = messageFilter.filter(self.messages());
            } else {
                filteredMessages = self.messages();
            }
            return filteredMessages;
        });

        self.pauseQueue = function() {
            _facade.toggleQueueState(self.queueName, "pause");
            window.location.reload(true);
        };

        self.resumeQueue = function() {
            _facade.toggleQueueState(self.queueName, "resume");
            window.location.reload(true);
        };

        self.processSelection = function(item, type) {
            var sendToReprocessing = [];
            if (item === "all") {
                self.messages().forEach(function(message) {
                    sendToReprocessing.push(message.messageId);
                });
            } else if (type === "queue") {
                self.messages().forEach(function(message) {
                    if (message.queue === item) {
                        sendToReprocessing.push(message.messageId);
                    }
                });
            } else {
                if(item) {
                    sendToReprocessing.push(item);
                }
            }
            if (sendToReprocessing.length > 0) {
                _reprocessMessages(sendToReprocessing);
            }
        };

        self.filterMessages = function() {
            self.messageFilter(self.searchText());
        };

        var _sortColumn = function(column, sortAsc) {
            _sortBy = column;
            _sortAsc = sortAsc;
            return _getMessages();
        };

        var _formatColumn = function(column, row) {
            if (row.status === "open") {
                return "status-open";
            } else {
                return "status-closed";
            }
        };

        var _reprocessMessages = function(messageList) {
            _facade.reprocessMessages(messageList)
                .done(function () {
                    window.location.reload(true);
                })
                .fail(function () {});
        };

        var _getMessages = function() {
            return _promiseFactory.defer(function(deferredObject) {
                //if (self.messages().length === 0) {
                    _facade.getMessages(self.queueName)
                        .done(function (results) {
                            self.messages(results);
                            deferredObject.resolve();
                        })
                        .fail(function (error) {
                            deferredObject.reject(error);
                        });
                //}
                _sorter.multiSort(self.messages, ["isEnabled", _sortBy], [false, _sortAsc]);
                deferredObject.resolve();
            });
        };

        self.resetMessageGrid = function() {
            //self.messages([]);
            _getMessages();
            _facade.getQueueMetaData(self.queueName)
                .done(function (result) {
                    self.queueMetaData(result);
                });
        };

        self.toggleAllDetails = function() {
            if (_showAllDetails === true) {
                _showAllDetails = false;
            } else {
                _showAllDetails = true;
            }

            var allMessages = self.messages();
            allMessages.forEach(function(message) {
                message.isDetailsVisible(_showAllDetails);
            });
        };

        self.activate = function(queueName) {
            var Facade = require('presentation/developer/facades/queueDetailsFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);
            var SorterConstructor = require('common/collections/collectionSorter');
            _sorter = new SorterConstructor();

            self.queueName = queueName;

            return _initialize(queueName);
        };

        var _initialize = function() {
            _facade.getQueueMetaData(self.queueName)
                .done(function (result) {
                    self.queueMetaData(result);
                });

            _getMessages();

            self.dataGridConfig.dataSet = self.filteredMessages;
            self.dataGridConfig.sortColumnCallback = _sortColumn;
            self.dataGridConfig.cellCssFormat = _formatColumn;
            if(self.queueName === "deadLetter") {
                self.dataGridConfig.columns = [
                    {"header": "Message ID", "key": "messageId", "isSortable": false},
                    {"header": "Sent From", "key": "queue", "isSortable": true},
                    {"header": "Timestamp", "key": "timeSentToDeadLetter", "isSortable": true},
                ];
            } else {
                self.dataGridConfig.columns = [
                    {"header": "Message ID", "key": "messageId", "isSortable": false},
                ];
            }


            return _promiseFactory.wait();
        };
    };
});

