define('presentation/account/presentationObjects/affiliatePresentationObject',[],function() {
    return function(){
        const self = this;

        self.affiliateId = null;
        self.affiliateName = null;
        self.affiliateCode = null;
        self.displayName = null;
    };
});

