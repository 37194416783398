define('presentation/account/activity/email/viewModels/emailViewModel',['presentation/common/modal',
        'presentation/common/window/windowControl',
        'common/promises/promiseFactory',
        'presentation/account/activity/email/viewModels/sendToViewModel',
        'presentation/account/activity/email/viewModels/composeViewModel'],
function() {
    var self = {};

    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = new PromiseFactoryConstructor();
    var _accountNumber = null;
    var _initialSendToEmailAddresses = null;
    var _modalService = null;
    var _windowControl = null;
    var _currentSection = null;
    var _isReplyMode = null;
    var _replyToNotationId = null;
    var _replyToStoredDocumentId = null;
    var _replyContentUrl = null;
    var _replyToSubject = null;
    var _sections = {
        "sendTo": {"name": "sendTo", "viewModel": null, "showPreviousLink": false, title: "New Email", cssName: "modal email-compose-send-to", warnOnNavigateAway: false},
        "compose": {"name": "compose", "viewModel": null, "showPreviousLink": true, title: "Compose Email", cssName: "modal email-compose-compose", warnOnNavigateAway: true}
    };
    var _confirmationActionTypes = {
        "previousSection": {"name": "previousSection", "message": "Are you sure you want to go back? All changes will be discarded.", "buttonLabel": "Go Back"},
        "closeModal": {"name": "closeModal", "message": "Are you sure you want to close?  All changes will be discarded.", "buttonLabel": "Close"}
    };
    var _currentConfirmationActionType = null;

    var SendToSectionConstructor = null;
    var ComposeSectionConstructor = null;

    self.isOpen = false;
    self.sectionViewModel = ko.observable();
    self.showPreviousLink = ko.observable();
    self.currentSectionCssName = ko.observable();
    self.currentSectionTitle = ko.observable();
    self.showConfirmationMessage = ko.observable(false);
    self.confirmationMessage = ko.observable("");
    self.confirmationMessageActionButtonText = ko.observable("");

    var _displayNextSection = function() {
        switch (_currentSection) {
            case _sections.sendTo.name:
                var currentlySelectedToEmailAddresses = _sections.sendTo.viewModel.sendToContactsSelected();
                _sections.compose.viewModel.toEmailAddresses(currentlySelectedToEmailAddresses);
                _sections.compose.viewModel.requiredToEmailAddresses(currentlySelectedToEmailAddresses);
                _sections.compose.viewModel.resetForm();
                self.sectionViewModel(_sections.compose.viewModel);
                _currentSection = _sections.compose.name;
                break;
            case null:
                self.sectionViewModel(_sections.sendTo.viewModel);
                _currentSection = _sections.sendTo.name;
                break;
        }

        _updateUIForCurrentSection();
    };

    var _displayPreviousSection = function() {
        switch (_currentSection) {
            case _sections.compose.name:
                self.sectionViewModel(_sections.sendTo.viewModel);
                _currentSection = _sections.sendTo.name;
                break;
        }

        _updateUIForCurrentSection();
    };

    var _updateUIForCurrentSection = function() {
        if (_currentSection !== null) {
            self.showPreviousLink(_sections[_currentSection].showPreviousLink);
            self.currentSectionCssName(_sections[_currentSection].cssName);
            self.currentSectionTitle(_sections[_currentSection].title);

            if (_sections[_currentSection].warnOnNavigateAway) {
                _windowControl.setConfirmationMessageOnNavigateAway("All changes will be lost.");
            } else {
                _windowControl.clearConfirmationMessageOnNavigateAway();
            }
        }
    };

    self.confirmConfirmationMessage = function() {
        switch (_currentConfirmationActionType) {
            case _confirmationActionTypes.previousSection:
                _displayPreviousSection();
                break;

            case _confirmationActionTypes.closeModal:
                self.isOpen = false;
                _modalService.closeModal(self);
                break;
        }

        self.showConfirmationMessage(false);
        _currentConfirmationActionType = null;
        _windowControl.clearConfirmationMessageOnNavigateAway();
    };

    self.cancelConfirmationMessage = function() {
        self.showConfirmationMessage(false);
        _currentConfirmationActionType = null;
    };

    self.onEmailSendComplete = function() {
        _windowControl.clearConfirmationMessageOnNavigateAway();
        self.isOpen = false;
        _modalService.closeModal(self);
    };

    self.closeModal = function() {
        if (_currentSection !== null && _sections[_currentSection].warnOnNavigateAway) {
            _modalService.restoreModal(self);
            _currentConfirmationActionType = _confirmationActionTypes.closeModal;
            self.confirmationMessage(_currentConfirmationActionType.message);
            self.confirmationMessageActionButtonText(_currentConfirmationActionType.buttonLabel);
            self.showConfirmationMessage(true);
        } else {
            self.isOpen = false;
            _modalService.closeModal(self);
        }
    };

    self.toggleMinimizeModal = function() {
        _modalService.toggleMinimizeModal(self);
    };

    self.goToNextSection = function() {
        _displayNextSection();
    };

    self.goToPreviousSection = function() {
        _currentConfirmationActionType = _confirmationActionTypes.previousSection;
        self.confirmationMessage(_currentConfirmationActionType.message);
        self.confirmationMessageActionButtonText(_currentConfirmationActionType.buttonLabel);
        self.showConfirmationMessage(true);
    };

    self.setAccountNumber = function(accountNumber) {
        _accountNumber = accountNumber;
    };

    self.setInitialSendToEmailAddress = function(initialSendToEmailAddress) {
        _initialSendToEmailAddresses = [initialSendToEmailAddress];
    };

    self.setInitialSendToEmailAddresses = function(initialSendToEmailAddressess) {
        _initialSendToEmailAddresses = initialSendToEmailAddressess;
    };

    self.setReplyMode = function(isReplyMode) {
        _isReplyMode = isReplyMode;
    };

    self.setReplyContentUrl = function(replyContentUrl) {
        _replyContentUrl = replyContentUrl;
    };

    self.setReplyToStoredDocumentId = function(replyToStoredDocumentId) {
        _replyToStoredDocumentId = replyToStoredDocumentId;
    };

    self.setReplyToNotationId = function(replyToNotationId) {
        _replyToNotationId = replyToNotationId;
    };

    self.setReplyToSubject = function(subject) {
        _replyToSubject = "Re: " + subject;
    };

    self.modalInitializedDeferredObject = null;

    self.activate = function() {
        var ModalServiceConstructor = require('presentation/common/modal');
        _modalService = new ModalServiceConstructor();

        _windowControl = require('presentation/common/window/windowControl');

        SendToSectionConstructor = require('presentation/account/activity/email/viewModels/sendToViewModel');
        ComposeSectionConstructor = require('presentation/account/activity/email/viewModels/composeViewModel');

        return _initialize();
    };

    self.activate2 = function(modalService, sendToSectionConstructor, composeSectionConstructor, windowControl) {
        _modalService = modalService;
        SendToSectionConstructor = sendToSectionConstructor;
        ComposeSectionConstructor = composeSectionConstructor;
        _windowControl = windowControl;

        return _initialize();
    };

    var _initialize = function() {
        self.isOpen = true;
        _currentSection = null;

        _promiseFactory.defer(function(deferredObject) {
            _sections.sendTo.viewModel = new SendToSectionConstructor(self, _accountNumber);
            _sections.sendTo.viewModel.activate()
                                        .done(function() {
                                            deferredObject.resolve();
                                        })
                                        .fail(function(error) {
                                            deferredObject.reject(error);
                                        });
        });

        _promiseFactory.defer(function(deferredObject) {
            _sections.compose.viewModel = new ComposeSectionConstructor(self, _accountNumber);

            _sections.compose.viewModel.isReplyMode(_isReplyMode);
            _sections.compose.viewModel.replyContentUrl(_replyContentUrl);
            _sections.compose.viewModel.replyToNotationId(_replyToNotationId);
            _sections.compose.viewModel.replyToStoredDocumentId(_replyToStoredDocumentId);
            _sections.compose.viewModel.toEmailAddresses(_initialSendToEmailAddresses);
            if (_isReplyMode === true) {
                _sections.compose.viewModel.subject(_replyToSubject);
            }
            _sections.compose.viewModel.activate()
                                            .done(function() {
                                                if (_initialSendToEmailAddresses === null || _initialSendToEmailAddresses[0] === null) {
                                                    _displayNextSection();
                                                } else {
                                                    _sections.compose.viewModel.resetForm();
                                                    self.sectionViewModel(_sections.compose.viewModel);
                                                    _currentSection = _sections.compose.name;
                                                    _updateUIForCurrentSection();
                                                }
                                                if (self.modalInitializedDeferredObject !== null) {
                                                    self.modalInitializedDeferredObject.resolve();
                                                }
                                                deferredObject.resolve();
                                            })
                                            .fail(function(error) {
                                                deferredObject.reject(error);
                                            });
        });

        return _promiseFactory.wait();
    };

    return self;
});

