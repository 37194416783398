define('presentation/developer/facades/scheduledJobsFacade',['common/promises/promiseFactory',
        'externalDependencies/clientApi'
    ],
    function() {
        let _promiseFactory = null;
        let _clientApi = null;

        const _listScheduledJobs = () => {
            return _promiseFactory.defer((deferredObject) => {
                let params = {};
                _clientApi.call("scheduledJobs/listJobsForEnvironment", "GET", params, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        if(result.success === false) {
                            let error = new Error("Failed to retrieve scheduled jobs");
                            error.message = result.errorMessage;
                            deferredObject.reject(error);
                            return;
                        }
                        const scheduledJobs = result.data.map((job) => {
                            return {
                                name: job.name,
                                displayName: job.displayName,
                                isEnabled: ko.observable(job.isEnabled),
                                isDetailsVisible: ko.observable(false)
                            };
                        });
                        deferredObject.resolve(scheduledJobs);
                    });
            });
        };

        const _updateScheduledJobStatus = (jobName, isEnabled) => {
            return _promiseFactory.defer((deferredObject) => {
                let params = {"jobName": jobName, "isEnabled": isEnabled};
                _clientApi.call("scheduledJobs/updateJobStatus", "POST", params, 'application/json')
                    .fail(deferredObject.reject)
                    .done(deferredObject.resolve);
            });
        };

        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;

            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();
        };

        return function() {
            let self = this;
            self.listScheduledJobs = _listScheduledJobs;
            self.updateScheduledJobStatus = _updateScheduledJobStatus;
            self.init = _init;
        };
    });
