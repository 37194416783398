define(
    'presentation/reports/viewModels/reportsViewModel',[
        'plugins/router',
        'common/promises/promiseFactory',
        'presentation/common/window/windowControl',
        'presentation/reports/facades/reportsFacade'
    ], function() {
        return function() {
            var self = this;

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();
            var _windowControl = require('presentation/common/window/windowControl');
            var _facade = null;
            var _router = null;

            var _listNotationReports = function() {
                return _promiseFactory.defer(function(deferredObject) {
                    _facade.getNotationReports()
                        .done(function(reports) {
                            self.reports(reports);
                            deferredObject.resolve(reports);
                        })
                        .fail(function(error) {
                            throw error;
                        });
                });
            };

            var _getReportsCount = function() {
                var asyncPromiseFactory = new PromiseFactoryConstructor();
                asyncPromiseFactory.deferIndefinitely(function(promise) {
                    _facade.getNotationReportCounts(promise);
                    promise
                        .done(function (notationReportCountHash) {
                            self.reports().forEach(function (currentReport) {
                                var reportCount = notationReportCountHash[currentReport.notationReportId];
                                if(reportCount === undefined){
                                    currentReport.reportsCountState("an error occurred.");
                                } else {
                                    currentReport.reportsCountState(reportCount);
                                }
                            });
                        })
                        .fail(function () {
                            self.reports().forEach(function (currentReport) {
                                currentReport.reportsCountState("an error occurred.");
                            });
                        });
                });
            };

            self.executeReport = function(reportPresentationObject) {
                return _promiseFactory.defer(function(promise) {
                    var notationReportId = reportPresentationObject.notationReportId;
                    _windowControl.openNewWindow("reports/execute/" + notationReportId);
                    promise.resolve();
                });
            };

            self.reports = ko.observableArray([]);

            self.activate = function() {
                var Facade = require('presentation/reports/facades/reportsFacade');
                _facade = new Facade();
                _facade.init(_promiseFactory);

                _router = require('plugins/router');

                return _initialize();
            };

            self.activate2 = function() {

                return _initialize();
            };

            var _initialize = function() {
                _listNotationReports()
                    .done(_getReportsCount);


                return _promiseFactory.wait();
            };
        };
    });

