define('presentation/account/facades/billingFacade',['common/promises/promiseFactory',
        'common/storage/workSessionIdStorage',
        'common/authentication/employeeAuthenticationStore',
        'common/converters/passedTimeSpanFormatter',
        'persistence/webSocket/webSocketApp'
],
    function() {
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');

        var PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
        var _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();

        var _workSessionIdStorage = null;

        var _webSocketBillingActions = null;

        function init() {
            _workSessionIdStorage = require('common/storage/workSessionIdStorage');

            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketBillingActions = new WebSocketAppConstructor();
            _webSocketBillingActions.init("crm_billing");

        }

        function init2() {
        }

        function getBillingActions(accountNumber) {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {
                    "employee_id" : _employeeAuthenticationStore.getEmployeeId(),
                    "customer_account_number" : accountNumber
                };
                _webSocketBillingActions.send("get_billing_actions", webSocketParams, function(result) {
                    var billingEvents = result.billingEvents.map(function(currentEvent){
                        return {
                            date : _passedTimeSpanFormatter.formatDateStringUTC(currentEvent.date),
                            description : currentEvent.description
                        };
                    });
                    promise.resolve(billingEvents);
                });
            });
        }

        function getBillingCommunications(accountNumber) {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {
                    "employee_id" : _employeeAuthenticationStore.getEmployeeId(),
                    "customer_account_number" : accountNumber
                };
                _webSocketBillingActions.send("get_billing_communications", webSocketParams, function(result) {
                    promise.resolve(result.billingCommunications);
                });
            });
        }

        function getCreditRequestUrl() {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {};
                _webSocketBillingActions.send("get_credit_request_url", webSocketParams, function(result) {
                    promise.resolve(result);
                });
            });
        }

        return {
            init : init,
            init2 : init2,
            getBillingActions : getBillingActions,
            getBillingCommunications: getBillingCommunications,
            getCreditRequestUrl: getCreditRequestUrl
        };
    });

