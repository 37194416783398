/**
Define values for UI animations that require a setTimeout.
Format: path_to_viewModel_[setting]: value
**/
define('settings/uiAnimationsConfiguration',[],function() {
    return {
        employeeProfile_hideEmployeeProfileOptions: 500
    };
});

