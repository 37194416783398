define('businessServices/router/plugins/redirectRouterPlugin',[], function() {
    function redirectRouter(routeId) {
        switch (routeId) {
            case "redirecting":
                return { stall : true };
            default:
                return true;
        }
    }

    return {
        loadUrlRedirect: redirectRouter
    };
});

