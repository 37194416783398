define('presentation/release/validators/addFeatureToAccountModalValidator',[
    'constants/validationMessageEnumerations',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validPhoneNumberSpecification'
], function() {
    return function() {
        let self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        let _commonValidator = null;
        let _viewModel = null;
        let _facade = null;

        self.registerViewModel = function(viewModel, facade) {
            let validationMessageBase = _validationMessageEnumerations.webcrm.presentation.release.addFeatureToAccount;
            let validationRules = new ValidationRulesConstructor();

            validationRules.array("accounts")
                .arrayField("accountId")
                .addValidation(_isAccountAStringWithValue, validationMessageBase.accountIdRequired)
                .addValidation(_isValidAccount, validationMessageBase.accountIdInvalid);

            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };
        const _isAccountAStringWithValue = (accountId) => {
            return _commonValidator.isStringWithValue(accountId);
        };

        const _isValidAccount = (accountId) => {
            return _facade.isValidAccountId(accountId);
        };
    };
});
