define('presentation/developer/facades/queueDetailsFacade',['common/promises/promiseFactory',
        'common/authentication/employeeAuthenticationStore',
        'persistence/webSocket/webSocketApp'],
    function() {
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _webSocketApp = null;

        var _getQueueMetaData = function(queueName) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "queue_name": queueName
                };
                _webSocketApp.send("get_queue_meta_data", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        var _getMessages = function(queueName) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "queue_name": queueName
                };
                _webSocketApp.send("get_queue_messages", webSocketParams, function (result) {
                    var formattedMessageList = [];
                    result.forEach(function(message) {
                        var formattedMessage = {};
                        formattedMessage.isDetailsVisible = ko.observable(false);
                        formattedMessage.payload = message.payload = JSON.parse(message.payload);
                        if (queueName === "deadLetter") {
                            if (message.payload.message.messageId) {
                                formattedMessage.messageId = message.payload.message.messageId;
                            } else if (message.payload.message["Msg-ID"]) {
                                formattedMessage.messageId = message.payload.message["Msg-ID"];
                            }
                            formattedMessage.queue = message.payload.deliveryInfo.queue;
                            formattedMessage.timeSentToDeadLetter = message.payload.timeSentToDeadLetter;
                        } else {
                            if (message.payload.messageId) {
                                formattedMessage.messageId = message.payload.messageId;
                            } else if (message.payload["Msg-ID"]) {
                                formattedMessage.messageId = message.payload["Msg-ID"];
                            }
                        }
                        formattedMessageList.push(formattedMessage);
                    });
                    deferredObject.resolve(formattedMessageList);
                });
            });
        };

        var _reprocessMessages = function(messageList) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "message_list": messageList
                };
                _webSocketApp.send("reprocess_dead_letter_messages", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        var _toggleQueueState = function(queueName, choice) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    "queue_name": queueName,
                    "choice" : choice
                };
                _webSocketApp.send("toggle_queue_state", webSocketParams, function(result) {
                    deferredObject.resolve(result);
                });
            });
        };

        var _init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("crm_rabbit_mq");
        };

        return function() {
            var self = this;
            self.getQueueMetaData = _getQueueMetaData;
            self.getMessages = _getMessages;
            self.reprocessMessages = _reprocessMessages;
            self.toggleQueueState = _toggleQueueState;
            self.init = _init;
        };
    });

