define('presentation/common/attachmentSelector/viewModels/attachmentSelectorViewModel',[],
function() {
    return function() {
        var self = this;

        self.attachmentsList = ko.observableArray([]);
        self.statementsList = ko.observableArray([]);
        self.selectedAttachmentsAndStatements = ko.observableArray([]);
        self.attachmentsListIsVisible = ko.observable(true);
        self.statementsListIsVisible = ko.observable(false);
        self.popupIsVisible = ko.observable(false);

        self.showAttachmentsList = function() {
            self.attachmentsListIsVisible(true);
            self.statementsListIsVisible(false);
        };

        self.showStatementsList = function() {
            self.attachmentsListIsVisible(false);
            self.statementsListIsVisible(true);
        };

        self.togglePopup = function() {
            var popupIsVisible = self.popupIsVisible();

            if (popupIsVisible === true) {
                self.popupIsVisible(false);
            } else {
                self.popupIsVisible(true);
            }
        };

        self.noAttachmentsAvailable = ko.computed(function() {
            return self.attachmentsList.length === 0;
        });

        self.noStatementsAvailable = ko.computed(function() {
            return self.statementsList.length === 0;
        });

        self.activate = function(settings) {
            return _initialize(settings);
        };

        self.activate2 = function(settings) {
            return _initialize(settings);
        };

        var _initialize = function(settings) {
            if (settings.attachmentsList === undefined) {
                throw new Error("attachmentsList is required");
            } else if (settings.statementsList === undefined) {
                throw new Error("statementsList is required");
            } else if (settings.selectedAttachmentsAndStatements === undefined) {
                throw new Error("selectedAttachmentsAndStatements is required");
            }

            self.attachmentsList = settings.attachmentsList;
            self.statementsList = settings.statementsList;
            self.selectedAttachmentsAndStatements = settings.selectedAttachmentsAndStatements;
        };
    };
});

