define('presentation/settings/validators/addEmployeeValidator',['common/promises/promiseFactory',
        'common/specifications/validEmailAddressSpecification',
        'common/specifications/validPasswordSpecification',
        'presentation/common/validation/commonValidator',
        'presentation/common/validation/validationRule',
        'constants/validationMessageEnumerations'], function() {
    return function() {
        var self = this;

        var _addEmployeeViewModel = null;
        var _addEmployeeFacade = null;
        var _commonValidator = null;

        var ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        var _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();

        var ValidPasswordSpecificationConstructor = require('common/specifications/validPasswordSpecification');
        var _validPasswordSpecification = new ValidPasswordSpecificationConstructor();

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        self.registerViewModel = function(addEmployeeViewModel, addEmployeeFacade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.settings.addEmployee;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("firstName")
                                .addValidation(_validateStringRequired, validationMessageBase.firstNameRequired);
            validationRules.field("lastName")
                                .addValidation(_validateStringRequired, validationMessageBase.lastNameRequired);
            validationRules.field("emailAddress")
                                .addValidation(_validateStringRequired, validationMessageBase.emailAddressRequired)
                                .addValidation(_validateEmailAddressFormatting, validationMessageBase.emailAddressInvalid)
                                .addValidation(_validateEmailAddressDomain, validationMessageBase.emailAddressInvalidDomain)
                                .addValidation(_validateEmailAddressUnique, validationMessageBase.emailAddressUnique);
            validationRules.field("password")
                                .addValidation(_validateStringRequiredWhenNew, validationMessageBase.passwordRequired)
                                .addValidation(_validateStringRequiredWhenEmailChanged, validationMessageBase.passwordRequired)
                                .addValidation(_validatePasswordValid, validationMessageBase.passwordInvalid);
            validationRules.field("confirmPassword")
                                .addValidation(_validateStringRequiredWhenNew, validationMessageBase.passwordConfirmRequired)
                                .addValidation(_validateStringRequiredWhenPasswordSet, validationMessageBase.passwordConfirmRequired)
                                .addValidation(_validatePasswordConfirmValid, validationMessageBase.passwordConfirmDoesNotMatch);
            validationRules.field("employeeNumber")
                                .addValidation(_validateStringRequired, validationMessageBase.employeeNumberRequired)
                                .addValidation(_validateEmployeeNumberValid, validationMessageBase.employeeNumberInvalid)
                                .addValidation(_validateEmployeeNumberUnique, validationMessageBase.employeeNumberUnique);

            _commonValidator = new CommonValidatorConstructor(addEmployeeViewModel, validationRules);
            _addEmployeeViewModel = addEmployeeViewModel;
            _addEmployeeFacade = addEmployeeFacade;
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateStringRequired = function(value) {
            return _commonValidator.isStringWithValue(value);
        };

        var _validateStringRequiredWhenNew = function(value) {
            if (_addEmployeeViewModel.currentMode === _addEmployeeViewModel.CURRENT_MODE_NEW) {
                return _commonValidator.isStringWithValue(value);
            } else {
                return true;
            }
        };

        var _validateStringRequiredWhenEmailChanged = function(value) {
            if (_addEmployeeViewModel.currentMode === _addEmployeeViewModel.CURRENT_MODE_EDIT) {
                var formattedEmailAddress = _addEmployeeViewModel.emailAddress().toLowerCase().trim();
                if (formattedEmailAddress !== _addEmployeeViewModel.originalEmployee.emailAddress) {
                    return _commonValidator.isStringWithValue(value);
                } else {
                    return true;
                }
            }
        };

        var _validateStringRequiredWhenPasswordSet = function(value) {
            if (_addEmployeeViewModel.currentMode === _addEmployeeViewModel.CURRENT_MODE_EDIT) {
                if (_commonValidator.isStringWithValue(_addEmployeeViewModel.password())) {
                    return _commonValidator.isStringWithValue(value);
                } else {
                    return true;
                }
            }
        };

        var _validateEmailAddressFormatting = function(emailAddress) {
            return _validEmailAddressSpecification.isSatisfiedBy(emailAddress.trim());
        };

        var _validateEmailAddressDomain = function(emailAddress) {
            return _validEmailAddressSpecification.isTrestaEmail(emailAddress.trim());
        };

        var _validateEmailAddressUnique = function(emailAddress) {
            var formattedEmailAddress = emailAddress.toLowerCase().trim();
            if ((_addEmployeeViewModel.currentMode === _addEmployeeViewModel.CURRENT_MODE_EDIT) &&
                (formattedEmailAddress === _addEmployeeViewModel.originalEmployee.emailAddress)) {
                return true;
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    _addEmployeeFacade.isEmailAddressUnique(formattedEmailAddress)
                        .done(function(isUnique) {
                            deferredObject.resolve(isUnique);
                        })
                        .fail(function(error) {
                            deferredObject.reject(error);
                        });
                });
            }
        };

        var _validatePasswordValid = function(password) {
            if (_commonValidator.isStringWithValue(password)) {
                return _validPasswordSpecification.isSatisfiedBy(password);
            } else {
                return true;
            }
        };

        var _validatePasswordConfirmValid = function(passwordConfirm) {
            var password = _addEmployeeViewModel.password();

            if (_commonValidator.isStringWithValue(passwordConfirm)) {
                if (password !== passwordConfirm) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };

        var _validateEmployeeNumberValid = function(employeeNumber) {
            var trimmedEmployeeNumber = employeeNumber.trim();
            var isAlphaNumeric = _commonValidator.isAlphanumericValues(employeeNumber);
            if ((trimmedEmployeeNumber.length === 4) && isAlphaNumeric) {
                return true;
            }
            return false;
        };

        var _validateEmployeeNumberUnique = function(employeeNumber) {
            if ((_addEmployeeViewModel.currentMode === _addEmployeeViewModel.CURRENT_MODE_EDIT) &&
                (employeeNumber === _addEmployeeViewModel.originalEmployee.employeeNumber)) {
                return true;
            } else {
                return _promiseFactory.defer(function(deferredObject) {
                    _addEmployeeFacade.isEmployeeNumberUnique(employeeNumber.trim())
                        .done(function(isUnique) {
                            deferredObject.resolve(isUnique);
                        })
                        .fail(function(error) {
                            deferredObject.reject(error);
                        });
                });
            }
        };

    };
});

