define('presentation/forgotPassword/facades/forgotPasswordFacade',['entities/emailEntity',
        'businessServices/authentication/authenticationProvider'],
 function() {

    var _promiseFactory = null;
    var _authenticationProvider = null;

    var EmailEntityConstructor = require('entities/emailEntity');

    var _sendPasswordResetEmail = function(userEmail) {
        return _promiseFactory.defer(function(deferredObject) {
            var emailEntity = new EmailEntityConstructor();
            emailEntity.to.push(userEmail);

            _authenticationProvider.forgotPassword(emailEntity)
                .done(function() {
                    deferredObject.resolve();
                })
                .fail(function(error) {
                    deferredObject.reject(error);
                });
        });
    };

    var _doesEmailAddressExist = function(emailAddress) {
        return _promiseFactory.defer(function(deferredObject) {
            _authenticationProvider.validateUsername(emailAddress)
                .done(function(result) {
                    var emailFound = result.emailAddressFound;
                    deferredObject.resolve(emailFound);
                })
                .fail(function(error) {
                    deferredObject.reject(error);
                });
        });
    };

    var _init = function(promiseFactory) {
        _promiseFactory = promiseFactory;

        var AuthenticationProviderConstructor = require('businessServices/authentication/authenticationProvider');
        _authenticationProvider = new AuthenticationProviderConstructor();
        _authenticationProvider.init();

    };

    var _init2 = function(promiseFactory, authenticationProvider) {
        _promiseFactory = promiseFactory;
        _authenticationProvider = authenticationProvider;
    };

    return function() {
        var self = this;

        self.init = _init;
        self.init2 = _init2;
        self.sendPasswordResetEmail = _sendPasswordResetEmail;
        self.doesEmailAddressExist = _doesEmailAddressExist;
    };
});

