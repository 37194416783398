define('presentation/marketing/viewModels/indexViewModel',['businessServices/router/crmRouter'],
function() {
    return function() {
        var self = this;
        var _router = null;

        self.activate = function() {
            _router = require('businessServices/router/crmRouter');
            return _initialize();
        };

        var _initialize = function() {
            _router.navigate("/marketing/emailNotifications");
        };
    };
});

