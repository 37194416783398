define('presentation/developer/facades/callMonitoringFacade',[
        'externalDependencies/clientApi'
    ],
    function() {
        let _clientApi = null;

        const _getHostedNumber = () => _clientApi.call("callMonitoring/getHostedNumber", "GET", {}, 'application/json');

        const _setHostedNumber = (hostedNumber) => {
            let params = {
                hostedNumber: hostedNumber
            };
            return _clientApi.call("callMonitoring/setHostedNumber", "POST", params, 'application/json');
        };

        const _init = () => {
            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();
        };

        return function() {
            let self = this;
            self.getHostedNumber = _getHostedNumber;
            self.setHostedNumber = _setHostedNumber;
            self.init = _init;
        };
    });
