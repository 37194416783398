define('presentation/developer/viewModels/deactivateNumbersModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/deactivateNumbersFacade',
], function() {
    return function(onModalSavePromise) {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        let _facade = null;
        let _modalService = null;
        let _onModalSavePromise = onModalSavePromise;

        self.cancelButtonText = "Cancel";
        self.closeButtonText = "Ok";
        self.modalTitle = 'Deactivate Phone Numbers';

        self.fromAccountNumber = ko.observable('');
        self.isComplete = ko.observable(false);
        self.moveSuccessfulNumbers = ko.observable('');
        self.moveFailedNumbers = ko.observable('');
        self.moveResultStatus = ko.observable('');
        self.selectedNumbers = ko.observable([]);
        self.toAccountNumber = ko.observable('');

        self.moveButtonText = ko.computed(() => "Deactivate");

        self.delay = ko.pureComputed(() => {
            const mSecondDelay = Math.ceil((self.selectedNumbers().length * 100) / 100);
            const date = new Date(0);
            date.setSeconds(mSecondDelay);
            return date.toISOString().substr(11, 8);
        });

        self.submitForm = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.deactivatePhoneNumbers(self.selectedNumbers(), self.fromAccountNumber())
                    .fail(deferredObject.reject)
                    .done((result) => {
                        if (_onModalSavePromise) {
                            _onModalSavePromise.resolve({action: "submit"});
                        }
                        self.isComplete(true);
                        self.moveResultStatus(result.status);
                        self.moveSuccessfulNumbers(result.successful.sort());
                        self.moveFailedNumbers(result.failures.sort());
                        deferredObject.resolve();
                    });
            });
        };

        self.cancelForm = () => {
            if (_onModalSavePromise) {
                _onModalSavePromise.resolve({action: "cancel"});
            }
            _modalService.closeModal(self);
        };

        self.activate = () => {
            const Facade = require('presentation/developer/facades/deactivateNumbersFacade');
            _facade = new Facade();
            _facade.init();

            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});


