define('presentation/account/viewModels/activityViewModel',['common/promises/promiseFactory',
        'presentation/common/window/windowControl',
        'presentation/account/activity/email/viewModels/emailViewModel',
        'presentation/account/activity/notation/viewModels/addNotationViewModel',
        'presentation/account/activity/notation/viewModels/updateNotationViewModel',
        'presentation/account/facades/activityFacade',
        'presentation/common/modal'],
    function () {
        return function () {
            var self = this;

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();
            var _modalService = null;
            var _emailViewModel = null;
            var _addNotationViewModel = null;
            var UpdateNotationViewModelConstructor = null;
            var _accountNumber = null;
            var _facade = null;
            var _sortBy = "createdDateTime";
            var _sortAsc = null;
            var _rowsPerPage = 20;
            var _currentPage = 1;
            var _showAllDetails = false;
            var _totalResults = 0;
            var _lobbyNewTicketUrl = null;
            var _windowControl = null;
            var _queryStringArguments = null;
            var _getNextBatchCalled = false;

            var _sortColumn = function(column, sortAsc) {
                _sortBy = column;
                _sortAsc = sortAsc;
                _currentPage = 1;
                return _getNotations();
            };

            var _formatColumn = function(column, row) {
                if (row.status === "open") {
                    return "status-open";
                } else {
                    return "status-closed";
                }
            };

            var _getNotations = function() {
                _currentPage = 1;
                return _promiseFactory.deferIndefinitely(function(deferredObject) {
                    _facade.getNotations(_accountNumber, _rowsPerPage, _currentPage, _sortBy, _sortAsc, self.notationFilter(), _showAllDetails)
                        .done(function(results) {
                            _totalResults = results.totalResults;
                            self.notations(results.results);
                            deferredObject.resolve();
                        })
                        .fail(function(error) {
                            deferredObject.reject(error);
                        });
                });
            };

            var _showEmailNotation = function(callId) {
                _currentPage = 1;
                return _promiseFactory.deferIndefinitely(function(deferredObject) {
                    var rowsPerPage = 1;
                    var currentPage = 1;
                    var sortBy = "";
                    var sortAsc = null;

                    self.notationFilter(callId);
                    _showAllDetails = true;

                    _facade.getNotations(_accountNumber, rowsPerPage, currentPage, sortBy, sortAsc, self.notationFilter(), _showAllDetails)
                        .done(function(results) {
                            _totalResults = results.totalResults;
                            self.notations(results.results);
                            deferredObject.resolve();
                        })
                        .fail(function(error) {
                            deferredObject.reject(error);
                        });
                });
            };

            var _getNotationsNextPage = function() {
                return _promiseFactory.deferIndefinitely(function(deferredObject) {
                    if (_getNextBatchCalled === false) {
                        _getNextBatchCalled = true;
                        _currentPage++;
                        if (self.notations().length < _totalResults) {
                            _facade.getNotations(_accountNumber, _rowsPerPage, _currentPage, _sortBy, _sortAsc, self.notationFilter(), _showAllDetails)
                                .fail(deferredObject.reject)
                                .done(function(results) {
                                    _totalResults = results.totalResults;
                                    results.results.forEach(function(notation) {
                                        self.notations.push(notation);
                                    });
                                    _getNextBatchCalled = false;
                                    deferredObject.resolve();
                                });
                        } else {
                            _getNextBatchCalled = false;
                            deferredObject.resolve();
                        }
                    } else {
                        deferredObject.resolve();
                    }
                });
            };

            var _initializeTicketingUrl = function() {
                return _promiseFactory.defer(function(promise){
                    _facade.getLobbyTicketingUrl()
                        .done(function(ticketingUrls){
                            _lobbyNewTicketUrl = ticketingUrls.ticketsUrl;
                            promise.resolve();
                        })
                        .fail(function(error){
                            promise.reject(error);
                        });
                });
            };

            var _showFilteredNotationsDetails = function(filterText) {
                _showAllDetails = true;
                _sortBy = "createdDateTime";
                _sortAsc = false;
                _currentPage = 1;
                self.notationFilter(filterText);

                self.dataGridConfig.columns.forEach(function(column) {
                    if (column.key === _sortBy) {
                        column.sortAsc(_sortAsc);
                    } else {
                        column.sortAsc(null);
                    }
                });

                return _getNotations();
            };

            self.notations = ko.observableArray([]);
            self.dataGridConfig = {};
            self.notationFilter = ko.observable();

            self.sendEmail = function() {
                if (_emailViewModel.isOpen === true) {
                    _modalService.toggleMinimizeModal(_emailViewModel);
                } else {
                    _emailViewModel.setInitialSendToEmailAddress(null);
                    _emailViewModel.setReplyMode(false);
                    _emailViewModel.setReplyToStoredDocumentId(null);
                    _emailViewModel.setReplyToNotationId(null);
                    _emailViewModel.setReplyContentUrl(null);
                    _modalService.showModal(_emailViewModel);
                }
            };

            self.launchEmailReply = function(emailNotation) {
                return _promiseFactory.deferIndefinitely(function(deferredObject){
                    _emailViewModel.setInitialSendToEmailAddresses(emailNotation.replyTo);
                    _emailViewModel.setReplyMode(true);
                    _emailViewModel.setReplyToStoredDocumentId(emailNotation.storedDocumentId);
                    _emailViewModel.setReplyToNotationId(emailNotation.replyToNotationId);
                    _emailViewModel.setReplyToSubject(emailNotation.subject);
                    _emailViewModel.setReplyContentUrl(emailNotation.storedDocumentUrl);
                    _emailViewModel.modalInitializedDeferredObject = deferredObject;
                    _modalService.showModal(_emailViewModel);
                });
            };

            self.addNotation = function() {
                _modalService.showModal(_addNotationViewModel);
            };

            self.updateNotation = function(notation) {
                var promise =  _promiseFactory.deferIndefinitely();
                promise
                    .done(function(dataChanged) {
                        if (dataChanged === true) {
                            _getNotations();
                        }
                    })
                    .fail(function(error) {
                        throw error;
                    });

                var updateNotationViewModel = new UpdateNotationViewModelConstructor(_accountNumber, notation.id, promise);

                _modalService.showModal(updateNotationViewModel);
            };

            self.searchNotations = function() {
                _getNotations();
            };

            self.openLobbyTicket = function () {
                _windowControl.openNewWindow(_lobbyNewTicketUrl);
            };

            self.toggleAllDetails = function() {
                if (_showAllDetails === true) {
                    _showAllDetails = false;
                } else {
                    _showAllDetails = true;
                }

                var allNotations = self.notations();
                allNotations.forEach(function(notation) {
                    notation.isDetailsVisible(_showAllDetails);
                });
            };

            self.resetNotationGrid = function() {
                _showAllDetails = false;
                self.notationFilter("");
                _sortBy = "createdDateTime";
                _sortAsc = false;

                self.dataGridConfig.columns.forEach(function(column) {
                    if (column.key === _sortBy) {
                        column.sortAsc(_sortAsc);
                    } else {
                        column.sortAsc(null);
                    }
                });

                _getNotations();
            };

            self.showAssociatedNotationsByNotationSessionId = function(associatedNotationData) {
                return _showFilteredNotationsDetails(associatedNotationData.notationSessionId);
            };

            self.showAssociatedNotationsByWorkSessionId = function(associatedNotationData) {
                return _showFilteredNotationsDetails(associatedNotationData.workSessionId);
            };

            self.activate = function (accountNumber, queryStringArguments, isCalledFromSidebar) {
                if (queryStringArguments !== undefined && queryStringArguments !== null) {
                    _queryStringArguments = queryStringArguments;
                }
                if (isCalledFromSidebar !== true) {
                    return;
                }
                _accountNumber = accountNumber;

                _emailViewModel = require('presentation/account/activity/email/viewModels/emailViewModel');
                _emailViewModel.setAccountNumber(_accountNumber);

                var AddNotationViewModelConstructor = require('presentation/account/activity/notation/viewModels/addNotationViewModel');
                _addNotationViewModel = new AddNotationViewModelConstructor(accountNumber);

                UpdateNotationViewModelConstructor = require('presentation/account/activity/notation/viewModels/updateNotationViewModel');

                _windowControl = require('presentation/common/window/windowControl');

                var Facade = require('presentation/account/facades/activityFacade');
                _facade = new Facade();
                _facade.init(_promiseFactory);

                var Modal = require('presentation/common/modal');
                _modalService = new Modal();

                return _initialize();
            };

            self.activate2 = function (accountNumber, modalService, emailViewModel, facade, addNotationViewModel, updateNotationViewModelConstructor) {
                _accountNumber = accountNumber;
                _modalService = modalService;
                _emailViewModel = emailViewModel;
                _facade = facade;
                _addNotationViewModel = addNotationViewModel;
                UpdateNotationViewModelConstructor = updateNotationViewModelConstructor;

                return _initialize();
            };

            var _initialize = function () {
                _initializeTicketingUrl();

                if (_queryStringArguments !== null && _queryStringArguments.action === "showEmail" && _queryStringArguments.callId !== undefined) {
                    _showEmailNotation(_queryStringArguments.callId);
                } else {
                    _getNotations();
                }

                self.dataGridConfig.dataSet = self.notations;
                self.dataGridConfig.sortColumnCallback = _sortColumn;
                self.dataGridConfig.cellCssFormat = _formatColumn;
                self.dataGridConfig.onGetNextPage = _getNotationsNextPage;
                self.dataGridConfig.columns = [
                    {"header": "Category", "key": "categoryName", "isSortable": false},
                    {"header": "Event", "key": "eventName", "isSortable": false},
                    {"header": "By", "key": "securityContext", "isSortable": false},
                    {"header": "Created", "key": "createdDateTime", "isSortable": true, "sortAsc": false},
                    {"header": "Due On", "key": "dueByDate", "isSortable": true},
                    {"header": "Comments", "key": "comment", "isSortable": true},
                ];

                return _promiseFactory.wait();
            };

        };
    });

