define('presentation/account/presentationObjects/userOtherContactInfoPhoneNumbersPresentationObject',[],function() {
    return function(){
        var self = this;
        self.otherContactInfoPhoneNumberId = ko.observable("");
        self.type = ko.observable("");
        self.typeOrder = ko.observable("");
        self.phoneNumber = ko.observable("");
        self.isDefault = ko.observable(false);
    };
});

