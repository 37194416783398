define('presentation/account/activity/email/validators/composeViewModelValidator',['presentation/common/validation/validationRule',
        'presentation/common/validation/commonValidator',
        'constants/validationMessageEnumerations',
        'common/promises/promiseFactory'], function() {
    return function() {
        var self = this;

        var _viewModel;
        var _facade;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _commonValidator = null;

        self.registerViewModel = function(viewModel, facade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.account.activity.email;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("selectedTemplate")
                                .addValidation(_validateTemplateRequired, validationMessageBase.templateRequired);
            validationRules.field("toEmailAddresses")
                                .addValidation(_validateEmailRequired, validationMessageBase.emailRequired);
            validationRules.field("subject")
                                .addValidation(_validateSubjectRequired, validationMessageBase.subjectRequired);
            validationRules.field("notationComments")
                                .addValidation(_validateNotationCommentsRequired, validationMessageBase.notationCommentsRequired);

            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        self.resetValidation = function() {
            _viewModel.selectedTemplate.resetValidation();
            _viewModel.toEmailAddresses.resetValidation();
            _viewModel.subject.resetValidation();
            _viewModel.notationComments.resetValidation();
        };

        var _validateTemplateRequired = function(template) {
            return _commonValidator.isStringWithValue(template.emailTemplateId);
        };

        var _validateEmailRequired = function(email) {
            if (_viewModel.noReplyNeeded() === true) {
                return true;
            } else {
                if (email.length > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        var _validateSubjectRequired = function(subject) {
            if (_viewModel.noReplyNeeded() === true) {
                return true;
            } else {
                if (_commonValidator.isStringWithValue(_viewModel.selectedTemplate().emailTemplateId)) {
                    return _commonValidator.isStringWithValue(subject);
                } else {
                    return true;
                }
            }
        };

        var _validateNotationCommentsRequired = function(notationComments) {
            return _commonValidator.isStringWithValue(notationComments);
        };

    };
});

