define('presentation/developer/viewModels/fraudSettingsViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/fraudSettingsFacade',
    'presentation/developer/validators/fraudSettingsValidator'
], function() {
    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _facade = null;
        let _validator = null;

        self.emailFraudScore = ko.observable();
        self.ipFraudScore = ko.observable();
        self.paymentMethodFraudScore = ko.observable();
        self.phoneNumberFraudScore = ko.observable();
        self.bypassFraudChecks = ko.observable(false);

        const _getFraudSettings = () => _promiseFactory.deferIndefinitely(promise => {
            _facade.getFraudSettings()
                .fail(promise.reject)
                .done(result => {
                    self.emailFraudScore(result.data.emailFraudScore);
                    self.ipFraudScore(result.data.ipFraudScore);
                    self.paymentMethodFraudScore(result.data.paymentMethodFraudScore);
                    self.phoneNumberFraudScore(result.data.phoneNumberFraudScore);
                    self.bypassFraudChecks(result.data.bypassFraudChecks);
                    promise.resolve();
                });
        });

        self.updateFraudSettings = () => _promiseFactory.deferIndefinitely(promise => {
            _validator.validate()
                .done((isValid) => {
                    if (!isValid) {
                        promise.resolve(false);
                        return;
                    }
                    _facade.updateFraudSettings(self.emailFraudScore(), self.ipFraudScore(), self.paymentMethodFraudScore(), self.phoneNumberFraudScore(), self.bypassFraudChecks())
                        .fail(promise.reject)
                        .done(result => {
                            self.emailFraudScore(result.data.emailFraudScore);
                            self.ipFraudScore(result.data.ipFraudScore);
                            self.paymentMethodFraudScore(result.data.paymentMethodFraudScore);
                            self.phoneNumberFraudScore(result.data.phoneNumberFraudScore);
                            self.bypassFraudChecks(result.data.bypassFraudChecks);
                            promise.resolve();
                        });
                });
        });

        self.activate = () => {
            const Facade = require('presentation/developer/facades/fraudSettingsFacade');
            _facade = new Facade();
            _facade.init();

            const ValidatorConstructor = require('presentation/developer/validators/fraudSettingsValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {

            _validator.registerViewModel(self, _facade);

            _getFraudSettings();

            return _promiseFactory.wait();
        };
    };
});

