define('presentation/common/clockDisplay/viewModels/clockDisplayViewModel',['businessServices/converters/timeZoneDateTimeFormatter'],
function() {
    return function() {
        var self = this;
        var _timeZone = null;
        var _tickIntervalId = null;

        var TimeZoneDateTimeFormatterConstructor = require('businessServices/converters/timeZoneDateTimeFormatter');
        var _timeZoneDateTimeFormatter = new TimeZoneDateTimeFormatterConstructor();

        var _tick = function() {
            var displayTime = _timeZoneDateTimeFormatter.formatTimeNow(_timeZone);
            self.clockDisplay(displayTime);
        };

        self.clockDisplay = ko.observable();

        self.detached = function() {
            clearInterval(_tickIntervalId);
        };

        self.activate = function(settings) {
            if (settings.timeZone === undefined) {
                throw new Error("timeZone is required");
            } else {
                if (typeof settings.timeZone === "function") {
                    _timeZone = settings.timeZone();
                } else {
                    _timeZone = settings.timeZone;
                }
            }

            return _initialize();
        };

        var _initialize = function() {
            _tick();
            _tickIntervalId = setInterval(_tick, 1000);
        };
    };
});

