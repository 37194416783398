define('presentation/settings/viewModels/addNotationEventViewModel',['common/promises/promiseFactory',
    'plugins/router',
    'presentation/settings/facades/addNotationEventFacade',
    'presentation/settings/validators/addNotationEventViewModelValidator'
], function () {
    return function(){
        var self = this;
        var _router = null;
        var _facade = null;
        var _validator = null;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var DEFAULT_STATUS_LIST = [
            { "value": "open",      "display":"Open" },
            { "value": "closed",       "display":"Closed" }
            
        ];

        self.notationEventId = ko.observable('');
        self.isSystemNotation = ko.observable();
        self.isNewNotationEvent = true;
        self.notationCategories = [];
        self.notationCategory = ko.observable();
        self.statusList = DEFAULT_STATUS_LIST;
        self.statusSelected = ko.observable();
        self.defaultStatus = "closed";
        self.notationEventName = ko.observable('');
        self.isActive = ko.observable(true);
        self.formTitle = "Add Notation Event";
        self.saveButtonTitle = "Save";
        self.showDefaultStatus = ko.observable(true);
        self.showIsActive = ko.observable(true);

        self.cancelForm = function () {
            return _promiseFactory.defer(function (deferredObject) {
                _router.navigate("/settings/notationEvent");
                deferredObject.resolve();
            });
        };
        self.saveNotationEvent = function() {
            return _promiseFactory.defer(function (deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid) {
                            if (self.notationEventId) {
                                _facade.updateNotationEvent(self.notationEventId, self.notationEventName(), self.notationCategory().notationCategoryId,
                                    self.isActive(), self.statusSelected().value, self.isSystemNotation())
                                    .done(function (updateResult) {
                                        deferredObject.resolve(updateResult);
                                        _router.navigate('/settings/notationEvent');
                                    })
                                    .fail(function (error) {
                                        error.notationEventId = self.notationEventId;
                                        error.notationCategoryId = self.notationCategory().notationCategoryId;
                                        error.notationCategoryDescription = self.notationCategory().notationCategoryDescription;
                                        error.notationEventName = self.notationEventName();
                                        error.isActive = self.isActive();
                                        error.defaultStatus = self.statusSelected().value;
                                        deferredObject.reject(error);
                                    });
                            }
                            else {
                                _facade.addNotationEvent(self.notationEventName(), self.notationCategory().notationCategoryId, self.isActive(), self.statusSelected().value)
                                    .done(function (addResult) {
                                        deferredObject.resolve(addResult);
                                        _router.navigate('/settings/notationEvent');
                                    })
                                    .fail(function (error) {
                                        error.notationCategoryId = self.notationCategory().notationCategoryId;
                                        error.notationCategoryDescription = self.notationCategory().notationCategoryDescription;
                                        error.notationEventName = self.notationEventName();
                                        error.isActive = self.isActive();
                                        error.defaultStatus = self.statusSelected().value;
                                        deferredObject.reject(error);
                                    });
                            }
                        } else {
                            deferredObject.resolve();
                        }    
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };


        self.activate = function(notationEventId) {
            _facade = require('presentation/settings/facades/addNotationEventFacade');
            
            _facade.init(_promiseFactory);
            _router = require('plugins/router');
            self.notationEventId = notationEventId;

            var ValidatorConstructor = require('presentation/settings/validators/addNotationEventViewModelValidator');
            _validator = new ValidatorConstructor();
            return _initialize();
        };

        self.activate2 = function (router, facade, notationEventId, validator) {
            _facade = facade;
            _router = router;

            self.notationEventId = notationEventId;
            _validator = validator;
            return _initialize();
        };

        var _initialize = function() {
            _facade.getAllNotationCategories().done(function (notationCategories) {
               
                self.notationCategories = notationCategories;

                if (self.notationEventId) {
                    self.formTitle = "Edit Notation Event";
                    self.isNewNotationEvent = false;
                    self.saveButtonTitle = "Save";
                   
                    _facade.findNotationEvent(self.notationEventId).done(function (notationEvent) {
                        if (notationEvent) {
                            self.notationCategory(self.notationCategories.find(function (notationCategory) {
                               return notationCategory.notationCategoryId === notationEvent.notationCategoryId;
                            }));
                            self.notationEventName(notationEvent.eventName);
                            self.isActive(notationEvent.isActive);
                            
                            self.isSystemNotation(notationEvent.isSystemNotation);
                            if ((self.isSystemNotation() === false) || (self.isSystemNotation() === "undefined")) {
                                self.showDefaultStatus(true);
                                self.showIsActive(true);
                            } else {
                                self.showDefaultStatus(false);
                                self.showIsActive(false);
                            }
                            
                            for (var x = 0; x < DEFAULT_STATUS_LIST.length; x++) {
                                var statusItem = DEFAULT_STATUS_LIST[x];
                                if (statusItem.value === notationEvent.defaultStatus) {
                                    self.statusSelected(statusItem);
                                }
                            }

                            _validator.registerViewModel(self, _facade);
                        }
                    });

                } else {
                    self.formTitle = "New Notation Event";
                    self.saveButtonTitle = "Create";
                    self.showDefaultStatus(true);
                    self.showIsActive(true);
                    _validator.registerViewModel(self, _facade);
                }
            });
            return _promiseFactory.wait();
        };    
    };
});

          

           
