define('presentation/account/activity/email/facades/composeFacade',['persistence/webSocket/webSocketApp',
        'common/authentication/employeeAuthenticationStore'],
function() {
    var _promiseFactory = null;
    var _webSocketApp = null;

    var _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');
    var _employeeId = _employeeAuthenticationStore.getEmployeeId();

    var _sendEmail = function(emailOptions) {
        return _promiseFactory.defer(function(deferredObject) {
            var accountNumber = emailOptions.accountNumber;
            var emailTemplateId = emailOptions.emailTemplateId;
            var toAddresses = emailOptions.toAddresses;
            var ccAddresses = [];
            var bccAddresses = [];
            var subject = emailOptions.subject;
            var message = emailOptions.message;
            var attachments = [];
            var statements = [];
            var notationComments = emailOptions.notationComments;
            var replyToNotationId = emailOptions.replyToNotationId;
            var replyToStoredDocumentId = emailOptions.replyToStoredDocumentId;
            var noReplyNeeded = emailOptions.noReplyNeeded;

            if (emailOptions.ccAddresses !== undefined && emailOptions.ccAddresses.length > 0) {
                ccAddresses = emailOptions.ccAddresses;
            }

            if (emailOptions.bccAddresses !== undefined && emailOptions.bccAddresses.length > 0) {
                bccAddresses = emailOptions.bccAddresses;
            }

            if (emailOptions.attachmentsAndStatements !== undefined && emailOptions.attachmentsAndStatements.length > 0) {
                for (var i=0; i < emailOptions.attachmentsAndStatements.length; i++) {
                    var currentAttachment = emailOptions.attachmentsAndStatements[i];
                    if (currentAttachment.emailDocumentAttachmentId !== undefined) {
                        attachments.push(currentAttachment.emailDocumentAttachmentId);
                    } else if (currentAttachment.statementId !== undefined) {
                        statements.push(currentAttachment.statementId);
                    }
                }
            }

            var params = {
                "account_number": accountNumber,
                "employee_id": _employeeId,
                "email_template_id": emailTemplateId,
                "to_addresses": toAddresses,
                "cc_addresses": ccAddresses,
                "bcc_addresses": bccAddresses,
                "subject": subject,
                "message": message,
                "attachments": attachments,
                "statements": statements,
                "notation_comments": notationComments,
                "no_reply_needed": noReplyNeeded,
                "reply_to_notation_id": replyToNotationId,
                "reply_to_stored_document_id": replyToStoredDocumentId
            };

            _webSocketApp.send("send_email", params, function(result) {
                deferredObject.resolve(result);
            });
        });
    };

    var _getEmailTemplates = function(accountNumber, replyToStoredDocumentId) {
        return _promiseFactory.defer(function(deferredObject) {
            var params = {
                "account_number": accountNumber,
                "employee_id": _employeeId,
                "reply_to_stored_document_id": replyToStoredDocumentId
            };
            _webSocketApp.send("get_email_templates", params, function(result) {
                deferredObject.resolve(result);
            });
        });
    };

    var _getDocumentAttachments = function(accountNumber) {
        return _promiseFactory.defer(function(deferredObject) {
            var params = {
                "account_number": accountNumber,
                "employee_id": _employeeId
            };
            _webSocketApp.send("get_document_attachments", params, function(result) {
                deferredObject.resolve(result);
            });
        });
    };

    var _getStatements = function(accountNumber) {
        return _promiseFactory.defer(function(deferredObject) {
            var params = {
                "account_number": accountNumber,
                "employee_id": _employeeId
            };
            _webSocketApp.send("get_statements", params, function(result) {
                deferredObject.resolve(result);
            });
        });
    };

    var _init = function(promiseFactory) {
        _promiseFactory = promiseFactory;

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("crm_account_email");
    };

    var _init2 = function(promiseFactory, webSocketApp) {
        _promiseFactory = promiseFactory;
        _webSocketApp = webSocketApp;
    };

    return function() {
        var self = this;

        self.init = _init;
        self.init2 = _init2;

        self.sendEmail = _sendEmail;
        self.getEmailTemplates = _getEmailTemplates;
        self.getDocumentAttachments = _getDocumentAttachments;
        self.getStatements = _getStatements;
    };
});

