define('presentation/settings/validators/addNotationEventViewModelValidator',['presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'constants/validationMessageEnumerations',
    'common/promises/promiseFactory'], function() {
    return function() {
        var self = this;

        var _addNotationEventViewModel;
        var _addNotationEventFacade;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();


        var _commonValidator = null;


        self.registerViewModel = function(addNotationEventViewModel, addNotationEventFacade) {
            
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.settings.addNotationEvent;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("notationEventName")
                .addValidation(_validateNotationEventDescriptionRequired, validationMessageBase.notationEventDescriptionRequired)
                .addValidation(_validateNotationEventDescriptionUnique, validationMessageBase.notationEventDescriptionUnique);
            validationRules.field("notationCategory")
                .addValidation(_validateNotationCategoryRequired, validationMessageBase.notationCategoryRequired);
            _addNotationEventViewModel = addNotationEventViewModel;
            _addNotationEventFacade = addNotationEventFacade;
            _commonValidator = new CommonValidatorConstructor(_addNotationEventViewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateNotationEventDescriptionRequired = function(eventName) {
            return _commonValidator.isStringWithValue(eventName);
        };

        var _validateNotationEventDescriptionUnique = function(eventName) {
            return _promiseFactory.defer(function(deferredResult) {
                var notationEventId = _addNotationEventViewModel.notationEventId;
                var notationCategoryId = _addNotationEventViewModel.notationCategory().notationCategoryId;
                _addNotationEventFacade.isNotationEventDescriptionUnique(notationEventId, notationCategoryId, eventName)
                    .done(function(isUnique) {
                        deferredResult.resolve(isUnique);
                    })
                    .fail(function(error) {
                        error.notationEventId = notationEventId;
                        error.notationCategoryId = notationCategoryId;
                        error.eventName = eventName;
                        deferredResult.reject(error);
                    });
            });
        };
        
        var _validateNotationCategoryRequired = function(notationCategory) {
            if (notationCategory === undefined) {
                return false;
            } else {
                return _commonValidator.isStringWithValue(notationCategory.notationCategoryId);
            }
        };

    };
}); 

