define('businessServices/router/authorizationPlugins/authorizationPlugin',[
    'settings/navigationConfiguration',
    'businessServices/authentication/authenticatedEmployee',
    'businessServices/authentication/authenticationProvider',
    'common/promises/promiseFactory'
], function(navigationConfiguration,
            authenticatedEmployee,
            AuthenticationProviderConstructor,
            PromiseFactoryConstructor) {

    function isRouteLoginPage(routeId) {
        return routeId === "login";
    }

    function isRouteSecure(routeId) {
        var routeInfo = navigationConfiguration.routesById[routeId];
        return routeInfo.isSecure;
    }

    function authorizationPlugin(routeId) {
        if (authenticatedEmployee.isAuthenticated()) {
            var authenticationProvider = new AuthenticationProviderConstructor();
            authenticationProvider.init();
            var promiseFactory = new PromiseFactoryConstructor();

            return promiseFactory.defer(function(promise) {
                authenticationProvider.configureAuthentication()
                    .done(function(results) {
                        if (results) {
                            if (isRouteLoginPage(routeId)) {
                                promise.resolve({routeUrl: navigationConfiguration.landingPageRouteUrl});

                            } else {
                                promise.resolve(true);
                            }

                        } else {
                            promise.resolve({routeUrl: navigationConfiguration.loginPageRouteUrl});
                        }
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            });
        }

        if (isRouteSecure(routeId)) {
            return {routeUrl: navigationConfiguration.loginPageRouteUrl};
        }
        return true;
    }

    return {
        guardRoute: authorizationPlugin
    };
});

