define('presentation/login/validators/loginViewModelValidator',['presentation/common/validation/validationRule',
        'presentation/common/validation/commonValidator',
        'constants/validationMessageEnumerations'], function() {
    return function() {
        var self = this;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');
        var _commonValidator = null;

        self.registerViewModel = function(loginViewModel) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.login;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("username")
                                .addValidation(_validateUsernameRequired, validationMessageBase.usernameRequired);
            validationRules.field("password")
                                .addValidation(_validatePasswordRequired, validationMessageBase.passwordRequired);

            _commonValidator = new CommonValidatorConstructor(loginViewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateUsernameRequired = function(username) {
            return _commonValidator.isStringWithValue(username);
        };

        var _validatePasswordRequired = function(password) {
            return _commonValidator.isStringWithValue(password);
        };
    };
});

