define('presentation/settings/facades/addNotationCategoryFacade',['persistence/webSocket/webSocketApp',
    'common/encryption/crypto'], function() {
    
    var _promiseFactory = null;
    var _webSocketApp = null;
    
    var _getAllNotationCategories = function() {
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {};
            _webSocketApp.send("get_notation_categories", webSocketParams, function(result) {
                deferredObject.resolve(result);
            });
        });
    };
    
    var _findNotationCategory = function(notationCategoryId){
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {"notationCategoryId" : notationCategoryId};
            _webSocketApp.send("get_notation_category", webSocketParams, function(result) {
                deferredObject.resolve(result);
            });
        });

    };

   
    
    
    
    var _isNotationCategoryDescriptionUnique = function(notationCategoryId, notationCategoryDescription) {
        return _promiseFactory.defer(function(deferredObject) {
            _getAllNotationCategories().done(function(notationCategories) {
                var formattedExpectedNotationCategoryDescription = notationCategoryDescription.toLowerCase();
                
                var isDuplicate = notationCategories.some(function(notationCategory) {
                    if (notationCategory.description.toLowerCase() === formattedExpectedNotationCategoryDescription) {
                        if (notationCategory.notationCategoryId !== notationCategoryId) {
                            // The Description belongs to another Category
                            return true;
                        }
                    }
                    return false;
                });
                var isUnique = !isDuplicate;
                // No other Categories have the description
                deferredObject.resolve(isUnique);
            })
                .fail(function(error) {
                    error.notationCategoryId = notationCategoryId;
                    error.notationCategoryDescription = notationCategoryDescription;
                    deferredObject.reject(error);
                });
        });
    };

    var _updateNotationCategory = function(notationCategoryId, description, isActive){
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {"notationCategoryId" : notationCategoryId,
                "description" : description,
                "isActive" : isActive};
            _webSocketApp.send("update_notation_category", webSocketParams, function(result) {
                deferredObject.resolve(result);
            });
        });

    };

    var _addNotationCategory = function(description, isActive){
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {"description" : description,
                "isActive" : isActive};
            _webSocketApp.send("add_notation_category", webSocketParams, function(result) {
                deferredObject.resolve(result);
            });
        });
    };

    var _init = function(promiseFactory) {
        _promiseFactory = promiseFactory;

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("notation_categories");
    };

    var _init2 = function(promiseFactory, webSocketApp) {
        _promiseFactory = promiseFactory;
        _webSocketApp = webSocketApp;
    };

    
    return {
        isNotationCategoryDescriptionUnique : _isNotationCategoryDescriptionUnique, 
        addNotationCategory : _addNotationCategory,
        updateNotationCategory : _updateNotationCategory,
        findNotationCategory : _findNotationCategory,
        init : _init,
        init2 : _init2
    };
});

