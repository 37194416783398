define('businessServices/router/crmRouter',[
    'plugins/router',
    'plugins/history',
    'settings/navigationConfiguration',
    'businessServices/router/crmRouterPluginManager',
    'common/promises/promiseFactory',
    'common/logging/logger',
    'constants/statusConstants'
], function(
    durandalRouter,
    durandalHistory,
    navigationConfiguration,
    crmRouterPluginManager,
    PromiseFactoryConstructor,
    LoggerConstructor,
    statusConstants
) {
    var _durandalRouter = durandalRouter;
    var _durandalHistory = durandalHistory;
    var _navigationConfiguration = navigationConfiguration;
    var _crmRouterPluginManager = crmRouterPluginManager;
    var _promiseFactory = new PromiseFactoryConstructor();
    var _hasExecutedAuthorizationPlugins = false;
    var _logger = new LoggerConstructor();

    var originalDurandalFunctions = {
        router : {
            loadUrl : _durandalRouter.loadUrl
        },
        history : {
            navigate : _durandalHistory.navigate
        }
    };

    var pluginConfigurations = _crmRouterPluginManager.getPluginConfigurations();
    var _routeGuards = pluginConfigurations.routeGuards;
    var _loadUrlRedirects = pluginConfigurations.loadUrlRedirects;
    var _authorizationPlugins = pluginConfigurations.authorizationPlugins;
    var _unknownRouteRedirects = pluginConfigurations.unknownRouteRedirects;
    var _callbacksWhenNextNavigationComplete = [];

    /* Durandal Overrides */
    function guardRoute(viewModel, routeInfo) {
        return _promiseFactory.defer(function (promise) {
            _durandalRouter.customState.applicationIsReady.done(function () {
                _durandalRouter.customState.isNavigationComplete(false);

                var routeId = routeInfo.config.customSettings.routeId;

                consultRouteGuards(_routeGuards, routeId, promise);
            });
        });
    }

    function onNavigationComplete() {
        _durandalRouter.customState.isNavigationComplete(true);

        if (_callbacksWhenNextNavigationComplete.length > 0) {
            var notifyCallbacks = _callbacksWhenNextNavigationComplete;
            _callbacksWhenNextNavigationComplete = [];
            notifyCallbacks.forEach(function(callback) {
                try {
                    callback();
                } catch (ex) {
                    _logger.logException(ex);
                }
            });
        }
    }

    function loadUrl(fragment) {
        var redirectPromise = _promiseFactory.defer();
        redirectPromise.done(function (loadFragmentUrl) {
            if (fragment !== loadFragmentUrl) {
                _durandalRouter.navigate(loadFragmentUrl);
                return false;
            } else {
                return originalDurandalFunctions.router.loadUrl(loadFragmentUrl);
            }
        });

        if (_hasExecutedAuthorizationPlugins) {
            consultLoadUrlRedirects(_loadUrlRedirects, fragment, redirectPromise);

        } else {
            _hasExecutedAuthorizationPlugins = true;
            var authorizationPromise = _promiseFactory.defer();
            authorizationPromise
                .done(function(result) {
                    _durandalRouter.customState.applicationIsReady.resolve();
                    consultLoadUrlRedirects(_loadUrlRedirects, fragment, redirectPromise, result);
                })
                .fail(function(error) {
                    redirectPromise.reject(error);
                });

            consultAuthorizationPlugins(_authorizationPlugins, fragment, authorizationPromise);
        }
    }

     function historyNavigate(fragment, options) {
         var promise = _promiseFactory.defer();

         consultLoadUrlRedirects(_loadUrlRedirects, fragment, promise);
         promise.done(function(loadFragmentUrl) {
             originalDurandalFunctions.history.navigate(loadFragmentUrl, options);
         });
    }

    /*  Private functions */
    function consultRouteGuards(routeGuards, routeId, consultRouteGuardsPromise) {
        if (routeGuards.length === 0) {
            consultRouteGuardsPromise.resolve(true);

        } else {
            var currentRouteGuard = routeGuards[0];
            var routeGuardResponse = currentRouteGuard(routeId);
            processPromiseOrResult(routeGuardResponse, function(result) {
                if (result === true) {
                    var remainingRouteGuards = routeGuards.slice(1);
                    consultRouteGuards(remainingRouteGuards, routeId, consultRouteGuardsPromise);

                } else if (result.routeUrl !== undefined && result.routeUrl !== null) {
                    consultRouteGuardsPromise.resolve(result.routeUrl);

                } else {
                    var error = new Error("Unexpected routeGuardResponse result. routeUrl must be specified in result.");
                    error.routeGuardResponse = result;
                    consultRouteGuardsPromise.reject(error);
                }
            });
        }
    }

    function consultLoadUrlRedirects(loadUrlRedirects, fragment, consultRedirectPromise, authorizationResults) {
        if (loadUrlRedirects.length === 0) {
            consultRedirectPromise.resolve(fragment);

        } else {
            var currentLoadUrlRedirect = loadUrlRedirects[0];
            var redirectResponse = currentLoadUrlRedirect(fragment, authorizationResults);
            processPromiseOrResult(redirectResponse, function (result) {
                if (result === true) {
                    var remainingUrlRedirects = loadUrlRedirects.slice(1);
                    consultLoadUrlRedirects(remainingUrlRedirects, fragment, consultRedirectPromise, authorizationResults);

                } else if ((result.routeUrl !== undefined) && (result.routeUrl !== null)) {
                    consultRedirectPromise.resolve(result.routeUrl);

                } else if (result.stall) {
                    // Do nothing, we want to stall

                } else {
                    var err = new Error("Unexpected redirectResponse result. routeUrl must be specified in the result.");
                    err.redirectResponse = result;
                    consultRedirectPromise.reject(err);
                }
            });
        }
    }

    function consultAuthorizationPlugins(authorizationPlugins, fragment, promise) {
        if (authorizationPlugins.length === 0) {
            promise.resolve("noauth");

        } else {
            var authorizationPlugin = authorizationPlugins[0];
            var authorizationResponse = authorizationPlugin(fragment);
            processPromiseOrResult(authorizationResponse, function(result) {
                switch (result.status) {
                    case statusConstants.ignored:
                        var remainingAuthorizationPlugins = authorizationPlugins.slice(1);
                        consultAuthorizationPlugins(remainingAuthorizationPlugins, fragment, promise);
                        break;
                    case statusConstants.success:
                        promise.resolve(result);
                        break;
                    case statusConstants.failed:
                        promise.resolve(statusConstants.failed);
                        break;
                }
            });
        }
    }

    function processPromiseOrResult(value, callback) {
        if (value.done && typeof value.done === "function") {
            value.done(function(promiseResult) {
                callback(promiseResult);
            });
        } else {
            callback(value);
        }
    }

    function configureRoutes() {
        var routeIds = _navigationConfiguration.routeIds;
        routeIds.forEach(function(routeId) {
            var routeInfo = _navigationConfiguration.routesById[routeId];

            _durandalRouter.map({
                "route" : routeInfo.url,
                "moduleId" : routeInfo.viewModelPath,
                "title" : routeInfo.pageTitle,
                "customSettings" : {
                    "routeId" : routeId
                }
            });
        });
    }

    function notifyWhenNextNavigationComplete(callback) {
        if (callback === undefined) {
            throw new Error("callback cannot be undefined");
        }
        _callbacksWhenNextNavigationComplete.push(callback);
    }

    // Configure the durandal router
    _durandalHistory.navigate = historyNavigate;
    _durandalRouter.deactivate();
    _durandalRouter.reset();
    _durandalRouter.guardRoute = guardRoute;
    _durandalRouter.loadUrl = loadUrl;
    _durandalRouter.customState = {
        isNavigationComplete : ko.observable(false),
        applicationIsReady : _promiseFactory.deferIndefinitely(),
        notifyWhenNextNavigationComplete : notifyWhenNextNavigationComplete
    };

    _durandalRouter.on('router:navigation:complete', onNavigationComplete);

    _durandalRouter.mapUnknownRoutes = function () {
        _unknownRouteRedirects.some(function(unknownRouteRedirect) {
            var redirectResult = unknownRouteRedirect();
            if (redirectResult.routeUrl) {
                _durandalRouter.navigate(redirectResult.routeUrl, {trigger: true,  replace: true});
                return;
            }
        });
    };

    _durandalRouter.on('router:route:not-found', function (fragment) {
        _durandalRouter.mapUnknownRoutes(fragment);
    });

    configureRoutes();
    _durandalRouter.buildNavigationModel();
    _durandalRouter.activate({
        "pushState": true
    });

    return _durandalRouter;
});

