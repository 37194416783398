define('presentation/account/presentationObjects/avatarPresentationObject',[],function() {
    return function(){
        var self = this;

        self.showDefaultImage = ko.observable(true);
        self.showImage = ko.observable(false);
        self.avatarUrl = ko.observable("");
        self.avatarFileHash = ko.observable("");
        self.encodingType = '';
        self.userId = null;
        self.isSaved = true;
    };
});

