define('presentation/developer/viewModels/reactivateNumbersModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/reactivateNumbersFacade',
], function() {
    return function(onModalSavePromise) {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        let _facade = null;
        let _modalService = null;
        let _onModalSavePromise = onModalSavePromise;

        self.cancelButtonText = "Cancel";
        self.closeButtonText = "Ok";
        self.modalTitle = 'Reactivate Phone Numbers';

        self.accountNumber = ko.observable('');
        self.isComplete = ko.observable(false);
        self.successfulNumbers = ko.observable('');
        self.failedNumbers = ko.observable('');
        self.resultStatus = ko.observable('');
        self.selectedNumbers = ko.observable([]);

        self.delay = ko.pureComputed(() => {
            const mSecondDelay = Math.ceil((self.selectedNumbers().length * 100) / 100);
            const date = new Date(0);
            date.setSeconds(mSecondDelay);
            return date.toISOString().substr(11, 8);
        });

        self.submitForm = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.reactivatePhoneNumbers(self.selectedNumbers(), self.accountNumber())
                    .fail(deferredObject.reject)
                    .done(result => {
                        if (_onModalSavePromise) {
                            _onModalSavePromise.resolve({action: "submit"});
                        }
                        self.isComplete(true);
                        self.resultStatus(result.status);
                        self.successfulNumbers(result.successful.sort());
                        self.failedNumbers(result.failures.sort());
                        deferredObject.resolve();
                    });
            });
        };

        self.cancelForm = () => {
            if (_onModalSavePromise) {
                _onModalSavePromise.resolve({action: "cancel"});
            }
            _modalService.closeModal(self);
        };

        self.activate = () => {
            const Facade = require('presentation/developer/facades/reactivateNumbersFacade');
            _facade = new Facade();
            _facade.init();

            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});


