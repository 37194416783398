var config = {};
config.externalDependencies = {};
config.externalDependencies.kazooApiServerUrl = "https://lobbyapi.tresta.com:443/v1/";
config.externalDependencies.clientApiServerUrl = "https://www.tresta.com/api/";
config.externalDependencies.webSocketServerUrl = "https://lobbysocket.tresta.com:443/";

config.isInDebugMode = true;
config.expireTimeInMilliseconds = 1000 * 60 * 60 * 4;
config.signupExpireTimeInMilliseconds = 1000 * 60 * 60 * 4;
config.webDomain = "https://app.tresta.com";
config.couchDatabaseUrl = "http://trestaProd01CouchUser:aTKPkzu627nfQt3BVEpJ@registration.prod01.tresta.com:5984/";

define("settings/environmentSettings", function(){});

