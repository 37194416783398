define('presentation/forgotPassword/facades/resetPasswordFacade',[
    'common/url/url',
    'persistence/dataProviders/resetPasswordDataProvider',
    'persistence/repositories/resetPasswordRepository'
], function() {

    var _resetPasswordDataProvider = null;
    var _resetPasswordRepository = null;

    var URLBusinessServiceConstructor = require('common/url/url');
    var _urlBusinessService = new URLBusinessServiceConstructor();

    var _constructResetPasswordUrl = function(emailAddress, expirationDateTime) {
        return _urlBusinessService.getBaseURL() + "/resetPassword/" + emailAddress + "/" + expirationDateTime;
    };

    var _validateLink = function(emailAddress, expirationDateTime, signature) {
        var url = _constructResetPasswordUrl(emailAddress, expirationDateTime);
        return _resetPasswordDataProvider.validateLink(url, signature);
    };

    var _resetPassword = function(emailAddress, password, expirationDateTime, signature) {
        var url = _constructResetPasswordUrl(emailAddress, expirationDateTime);
        return _resetPasswordRepository.resetPassword(emailAddress, password, url, signature);
    };

    var _init = function() {
        var ResetPasswordDataProviderConstructor = require('persistence/dataProviders/resetPasswordDataProvider');
        _resetPasswordDataProvider = new ResetPasswordDataProviderConstructor();

        var ResetPasswordRepositoryConstructor = require('persistence/repositories/resetPasswordRepository');
        _resetPasswordRepository = new ResetPasswordRepositoryConstructor();
    };

    var _init2 = function(promiseFactory, resetPasswordDataProvider, resetPasswordRepository) {
        _resetPasswordDataProvider = resetPasswordDataProvider;
        _resetPasswordRepository = resetPasswordRepository;
    };

    return function() {
        var self = this;

        self.validateLink = _validateLink;
        self.resetPassword = _resetPassword;
        self.init = _init;
        self.init2 = _init2;
    };
});

