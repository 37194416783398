define('presentation/developer/facades/rabbitMqFacade',['common/promises/promiseFactory',
        'persistence/webSocket/webSocketApp'],
    function() {
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _webSocketApp = null;

        var _getQueues = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("get_important_queues", {}, function(result) {
                    result.forEach(function(queueMessage){
                        queueMessage.queueDetailsUrl = "/developer/queueDetails/" + queueMessage.name;
                    });
                    deferredObject.resolve(result);
                });
            });
        };

        var _init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("crm_rabbit_mq");
        };

        return function() {
            var self = this;
            self.getQueues = _getQueues;
            self.init = _init;
        };
    });
