define('businessServices/authentication/authenticationProvider',[
    'common/authentication/employeeAuthenticationStore',
    'businessServices/events/eventManager',
    'common/promises/promiseFactory',
    'common/storage/workSessionIdStorage',
    'constants/statusConstants',
    'persistence/webSocket/webSocketEmployeeAuthentication',
    'externalDependencies/kazooApi',
    'businessServices/events/webSocketEventManager',
    'businessServices/router/crmRouter'
], function() {

    return function()
    {
        var self = this;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _employeeAuthenticationStore = null;
        var _kazoo = null;
        var _eventManager = null;
        var _statusConstants = require('constants/statusConstants');
        var _webSocketEmployeeAuthentication = null;
        var _router = require('businessServices/router/crmRouter');
        var _workSessionIdStorage = require('common/storage/workSessionIdStorage');

        var _webSocketEventManager = require('businessServices/events/webSocketEventManager');

        self.configureAuthentication = function() {
            return _webSocketEmployeeAuthentication.configureAuthentication();
        };

        var _employeeSessionExpired = function(eventData) {
            return _promiseFactory.defer(function(promise) {
                var workSessionId = _workSessionIdStorage.getWorkSessionId();
                if (workSessionId === eventData.workSessionId) {
                    self.logout()
                        .fail(promise.reject)
                        .done(promise.resolve);
                } else {
                    promise.resolve();
                }
            });
        };

        var _validateCredentials = function(username, password) {
            return _promiseFactory.defer(function(promise) {
                var workSessionId = _workSessionIdStorage.getWorkSessionId();

                _webSocketEmployeeAuthentication.loginEmployee(username, password, workSessionId)
                    .done(function(result) {
                        switch(result.status) {
                            case _statusConstants.success:
                                _workSessionIdStorage.setWorkSessionId(result.workSessionId);
                                promise.resolve({
                                    status: _statusConstants.success,
                                    employeeId : result.employeeId,
                                    reconnectionToken : result.reconnectionToken
                                });
                                break;

                            case _statusConstants.employeeInactive:
                            case _statusConstants.invalidEmployeeName:
                            case _statusConstants.invalidPassword:
                                _workSessionIdStorage.deleteWorkSessionId();
                                promise.resolve({
                                    status: result.status,
                                    employeeId : null,
                                    reconnectionToken : null
                                });
                                break;

                            default:
                                var authenticationError = new Error();
                                authenticationError.message = "Invalid response message";
                                authenticationError.status = result.status;
                                _workSessionIdStorage.deleteWorkSessionId();
                                promise.reject(authenticationError);
                        }
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            });
        };

        self.validateUsername = function(username) {
            return _promiseFactory.defer(function(promise) {
                _kazoo.call('lobby_employee_auth/validateEmployeeName/' + username, 'GET')
                    .done(function(result){
                        var validationResult = {
                            emailAddressFound : result.data.data.emailAddressFound,
                            lobbyUserId : result.data.data.lobbyUserId
                        };

                        promise.resolve(validationResult);
                    })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            });
        };

        self.forgotPassword = function(emailEntity) {
            return _promiseFactory.defer(function(deferredObject) {
                var emailDocument = {
                    data : emailEntity
                };

                _kazoo.unauthenticatedCall('lobby_employee_auth/forgotPassword', 'PUT', emailDocument)
                    .done(function(result) {
                        deferredObject.resolve(result);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };


        self.logIn = function(userName, password) {
            return _promiseFactory.defer(function(promise) {
                _validateCredentials(userName, password)
                    .done(function(results) {
                        var eventManager = require('businessServices/events/eventManager');
                        if (results.status === _statusConstants.success) {
                            _employeeAuthenticationStore.setEmployeeAuthentication(results.employeeId, results.reconnectionToken);
                            eventManager.publishEmployeeAuthenticated(true);

                        } else {
                            _employeeAuthenticationStore.resetEmployeeAuthentication();
                            eventManager.publishEmployeeAuthenticated(false);
                        }
                        promise.resolve(results);
                    })
                    .fail(function(error) {
                        // Do not log the password, because that could get leaked.
                        error.userName = userName;
                        promise.reject(error);
                    });
            });
        };

        self.logout = function() {
            return _promiseFactory.defer(function(promise) {
               _webSocketEmployeeAuthentication.logout()
                   .fail(promise.reject)
                   .done(function() {
                       _employeeAuthenticationStore.resetEmployeeAuthentication();
                       _eventManager.publishEmployeeAuthenticated(false);
                       _router.navigate("/login");
                       promise.resolve();
                   });
            });
        };

        self.setWorkSessionId = function(workSessionId) {
            return _promiseFactory.defer(function(promise) {
                var currentWorkSessionId = _workSessionIdStorage.getWorkSessionId();
                if (currentWorkSessionId === workSessionId) {
                    promise.resolve();

                } else {
                    // This must be set before we update the socket, or else a timing issue will occur where the worksession is incorrectly updated by other workflows.
                    _workSessionIdStorage.setWorkSessionId(workSessionId);
                    _webSocketEmployeeAuthentication.setWorkSessionId(workSessionId)
                        .done(function() {
                            promise.resolve();
                        })
                        .fail(function(error) {
                            promise.reject(error);
                        });
                }
            });
        };

        self.validateUrlNonce = function(urlFragment) {
            return _promiseFactory.defer(function(promise) {
                _webSocketEmployeeAuthentication.validateUrlNonce(urlFragment)
                    .done(function(nonceResult) {
                        switch (nonceResult.status) {
                            case _statusConstants.success:
                                _webSocketEmployeeAuthentication.loginToken(nonceResult.employeeId, nonceResult.reconnectionToken, nonceResult.workSessionId)
                                    .done(function(loginResult) {
                                        if (loginResult.status === _statusConstants.success && nonceResult.accountNumber !== undefined) {
                                            _employeeAuthenticationStore.setEmployeeAuthentication(nonceResult.employeeId, nonceResult.reconnectionToken);
                                            promise.resolve({
                                                status: _statusConstants.success,
                                                accountNumber: nonceResult.accountNumber,
                                                callId: nonceResult.callId,
                                                phoneNumber: nonceResult.phoneNumber
                                            });

                                        } else {
                                            promise.resolve({status : _statusConstants.failed});
                                        }
                                    });
                                break;

                            case _statusConstants.alreadyLoggedIn:
                                promise.resolve({
                                    status : _statusConstants.success,
                                    accountNumber: nonceResult.accountNumber,
                                    callId: nonceResult.callId,
                                    phoneNumber: nonceResult.phoneNumber
                                });
                                break;

                            default:
                                promise.resolve({status : _statusConstants.failed});
                        }
                    });
            });
        };

        self.validateClientUiUrlNonce = function (urlFragment) {
            return _promiseFactory.defer(function (promise) {
                _webSocketEmployeeAuthentication.validateClientUiUrlNonce(urlFragment)
                    .done(function (nonceResult) {
                        // Make sure work session is set or websocket reconnect will not have a work session id to work with
                        self.setWorkSessionId(nonceResult.work_session_id)
                            .done(function() {
                                switch(nonceResult.status) {
                                    case _statusConstants.success:
                                        _webSocketEmployeeAuthentication.loginToken(nonceResult.employee_id, nonceResult.reconnection_token, nonceResult.work_session_id)
                                            .done(function (loginResult) {
                                                if(loginResult.status === _statusConstants.success && nonceResult.account_number !== undefined) {
                                                    _employeeAuthenticationStore.setEmployeeAuthentication(nonceResult.employee_id, nonceResult.reconnection_token);
                                                    promise.resolve({
                                                        status: _statusConstants.success,
                                                        accountNumber: nonceResult.account_number
                                                    });
                                                } else {
                                                    promise.resolve({status: _statusConstants.failed});
                                                }
                                            });
                                        break;
                                    case _statusConstants.alreadyLoggedIn:
                                        promise.resolve({
                                            status: _statusConstants.success,
                                            accountNumber: nonceResult.account_number
                                        });
                                        break;
                                    default:
                                        promise.resolve({status: _statusConstants.failed});
                                }
                            })
                            .fail(function(error) {
                                promise.reject(error);
                            });
                    });
            });
        };

        self.init = function() {
            var WebSocketAuthenticationConstructor = require('persistence/webSocket/webSocketEmployeeAuthentication');
            _webSocketEmployeeAuthentication = new WebSocketAuthenticationConstructor();
            _webSocketEmployeeAuthentication.init();

            _webSocketEventManager.init();
            _webSocketEventManager.subscribeEmployeeSessionExpired(_employeeSessionExpired);

            _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');

            _eventManager = require('businessServices/events/eventManager');

            var KazooConstructor = require('externalDependencies/kazooApi');
            _kazoo = new KazooConstructor();
            _kazoo.init();
        };

        self.init2 = function(promiseFactory, kazoo, webSocketAuthentication) {
            _promiseFactory = promiseFactory;
            _kazoo = kazoo;
            _webSocketEmployeeAuthentication = webSocketAuthentication;
        };
    };
});

