define('presentation/account/viewModels/accountViewModel',[
    'presentation/account/viewModels/accountContentViewModel',
    'presentation/account/sidebar/viewModels/accountSidebarViewModel',
    'common/promises/promiseFactory'], function () {
    return function () {
        var self = this;
        var _accountId = null;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.sidebarPane = ko.observable();
        self.contentPane = ko.observable();

        self.activate = function (accountId, tabSelection, parameters) {
            _accountId = accountId;

            if (parameters === undefined) {
                parameters = {};
            }

            var AccountContentViewModelConstructor = require('presentation/account/viewModels/accountContentViewModel');
            var contentViewModel = new AccountContentViewModelConstructor();
            contentViewModel.activate(accountId, tabSelection, parameters)
                .done(function() {
                    self.contentPane(contentViewModel);
                });

            var AccountSidebarViewModelConstructor = require('presentation/account/sidebar/viewModels/accountSidebarViewModel');
            var sideBarViewModel = new AccountSidebarViewModelConstructor(accountId);
            sideBarViewModel.activate()
                .done(function() {
                    self.sidebarPane(sideBarViewModel);
                });

            return _initialize();
        };

        self.activate2 = function (accountId, contentViewModel, sidebarViewModel) {
            _accountId = accountId;
            self.contentPane(contentViewModel);
            self.sidebarPane(sidebarViewModel);

            return _initialize();
        };

        var _initialize = function () {
            return _promiseFactory.wait();
        };
    };
});

