define('presentation/settings/facades/notationEventManagementFacade',['persistence/webSocket/webSocketApp',
    'common/encryption/crypto',
    'presentation/common/dateTimeValue'], function() {

    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        var _webSocketAppCategory = null;
        var _webSocketApp = null;

      

        self.getAllNotationEvents = function() {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {};
                _webSocketAppCategory.send("get_notation_categories", webSocketParams, function(notationCategoriesList) {
                    _webSocketApp.send("get_notation_events", webSocketParams, function(notationEvents) {
                        var formattedNotationEvents = _formatNotationEvents(notationEvents, notationCategoriesList);
                        deferredObject.resolve(formattedNotationEvents);
                    });
                });
                
            });
        };
        
        
        var _formatNotationEvents = function(notationEventsList, notationCategoriesList) {
            var notationCategoryDescriptions = [];
            notationCategoriesList.forEach(function(notationCategory){
                notationCategoryDescriptions[notationCategory.notationCategoryId] = notationCategory.description;
            });
            
            
            var notationEventListFormatted = notationEventsList.map(function(event){
                if(event.modifiedDateTime === undefined){
                    event.modifiedDateTimeDisplayValue = 'N/A';
                }
                else {
                    var modifiedDateTime = new DateTimeValueConstructor(event.modifiedDateTime);
                    event.modifiedDateTimeDisplayValue = modifiedDateTime.displayValue;
                }    
                    
                if(event.createdDateTime === undefined) {
                    event.createdDateTimeDisplayValue = "N/A";
                } else {
                    var createdDateTime = new DateTimeValueConstructor(event.createdDateTime);
                    event.createdDateTimeDisplayValue = createdDateTime.displayValue;
                }
            
                if(event.defaultStatus === undefined || event.defaultStatus === null) {
                    event.defaultStatus = "Open";
                } else if (event.defaultStatus === "open"){
                    event.defaultStatus = "Open";
                } else {
                    event.defaultStatus = "Closed";
                }
                    
                event.notationCategoryDescription = notationCategoryDescriptions[event.notationCategoryId];
                
                event.editNotationEventURL = "/settings/addNotationEvent/" + event.notationEventId;
                if(event.isActive === true){
                    event.status = "ACTIVE";
                } else {
                    event.status = "INACTIVE";
                    event.isActive = false;
                }

                if(event.isSystemNotation === true || event.isSystemNotation === "true"){
                    event.isSystemNotation = "True";
                } else {
                    event.isSystemNotation = "False";
                }
                return event;
            });
            return notationEventListFormatted;
        };
        
        self.init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("notation_events");
            
            _webSocketAppCategory = new WebSocketAppConstructor();
            _webSocketAppCategory.init("notation_categories");
        };

        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});

