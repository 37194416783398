define(
    'presentation/reports/viewModels/executeReportViewModel',[
        'plugins/router',
        'common/promises/promiseFactory',
        'common/collections/collectionSorter',
        'presentation/common/window/windowControl',
        'presentation/reports/facades/executeReportFacade'
    ], function() {
        return function() {
            var self = this;

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();
            var SorterConstructor = require('common/collections/collectionSorter');
            var _windowControl = require('presentation/common/window/windowControl');
            var _sorter = new SorterConstructor();
            var _facade = null;
            var _router = null;
            var _ascending = true;

            var _sortColumn = function(column, sortAsc) {
                var pastDueArray = [];
                var notationsArray = [];
                self.notations().forEach(function(notation) {
                    var date = new Date();
                    var dateISO  = date.toISOString();
                   if(notation.dueByDate.sortValue <= dateISO) {
                       pastDueArray.push(notation);
                   } else {
                       notationsArray.push(notation);
                   }
                });
                _sorter.sort(pastDueArray, column, sortAsc);
                _sorter.sort(notationsArray, column, sortAsc);
                var sortedArray = pastDueArray.concat(notationsArray);
                self.notations(sortedArray);
            };

            var _clearSortColumnCss = function() {
                self.sortAccountNumberCss("");
                self.sortAccountNameCss("");
                self.sortEventNameCss("");
                self.sortLocaltimeCss("");
                self.sortInputDateCss("");
                self.sortDueDateCss("");
                self.sortCommentsCss("");
            };

            var _sortColumnCss = function(observable, sortAsc) {
                if(sortAsc === true) {
                    observable("sort-asc");
                } else {
                    observable("sort-desc");
                }
            };

            var _executeReport = function() {
                return _promiseFactory.deferIndefinitely(function(deferredObject) {
                    _facade.executeReport(self.reportId)
                        .done(function(notationsReport) {
                            self.reportName(notationsReport.reportName);
                            self.totalReportItems(notationsReport.totalReportItems);
                            self.totalPastDueItems(notationsReport.totalPastDueItems);
                            self.notations(notationsReport.notations);
                            self.sortDueDate();
                            self.pleaseWait(false);
                            deferredObject.resolve();
                        })
                        .fail(function(error) {
                            throw error;
                        });
                });
            };

            self.refreshPage = function() {
                self.pleaseWait(true);
                _clearSortColumnCss();
                self.sortDueDateCss("sort-asc");
                _executeReport();
            };

            self.openAccount = function(notation) {
                return _promiseFactory.defer(function(promise) {
                    var accountNumber = notation.accountNumber.toString();
                    _windowControl.openNewWindow("account/" + accountNumber + "/activity");
                    promise.resolve();
                });
            };

            self.sortAccountNumber = function() {
                _clearSortColumnCss();
                _sortColumnCss(self.sortAccountNumberCss, _ascending);
                _sortColumn("accountNumber", _ascending);
                _ascending = !_ascending;
            };

            self.sortAccountName = function() {
                _clearSortColumnCss();
                _sortColumnCss(self.sortAccountNameCss, _ascending);
                _sortColumn("accountName", _ascending);
                _ascending = !_ascending;
            };

            self.sortEventName = function() {
                _clearSortColumnCss();
                _sortColumnCss(self.sortEventNameCss, _ascending);
                _sortColumn("eventName", _ascending);
                _ascending = !_ascending;
            };

            self.sortLocaltime = function() {
                _clearSortColumnCss();
                _sortColumnCss(self.sortLocaltimeCss, _ascending);
                _sortColumn("customerTimeZone", _ascending);
                _ascending = !_ascending;
            };

            self.sortInputDate = function() {
                _clearSortColumnCss();
                _sortColumnCss(self.sortInputDateCss, _ascending);
                _sortColumn("createdDateTime.sortValue", _ascending);
                _ascending = !_ascending;
            };

            self.sortDueDate = function() {
                _clearSortColumnCss();
                _sortColumnCss(self.sortDueDateCss, _ascending);
                _sortColumn("dueByDate.sortValue", _ascending);
                _ascending = !_ascending;
            };

            self.sortComments = function() {
                _clearSortColumnCss();
                _sortColumnCss(self.sortCommentsCss, _ascending);
                _sortColumn("comment", _ascending);
                _ascending = !_ascending;
            };

            self.reportId = null;
            self.reportName = ko.observable();
            self.totalReportItems = ko.observable();
            self.totalPastDueItems = ko.observable();
            self.notations = ko.observableArray([]);
            self.dataGridConfig = {};
            self.pleaseWait = ko.observable(true);
            self.sortAccountNumberCss = ko.observable();
            self.sortAccountNameCss = ko.observable();
            self.sortEventNameCss = ko.observable();
            self.sortLocaltimeCss = ko.observable();
            self.sortInputDateCss = ko.observable();
            self.sortDueDateCss = ko.observable("sort-asc");
            self.sortCommentsCss = ko.observable();

            self.activate = function(reportId) {
                self.reportId = reportId;

                var Facade = require('presentation/reports/facades/executeReportFacade');
                _facade = new Facade();
                _facade.init(_promiseFactory);

                _router = require('plugins/router');

                return _initialize();
            };

            self.activate2 = function() {

                return _initialize();
            };

            var _initialize = function() {
                _executeReport();
                return _promiseFactory.wait();
            };
        };
    });

