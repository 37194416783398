define('presentation/settings/viewModels/settingsViewModel',['common/promises/promiseFactory',
    'presentation/navigation/facades/mainNavigationFacade',
    'presentation/settings/viewModels/settingsSidebarViewModel',
    'businessServices/router/crmRouter'
], function() {
    var SIDEBAR_CONTENT_ITEMS = [
        {
            selection : "employeeManagement",
            title : "Employee Management",
            iconClass : "icon-nav-user",
            permissionId : 'CRM.Settings.EmployeeManagement',
            viewModelPath : 'presentation/settings/viewModels/employeeManagementViewModel',
            urlParts : ["settings", "employeeManagement"],
            showInSidebar : true,
            showSelectedFor : ["addEmployee"]
        },
        {
            selection : "addEmployee",
            title : "Add Employee",
            permissionId : 'CRM.Settings.EmployeeManagement',
            viewModelPath : 'presentation/settings/viewModels/addEmployeeViewModel',
            urlParts : ["settings", "addEmployee"],
            showInSidebar : false
        },
        {
            selection : "notationCategory",
            title : "Notation Category",
            iconClass : "icon-nav-notation-category",
            permissionId : 'CRM.Settings.EmployeeManagement',
            viewModelPath : 'presentation/settings/viewModels/notationCategoryManagementViewModel',
            urlParts : ["settings", "notationCategory"],
            showInSidebar : true,
            showSelectedFor : ["addNotationCategory"]
        },
        {
            selection : "notationEvent",
            title : "Notation Event",
            iconClass : "icon-nav-notation-event",
            permissionId : 'CRM.Settings.EmployeeManagement',
            viewModelPath : 'presentation/settings/viewModels/notationEventManagementViewModel',
            urlParts : ["settings", "notationEvent"],
            showInSidebar : true,
            showSelectedFor : ["addNotationEvent"]
        },
        {
            selection : "notationReport",
            title : "Notation Report",
            iconClass : "icon-nav-notation-event",
            permissionId : 'CRM.Settings.EmployeeManagement',
            viewModelPath : 'presentation/settings/viewModels/notationReportManagementViewModel',
            urlParts : ["settings", "notationReport"],
            showInSidebar : true
        },
        {
            selection : "addNotationReport",
            title : "Add Notation Report",
            permissionId : 'CRM.Settings.EmployeeManagement',
            viewModelPath : 'presentation/settings/viewModels/addNotationReportViewModel',
            urlParts : ["settings", "addNotationReport"],
            showInSidebar : false
        },
        {
            selection : "addNotationEvent",
            title : "Add Notation Event",
            permissionId : 'CRM.Settings.EmployeeManagement',
            viewModelPath : 'presentation/settings/viewModels/addNotationEventViewModel',
            urlParts : ["settings", "addNotationEvent"],
            showInSidebar : false
        },
        {
            selection : "addNotationCategory",
            title : "Add Notation Category",
            permissionId : 'CRM.Settings.EmployeeManagement',
            viewModelPath : 'presentation/settings/viewModels/addNotationCategoryViewModel',
            urlParts : ["settings", "addNotationCategory"],
            showInSidebar : false
        },
        {
            selection : "potentialCustomer",
            iconClass : "icon-nav-user",
            title : "Potential Customer",
            permissionId : 'CRM.Settings.EmployeeManagement',
            viewModelPath : 'presentation/settings/viewModels/potentialCustomerViewModel',
            urlParts : ["settings", "potentialCustomer"],
            showInSidebar : true
        },
        {
            selection: "blacklistPhoneNumbers",
            iconClass: "icon-nav-gear",
            title: "Blacklist Phone Numbers",
            permissionId: "CRM.Settings.BlacklistPhoneNumbers",
            viewModelPath: 'presentation/settings/viewModels/blacklistPhoneNumbersViewModel',
            urlParts: ["settings", "blacklistPhoneNumbers"],
            showInSidebar: true
        },
        {
            selection : "addBlacklistPhoneNumber",
            title : "Add Blacklist Phone Number",
            permissionId : 'CRM.Settings.BlacklistPhoneNumbers',
            viewModelPath : 'presentation/settings/viewModels/addBlacklistPhoneNumberViewModel',
            urlParts : ["settings", "addBlacklistPhoneNumber"],
            showInSidebar : false
        },
        {
            selection: "crmOptions",
            iconClass: "icon-nav-gear",
            title: "CRM Options",
            permissionId: "CRM.Settings.CRMOptions",
            viewModelPath: 'presentation/settings/viewModels/crmOptionsViewModel',
            urlParts: ["settings", "crmOptions"],
            showInSidebar: true
        },
        {
            selection: "emailNotifications",
            iconClass: "icon-nav-gear",
            title: "Email Notifications",
            permissionId: "CRM.Settings.EmailNotifications",
            viewModelPath: 'presentation/settings/viewModels/emailNotificationsViewModel',
            urlParts: ["settings", "emailNotifications"],
            showInSidebar: true
        }
    ];

    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = new PromiseFactoryConstructor();

    var _sidebarViewModel = null;
    var _sidebarPane = ko.observable(null);
    var _contentPane = ko.observable(null);
    var _router = null;
    var _isInitialized = false;

    var Constructor = function() {
        var self = this;

        self.sidebarPane = _sidebarPane;
        self.contentPane = _contentPane;

        self.activate = function(settingsSelection) {
            if (_isInitialized === false) {
                _router = require('businessServices/router/crmRouter');
            }
            self.router = _router;

            return _initialize(settingsSelection);
        };

        self.activate2 = function(settingsSelection, router) {
            self.router = router;
            return _initialize(settingsSelection);
        };


        var _initialize = function(settingsSelection) {
            var initializedPromise = _promiseFactory.deferIndefinitely();
            var contentPaneLoadedPromise = _promiseFactory.deferIndefinitely();

            if (_isInitialized === false) {
                _isInitialized = true;

                var SidebarViewModelConstructor = require('presentation/settings/viewModels/settingsSidebarViewModel');
                _sidebarViewModel = new SidebarViewModelConstructor();
                _sidebarViewModel.sidebarConfig = SIDEBAR_CONTENT_ITEMS;
                _sidebarViewModel.parentSettingsViewModel = self;
                _sidebarViewModel.activate()
                    .done(function () {
                        _sidebarPane(_sidebarViewModel);

                        initializedPromise.resolve();
                    });
            } else {
                initializedPromise.resolve();
            }

            initializedPromise.done(function() {
                if (settingsSelection === null) {
                    contentPaneLoadedPromise.resolve();
                } else {
                    var pathParts = settingsSelection.split("/");
                    var pathFirstPart = pathParts[0];
                    var pathSubsequentParts = pathParts.slice(1).join("/");

                    _sidebarViewModel.settingSelectionChanged(pathFirstPart);

                    var foundSidebarItem = SIDEBAR_CONTENT_ITEMS.find(function(item) {
                        return (item.selection === pathFirstPart);
                    });

                    if (foundSidebarItem === undefined) {
                        _contentPane(null);
                        self.router.navigate('error', { trigger: true, replace: true });
                        contentPaneLoadedPromise.resolve();
                    } else {
                        require([foundSidebarItem.viewModelPath], function(ViewModelConstructor) {
                            var viewModel = new ViewModelConstructor();
                            viewModel.activate(pathSubsequentParts)
                                .done(function() {
                                    _contentPane(viewModel);

                                    contentPaneLoadedPromise.resolve();
                                });
                        });
                    }
                }
            });

            return _promiseFactory.wait();
        };
    };


    var instance = new Constructor();
    return instance;
});

