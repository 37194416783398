define('presentation/shell/viewModels/templateHeaderSidebarAccountContentViewModel',['common/promises/promiseFactory',
        'businessServices/router/crmRouter',
        'settings/navigationConfiguration',
        'presentation/account/sidebar/viewModels/accountSidebarViewModel',
        'presentation/account/viewModels/accountContentViewModel'
    ],
    function(){
        return function() {
            var self = this;

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();
            var _navigationConfiguration = require('settings/navigationConfiguration');
            var _accountNumber = null;
            var _tabSelection = null;
            var _queryStringArguments = null;

            self.router = require('businessServices/router/crmRouter');

            self.headerPane = ko.observable();
            self.sidebarPane = ko.observable();
            self.accountContent = ko.observable();
            self.contentPane = ko.observable();

            var _watchChild = function(childViewModel) {
                childViewModel.activate(_accountNumber, undefined, true);
                self.contentPane(childViewModel);
            };

            var _onActiveItemChange = function() {
                var activeItem = self.router.activeItem();
                _watchChild(activeItem);
            };

            var _loadHeader = function() {
                var instruction = self.router.activeInstruction();
                var routeId = instruction.config.customSettings.routeId;

                var route = _navigationConfiguration.routesById[routeId];

                var headerViewModelPath = route.headerViewModelPath;
                if (headerViewModelPath === undefined) {
                    headerViewModelPath = _navigationConfiguration.defaultHeaderViewModelPath;
                }

                return _promiseFactory.defer(function(promise) {
                    require([headerViewModelPath], function(HeaderViewModelConstructor) {
                        var headerViewModel = new HeaderViewModelConstructor();
                        headerViewModel.activate();
                        headerViewModel.setRouter(self.router);

                        self.headerPane(headerViewModel);
                        promise.resolve();
                    });
                });
            };

            var _loadSideBar = function() {
                return _promiseFactory.defer(function(deferredObject) {
                    var AccountSidebarViewModelConstructor = require('presentation/account/sidebar/viewModels/accountSidebarViewModel');
                    var sideBarViewModel = new AccountSidebarViewModelConstructor(_accountNumber);
                    sideBarViewModel.activate()
                        .done(function() {
                            self.sidebarPane(sideBarViewModel);
                            deferredObject.resolve();
                        })
                        .fail(deferredObject.reject);
                });
            };

            var _loadAccountContentPane = function() {
                return _promiseFactory.defer(function(deferredObject) {
                    var routeId = self.router.activeInstruction().config.customSettings.routeId;
                    switch (routeId) {
                        case "accountUsers":
                            _tabSelection = "users";
                            break;
                        case "accountBilling":
                            _tabSelection = "billing";
                            break;
                        case "accountActivity":
                            _tabSelection = "activity";
                            break;
                    }
                    var AccountContentViewModelConstructor = require('presentation/account/viewModels/accountContentViewModel');
                    var accountContentViewModel = new AccountContentViewModelConstructor();
                    accountContentViewModel.activate(_accountNumber, _tabSelection, _queryStringArguments)
                        .done(function () {
                            self.accountContent(accountContentViewModel);
                            deferredObject.resolve();
                        })
                        .fail(deferredObject.reject);
                });
            };

            var _loadContentPane = function() {
                return _promiseFactory.defer(function(promise) {
                    self.router.activeItem.subscribe(_onActiveItemChange);
                    self.router.customState.applicationIsReady.done(function() {
                        var activeItem = self.router.activeItem();
                        if (activeItem === null) {
                            self.router.customState.notifyWhenNextNavigationComplete(function() {
                                var activeItem = self.router.activeItem();
                                _watchChild(activeItem);
                            });
                        } else {
                            _watchChild(activeItem);
                        }
                        promise.resolve();
                    });
                });
            };

            self.activate = function(accountNumber, queryStringArguments) {
                _accountNumber = accountNumber;
                if (typeof queryStringArguments === "object") {
                    _queryStringArguments = queryStringArguments;
                }

                return _initialize();
            };

            self.activate2 = function() {

                return _initialize();
            };

            var _initialize = function() {
                if(_accountNumber !== undefined) {
                    _loadHeader();
                    _loadSideBar();
                    _loadAccountContentPane();
                    _loadContentPane();
                }

                return _promiseFactory.wait();
            };
        };
    });

