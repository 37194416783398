define('presentation/navigation/facades/mainNavigationFacade',[
    'common/promises/promiseFactory',
    'common/storage/workSessionIdStorage',
    'businessServices/authentication/authenticationProvider',
    'common/authentication/employeeAuthenticationStore',
    'businessServices/authentication/employeeAuthorization',
    'businessServices/events/eventManager',
    'persistence/webSocket/webSocketApp'
], function() {
    return function(){
        let self = this;

        let _promiseFactory = null;
        let _employeeAuthorization = null;
        let _authenticationProvider = null;
        let _employeeAuthenticationStore = null;
        let _eventManager = null;
        let _webSocketNavigation = null;
        let _employeeId = null;
        let _workSessionIdStorage = null;
        let _workSessionId = null;

        self.getEnvironmentLabel = () => {
            return _promiseFactory.defer((promise) => {
                _webSocketNavigation.send("get_environment_label", {}, function (result) {
                    promise.resolve(result.environmentLabel);
                });
            });
        };

        self.hasAccessToCrmSettings = () => {
            return _promiseFactory.defer((promise) => {
                _employeeAuthorization.hasPermission("CRM.Settings")
                    .done(promise.resolve)
                    .fail(promise.reject);
            });
        };

        self.hasAccessToCrmMarketing = () => {
            return _promiseFactory.defer((promise) => {
                _employeeAuthorization.hasPermission("CRM.Marketing")
                    .done(promise.resolve)
                    .fail(promise.reject);
            });
        };

        self.hasAccessToCrmRelease = () => {
            return _promiseFactory.defer((promise) => {
                _employeeAuthorization.hasPermission("CRM.Release")
                    .done(promise.resolve)
                    .fail(promise.reject);
            });
        };

        self.hasAccessToCrmDeveloper = () => {
            return _promiseFactory.defer((promise) => {
                _employeeAuthorization.hasPermission("CRM.Developer")
                    .done(promise.resolve)
                    .fail(promise.reject);
            });
        };

        self.logoutEmployee = () => {
            return _promiseFactory.defer((promise) => {
                _authenticationProvider.logout()
                    .done(() => {
                        _employeeAuthenticationStore.resetEmployeeAuthentication();
                        _eventManager.publishEmployeeAuthenticated(false);
                        promise.resolve();
                    })
                    .fail(promise.reject);
            });
        };

        self.getLobbyTicketingUrl = () => {
            return _promiseFactory.defer((deferredObject) => {
                let webSocketParams = {
                    "employee_id": _employeeId
                };
                _webSocketNavigation.send("get_lobby_ticketing_url", webSocketParams, function (result) {
                    deferredObject.resolve(result);
                });
            });
        };

        self.init = function(promiseFactory) {
            _employeeAuthorization = require('businessServices/authentication/employeeAuthorization');

            const AuthenticationProviderConstructor = require('businessServices/authentication/authenticationProvider');
            _authenticationProvider = new AuthenticationProviderConstructor();
            _authenticationProvider.init();

            _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');
            _employeeId = _employeeAuthenticationStore.getEmployeeId();

            _workSessionIdStorage = require('common/storage/workSessionIdStorage');
            _workSessionId = _workSessionIdStorage.getWorkSessionId();

            _eventManager = require('businessServices/events/eventManager');

            _promiseFactory = promiseFactory;

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');

            _webSocketNavigation = new WebSocketAppConstructor();
            _webSocketNavigation.init("crm_navigation");
        };
    };
});

