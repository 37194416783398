define('businessServices/events/webSocketEventManager',['common/uniqueId/uniqueIdGenerator',
        'externalDependencies/webSocket',
        'persistence/webSocket/webSocketApp'
    ],
    function() {

        var UniqueNameGenerator = require('common/uniqueId/uniqueIdGenerator');
        var _uniqueNameGenerator = new UniqueNameGenerator();

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        var _webSocketApp = null;

        var _initialized = false;

        var WebSocketEventManagerSingleton = function() {
            if (WebSocketEventManagerSingleton.prototype._singletonInstance) {
                return WebSocketEventManagerSingleton.prototype._singletonInstance;
            }
            var self = this;
            WebSocketEventManagerSingleton.prototype._singletonInstance = self;

            var _events = [];

            var _findEvent = function(eventName) {
                for (var x = 0; x < _events.length; x++) {
                    var currentEvent = _events[x];
                    if (currentEvent.eventName === eventName) {
                        return currentEvent;
                    }
                }
                return null;
            };

            var _getOrAddEvent = function(eventName, uniqueId) {
                var currentEvent = _findEvent(eventName);
                if (currentEvent === null) {
                    var newEvent = {
                        eventName : eventName,
                        subscribedCallbacks : [],
                        uniqueId: uniqueId
                    };
                    _events.push(newEvent);
                    return newEvent;
                } else {
                    return currentEvent;
                }
            };

            var _handleEvent = function(eventName, eventData) {
                var currentEvent = _findEvent(eventName);
                if (currentEvent === null) {
                    return;
                }
                for (var x = 0; x < currentEvent.subscribedCallbacks.length; x++) {
                    var currentCallback = currentEvent.subscribedCallbacks[x].callback;
                    currentCallback(eventData);
                }
            };

            var _subscribe = function(eventName, callbackFunction) {
                var uniqueId = _uniqueNameGenerator.generateUniqueIdWithoutHyphens();
                var currentEvent = _getOrAddEvent(eventName, uniqueId);
                currentEvent.subscribedCallbacks.push({"callback": callbackFunction, "id": uniqueId});
                return uniqueId;
            };

            self.init = function() {
                if(_initialized === false) {
                    _initialized = true;

                    _webSocketApp = new WebSocketAppConstructor();
                    _webSocketApp.init("crm_events");

                    var webSocket = require('externalDependencies/webSocket');
                    webSocket.init();
                    webSocket.on.webSocketEvent(_handleEvent);
                }
            };

            self.unsubscribe = function(callbackFunctionId) {
                for(var x = 0; x < _events.length; x++) {
                    for(var i = 0; i < _events[x].subscribedCallbacks.length; i++) {
                        if(_events[x].subscribedCallbacks[i].id === callbackFunctionId) {
                            _events[x].subscribedCallbacks.splice(i, 1);
                            break;
                        }
                    }
                }
            };

            self.subscribeEmployeeSessionExpired = function(CallbackFunction) {
                return _subscribe("employee_session_expired", CallbackFunction);
            };
            
        };
        return new WebSocketEventManagerSingleton();
    }
);

