define('presentation/account/activity/email/viewModels/composeViewModel',['common/specifications/validEmailAddressSpecification',
        'presentation/account/activity/email/facades/composeFacade',
        'presentation/account/activity/email/validators/composeViewModelValidator',
        'common/promises/promiseFactory'],
function() {
    return function(parentViewModel, accountNumber) {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var ValidEmailAddressSpecificationConstructor = require('common/specifications/validEmailAddressSpecification');
        var _validEmailAddressSpecification = new ValidEmailAddressSpecificationConstructor();
        var _facade = null;
        var _accountNumber = accountNumber;
        var _parentViewModel = parentViewModel;
        var _validator = null;
        var _requiredToEmailAddressList = [];
        var NOREPLYNEEDED_EMAILTEMPLATEID = "no reply needed - email template id";

        self.showCCField = ko.observable(false);
        self.showBCCField = ko.observable(false);
        self.toEmailAddresses = ko.observable();
        self.requiredToEmailAddresses = ko.observable();
        self.ccEmailAddresses = ko.observable();
        self.bccEmailAddresses = ko.observable();
        self.subject = ko.observable();
        self.emailContentIsEditable = ko.observable(false);
        self.message = ko.observable();
        self.initialMessage = ko.observable();
        self.emailTemplateStyles = ko.observable();
        self.templates = ko.observableArray([]);
        self.selectedTemplate = ko.observable();
        self.sendButtonName = ko.pureComputed(function() {
            if(self.selectedTemplate() !== undefined) {
                if(self.selectedTemplate().name !== "No Reply Needed") {
                    return "Send";
                } else {
                    return "Save";
                }
            } else {
                return "Send";
            }
        });
        self.attachments = ko.observableArray([]);
        self.statements = ko.observableArray([]);
        self.selectedAttachmentsAndStatements = ko.observableArray([]);
        self.notationComments = ko.observable();
        self.isReplyMode = ko.observable(false);
        self.replyContentUrl = ko.observable();
        self.replyToNotationId = ko.observable();
        self.replyToStoredDocumentId = ko.observable();
        self.noReplyNeeded = ko.observable(false);

        var _onSelectedTemplateChange = function() {
            var selectedTemplate = self.selectedTemplate();

            switch (selectedTemplate.emailTemplateId) {
                case undefined:
                    self.emailContentIsEditable(false);
                    self.noReplyNeeded(false);
                    break;

                case NOREPLYNEEDED_EMAILTEMPLATEID:
                    self.noReplyNeeded(true);
                    break;

                default:
                    self.emailContentIsEditable(true);
                    self.noReplyNeeded(false);
                    if (self.isReplyMode() === false) {
                        self.subject(selectedTemplate.subject);
                    }
                    self.initialMessage(selectedTemplate.content);
                    self.emailTemplateStyles(selectedTemplate.css);
                    break;
            }
        };

        self.isValidEmailAddress = function(emailAddress) {
            return _validEmailAddressSpecification.isSatisfiedBy(emailAddress.trim());
        };

        self.toggleCCField = function() {
            if (self.showCCField() === true) {
                self.showCCField(false);
            } else {
                self.showCCField(true);
            }
        };

        self.toggleBCCField = function() {
            if (self.showBCCField() === true) {
                self.showBCCField(false);
            } else {
                self.showBCCField(true);
            }
        };

        self.removeTag = function(attachmentOrStatement) {
            self.selectedAttachmentsAndStatements.remove(attachmentOrStatement);
        };

        self.cancelEmail = function() {
            _parentViewModel.closeModal();
        };

        self.resetForm = function() {
            self.showCCField(false);
            self.showBCCField(false);
            self.ccEmailAddresses("");
            self.bccEmailAddresses("");
            if (self.isReplyMode() === false) {
                self.subject("");
            }
            self.message("");
            self.selectedTemplate(self.templates()[0]);
            self.selectedAttachmentsAndStatements([]);
            self.notationComments("");
            _validator.resetValidation();
        };

        function _parseEmailAddressItems(emailAddressItems) {
            if (Array.isArray(emailAddressItems)) {
                return emailAddressItems;
            } else if (typeof(emailAddressItems) === 'string') {
                if (emailAddressItems.length > 0) {
                    return emailAddressItems.split(',');
                } else {
                    return [];
                }
            } else {
                return [];
            }
        }

        self.sendEmail = function() {
            return _promiseFactory.defer(function (deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid === false) {
                            deferredObject.resolve();
                        } else {

                            var toAddressList = _parseEmailAddressItems(self.toEmailAddresses());
                            var ccAddressList = _parseEmailAddressItems(self.ccEmailAddresses());
                            var bccAddressList = _parseEmailAddressItems(self.bccEmailAddresses());

                            var params = {
                                accountNumber: _accountNumber,
                                emailTemplateId: self.selectedTemplate().emailTemplateId,
                                toAddresses: toAddressList,
                                ccAddresses: ccAddressList,
                                bccAddresses: bccAddressList,
                                subject: self.subject(),
                                message: self.message(),
                                attachmentsAndStatements: self.selectedAttachmentsAndStatements(),
                                notationComments: self.notationComments()
                            };

                            if (self.isReplyMode() === true) {
                                params.replyToNotationId = self.replyToNotationId();
                                params.replyToStoredDocumentId = self.replyToStoredDocumentId();
                                params.noReplyNeeded = self.noReplyNeeded();
                            }

                            _facade.sendEmail(params)
                                .done(function() {
                                    _parentViewModel.onEmailSendComplete();
                                    deferredObject.resolve();
                                });
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.activate = function() {
            var Facade = require('presentation/account/activity/email/facades/composeFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            var ValidatorConstructor = require('presentation/account/activity/email/validators/composeViewModelValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        self.activate2 = function(facade, validator) {
            _facade = facade;
            _validator = validator;

            return _initialize();
        };

        var _initialize = function() {
            _facade.getDocumentAttachments(_accountNumber)
                .done(function(documentAttachments) {
                    self.attachments(documentAttachments);
                })
                .fail(function(error) {
                    throw error;
                });

            _facade.getStatements(_accountNumber)
                .done(function(statementAttachments) {
                    self.statements(statementAttachments);
                })
                .fail(function(error) {
                    throw error;
                });

            _facade.getEmailTemplates(_accountNumber, self.replyToStoredDocumentId())
                .done(function(templates) {
                    templates.unshift({"name": "[Choose Template]"});
                    if (self.isReplyMode() === true) {
                        templates.push({"name": "No Reply Needed", "emailTemplateId": NOREPLYNEEDED_EMAILTEMPLATEID});
                    }
                    self.templates(templates);
                    self.selectedTemplate(templates[0]);
                    self.selectedTemplate.subscribe(_onSelectedTemplateChange);

                })
                .fail(function(error) {
                    throw error;
                });

            _promiseFactory.wait()
                .done(function() {
                    _validator.registerViewModel(self, _facade);
                })
                .fail(function(error) {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

