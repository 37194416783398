define('presentation/common/dateTimePicker/viewModels/dateTimePickerViewModel',['common/converters/passedTimeSpanFormatter',
        'common/time/date'],
function() {
    return function() {
        var self = this;
        var _settings;
        var DateTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
        var DateConstructor = require('common/time/date');

        var _getDateTimeValue = function() {
            if (self.timeValue() === "") {
                return null;
            } else {
                var dateTimeValue = new Date(self.dateValue() + " " + self.timeValue());
                return dateTimeValue;
            }
        };

        var _setDateTimeValue = function(dateTime) {
            if (ko.isObservable(_settings.value)) {
                _settings.value(dateTime);
            } else {
                _settings.value = dateTime;
            }
        };

        self.dateValue = ko.observable();
        self.dateValue.subscribe(function(newDateValue) {
            _setDateTimeValue(_getDateTimeValue());
        });
        self.timeValue = ko.observable();
        self.timeValue.subscribe(function(newTimeValue) {
            _setDateTimeValue(_getDateTimeValue());
        });

        self.activate = function(settings) {
            _settings = settings;

            return _initialize();
        };

        self.activate2 = function(settings) {
            _settings = settings;

            return _initialize();
        };

        var _initialize = function() {
            if (_settings.value === undefined) {
                throw new Error("'value' is required");
            } else {
                var initialDateTimeValue = ko.utils.unwrapObservable(_settings.value);

                if (initialDateTimeValue instanceof Date && !isNaN(initialDateTimeValue.valueOf())) {
                    var initialDateTimeObject = new DateConstructor();
                    initialDateTimeObject.setFromDateString(initialDateTimeValue);
                    var dateTimeFormatter = new DateTimeSpanFormatterConstructor();
                    self.dateValue(dateTimeFormatter.formatDate(initialDateTimeObject));
                    self.timeValue(dateTimeFormatter.formatTime(initialDateTimeObject));
                }
            }
        };
    };
});

