define('presentation/developer/facades/deactivateNumbersFacade',[
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketApp',
    'presentation/common/dateTimeValue',
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const _promiseFactory = new PromiseFactoryConstructor();

    const DateTimeValueConstructor = require('presentation/common/dateTimeValue');

    let _webSocketApp = null;

    const _cleanAccountNumber = (accountNumber) => {
        return accountNumber.replace(/-|\s/g, "");
    };

    const _getAllActivePhoneNumbers = (accountNumber) => {
        return _promiseFactory.defer(function(deferredObject) {
            let webSocketParams = {
                account_number: _cleanAccountNumber(accountNumber)
            };
            _webSocketApp.send("get_all_active_phone_numbers", webSocketParams, function(response) {
                let phoneNumbersObjects = response.phoneNumbers.map(function(phoneNumber) {
                    let addedDateTime = new DateTimeValueConstructor(phoneNumber.createdDateTime);
                    return {
                        phoneNumberName: phoneNumber.phoneNumberName,
                        phoneNumber: phoneNumber.phoneNumber,
                        addedDateTime: addedDateTime.displayValue,
                        createdDateTime: addedDateTime,
                        isSelected: ko.observable(false),
                        isDetailsVisible: ko.observable(false),
                        phoneConnectorId: phoneNumber.phoneConnectorId,
                    };
                });
                deferredObject.resolve(phoneNumbersObjects);
            });
        });
    };

    const _isValidAccountNumber = (accountNumber) => {
        return _promiseFactory.defer((promise) => {
            let cleanAccountNumber = _cleanAccountNumber(accountNumber);
            let webSocketParams = {
                account_number: cleanAccountNumber
            };
            _webSocketApp.send("is_valid_account", webSocketParams, function(isValidAccount) {
                promise.resolve(isValidAccount);
            });
        });
    };

    const _deactivatePhoneNumbers = function(phoneNumbers, fromAccountNumber) {
        return _promiseFactory.deferIndefinitely(function(deferredObject) {
            let webSocketParams = {
                from_account_number: _cleanAccountNumber(fromAccountNumber),
                phone_numbers: phoneNumbers.map(({phoneConnectorId, phoneNumber}) => ({phoneConnectorId, phoneNumber})),
            };
            _webSocketApp.send("deactivate_phone_numbers", webSocketParams, function(moveNumbersResult) {
                deferredObject.resolve(moveNumbersResult);
            });
        });
    };

    const _init = function() {
        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("crm_phone_number_management");
    };

    return function() {
        let self = this;

        self.getAllActivePhoneNumbers = _getAllActivePhoneNumbers;
        self.isValidAccountNumber = _isValidAccountNumber;
        self.deactivatePhoneNumbers = _deactivatePhoneNumbers;

        self.init = _init;
    };
});

