define('presentation/promptDialog/presentationObjects/buttonPresentationObject',[],function() {
    return function(){
        var self = this;

        self.buttonStyle = null;
        self.buttonText = null;
        self.buttonKey = null;
    };
});

