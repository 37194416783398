define('presentation/promptDialog/viewModels/promptDialogViewModel',['presentation/promptDialog/presentationObjects/buttonPresentationObject',
        'presentation/promptDialog/presentationObjects/linePresentationObject',
        'presentation/common/modal',
        'common/promises/promiseFactory',
        'constants/referentialConstraintEnumerations'], function () {

    var PromiseFactoryConstructor = require('common/promises/promiseFactory');
    var _promiseFactory = new PromiseFactoryConstructor();

    var ButtonPresentationObjectConstructor = null;
    var LinePresentationObjectConstructor = null;

    return function () {
        var self = this;
        var _deferredResult = null;
        var _modalDisplay = null;
        var _stateDeferredObject = null;

        self.promptTitle = ko.observable('');
        self.promptRedTitle = ko.observable('');
        self.buttons = ko.observableArray();
        self.captionLines = ko.observableArray();
        self.showWarningMessage = ko.observable(false);
        self.isCancelled = false;
        self.userSelection = null;
        self.newDefault = ko.observable("");

        self.complete = function() {
            _stateDeferredObject.resolve();
            _modalDisplay.closeModal(self);
        };

        self.fail = function() {
            _stateDeferredObject.reject();
        };

        self.resetCaption = function () {
            self.captionLines.removeAll();
            return self;
        };

        self.showDialog = function () {
            self.isCancelled = false;
            self.userSelection = null;

            _deferredResult = _promiseFactory.deferIndefinitely();

            _modalDisplay.showModal(self);
            return _deferredResult;
        };

        self.setTitle = function (title) {
            self.promptTitle(title);
            return self;
        };

        self.setRedTitle = function (title) {
            self.promptRedTitle(title);
            return self;
        };

        self.closeDialog = function () {
            _stateDeferredObject = _promiseFactory.deferIndefinitely();
            var promise = _stateDeferredObject.promise();

            self.isCancelled = true;
            _deferredResult.resolve();
            _stateDeferredObject.resolve();
            _modalDisplay.closeModal(self);

            return promise;
        };

        self.buttonClicked = function (buttonPo) {
            _stateDeferredObject = _promiseFactory.deferIndefinitely();
            var promise = _stateDeferredObject.promise();

            self.userSelection = buttonPo.buttonKey;
            _deferredResult.resolve(self.newDefault());

            return promise;
        };

        self.addBoldLine = function (lineText) {
            var linePo = new LinePresentationObjectConstructor();
            linePo.lineStyle = "boldLine";
            linePo.lineText = lineText;

            self.captionLines.push(linePo);
            return self;
        };

        self.addEmptyLine = function () {
            var linePo = new LinePresentationObjectConstructor();
            linePo.lineStyle = "boldLine";

            self.captionLines.push(linePo);
            return self;
        };

        self.addLine = function (lineText) {
            var linePo = new LinePresentationObjectConstructor();
            linePo.lineStyle = "normalLine";
            linePo.lineText = lineText;

            self.captionLines.push(linePo);
            return self;
        };

        self.addCenteredLine = function (lineText) {
            var linePo = new LinePresentationObjectConstructor();
            linePo.lineStyle = "normalCenteredLine";
            linePo.lineText = lineText;

            self.captionLines.push(linePo);
            return self;
        };

        self.addCenteredPhoneNumberLine = function(phoneNumber) {
            var linePo = new LinePresentationObjectConstructor();
            linePo.lineStyle = "normalCenteredPhoneNumberLine";
            linePo.lineText = phoneNumber;

            self.captionLines.push(linePo);
            return self;
        };

        self.addDefaultSelection = function(lineText, options, optionsText) {
            var linePo = new LinePresentationObjectConstructor();
            linePo.lineStyle = "selectNewDefaultBox";
            linePo.lineText = lineText;
            linePo.options = options;
            linePo.optionsText = optionsText;

            self.captionLines.push(linePo);
            return self;

        };

        self.addRedButton = function (buttonText, buttonKey) {
            var buttonPo = new ButtonPresentationObjectConstructor();
            buttonPo.buttonStyle = 'redButton';
            buttonPo.buttonText = buttonText;
            buttonPo.buttonKey = buttonKey;

            self.buttons.push(buttonPo);
            return self;
        };

        self.addButton = function (buttonText, buttonKey) {
            var buttonPo = new ButtonPresentationObjectConstructor();
            buttonPo.buttonStyle = 'normalButton';
            buttonPo.buttonText = buttonText;
            buttonPo.buttonKey = buttonKey;

            self.buttons.push(buttonPo);
            return self;
        };

        self.addPrimaryButton = function (buttonText, buttonKey) {
            var buttonPo = new ButtonPresentationObjectConstructor();
            buttonPo.buttonStyle = 'primaryButton';
            buttonPo.buttonText = buttonText;
            buttonPo.buttonKey = buttonKey;

            self.buttons.push(buttonPo);
            return self;
        };

        self.masterClear = function(){
            self.promptTitle("");
            self.promptRedTitle("");
            self.captionLines([]);
            self.buttons([]);
            return self;
        };

        self.activate = function () {
            ButtonPresentationObjectConstructor = require('presentation/promptDialog/presentationObjects/buttonPresentationObject');
            LinePresentationObjectConstructor = require('presentation/promptDialog/presentationObjects/linePresentationObject');

            var ModalDisplayConstructor = require('presentation/common/modal');
            _modalDisplay = new ModalDisplayConstructor();

            return _initialize();
        };

        self.activate2 = function (modalDisplay) {
            ButtonPresentationObjectConstructor = require('presentation/promptDialog/presentationObjects/buttonPresentationObject');
            LinePresentationObjectConstructor = require('presentation/promptDialog/presentationObjects/linePresentationObject');
            _modalDisplay = modalDisplay;

            return _initialize();
        };

        var _initialize = function() {
            self.newDefault(null);
            return self;
        };
    };
});

