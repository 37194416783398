define('presentation/reports/presentationObjects/reportsPresentationObject',[],function() {
    return function(){
        var self = this;
        self.reportsCountState = ko.observable("loading...");
        self.count = ko.observable("");
        self.notationReportId = null;
        self.reportName = null;
        self.createdDateTime = null;
        self.createdDateTimeDisplayValue = null;
        self.modifiedDateTime = null;
        self.modifiedDateTimeDisplayValue = null;
    };
});

