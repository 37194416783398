define('presentation/settings/facades/notationReportManagementFacade',['common/promises/promiseFactory',
        'persistence/webSocket/webSocketApp',
        'presentation/common/dateTimeValue'],
    function() {
        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = null;
        var _webSocketApp = null;

        var _getNotationReports = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("get_all_notation_reports", {}, function(result) {
                    var formattedNotationReports = _formatNotationReports(result.notationReports);
                    deferredObject.resolve(formattedNotationReports);
                });
            });
        };

        var _formatNotationReports = function(notationReportsList) {
            notationReportsList.forEach(function(notationReport){
                var modifiedDateTime = new DateTimeValueConstructor(notationReport.modifiedDateTime);
                notationReport.modifiedDateTimeDisplayValue = modifiedDateTime.displayValue;
                notationReport.modifiedDateTime = modifiedDateTime;
                var createdDateTime = new DateTimeValueConstructor(notationReport.createdDateTime);
                notationReport.createdDateTimeDisplayValue = createdDateTime.displayValue;
                notationReport.createdDateTime = createdDateTime;
                notationReport.reportName = notationReport.reportName;
                notationReport.editUrl = "/settings/addNotationReport/" + notationReport.notationReportId;
            });
            return notationReportsList;
        };

        var _deleteNotationReport = function(notationReportId) {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("delete_notation_report", {notationReportId: notationReportId}, function() {
                    deferredObject.resolve();
                });
            });
        };

        var _init = function() {
            _promiseFactory = new PromiseFactoryConstructor();
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("notation_report_management");
        };

        var _init2 = function(promiseFactory, webSocketApp) {
            _promiseFactory = promiseFactory;
            _webSocketApp = webSocketApp;
        };

        return function() {
            var self = this;
            self.getNotationReports = _getNotationReports;
            self.deleteNotationReport = _deleteNotationReport;
            self.init = _init;
            self.init2 = _init2;
        };

});

