define('presentation/settings/viewModels/employeeManagementViewModel',['common/promises/promiseFactory',
        'plugins/router',
        'presentation/settings/facades/employeeManagementFacade',
        'common/collections/collectionFilter',
        'common/collections/collectionSorter'], function() {
    return function(){
        var self = this;
        var _facade = null;
        var _router = null;

        var EMPLOYEE_DEFAULT_SORT_ORDER = "firstName";
        var EMPLOYEE_SORT_OPTIONS = [
            { "value": "firstName",      "displayName":"First Name" },
            { "value": "lastName",       "displayName":"Last Name" },
            { "value": "employeeNumber", "displayName":"Employee Number" }
        ];

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var FilterConstructor = require('common/collections/collectionFilter');
        var SorterConstructor = require('common/collections/collectionSorter');
        var _sorter = new SorterConstructor();

        var _onSortChange = function() {
            var sortOrder = self.selectedSort().value;
            if (sortOrder !== null) {
                _sorter.multiSort(self.employeeList, ["isEnabled", sortOrder], [false, true]);
            }
        };

        self.employeeList = ko.observableArray([]);
        self.sortOptions = ko.observableArray(EMPLOYEE_SORT_OPTIONS);
        self.selectedSort = ko.observable(EMPLOYEE_SORT_OPTIONS[0]);
        self.employeeSearch = ko.observable('');
        self.filteredEmployeeList = ko.computed(function() {
            var searchText = self.employeeSearch().trim().toLowerCase();
            var filteredEmployeeList = [];
            if (searchText) {
                var employeeListFilter = new FilterConstructor();
                employeeListFilter.addProperty('firstName');
                employeeListFilter.addProperty('lastName');
                employeeListFilter.addProperty('emailAddress');
                employeeListFilter.addProperty('employeeNumber');
                employeeListFilter.addProperty('createdDateTimeDisplayValue');
                employeeListFilter.addProperty('modifiedDateTimeDisplayValue');
                employeeListFilter.addValue(searchText);
                filteredEmployeeList = employeeListFilter.filter(self.employeeList());
            } else {
                filteredEmployeeList = self.employeeList();
            }
            return filteredEmployeeList;
        });

        self.addEmployee = function () {
            _router.navigate("/settings/addEmployee");
        };

        self.activate = function() {
            var Facade = require('presentation/settings/facades/employeeManagementFacade');
            _facade = new Facade();
            _facade.init();

            _router = require('plugins/router');

            return _initialize();
        };

        self.activate2 = function(facade, router) {
            _facade = facade;
            _router = router;

            return _initialize();
        };

        var _initialize = function() {
            return _promiseFactory.defer(function(deferredResult) {
                _facade.getEmployeeList()
                    .done(function(employeeList) {
                        _sorter.multiSort(employeeList, ["isEnabled", EMPLOYEE_DEFAULT_SORT_ORDER], [false, true]);
                        self.employeeList(employeeList);
                        self.selectedSort.subscribe(_onSortChange);
                        deferredResult.resolve();
                    });
            });
        };
    };
});

