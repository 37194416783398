define('presentation/account/sidebar/facades/vanityAddFacade',[
    'businessServices/authentication/employeeAuthorization',
    'common/converters/phoneNumberFormatConverter',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketApp'
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    let _promiseFactory = new PromiseFactoryConstructor();

    const PhoneNumberFormatConverterConstructor = require('common/converters/phoneNumberFormatConverter');
    let _phoneNumberFormatConverter = new PhoneNumberFormatConverterConstructor();

    const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
    let _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

    let _employeeAuthorization = null;
    let _webSocketApp = null;

    const _vanityAddPhoneNumbers = function(accountNumber, phoneNumbers) {
        let cleanPhoneNumbers = phoneNumbers.map(function(phoneNumber) {
            let cleanNumber = _phoneNumberFormatConverter.clean(phoneNumber);
            return  _phoneNumberFormatter.toEOneSixFour(cleanNumber);
        });
        return _promiseFactory.defer(function(promise) {
            let webSocketParams = {
                account_number: accountNumber,
                phone_numbers: cleanPhoneNumbers
            };
            _webSocketApp.send("add_vanity_add_phone_numbers", webSocketParams, function(result) {
                promise.resolve(result);
            });
        });
    };

    const _cancelPort = function(accountNumber, phoneNumber) {
        return _promiseFactory.defer(function(promise) {
            let webSocketParams = {
                account_number: accountNumber,
                phone_number: _phoneNumberFormatter.toEOneSixFour(phoneNumber)
            };
            _webSocketApp.send("cancel_vanity_add", webSocketParams, function(result) {
                promise.resolve(result);
            });
        });
    };


    const _finishPort = function(accountNumber, phoneNumber) {
        return _promiseFactory.defer(function(promise) {
            let webSocketParams = {
                account_number: accountNumber,
                phone_number: _phoneNumberFormatter.toEOneSixFour(phoneNumber)
            };
            _webSocketApp.send("confirm_vanity_add", webSocketParams, function(result) {
                promise.resolve(result);
            });
        });
    };

    const _isAllowedPhoneNumber = function(accountNumber, phoneNumber) {
        return _promiseFactory.defer(function (isAllowedPromise) {
            if (phoneNumber === "") {
                isAllowedPromise.resolve(false);
            } else {
                let webSocketParams = {
                    account_number: accountNumber,
                    phoneNumber: _phoneNumberFormatter.toEOneSixFour(phoneNumber)
                };
                _webSocketApp.send("is_restricted_phone_number", webSocketParams, function (result) {
                    let isAllowedPhoneNumber = !result.status;
                    isAllowedPromise.resolve(isAllowedPhoneNumber);
                });
            }
        });
    };

    const _isPhoneNumberNotOnAnotherAccount = function(accountNumber, phoneNumber) {
        return _promiseFactory.defer(function(promise) {
            if (phoneNumber === "") {
                promise.resolve(false);

            } else {
                let parameters = {
                    account_number: accountNumber,
                    phone_number: _phoneNumberFormatter.toEOneSixFour(phoneNumber)
                };
                _webSocketApp.send("is_phone_number_on_another_account", parameters, function(result) {
                    let isPhoneNumberOnAnotherAccount = result.status;
                    promise.resolve(!isPhoneNumberOnAnotherAccount);
                });
            }
        });
    };

    const _isPhoneNumberNotOnThisAccount = function(accountNumber, phoneNumber) {
        return _promiseFactory.defer(function(promise) {
            if (phoneNumber === "") {
                promise.resolve(false);

            } else {
                let parameters = {
                    account_number: accountNumber,
                    phone_number: _phoneNumberFormatter.toEOneSixFour(phoneNumber)
                };
                _webSocketApp.send("is_phone_number_on_account", parameters, function(result) {
                    var isPhoneNumberOnThisAccount = result.status;
                    promise.resolve(!isPhoneNumberOnThisAccount);
                });
            }
        });
    };

    const _init = function() {
        let WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("crm_phone_number_porting");

        _employeeAuthorization = require('businessServices/authentication/employeeAuthorization');
    };

    return function() {
        let self = this;
        self.init = _init;

        self.cancelPort = _cancelPort;
        self.finishPort = _finishPort;
        self.isAllowedPhoneNumber = _isAllowedPhoneNumber;
        self.isPhoneNumberNotOnAnotherAccount = _isPhoneNumberNotOnAnotherAccount;
        self.isPhoneNumberNotOnThisAccount = _isPhoneNumberNotOnThisAccount;
        self.vanityAddPhoneNumbers = _vanityAddPhoneNumbers;
    };
});

