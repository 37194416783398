define('presentation/forgotPassword/validators/resetPasswordValidator',['presentation/common/validation/validationRule',
        'constants/validationMessageEnumerations',
        'common/specifications/validPasswordSpecification',
        'presentation/common/validation/commonValidator'], function() {
    return function() {
        var self = this;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidPasswordSpecificationConstructor = require('common/specifications/validPasswordSpecification');
        var _validPasswordSpecification = new ValidPasswordSpecificationConstructor();
        var _viewModel = null;
        var _commonValidator = null;
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var _validatePasswordRequired = function(password) {
            return _commonValidator.isStringWithValue(password);
        };

        var _validatePasswordValid = function(password) {
            return _validPasswordSpecification.isSatisfiedBy(password);
        };

        var _validatePasswordConfirmRequired = function(confirmPassword) {
            return _commonValidator.isStringWithValue(confirmPassword);
        };

        var _validatePasswordConfirmValid = function(confirmPassword) {
            var password = _viewModel.password();
            return password === confirmPassword;
        };

        self.registerViewModel = function(viewModel) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.resetPassword;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("password")
                                .addValidation(_validatePasswordRequired, validationMessageBase.passwordValid)
                                .addValidation(_validatePasswordValid, validationMessageBase.passwordValid);
            validationRules.field("confirmPassword")
                                .addValidation(_validatePasswordConfirmRequired, validationMessageBase.passwordConfirmRequired)
                                .addValidation(_validatePasswordConfirmValid, validationMessageBase.passwordConfirmValid);

            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };
    };
});

