define('presentation/settings/viewModels/notationEventManagementViewModel',['common/promises/promiseFactory', 
    'presentation/settings/facades/notationEventManagementFacade', 
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'plugins/router'], function() {
    return function(){
        var self = this;
        var _facade = null;
        var _router = null;
        
        var FilterConstructor = require('common/collections/collectionFilter');
        var SorterConstructor = require('common/collections/collectionSorter');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _availableNotationEvents = null;
         
        var _notationEventFilterOnChange = function() {
            _updateDisplayedNotationEvents();
        };
        
        var NOTATION_EVENT_SORT_OPTIONS = [
            { "value": "eventName",                     "displayName":"Event Name",         "isAscending" : true},
            { "value": "notationCategoryDescription",   "displayName":"Notation Category",  "isAscending" : true},
            { "value": "createdDateTime",               "displayName":"Created Date",       "isAscending" : false},
            { "value": "modifiedDateTime",              "displayName":"Modified Date",      "isAscending" : false}
        ];
        
        self.displayNotationEvents = ko.observableArray();
        self.sortOptions = ko.observableArray(NOTATION_EVENT_SORT_OPTIONS);
        self.selectedSort = ko.observable(NOTATION_EVENT_SORT_OPTIONS[0]);
        self.notationEventFilter = ko.observable('');
        self.notationEventFilter.subscribe(_notationEventFilterOnChange);
        self.isActive = ko.observable(false);
        self.addNotationEvent = function () {
            _router.navigate("/settings/addNotationEvent");
        };
        
        var _updateDisplayedNotationEvents = function() {
            
            var filteredNotationEvents;
           
           
            var filterContent = self.notationEventFilter().trim().toLowerCase();
            if (filterContent) {
                var notationEventFilter = new FilterConstructor();
                
                notationEventFilter.addProperty('notationEventId');
                notationEventFilter.addProperty('notationCategoryId');
                notationEventFilter.addProperty('eventName');
                notationEventFilter.addProperty('isActive');
                notationEventFilter.addProperty('defaultStatus');
                notationEventFilter.addProperty('commentTemplate');
                notationEventFilter.addProperty('modifiedDateTime.filterValue');
                notationEventFilter.addProperty('createdDateTime.filterValue');
                notationEventFilter.addValue(filterContent);
                filteredNotationEvents = notationEventFilter.filter(_availableNotationEvents);
            }
            else {
                filteredNotationEvents = _availableNotationEvents;
            }

            self.displayNotationEvents.removeAll();
            for (var x = 0; x < filteredNotationEvents.length; x++) {
                var notationEvent = filteredNotationEvents[x];
                self.displayNotationEvents.push(notationEvent);
            }
       
          _sortDisplayedNotationEvents();
        };

        var _sortDisplayedNotationEvents = function() {
            var selectedSort = self.selectedSort();
            var sortField = selectedSort.value;
            var isAscending = selectedSort.isAscending;
            var _sorter = new SorterConstructor();
            if (sortField !== null) {
                _sorter.multiSort(self.displayNotationEvents, ["isActive", sortField], [false, isAscending]);
            }
        };
        
        
        var _refreshNotationEvents = function() {
            _facade.getAllNotationEvents()
                .done(function(notationEvents) {
                    _availableNotationEvents = notationEvents;
                    _updateDisplayedNotationEvents();
                    if(notationEvents.length > 0) {
                        
                        self.showWelcomeState(false);
                    } else {
                         
                        self.showWelcomeState(true);
                    }
                });
        };
        self.showEmptyState = ko.computed(function() {
            return self.displayNotationEvents().length <= 0;
        });
        self.showWelcomeState = ko.observable(false);


        self.activate = function() {
            var FacadeConstructor = require('presentation/settings/facades/notationEventManagementFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);
            _router = require('plugins/router');
            return _initialize();
        };

        self.activate2 = function(facade, router) {
            _facade = facade;
            _router = router;
            return _initialize();
        };

        var _initialize = function() {
            _refreshNotationEvents();
            self.selectedSort.subscribe(_sortDisplayedNotationEvents);
            return _promiseFactory.wait();
        };
    };
});

