define('presentation/common/toggleSwitch/viewModels/toggleSwitchViewModel',[], function () {
    return function () {
        var self = this;

        var _settings = {};

        var _onIsCheckedChanged = function (checked) {
            if(checked === true) {
                self.switchStyle("toggle-switch-on");
            } else {
                self.switchStyle("toggle-switch-off");
            }
        };

        self.isChecked = ko.observable(false);
        self.isEnabled = ko.observable(false);
        self.switchStyle = ko.observable("toggle-switch-off");

        self.toggleSwitch = function () {
            if(self.isEnabled() === true) {
                self.isChecked(!self.isChecked());
            }
        };

        self.activate = function (settings) {
            _settings = settings;

            return _initialize();
        };

        self.activate2 = function (settings) {
            _settings = settings;

            return _initialize();
        };

        var _initialize = function () {

            if(_settings.isEnabled) {
                self.isEnabled(_settings.isEnabled);
            }
            if(_settings.isChecked) {
                self.isChecked = _settings.isChecked;
                _onIsCheckedChanged(_settings.isChecked());
            }

            self.isChecked.subscribe(_onIsCheckedChanged);
        };
    };
});
