define('presentation/developer/viewModels/scheduledJobsViewModel',[
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/scheduledJobsFacade',
    'presentation/developer/viewModels/editScheduledJobModalViewModel'
], function() {
    return function(){
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const EditScheduledJobModalViewModelConstructor = require('presentation/developer/viewModels/editScheduledJobModalViewModel');

        let _facade = null;
        let _modalService = null;
        let _sorter = null;

        let _sortAsc = true;
        let _sortBy = "displayName";

        self.dataGridConfig = {};
        self.scheduledJobs = ko.observableArray([]);

        self.showDataGrid = ko.computed(() => {
            return self.scheduledJobs().length > 0;
        });

        const _getScheduledJobs = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.listScheduledJobs()
                    .fail(deferredObject.reject)
                    .done((scheduledJobs) => {
                        self.scheduledJobs(scheduledJobs);
                        deferredObject.resolve();
                    });
            });
        };

        const _selectRow = (scheduledJob) => {
            self.launchEditScheduledJobModal(scheduledJob);
        };

        const _sortColumn = (column, sortAsc) => {
            _sortBy = column;
            _sortAsc = sortAsc;
            return _updateDisplayedScheduledJobs();
        };

        const _updateDisplayedScheduledJobs = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _sorter.multiSort(self.scheduledJobs, [_sortBy], [_sortAsc]);
                deferredObject.resolve();
            });
        };

        self.refreshResults = () => {
            return _getScheduledJobs();
        };

        self.launchEditScheduledJobModal = (scheduledJob) => {
            let onModalSavePromise = _promiseFactory.deferIndefinitely();
            let modal = new EditScheduledJobModalViewModelConstructor(scheduledJob, onModalSavePromise);

            onModalSavePromise
                .done((result) => {
                    if (result.action === "save") {
                        self.refreshResults();
                    }
                })
                .fail((error) => {
                    throw error;
                });

            _modalService.showModal(modal);
        };

        self.activate = () => {
            const Facade = require('presentation/developer/facades/scheduledJobsFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const ModalConstructor = require('presentation/common/modal');
            _modalService = new ModalConstructor();

            const SorterConstructor = require('common/collections/collectionSorter');
            _sorter = new SorterConstructor();

            return _initialize();
        };

        const _initialize = () => {

            _getScheduledJobs();

            self.dataGridConfig.dataSet = self.scheduledJobs;
            self.dataGridConfig.selectRowCallback = _selectRow;
            self.dataGridConfig.sortColumnCallback = _sortColumn;
            self.dataGridConfig.hasRowDetails = false;
            self.dataGridConfig.columns = [
                {"header": "Name", "key": "displayName", "isSortable": true, "sortAsc": true},
                {"header": "Enabled", "key": "isEnabled", "isSortable": true, "isSelectable": true},
            ];

            return _promiseFactory.wait();
        };
    };
});

