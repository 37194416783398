define('presentation/marketing/presentationObjects/urlPresentationObject',[],
function() {
    return function() {
        var self = this;

        self.urlId = null;
        self.url = ko.observable("").extend({ urlSafeValue: {} });
        self.rawUrl = ko.observable("");
        self.urlWithDomain = ko.observable("");
        self.isDefault = ko.observable(false);
    };
});

