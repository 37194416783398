define('persistence/fileStores/fileManagement/fileReaderResult',[],
function() {
    return function() {
        var self = this;

        self.fileName = null;
        self.fileEncodingType = null;
        self.fileSize = null;
        self.fileContent = null;
    };
});

