define('presentation/account/activity/email/facades/sendToFacade',['persistence/webSocket/webSocketApp',
        'common/authentication/employeeAuthenticationStore',],
function() {
    var _promiseFactory = null;
    var _webSocketApp = null;

    var _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');
    var _employeeId = _employeeAuthenticationStore.getEmployeeId();

    var _getSendToContacts = function(accountNumber) {
        return _promiseFactory.defer(function(deferredObject) {
            var params = {
                "account_number": accountNumber,
                "employee_id": _employeeId
            };

            _webSocketApp.send("get_send_to_contacts", params, function(result) {
                deferredObject.resolve(result);
            });
        });
    };

    var _init = function(promiseFactory) {
        _promiseFactory = promiseFactory;

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("crm_account_email");
    };

    var _init2 = function(promiseFactory, webSocketApp) {
        _promiseFactory = promiseFactory;
        _webSocketApp = webSocketApp;
    };

    return function() {
        var self = this;

        self.init = _init;
        self.init2 = _init2;

        self.getSendToContacts = _getSendToContacts;
    };
});

