define('presentation/common/optionsBox/presentationObjects/optionsBoxOptionPresentationObject',[],
function() {
    return function(title, value) {
        var self = this;

        self.title = title ? title : "";
        self.value = value ? value : "";
        self.containerHeight = '';
        self.containerWidth = '';
        self.iconHeight = '';
        self.iconName = '';
        self.iconWidth = '';
        self.hasIcon = false;
        self.hasTooltip = false;
        self.tooltip = '';
        self.isSelected = ko.observable();
        self.isEnabled = true;
    };
});
