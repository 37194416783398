define('presentation/common/knockoutConfiguration',[
        'jquery',
        'tooltip',
        'spin',
        'spin-jquery',
        'jquery-resize',
        'jquery-ui',
        'jquery-mousewheel',
        'timepicker',
        'uniform',
        'headroom',
        'jquery-headroom',
        'jplayer',
        'column-resizable',
        'tagsinput',
        'ckeditor',
        'jquery-inputmask'
    ], function() {
    var configure = function() {

        ko.bindingHandlers.tagEditorThemed = {
            init: function(element, valueAccessor, allBindingsAccessor) {
                var dataBindOptions = valueAccessor();
                var allDataBindOptions = allBindingsAccessor();

                setTimeout(function() {
                    //Keycodes: enter, space, commma
                    var options = {
                        confirmKeys: [13, 32, 188],
                        trimValue: true
                    };

                    if (dataBindOptions.tagItemValueProperty) {
                        options.itemValue = dataBindOptions.tagItemValueProperty;
                    }
                    if (dataBindOptions.tagItemTextProperty) {
                        options.itemText = dataBindOptions.tagItemTextProperty;
                    }
                    $(element).tagsinput(options);

                    if (allDataBindOptions.value && ko.isObservable(allDataBindOptions.value)) {
                        allDataBindOptions.value.subscribe(function(newValueList) {
                            $(element).tagsinput("refresh");
                        });
                    }

                    if (dataBindOptions.allowTagValidator && typeof dataBindOptions.allowTagValidator === "function") {
                        $(element).on("beforeItemAdd", function(event) {
                            if (dataBindOptions.allowTagValidator(event.item) === false) {
                                event.cancel = true;
                            }
                        });
                    }

                    if (dataBindOptions.removeTagValidator && typeof dataBindOptions.removeTagValidator === "function") {
                        $(element).on("beforeItemRemove", function(event) {
                            if (dataBindOptions.removeTagValidator(event.item) === false) {
                                event.cancel = true;
                            }
                        });
                    }

                    $(element).on("itemAdded itemRemoved", function() {
                        allDataBindOptions.value($(this).val());
                    });
                }, 1);
            }
        };


        ko.bindingHandlers.historyGridThemed = {
            init: function(element, valueAccessor) {
                var dataBindOptions = valueAccessor();

                $('body').click(function() {
                    dataBindOptions.isGridOpen(false);
                });

                $(element).click(function(event){
                    event.stopPropagation();
                });
            }
        };

        ko.bindingHandlers.wysiwygEditorThemed = {
            init: function(element, valueAccessor) {
                var dataBindOptions = valueAccessor();

                var toolbarOptions = [
                    ["Bold", "Italic", "Underline"],
                    ["BulletedList", "NumberedList"],
                    ["JustifyLeft", "JustifyCenter", "JustifyRight"],
                    ["Indent", "Outdent"],
                    ["Link", "Unlink"]
                ];

                var editorOptions = {
                    allowedContent: true,
                    toolbar: toolbarOptions,
                    removeDialogTabs: "image:advanced;link:advanced",
                    removePlugins: "showborders,elementspath",
                    scayt_autoStartup: true,
                    contentsCss: "",
                    skin: "minimalist",
                    customConfig: ""
                };

                setTimeout(function() {
                    var wysiwygEditor = CKEDITOR.replace(element, editorOptions);
                    var dataBindSubscriptions = [];

                    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                        for (var i=0; i < dataBindSubscriptions.length; i++) {
                            dataBindSubscriptions[i].dispose();
                        }
                        wysiwygEditor.destroy();

                        if (dataBindOptions.content) {
                            if (ko.isObservable(dataBindOptions.content)) {
                                dataBindOptions.content('');
                            } else {
                                dataBindOptions.content = '';
                            }
                        }
                    });

                    if (dataBindOptions.styles) {
                        CKEDITOR.addCss(ko.utils.unwrapObservable(dataBindOptions.styles));

                        if (ko.isObservable(dataBindOptions.styles)) {
                            dataBindSubscriptions.push(dataBindOptions.styles.subscribe(function(newStyles) {
                                                            CKEDITOR.addCss(newStyles);
                                                        })
                                                      );
                        }
                    }

                    if (dataBindOptions.content) {
                        wysiwygEditor.setData(ko.utils.unwrapObservable(dataBindOptions.content));

                        if (ko.isObservable(dataBindOptions.content)) {
                            dataBindSubscriptions.push(dataBindOptions.content.subscribe(function(newContentValue) {
                                                            wysiwygEditor.setData(newContentValue);
                                                        })
                                                       );
                        }
                    }

                    if (dataBindOptions.value) {
                        wysiwygEditor.on("change", function(event) {
                            if (ko.isWriteableObservable(dataBindOptions.value)) {
                                dataBindOptions.value(event.editor.getData());
                            } else {
                                dataBindOptions.value = event.editor.getData();
                            }
                        });
                    }

                    if (dataBindOptions.enabled) {
                        wysiwygEditor.on("instanceReady", function() {
                            var isEnabled = ko.utils.unwrapObservable(dataBindOptions.enabled);

                            if (isEnabled === true) {
                                wysiwygEditor.setReadOnly(false);
                            } else {
                                wysiwygEditor.setReadOnly(true);
                            }

                            if (ko.isObservable(dataBindOptions.enabled)) {
                                dataBindSubscriptions.push(dataBindOptions.enabled.subscribe(function(isEnabled) {
                                                                    if (isEnabled === true) {
                                                                        wysiwygEditor.setReadOnly(false);
                                                                    } else {
                                                                        wysiwygEditor.setReadOnly(true);
                                                                    }
                                                                })
                                                          );
                            }
                        });
                    }
                }, 1);
            }
        };

        var _togglePanelDisplay = function() {
            var $panelTitle = $(this);
            var $panelGroup = $panelTitle.parents(".panel-group");
            var $panel = $panelTitle.parent(".panel");
            var $panelContent = $(".panel-content", $panel);

            if ($panel.hasClass("expanded")) {
                $panelContent
                    .slideUp(220, "easeInOutCubic", function() {
                        $panel.removeClass("expanded");
                        $panelContent.siblings(".panel-title").removeClass("collapsing");
                    })
                    .siblings(".panel-title")
                    .addClass("collapsing");
            } else {
                $panelContent
                    .slideDown(220, "easeInOutCubic", function() {
                        $panel.addClass("expanded");
                        $panelContent.siblings(".panel-title").removeClass("expanding");
                    })
                    .siblings(".panel-title")
                    .addClass("expanding");

                $(".panel.expanded > .panel-content", $panelGroup)
                    .not($panelContent)
                    .slideUp(220, "easeInOutCubic", function() {
                        $(this)
                            .parents(".panel")
                            .removeClass("expanded");
                        $(".collapsing", $panelGroup).removeClass("collapsing");
                    })
                    .siblings(".panel-title")
                    .addClass("collapsing");
            }
        };

        ko.bindingHandlers.panelToggle = {
            init: function(element) {
                setTimeout(function() {
                    $(".panel-title", element).on("click", _togglePanelDisplay);
                    $(".panel:eq(0)", element).addClass("expanded");
                }, 1);
            }
        };

        var _togglePanelNoSiblingDisplay = function() {
            var $panelTitle = $(this);
            var $panel = $panelTitle.parent(".panel");
            var $panelContent = $(".panel-content", $panel);

            if ($panel.hasClass("expanded")) {
                $panelContent
                    .slideUp(220, "easeInOutCubic", function() {
                        $panel.removeClass("expanded");
                    });
            } else {
                $panelContent
                    .slideDown(220, "easeInOutCubic", function() {
                        $panel.addClass("expanded");
                        $panelContent.siblings(".panel-title").removeClass("expanding");
                    });
            }
        };

        ko.bindingHandlers.panelToggleNoSiblingAction = {
            init: function(element) {
                setTimeout(function() {
                    $(".panel-title", element).on("click", _togglePanelNoSiblingDisplay);
                    $(".panel", element).addClass("expanded");
                }, 1);
            }
        };

        var _findScrollableItem = function(element) {
            var $element = $(element);
            var overflow = $element.css("overflow");

            if (overflow.toLowerCase() === "scroll" || overflow.toLowerCase() === "auto") {
                return $element;
            } else {
                if ($element.parent().length > 0) {
                    return _findScrollableItem($element.parent());
                } else {
                    return null;
                }
            }
        };

        var _countUpTimerInstance = null;
        ko.bindingHandlers.countUpTimer = {
            update: function (element, valueAccessor) {
                var isTimerActive = ko.utils.unwrapObservable(valueAccessor());
                if(isTimerActive === true) {
                    if(_countUpTimerInstance) {
                        clearInterval(_countUpTimerInstance);
                        _countUpTimerInstance = null;
                    }
                    var startTime = new Date();
                    _countUpTimerInstance = setInterval(function() {
                        var nowTime = new Date();
                        var timeDiff = nowTime.getTime() - startTime.getTime();
                        var timeDiffSeconds = parseInt(timeDiff/1000);
                        $(element).text(timeDiffSeconds);
                    }, 1000);
                } else {
                    if(_countUpTimerInstance) {
                        clearInterval(_countUpTimerInstance);
                        _countUpTimerInstance = null;
                    }
                }
            }
        };

        ko.bindingHandlers.crmDataGridControls = {
            init: function(element, valueAccessor) {
                var settings = ko.utils.unwrapObservable(valueAccessor());
                var $element = $(element);
                var buffer = 300;
                var waitingForCallback = false;
                var getNextPageCallback = null;
                var hasRowDetails = null;
                var scrollableParent = _findScrollableItem(element);
                var parent = $element.parent();

                if (settings.onGetNextPage !== undefined) {
                    getNextPageCallback = settings.onGetNextPage;
                }
                if (settings.hasRowDetails !== undefined) {
                    hasRowDetails = settings.hasRowDetails;
                }

                if (scrollableParent !== null && getNextPageCallback !== null) {
                    ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
                        // This will be called when the element is removed by Knockout or
                        // if some other part of your code calls ko.removeNode(element)
                        $(scrollableParent).off();
                    });

                    $(scrollableParent).scroll(function (event) {
                        var scrollTop = event.target.scrollTop;
                        var scrollHeight = event.target.scrollHeight;
                        var offsetHeight = event.target.offsetHeight;
                        var offset = scrollHeight - offsetHeight - scrollTop;

                        if (offset < buffer && waitingForCallback === false) {
                            waitingForCallback = true;

                            getNextPageCallback()
                                .done(function() {
                                    waitingForCallback = false;
                                })
                                .fail(function(error) {
                                    waitingForCallback = false;
                                    throw error;
                                });
                        }
                    });
                }

                $(element).delegate("tbody tr", "click", function() {
                    var $this = $(this);

                    if ($this.hasClass("details-row")) {
                        return;
                    }

                    if (hasRowDetails()){
                        $this.next().toggleClass("show");
                    }

                });

                setTimeout(function() {
                    $element.colResizable();
                }, 1);
            }
        };

        ko.bindingHandlers.dateTimePickerUI = {
            init: function(element, valueAccessor) {
                var dataBindOptions = valueAccessor();
                var dateValueObservable = dataBindOptions.dateValue;
                var timeValueObservable = dataBindOptions.timeValue;

                $(".date-part", element)
                    .datepicker({
                        "onSelect": function() {
                            // date picker doesn't trigger change event so we will manually to force knockout binding update
                            $(".date-part", element).change();
                        }
                    }).inputmask({
                        inputFormat: "mm/dd/yyyy",
                        alias: "datetime",
                        clearIncomplete: true
                    });

                $(".time-part", element).inputmask({
                    inputFormat: "hh:MM tt",
                    alias: "datetime",
                    hourFormat: "12",
                    clearIncomplete: true
                });

                $(".date-part", element).on("change", function() {
                    dateValueObservable($(this).val());
                });
                $(".time-part", element).on("change", function() {
                    timeValueObservable($(this).val());
                });
            }
        };
    };

    var _handleFileSelect = function (element, allBindingsAccessor) {
        var files = element.files; // FileList object
        var accessor = allBindingsAccessor().lobbyFileHandler;
        if (files.length === 0) {
            accessor(undefined);
        } else {
            for (var index = 0; index < files.length; index++) {
                var file = files[index];
                accessor(file);
            }
        }
    };
    ko.bindingHandlers.lobbyFileHandler = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
            element.addEventListener('change', function () {
                _handleFileSelect(element, allBindingsAccessor);
            }, false);
        }
    };

    ko.extenders.urlSafeValue = function(targetObservable) {
        var computedObservable = ko.pureComputed({
            read: targetObservable,
            write: function(newValue) {
                var currentValue = targetObservable();
                var formattedNewValue = newValue.toString()
                                                .trim()
                                                .toLowerCase()
                                                .replace(/\s+/g, '-')
                                                .replace(/&/g, '-and-')
                                                .replace(/[^\w\-]+/g, '')
                                                .replace(/\-\-+/g, '-')
                                                .replace(/^-+/, '')
                                                .replace(/-+$/, '');
                if (formattedNewValue !== currentValue) {
                    targetObservable(formattedNewValue);
                } else {
                    if (newValue !== currentValue) {
                        targetObservable.notifySubscribers(formattedNewValue);
                    }
                }
            }
        }).extend({ notify: 'always'});

        computedObservable(targetObservable());

        return computedObservable;
    };

    return {
        configureKnockout : configure
    };
});

