define('presentation/account/sidebar/viewModels/vanityAddModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/account/sidebar/facades/vanityAddFacade',
    'presentation/account/sidebar/presentationObjects/vanityAddPresentationObject',
    'presentation/account/sidebar/validators/vanityAddValidator'
], function () {
    return function (accountNumber, onLoadCompletePromise, onModalCloseCompletePromise) {
        var self = this;

        var _modalService = null;
        var _onLoadCompletePromise = null;
        var _onModalCloseCompletePromise = null;
        var _facade = null;
        var _validator = null;

        var PhoneNumberPresentationObject = require('presentation/account/sidebar/presentationObjects/vanityAddPresentationObject');
        var _accountNumber = accountNumber;

        self.accountNumber = accountNumber;
        self.modalHeaderText = "Vanity Add";
        self.phoneNumbers = ko.observableArray([new PhoneNumberPresentationObject()]);
        self.isLastPhoneNumber = ko.pureComputed(function() {
            return self.phoneNumbers().length === 1;
        });
        self.saveButtonText = "Save";

        self.closeModal = function () {
            _onModalCloseCompletePromise.resolve('cancel');
            _modalService.closeModal(self);
        };

        self.saveButton = function() {
            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const promiseFactory = new PromiseFactoryConstructor();
            return promiseFactory.deferIndefinitely(function(deferredObject) {
                self.phoneNumbers.remove(function(phoneNumber) {
                    return phoneNumber.phoneNumber().trim() === "";
                });

                if (self.phoneNumbers().length === 0) {
                    self.closeModal();
                    deferredObject.resolve();
                } else {
                    _validator.validate()
                        .fail(err => {
                                _onModalCloseCompletePromise.reject(err);
                                deferredObject.reject(err);
                            }
                        )
                        .done(function(isValid) {
                            if (isValid) {
                                var phoneNumbers = self.phoneNumbers().map(function(phoneNumber) {
                                    return phoneNumber.phoneNumber();
                                });
                                _facade.vanityAddPhoneNumbers(_accountNumber, phoneNumbers)
                                    .fail(err => {
                                        _onModalCloseCompletePromise.reject(err);
                                        deferredObject.reject(err);
                                    })
                                    .done(function(result) {
                                        _modalService.closeModal(self);
                                        _onModalCloseCompletePromise.resolve(result);
                                        deferredObject.resolve();
                                    });
                            }
                        });
                }
            });
        };

        self.addAnotherPhoneNumber = function () {
            self.phoneNumbers.push(new PhoneNumberPresentationObject());
        };

        self.deletePhoneNumber = function (phoneNumber) {
            self.phoneNumbers.remove(phoneNumber);
        };

        self.activate = function () {
            var ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            var FacadeConstructor = require('presentation/account/sidebar/facades/vanityAddFacade');
            _facade = new FacadeConstructor();

            var ValidatorConstructor = require('presentation/account/sidebar/validators/vanityAddValidator');
            _validator = new ValidatorConstructor();

            _onLoadCompletePromise = onLoadCompletePromise;
            _onModalCloseCompletePromise = onModalCloseCompletePromise;

            return _initialize();
        };

        var _initialize = function () {
            _validator.registerViewModel(self, _facade);
        };
    };
});

