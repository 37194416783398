define('presentation/account/validators/patliveAccountNumberValidator',[
    'constants/validationMessageEnumerations',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validPatliveAccountNumberSpecification'
], function() {
    return function() {
        var self = this;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');
        var ValidPatliveAccountNumberSpecificationConstructor = require('presentation/common/validation/validPatliveAccountNumberSpecification');
        var _validPatliveAccountNumberSpecification = new ValidPatliveAccountNumberSpecificationConstructor();

        var _commonValidator = null;
        var _viewModel = null;
        var _facade = null;

        self.registerViewModel = function(viewModel, facade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.settings.potentialCustomer;
            var validationRules = new ValidationRulesConstructor();

            validationRules.field("patliveAccountNumber")
                .addValidation(_isValidPatliveAccountNumber, validationMessageBase.accountNumberInvalid);

            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _isValidPatliveAccountNumber = function(patliveAccountNumber) {
            return _validPatliveAccountNumberSpecification.isValidPatliveAccountNumber(patliveAccountNumber);
        };
    };
});

