define('presentation/developer/viewModels/operatorViewModel',[
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/developer/facades/operatorFacade'
], function() {
    return function() {
        let self = this;
        
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();
        
        let _facade = null;
        let _sorter = null;

        let _sortAsc = true;
        let _sortBy = "operatorState";
    
        self.operatorInstancesDataGridConfig = {};
        self.operators = ko.observableArray([]);

        self.isHangSelectedServersVisible = ko.computed(() => {
            return self.operators().some((instance) => instance.isSelected() === true);
        });

        self.hangupSelectedServers = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                const selectedOperatorInstances = self.operators().filter((operatorInstance) => operatorInstance.isSelected() === true);
                _promiseFactory.deferredList(selectedOperatorInstances, _facade.hangupOperatorInstance)
                    .fail(deferredObject.reject)
                    .done(() => {
                        _refreshOperatorInstances()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    });
            });
        };
        
        self.drainStopAndTerminateSelectedServers = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                const selectedOperatorInstances = self.operators().filter((operatorInstance) => operatorInstance.isSelected() === true);
                _promiseFactory.deferredList(selectedOperatorInstances, _facade.drainStopAndTerminateOperatorInstances)
                    .fail(deferredObject.reject)
                    .done(() => {
                        _refreshOperatorInstances()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    });
            });
        };
    
        self.drainStopAndDetachSelectedServers = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                const selectedOperatorInstances = self.operators().filter((operatorInstance) => operatorInstance.isSelected() === true);
                _promiseFactory.deferredList(selectedOperatorInstances, _facade.drainStopAndDetachOperatorInstances)
                    .fail(deferredObject.reject)
                    .done(() => {
                        _refreshOperatorInstances()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    });
            });
        };

        self.hangupCallsForServer = (operatorInstance) => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.hangupOperatorInstance(operatorInstance)
                    .fail(deferredObject.reject)
                    .done(() => {
                        _refreshOperatorInstances()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    });
            });
        };

        self.refreshOperatorInstances = () => {
            return _refreshOperatorInstances();
        };

        self.activate = () => {
            const Facade = require('presentation/developer/facades/operatorFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const SorterConstructor = require('common/collections/collectionSorter');
            _sorter = new SorterConstructor();
            
            return _initialize();
        };
        
        const _getOperatorInstances = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.getOperatorInstances()
                    .fail(deferredObject.reject)
                    .done((instances) => {
                        let operatorInstances = instances.map((instance) => {
                           return {
                               isSelected: ko.observable(false),
                               instanceId: instance.instanceId,
                               publicIpAddress: instance.publicIpAddress,
                               serverId: instance.serverId,
                               operatorState: instance.status,
                               totalChannelCount: instance.totalChannelCount,
                               isDetailsVisible: ko.observable(false)
                           };
                        });
                        self.operators(operatorInstances);
                        deferredObject.resolve();
                    });
            });
        };

        const _refreshOperatorInstances = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _getOperatorInstances()
                    .fail(deferredObject.reject)
                    .done(() => {
                        _applySort()
                            .done(deferredObject.resolve);
                    });
            });
        };

        const _selectRow = (row) => {
            let operatorInstance = self.operators().find((operator) => {
                return operator.serverId === row.serverId;
            });
            operatorInstance.isSelected(!operatorInstance.isSelected());
        };

        const _sortColumn = (column, sortAsc) => {
            _sortBy = column;
            _sortAsc = sortAsc;
            return _applySort();
        };

        const _applySort = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _sorter.multiSort(self.operators, [_sortBy], [_sortAsc]);
                deferredObject.resolve();
            });
        };

        const _initialize = () => {
            self.operatorInstancesDataGridConfig.dataSet = self.operators;
            self.operatorInstancesDataGridConfig.selectRowCallback = _selectRow;
            self.operatorInstancesDataGridConfig.sortColumnCallback = _sortColumn;
            self.operatorInstancesDataGridConfig.hasRowDetails = false;
            self.operatorInstancesDataGridConfig.columns = [
                {"header": "Selected", "key": "isSelected", "isSortable": false, "isSelectable": true},
                {"header": "Instance Id", "key": "instanceId", "isSortable": true},
                {"header": "Public IpAddress", "key": "publicIpAddress", "isSortable": true},
                {"header": "Server Id", "key": "serverId", "isSortable": true},
                {"header": "Operator State", "key": "operatorState", "isSortable": true},
                {"header": "Channel Count", "key": "totalChannelCount", "isSortable": true},
            ];
            
            _getOperatorInstances();
            
            return _promiseFactory.wait();
        };
    };
});


