define('presentation/shell/viewModels/templateHeaderMarketingSidebarContentViewModel',['common/promises/promiseFactory',
        'businessServices/router/crmRouter',
        'presentation/navigation/viewModels/mainNavigationViewModel',
        'businessServices/authentication/employeeAuthorization'
    ],
    function(){
        return function() {
            var self = this;

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();
            var HeaderViewModelConstructor = require('presentation/navigation/viewModels/mainNavigationViewModel');
            var _employeeAuthorization = null;

            var _setSidebarNavigationIsSelected = function(navItemSelectedWhenRouterFragmentStartsWith) {
                return ko.computed(function() {
                    return self.router.activeInstruction().fragment.indexOf(navItemSelectedWhenRouterFragmentStartsWith) === 0;
                });
            };

            var _doesEmployeeHavePermission = function() {
                return _employeeAuthorization.hasPermission("CRM.Marketing");
            };

            var _populateSidebarNavigation = function() {
                _doesEmployeeHavePermission()
                    .done((hasPermission) => {
                        if (hasPermission) {
                            self.sidebarItems([
                                {
                                    title: "Email Notifications",
                                    url: "marketing/emailNotifications",
                                    iconClass: "icon-nav-gear",
                                    isSelected: _setSidebarNavigationIsSelected("marketing/emailNotifications")
                                }
                            ]);
                        }
                    });
            };

            var _loadHeader = function() {
                return _promiseFactory.defer(function(promise) {
                    var headerViewModel = new HeaderViewModelConstructor();
                    headerViewModel.activate();
                    headerViewModel.setRouter(self.router);

                    self.headerPane(headerViewModel);
                    promise.resolve();
                });
            };

            self.router = require('businessServices/router/crmRouter');

            self.headerPane = ko.observable();
            self.sidebarItems = ko.observableArray([]);

            self.activate = function() {
                return _initialize();
            };


            var _initialize = function() {

                _employeeAuthorization = require('businessServices/authentication/employeeAuthorization');

                _loadHeader();
                _populateSidebarNavigation();

                return _promiseFactory.wait();
            };
        };
    });

