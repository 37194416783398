define('constants/reasonForCancellationEnumerations',[], function () {
    return  {
        empty: "",
        adCampaignEnded: "Ad/Campaign Ended",
        customerServiceProblem: "Customer Service Problem",
        fraudulent: "Fraudulent",
        noLongerNeeded: "No Longer Needed",
        nonPayment: "Non-Payment",
        notEnoughFeatures: "Not Enough Features",
        other: "Other",
        costTooMuch: "Service Cost Too Much",
        switchedToCompetitor: "Switched to Competitor",
        technicalProblems: "Technical Problems",
        tooDifficultToUse: "Too Difficult To Use",
        unknown: "Unknown"
    };
});

