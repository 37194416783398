define('entities/notationReportEntity',[],function() {
    return function(){
        var self = this;

        self.notationReportId = null;
        self.reportName = null;
        self.events = null;
        self.isDeleted = null;
        self.createdDateTime = null;
        self.modifiedDateTime = null;
    };
});

