define('presentation/shell/viewModels/shellViewModel',['common/promises/promiseFactory',
    'businessServices/router/crmRouter',
    'businessServices/events/eventManager',
    'settings/navigationConfiguration',
    'common/promises/promiseFactory'
], function() {

    return function() {
        var self = this;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _navigationConfiguration = require('settings/navigationConfiguration');

        var _updateShell = function(isNavigateComplete) {
            if (isNavigateComplete){
                if (self.router.activeInstruction()) {
                    var activeInstruction = self.router.activeInstruction();
                    var params = activeInstruction.params;
                    var routeId = activeInstruction.config.customSettings.routeId;
                    var routeInfo = _navigationConfiguration.routesById[routeId];
                    var template = routeInfo.cssTemplate;
                    if(template !== self.shellName()) {
                        switch (template) {
                            case "templateContent":
                                self.shellViewModel('presentation/shell/viewModels/templateContentViewModel');
                                self.shellName("templateContent");
                                break;
                            case "templateHeaderContent":
                                self.shellViewModel('presentation/shell/viewModels/templateHeaderContentViewModel');
                                self.shellName("templateHeaderContent");
                                break;
                            case "templateHeaderSidebarContent":
                                self.shellViewModel('presentation/shell/viewModels/templateHeaderSidebarContentViewModel');
                                self.shellName("templateHeaderSidebarContent");
                                break;
                            case "templateHeaderSidebarAccountContent":
                                self.shellViewModel('presentation/shell/viewModels/templateHeaderSidebarAccountContentViewModel');
                                self.shellName("templateHeaderSidebarAccountContent");
                                break;
                            case "templateHeaderMarketingSidebarContent":
                                self.shellViewModel('presentation/shell/viewModels/templateHeaderMarketingSidebarContentViewModel');
                                self.shellName("templateHeaderMarketingSidebarContent");
                                break;
                            default:
                                self.shellViewModel('presentation/shell/viewModels/templateContentViewModel');
                                self.shellName("templateContent");
                                break;
                        }
                        self.params(params);
                    }
                }
            }
        };

        self.shellViewModel = ko.observable();
        self.shellName = ko.observable("");
        self.params = ko.observable();

        self.activate = function() {
            self.router = require('businessServices/router/crmRouter');
            return _initialize();
        };

        self.activate2 = function(router) {
            self.router = router;
            return _initialize();
        };

        var _initialize = function() {
            _promiseFactory.defer(function(promise) {
                if (self.router.customState.isNavigationComplete()) {
                    _updateShell(true);
                }
                self.router.customState.isNavigationComplete.subscribe(_updateShell);
                self.router.customState.applicationIsReady.resolve(true);
                promise.resolve();
            });
            return _promiseFactory.wait();
        };
    };
});

