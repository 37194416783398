define('presentation/developer/viewModels/sqsQueueDetailsViewModel',['common/promises/promiseFactory',
    'presentation/developer/facades/sqsQueueDetailsFacade'], function() {
    return function(){
        const self = this;
        let _facade = null;
        
        const Facade = require('presentation/developer/facades/sqsQueueDetailsFacade');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    
        const _promiseFactory = new PromiseFactoryConstructor();
    
        self.queueUrl = null;
        self.queueName = null;
        self.isDeadLetterQueue = ko.observable(false);
        self.queueMetaData = ko.observable(null);
        
        const refresh = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.getQueueMetaData(self.queueUrl)
                    .fail(deferredObject.reject)
                    .done((result) => {
                        self.queueName = result.name;
                        self.isDeadLetterQueue(result.is_dead_letter_queue);
                        self.queueMetaData(result);
                        deferredObject.resolve();
                    });
            });
        };
        
        self.reprocessAllMessages = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.reprocessMessages(self.queueUrl)
                    .fail(deferredObject.reject)
                    .done(() => {
                        refresh()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    });
            });
        };
        
        self.activate = (encodedQueueUrl) => {
            _facade = new Facade();
            _facade.init(_promiseFactory);
            
            self.queueUrl = decodeURIComponent(encodedQueueUrl);
            
            return _initialize();
        };
        
        const _initialize = () => {
            refresh();
            
            return _promiseFactory.wait();
        };
    };
});

