define('businessServices/router/plugins/fromClientUIRouterPlugin',[
    'settings/navigationConfiguration',
    'businessServices/authentication/authenticatedEmployee',
    'constants/statusConstants'
], function (
    navigationConfiguration,
    authenticatedEmployee,
    statusConstants
) {
    function isFromClientUI(urlFragment) {
        var urlParts = urlFragment.split("/");
        return urlParts.length === 4 && urlParts[0] === "fromClientUI";
    }

    function fromClientUIRedirect(urlFragment, results) {
        if(!isFromClientUI(urlFragment)) {
            return true;
        }

        if(results !== undefined && results.status === statusConstants.success && authenticatedEmployee.isAuthenticated()) {
            return {routeUrl: "account/" + results.accountNumber + "/users"};
        }

        return {routeUrl: navigationConfiguration.loginPageRouteUrl};
    }

    return {
        loadUrlRedirect: fromClientUIRedirect
    };
});
