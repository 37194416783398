define('presentation/settings/viewModels/notationReportManagementViewModel',['common/promises/promiseFactory',
        'presentation/settings/facades/notationReportManagementFacade',
        'common/collections/collectionFilter',
        'common/collections/collectionSorter',
        'presentation/promptDialog/viewModels/promptDialogViewModel',
        'plugins/router'],
function() {
    return function(){
        var self = this;
        var _facade = null;
        var _promptDialog = null;
        var FilterConstructor = require('common/collections/collectionFilter');
        var SorterConstructor = require('common/collections/collectionSorter');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _availableNotationReports = null;
        var _sorter = new SorterConstructor();

        var _buildDeleteNotationReportModal  = function() {
            _promptDialog.masterClear();
            _promptDialog.setTitle('Delete Notation Report');
            _promptDialog.addPrimaryButton('Delete', 'delete');
            _promptDialog.addButton('Cancel', 'cancel');
            _promptDialog.addLine("Are you sure you want to delete the notation report?");
            _promptDialog.addEmptyLine();
        };

        var _refreshNotationReports = function() {
            _facade.getNotationReports()
                .done(function(notationReportsList) {
                    _availableNotationReports = notationReportsList;
                    _updateDisplayedNotationReports();
                });
        };

        var _notationReportFilterOnChange = function() {
            _updateDisplayedNotationReports();
        };

        var _updateDisplayedNotationReports = function() {
            var filteredNotationReports;

            var filterContent = self.notationReportSearch();

            if (filterContent !== '') {
                var notationReportSearch = new FilterConstructor();

                notationReportSearch.addProperty('notationReportId');
                notationReportSearch.addProperty('reportName');
                notationReportSearch.addProperty('modifiedDateTime.filterValue');
                notationReportSearch.addProperty('createdDateTime.filterValue');
                notationReportSearch.addValue(filterContent);
                filteredNotationReports = notationReportSearch.filter(_availableNotationReports);
            }
            else {
                filteredNotationReports = _availableNotationReports;
            }

            self.notationReports.removeAll();
            for (var x = 0; x < filteredNotationReports.length; x++) {
                var notationEvent = filteredNotationReports[x];
                self.notationReports.push(notationEvent);
            }

            _sortDisplayedNotationReports();
        };

        var _sortDisplayedNotationReports = function() {
            var sortOrder = self.selectedSort();
            var options = self.sortOptions();
            for (var x = 0; x < options.length; x ++) {
                if (options[x].data === sortOrder.data) {
                    _sorter.sort(self.notationReports, options[x].sortPath, options[x].isAscending);
                    break;
                }
            }
        };

        self.deleteReport = function(reportPresentationObject) {
            return _promiseFactory.deferIndefinitely(function (deferredObject) {
                _promptDialog.showDialog()
                    .done(function() {
                        if (_promptDialog.userSelection === 'delete') {
                            var notationReportId = reportPresentationObject.notationReportId;
                            _facade.deleteNotationReport(notationReportId)
                                .done(function() {
                                    _refreshNotationReports();
                                    _promptDialog.complete();
                                    deferredObject.resolve();
                                })
                                .fail(function(error) {
                                    deferredObject.reject(error);
                                });
                        } else {
                            _promptDialog.complete();
                            deferredObject.resolve();
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.sortOptions = ko.observableArray([
            {data: "alphabetical", displayName: "Name", sortPath : "reportName", isAscending : true},
            {data: "date_created", displayName: "Recently Added", sortPath : "createdDateTime.sortValue", isAscending : false},
            {data: "date_modified", displayName: "Recently Modified", sortPath : "modifiedDateTime.sortValue", isAscending : false}
        ]);
        self.addNotationReportUrl = "/settings/addNotationReport";
        self.notationReportSearch = ko.observable('');
        self.notationReportSearch.subscribe(_notationReportFilterOnChange);
        self.notationReports = ko.observableArray([]);
        self.selectedSort = ko.observable({data: "alphabetical", displayName: "Name", sortPath : "reportName", isAscending : true});
        self.selectedSort.subscribe(_sortDisplayedNotationReports);
        self.isActive = ko.observable(true);

        self.activate = function() {
            var Facade = require('presentation/settings/facades/notationReportManagementFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            var PromptsDialogViewModelConstructor = require('presentation/promptDialog/viewModels/promptDialogViewModel');
            _promptDialog = new PromptsDialogViewModelConstructor();


            return _initialize();
        };

        self.activate2 = function() {
            return _initialize();
        };

        var _initialize = function() {
            _promptDialog.activate();
            _buildDeleteNotationReportModal();
            _refreshNotationReports();

            return _promiseFactory.wait();
        };
    };
});

