define('presentation/developer/viewModels/numberManagementViewModel',[
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/numberManagementFacade',
    'presentation/developer/validators/numberManagementValidator',
    'presentation/developer/viewModels/moveNumbersModalViewModel'
], function() {
    return function(){
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const FilterConstructor = require('common/collections/collectionFilter');
        const MoveNumbersModalViewModelConstructor = require('presentation/developer/viewModels/moveNumbersModalViewModel');

        let _availablePhoneNumbers = null;
        let _facade = null;
        let _modalService = null;
        let _sorter = null;
        let _validator = null;

        let _sortAsc = true;
        let _sortBy = "phoneNumber";

        const _phoneNumbersFilterOnChange = () => {
            _updateDisplayedPhoneNumbers();
        };

        const _selectAllPhoneNumbers = () => {
            const selectedAll = self.selectedAll();
            _availablePhoneNumbers.forEach((phoneNumber) => {
                phoneNumber.isSelected(selectedAll);
            });
            _updateDisplayedPhoneNumbers();
        };

        self.dataGridConfig = {};

        self.accountNumber = ko.observable('');
        self.displayPhoneNumbers = ko.observableArray([]);
        self.phoneNumbersFilter = ko.observable('');
        self.phoneNumbersFilter.subscribe(_phoneNumbersFilterOnChange);
        self.phoneNumbersGetNext = ko.observable();
        self.selectedAll = ko.observable(false);
        self.selectedAll.subscribe(_selectAllPhoneNumbers);
        self.showDataGrid = ko.observable(false);

        self.areAllNumbersSelected = ko.computed(() => {
            return _availablePhoneNumbers && _availablePhoneNumbers.every((phoneNumber) => {
                return phoneNumber.isSelected() === true;
            });
        });

        const _refreshDisplayedPhoneNumbers = () => {
            return _promiseFactory.deferIndefinitely(function (deferredObject) {
                _facade.getAllPhoneNumbers(self.accountNumber())
                    .fail(deferredObject.reject)
                    .done((phoneNumbers) => {
                        _availablePhoneNumbers = phoneNumbers;
                        _updateDisplayedPhoneNumbers();

                        if (phoneNumbers.length > 0) {
                            self.showDataGrid(true);
                        } else {
                            self.showDataGrid(false);
                        }
                        self.selectedAll(false);
                        deferredObject.resolve();
                    });
            });
        };

        const _selectRow = (row) => {
            let selectedNumber = _availablePhoneNumbers.find((phoneNumber) => {
                return phoneNumber.phoneNumber === row.phoneNumber;
            });
            selectedNumber.isSelected(!selectedNumber.isSelected());

            return _updateDisplayedPhoneNumbers();
        };

        const _sortColumn = (column, sortAsc) => {
            _sortBy = column === "addedDateTime" ? "createdDateTime.sortValue" : column;
            _sortAsc = sortAsc;
            return _updateDisplayedPhoneNumbers();
        };

        const _updateDisplayedPhoneNumbers = () => {
            return _promiseFactory.deferIndefinitely(function (deferredObject) {
                let filteredPhoneNumbers;
                const filterContent = self.phoneNumbersFilter().trim().toLowerCase();
                if (filterContent) {
                    let phoneNumbersFilter = new FilterConstructor();
                    phoneNumbersFilter.addProperty('phoneNumber');
                    phoneNumbersFilter.addProperty('phoneNumberName');
                    phoneNumbersFilter.addValue(filterContent);
                    filteredPhoneNumbers = phoneNumbersFilter.filter(_availablePhoneNumbers);
                }
                else {
                    filteredPhoneNumbers = _availablePhoneNumbers;
                }

                self.displayPhoneNumbers.removeAll();
                filteredPhoneNumbers.forEach((phoneNumber) => {
                    phoneNumber.isDetailsVisible = ko.observable(false);
                    self.displayPhoneNumbers.push(phoneNumber);
                });

                _sorter.multiSort(self.displayPhoneNumbers, [_sortBy], [_sortAsc]);
                deferredObject.resolve();
            });
        };

        self.launchMovePhoneNumbersModal = () => {
            let onModalSavePromise = _promiseFactory.deferIndefinitely();
            let selectedPhoneNumbers = self.displayPhoneNumbers() && self.displayPhoneNumbers().filter((number) => number.isSelected());
            let modal = new MoveNumbersModalViewModelConstructor(onModalSavePromise);
            modal.fromAccountNumber(self.accountNumber());
            modal.selectedAll = self.areAllNumbersSelected();
            modal.selectedNumbers(selectedPhoneNumbers);

            _modalService.showModal(modal);
            onModalSavePromise
                .done((result) => {
                    if (result.action === "submit"){
                        _refreshDisplayedPhoneNumbers();
                    }
                })
                .fail((error) => {
                    throw error;
                });
        };

        self.refreshResults = () => {
            return _promiseFactory.deferIndefinitely(function (deferredObject) {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if(isValid === false) {
                            deferredObject.resolve();
                            return;
                        }
                        _refreshDisplayedPhoneNumbers()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    });
            });
        };

        self.activate = () => {
            const Facade = require('presentation/developer/facades/numberManagementFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const ModalConstructor = require('presentation/common/modal');
            _modalService = new ModalConstructor();

            const SorterConstructor = require('common/collections/collectionSorter');
            _sorter = new SorterConstructor();

            const ValidatorConstructor = require('presentation/developer/validators/numberManagementValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {

            self.dataGridConfig.dataSet = self.displayPhoneNumbers;
            self.dataGridConfig.selectRowCallback = _selectRow;
            self.dataGridConfig.sortColumnCallback = _sortColumn;
            self.dataGridConfig.hasRowDetails = false;
            self.dataGridConfig.columns = [
                {"header": "Selected", "key": "isSelected", "isSortable": true, "isSelectable": true},
                {"header": "Phone Number Name", "key": "phoneNumberName", "isSortable": true},
                {"header": "Phone Number", "key": "phoneNumber", "isSortable": true, "sortAsc": true},
                {"header": "Added", "key": "addedDateTime", "isSortable": true},
                {"header": "Status", "key": "status", "isSortable": true},
            ];

            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();
        };
    };
});

