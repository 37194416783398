define('presentation/settings/viewModels/addBlacklistPhoneNumberViewModel',['common/promises/promiseFactory',
    'plugins/router',
    'presentation/settings/facades/addBlacklistPhoneNumberFacade',
    'presentation/settings/validators/addBlacklistPhoneNumberValidator'
], function () {
    return function(){
        var self = this;
        var _router = null;
        var _facade = null;
        var _validator = null;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        self.blacklistPhoneNumbers = [];
        self.formTitle = "Add New Blacklist Phone Number";
        self.saveButtonTitle = "Add";
        self.phoneNumber = ko.observable('');
        self.countryAbbreviation = ko.observable();
        self.countryCode = ko.observable();

        self.cancelForm = function () {
            return _promiseFactory.defer(function (deferredObject) {
                _router.navigate("/settings/blacklistPhoneNumbers");
                deferredObject.resolve();
            });
        };
        self.addNewBlacklistPhoneNumber = function() {
            return _promiseFactory.defer(function (deferredObject) {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done(function(isValid) {
                        if (isValid) {
                            _facade.addPhoneNumberToBlackList(self.phoneNumber(), self.countryAbbreviation(), self.countryCode())
                                .fail(deferredObject.reject)
                                .done(function () {
                                    deferredObject.resolve();
                                    _router.navigate('/settings/blacklistPhoneNumbers');
                                });
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };


        self.activate = function() {
            var FacadeConstructor = require('presentation/settings/facades/addBlacklistPhoneNumberFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);
            _router = require('plugins/router');

            var ValidatorConstructor = require('presentation/settings/validators/addBlacklistPhoneNumberValidator');
            _validator = new ValidatorConstructor();
            return _initialize();
        };

        self.activate2 = function () {
            return _initialize();
        };

        var _initialize = function() {
            _validator.registerViewModel(self, _facade);
            return _promiseFactory.wait();
        };
    };
});

          

           
