define('presentation/release/viewModels/releaseTogglesViewModel',['common/promises/promiseFactory',
        'common/collections/collectionSorter',
        'presentation/release/facades/releaseTogglesFacade',
        'presentation/release/facades/addFeatureToAccountModalFacade',
        'presentation/release/viewModels/addFeatureToAccountModalViewModel',
        'presentation/promptDialog/viewModels/promptDialogViewModel',
        'presentation/common/modal',
        'plugins/router'
    ], function() {
        return function() {
            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();
            const CollectionSorterConstructor = require('common/collections/collectionSorter');
            const _collectionSorter = new CollectionSorterConstructor();

            let self = this;
            let _facade = null;
            let _promptDialog = null;
            let _modalService = null;
            let AddFeatureToAccountModalConstructor = null;
            let AddFeatureToAccountFacadeConstructor = null;
            let _addFeatureToAccountFacade = null;

            self.showConfirmationMessage = ko.observable(false);
            self.confirmationMessage = ko.observable("");
            self.confirmationMessageActionButtonText = ko.observable("");
            self.releaseToggles = ko.observableArray([]);
            self.changedFeatures = ko.observableArray([]);
            self.changesMade = ko.computed(function() {
                return ko.utils.arrayFirst(self.releaseToggles(), function (releaseToggle) {
                    return releaseToggle.isChanged();
                });
            });
            self.saveButtonText = ko.computed(function() {
                if (self.changesMade()) {
                    return "Save";
                }
                return "OK";
            });

            const _refreshReleaseToggles = () => {
                _facade.getReleaseToggles()
                    .done((result) => {
                        _collectionSorter.sort(result, "name");
                        self.releaseToggles(result);
                    });
            };

            self.toggleFeatureIsChanged = (feature) => {
                let currentFeature = ko.utils.arrayFirst(self.releaseToggles(), function(releaseToggle) {
                    return releaseToggle.featureToggleId === feature.featureToggleId;
                });
                let changedFeature = ko.observable(currentFeature);
                self.releaseToggles.replace(changedFeature, ko.observable(changedFeature().isChanged(!changedFeature().isChanged())));
            };

            self.openAddFeatureToAccountsModal = (feature) => {
                return _promiseFactory.deferIndefinitely((onLoadCompletePromise) => {
                    let onModalCloseCompletePromise = _promiseFactory.deferIndefinitely();
                    let addFeatureToAccountModal = new AddFeatureToAccountModalConstructor(feature, onLoadCompletePromise, onModalCloseCompletePromise);

                    onModalCloseCompletePromise
                        .fail(onLoadCompletePromise.reject)
                        .done(() => {
                            _refreshReleaseToggles();
                        });

                    addFeatureToAccountModal.activate();
                    _modalService.showModal(addFeatureToAccountModal);
                });
            };

            self.saveButton = () => {
                return _promiseFactory.defer((promise) => {
                    if (!self.changesMade()) {
                        promise.resolve();
                    } else {
                        _promptDialog.masterClear();
                        _promptDialog.setTitle('Activate These Changes?');
                        _promptDialog.addLine("Saving these changes will immediately effect Tresta.");
                        _promptDialog.addRedButton('Activate Changes', 'activate');
                        _promptDialog.addButton('Cancel', 'cancel');
                        _promptDialog.showDialog()
                            .done(() => {
                                if (_promptDialog.userSelection === 'activate') {
                                    _facade.saveFeatures(self.releaseToggles())
                                        .done(() => {
                                            _promptDialog.complete();
                                            _refreshReleaseToggles();
                                            promise.resolve();
                                        });
                                } else {
                                    _promptDialog.complete();
                                    promise.resolve();
                                }
                            });

                        self.confirmationMessage("");
                        self.showConfirmationMessage(true);
                    }
                });
            };

            self.removeFeature = (featureToggle, accountToRemove) => {
                return _promiseFactory.defer((promise) => {
                    featureToggle.accounts.remove(account => account.accountId === accountToRemove.accountId);
                    let accountId = accountToRemove.accountId;
                    _facade.removeFeatureFromAccount(featureToggle.featureToggleId, accountId)
                        .done(() => {
                            _refreshReleaseToggles();
                            promise.resolve();
                        });
                });
            };

            self.activate = function() {
                const Facade = require('presentation/release/facades/releaseTogglesFacade');
                _facade = new Facade();
                _facade.init(_promiseFactory);

                const PromptsDialogViewModelConstructor = require('presentation/promptDialog/viewModels/promptDialogViewModel');
                _promptDialog = new PromptsDialogViewModelConstructor();

                const ModalServiceConstructor = require('presentation/common/modal');
                _modalService = new ModalServiceConstructor();

                AddFeatureToAccountModalConstructor = require('presentation/release/viewModels/addFeatureToAccountModalViewModel');

                AddFeatureToAccountFacadeConstructor = require('presentation/release/facades/addFeatureToAccountModalFacade');
                _addFeatureToAccountFacade = new AddFeatureToAccountFacadeConstructor();
                _addFeatureToAccountFacade.init();

                return _initialize();
            };

            const _initialize = function() {
                _promptDialog.activate();
                _refreshReleaseToggles();

                return _promiseFactory.wait();
            };
        };
    });

