define('presentation/account/sidebar/presentationObjects/accountFlagPresentationObject',[], function () {
    return function () {
        var self = this;

        self.name = null;
        self.text = null;
        self.icon = null;
        self.type = null;
        self.order = null;
        self.isHidden = false;
        self.status = ko.observable();
    };
});

