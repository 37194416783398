define('presentation/navigation/facades/recentlyViewedAccountsFacade',['common/authentication/employeeAuthenticationStore',
    'common/converters/accountNumberFormatter',
    'businessServices/pathProviders/urlFormatter',
    'common/converters/passedTimeSpanFormatter',
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketApp'
], function() {
    return function(){
        var self = this;

        var _promiseFactory = null;
        var _webSocketRecentlyViewedAccounts = null;
        var _employeeAuthenticationStore = null;
        var _employeeId = null;

        var PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
        var _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();

        var _urlFormatter = require('businessServices/pathProviders/urlFormatter');

        var AccountNumberFormatterConstructor = require('common/converters/accountNumberFormatter');
        var _accountNumberFormatter = new AccountNumberFormatterConstructor();

        self.getRecentlyViewedAccounts = function() {
            return _promiseFactory.defer(function(promise) {
                var webSocketParams = {
                    "employee_id" : _employeeId
                };
                _webSocketRecentlyViewedAccounts.send("get_employee_history", webSocketParams, function(results) {
                    if(results) {
                        var recentEmployeeHistory = results.map(function (currentHistory) {
                            currentHistory.accountUrl = _urlFormatter.buildUrl(["account", currentHistory.accountNumber, "users"]);
                            currentHistory.accountNumber = _accountNumberFormatter.formatAccountNumber(currentHistory.accountNumber);
                            currentHistory.dateViewedPresentation = _passedTimeSpanFormatter.formatDateTime(currentHistory.dateViewed);
                            return currentHistory;
                        });
                        promise.resolve(recentEmployeeHistory);
                    } else {
                        promise.resolve([]);
                    }
                });
            });
        };

        self.init = function(promiseFactory) {
            _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');
            _employeeId = _employeeAuthenticationStore.getEmployeeId();

            _promiseFactory = promiseFactory;

            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketRecentlyViewedAccounts = new WebSocketAppConstructor();
            _webSocketRecentlyViewedAccounts.init("crm_recently_viewed_accounts");
        };

        self.init2 = function(promiseFactory, webSocketRecentlyViewedAccounts, EmployeeAuthenticationStore) {
            _promiseFactory = promiseFactory;
            _webSocketRecentlyViewedAccounts = webSocketRecentlyViewedAccounts;
            _employeeAuthenticationStore = EmployeeAuthenticationStore;
            _employeeId = _employeeAuthenticationStore.getEmployeeId();
        };
    };
});

