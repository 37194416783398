define('presentation/accountLookup/facades/accountLookupFacade',['common/promises/promiseFactory',
        'persistence/webSocket/webSocketApp',
        'common/converters/passedTimeSpanFormatter',
        'common/converters/accountNumberFormatter'],
    function() {

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _webSocketApp = null;

        var PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
        var _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();

        var AccountNumberFormatterConstructor = require('common/converters/accountNumberFormatter');
        var _accountNumberFormatter = new AccountNumberFormatterConstructor();

        function init() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("crm_account_lookup");
        }

        function init2() {

        }

        function processSearchResult(searchResult) {
            var result;
            switch (searchResult.status) {
                case "success":
                    var accountInfos = searchResult.accountInfos;
                    accountInfos.sort(function (documentA, documentB) {
                        if (documentA.accountNumber > documentB.accountNumber) {
                            return 1;
                        } else if (documentA.accountNumber < documentB.accountNumber) {
                            return -1;
                        } else {
                            return 0;
                        }
                    });

                    var accounts = accountInfos.map(function(accountInfo) {
                        var signupdateDate = _passedTimeSpanFormatter.format(accountInfo.signupDate);
                        var formattedAccountNumber = _accountNumberFormatter.formatAccountNumber(accountInfo.accountNumber);
                        const city = accountInfo.city === "undefined" ? "unknown" : accountInfo.city;
                        const state = accountInfo.state === "undefined" ? "unknown" : accountInfo.state;

                        return {
                            accountNumber: formattedAccountNumber,
                            accountStatus: accountInfo.accountStatus,
                            signupDate: signupdateDate,
                            accountHolderName: accountInfo.accountHolderName,
                            billingStatus: accountInfo.billingStatus,
                            city,
                            state,
                            url: "account/" + accountInfo.accountNumber + "/users"
                        };
                    });

                    result = {
                        success : true,
                        accounts : accounts
                    };
                    break;
                case "accountNotFound":
                    result = {
                        success : false,
                        accountNumber : null,
                        reason : "account not found"
                    };
                    break;
                default:
                    result = {
                        success : false,
                        accountNumber : null,
                        reason : "unknown"
                    };
                    break;
            }
            return result;
        }

        function lookup(searchValue) {
            return _promiseFactory.defer(function(promise) {
                var formattedSearchValue = searchValue.trim();

                var params = {
                    "search_parameter" : formattedSearchValue
                };
                _webSocketApp.send("search", params, function(searchResult) {
                    var result = processSearchResult(searchResult);
                    promise.resolve(result);
                });
            });
        }

        return {
            init : init,
            init2 : init2,
            lookup : lookup
        };
    });

