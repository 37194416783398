define('presentation/developer/viewModels/sqsViewModel',['common/promises/promiseFactory',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'presentation/developer/facades/sqsFacade'], function() {
    return function(){
        const self = this;
        let _facade = null;
    
        const Facade = require('presentation/developer/facades/sqsFacade');
        const FilterConstructor = require('common/collections/collectionFilter');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const SorterConstructor = require('common/collections/collectionSorter');
    
        const _promiseFactory = new PromiseFactoryConstructor();
        const _sorter = new SorterConstructor();
        
        const QUEUE_SORT_OPTIONS = [
            { "value": "name",                    "displayName":"Queue Name" },
            { "value": "messages",                "displayName":"Total Messages" },
            { "value": "messages_in_flight",      "displayName":"Messages in Flight" }
        ];
        
        self.queueList = ko.observableArray([]);
        self.queueSearch = ko.observable('');
        self.sortOptions = ko.observableArray(QUEUE_SORT_OPTIONS);
        self.selectedSort = ko.observable(QUEUE_SORT_OPTIONS[1]);
        
        const _onSortChange = () => {
            const sortOrder = self.selectedSort().value;
            if (sortOrder !== null) {
                _sorter.multiSort(self.queueList, ["isEnabled", sortOrder], [false, (sortOrder === "name")]);
            }
        };
        
        self.filteredQueueList = ko.computed(() => {
            const searchText = self.queueSearch().trim().toLowerCase();
            let filteredQueueList = [];
            if (searchText) {
                let queueListFilter = new FilterConstructor();
                queueListFilter.addProperty('name');
                queueListFilter.addValue(searchText);
                filteredQueueList = queueListFilter.filter(self.queueList());
            } else {
                filteredQueueList = self.queueList();
            }
            return filteredQueueList;
        });
        
        self.resetQueueList = () => {
            return _promiseFactory.defer((promise) => {
                _getQueues()
                    .fail(promise.reject)
                    .done(promise.resolve);
            });
        };
        
        const _getQueues = () => {
            return _promiseFactory.defer((promise) => {
                _facade.getQueues()
                    .fail(promise.reject)
                    .done((result) => {
                        self.queueList(result);
                        _onSortChange();
                        promise.resolve();
                    });
            });
        };
        
        self.activate = () => {
            _facade = new Facade();
            _facade.init(_promiseFactory);
            
            return _initialize();
        };
        
        const _initialize = () => {
            _getQueues();
            self.selectedSort.subscribe(_onSortChange);
            _onSortChange();
            return _promiseFactory.wait();
        };
    };
});

