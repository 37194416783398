define('presentation/settings/facades/employeeManagementFacade',['common/promises/promiseFactory',
        'persistence/webSocket/webSocketApp',
        'presentation/common/dateTimeValue'],
    function() {
        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = null;
        var _webSocketApp = null;

        var _getEmployeeList = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _webSocketApp.send("get_employee_list", {}, function(employeeList) {
                    var formattedEmployees = _formatEmployees(employeeList);
                    deferredObject.resolve(formattedEmployees);
                });
            });
        };

        var _formatEmployees = function(employeeList) {
            employeeList.forEach(function(employee){
                var modifiedDateTime = new DateTimeValueConstructor(employee.modifiedDateTime);
                employee.modifiedDateTimeDisplayValue = modifiedDateTime.displayValue;
                var createdDateTime = new DateTimeValueConstructor(employee.createdDateTime);
                employee.createdDateTimeDisplayValue = createdDateTime.displayValue;
                employee.emailUrl = "mailto:" + employee.emailAddress;
                employee.editUrl = "/settings/addEmployee/" + employee.employeeId;
            });
            return employeeList;
        };

        var _init = function() {
            _promiseFactory = new PromiseFactoryConstructor();
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("crm_employee_management");
        };

        var _init2 = function(promiseFactory, webSocketApp) {
            _promiseFactory = promiseFactory;
            _webSocketApp = webSocketApp;
        };

        return function() {
            var self = this;
            self.getEmployeeList = _getEmployeeList;
            self.init = _init;
            self.init2 = _init2;
        };
    });

