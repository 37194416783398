define('businessServices/router/plugins/unknownRoutePlugin',[
    'businessServices/authentication/authenticatedEmployee',
    'settings/navigationConfiguration'
], function(authenticatedEmployee, navigationConfiguration) {

    function unknownRouteRedirect() {
        return {routeUrl: navigationConfiguration.errorPageRouteUrl};
    }

    return {
        unknownRouteRedirect: unknownRouteRedirect
    };
});

