define('presentation/account/sidebar/presentationObjects/phoneNumberPresentationObject',[], function () {
    return function () {
        var self = this;

        self.phoneNumber = null;
        self.formattedPhoneNumber = null;
        self.phoneNumberName = null;
        self.phoneNumberNameCss = null;
        self.isPortPending = null;
        self.isPortReadyToComplete = ko.observable(null);
    };
});
