define('presentation/account/sidebar/viewModels/accountSidebarViewModel',[
    'presentation/account/sidebar/facades/accountSidebarFacade',
    'presentation/account/sidebar/facades/vanityAddFacade',
    'presentation/account/sidebar/viewModels/editAccountFlagsModalViewModel',
    'presentation/account/sidebar/viewModels/editAccountStatusModalViewModel',
    'presentation/account/sidebar/viewModels/editPatliveAccountNumberModalViewModel',
    'presentation/account/sidebar/viewModels/vanityAddModalViewModel',
    'constants/accountStatusEnumerations',
    'presentation/common/modal',
    'common/promises/promiseFactory',
    'presentation/common/window/windowControl',
    'presentation/promptDialog/viewModels/promptDialogViewModel',
    'businessServices/events/eventManager',
    'presentation/account/sidebar/validators/retirePhoneNumberValidator'
    ], function() {
    return function (accountNumber) {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _facade = null;
        var _modalService = null;
        var EditAccountFlagsModalConstructor = null;
        var EditAccountStatusModalViewModelConstructor = null;
        var EditPatliveAccountNumberModalConstructor = null;
        var VanityAddModalConstructor = null;
        var VanityAddFacadeConstructor = null;
        var _vanityAddFacade = null;

        var _retireNumberValidator = null;
        var _accountNumber = null;
        var _windowControl = null;
        var _promptDialog = null;
        var _eventManager = null;

        var _accountStatusEnumerations = require('constants/accountStatusEnumerations');

        var _getSidebarData = function() {
            return _promiseFactory.defer(function(promise) {
                _facade.getSidebarData(_accountNumber)
                    .fail(promise.reject)
                    .done(function(result) {
                        self.phoneNumbers(result.phoneNumbers);
                        self.isCancelPortDisabled(result.phoneNumbers.length <= 1);
                        self.phoneNumberCount(result.phoneNumbers.length);
                        self.accountStatus(result.accountStatus);
                        self.messagingStatus(result.messagingStatus);
                        self.tollFreeMessagingStatus(result.tollFreeMessagingStatus);
                        self.billingStatus(result.billingStatus);
                        self.fraudStatus(result.fraudStatus);
                        self.reasonForCancellation(result.reasonForCancellation);
                        self.cancellationComment(result.cancellationComment);
                        self.capturedPhoneNumbers(result.capturedPhoneNumbers);
                        self.capturedPhoneNumbersCount(result.capturedPhoneNumbers.length);
                        self.capturedEmailAddresses(result.capturedEmailAddresses);
                        self.capturedEmailAddressesCount(result.capturedEmailAddresses.length);
                        self.patliveAccountNumber(result.patliveAccountNumber);

                        if (result.accountFlags) {
                            self.activeAccountFlags.removeAll();
                            result.accountFlags.forEach(function(accountFlag) {
                                if (_isActiveAccountFlag(accountFlag)) {
                                    self.activeAccountFlags.push(accountFlag);
                                }
                            });
                        }

                        promise.resolve();
                    });
            });
        };

        var _buildDeleteCapturedPhoneConfirmationModal  = function(phoneNumber) {
            _promptDialog.masterClear()
                .setTitle('Delete Captured Phone Number')
                .addRedButton('Delete Captured Phone Number', 'delete')
                .addButton('Cancel', 'cancel')
                .addLine("Are you sure you want to delete this captured phone number from this account?")
                .addEmptyLine()
                .addCenteredPhoneNumberLine(phoneNumber);
        };

        var _buildDeleteCapturedEmailAddressConfirmationModal  = function(emailAddress) {
            _promptDialog.masterClear()
                .setTitle('Delete Captured Email Address')
                .addRedButton('Delete Captured Email Address', 'delete')
                .addButton('Cancel', 'cancel')
                .addLine("Are you sure you want to delete this captured email address from this account?")
                .addEmptyLine()
                .addCenteredLine(emailAddress);
        };

        var _showConfirmPortConfirmationDialog  = function(phoneNumber) {
            return _promptDialog.masterClear()
                .setTitle('Confirm Port')
                .addPrimaryButton('Confirm', 'confirm')
                .addButton('Cancel', 'cancel')
                .addLine("The following phone number will be ported to this customer's account.")
                .addEmptyLine()
                .addCenteredPhoneNumberLine(phoneNumber)
                .showDialog();
        };

        var _showPortConfirmationErrorDialog  = function(phoneNumber, status) {
            return _promptDialog.masterClear()
                .setTitle('Confirm Port')
                .addPrimaryButton('Ok', 'ok')
                .addLine("The following phone number failed to port to this customer's account.")
                .addEmptyLine()
                .addCenteredPhoneNumberLine(phoneNumber)
                .addEmptyLine()
                .addBoldLine("The return status is: " + status)
                .showDialog();
        };

        var _showCancelPortInProgressDialog  = function(phoneNumber) {
            return _promptDialog.masterClear()
                .setTitle('Cancel Port')
                .addPrimaryButton('Confirm', 'confirm')
                .addButton('Cancel', 'cancel')
                .addLine("The following phone number will be removed from this customer's account.")
                .addEmptyLine()
                .addCenteredPhoneNumberLine(phoneNumber)
                .showDialog();
        };

        var _isActiveAccountFlag = function(accountFlag) {
            return accountFlag.active() === true && accountFlag.isHidden === false;
        };

        self.phoneNumbers = ko.observableArray();
        self.accountStatus = ko.observable();
        self.messagingStatus = ko.observable();
        self.tollFreeMessagingStatus = ko.observable();
        self.billingStatus = ko.observable();
        self.fraudStatus = ko.observable();
        self.reasonForCancellation = ko.observable();
        self.cancellationComment = ko.observable();
        self.phoneNumberCount = ko.observable();
        self.activeAccountFlags = ko.observableArray([]);
        self.capturedPhoneNumbers = ko.observableArray([]);
        self.capturedPhoneNumbersCount = ko.observable();
        self.capturedEmailAddresses = ko.observableArray([]);
        self.capturedEmailAddressesCount = ko.observable();
        self.canEmployeeVanityAdd = ko.observable(false);
        self.canEmployeeRetireNumber = ko.observable(false);
        self.patliveAccountNumber = ko.observable();
        self.isCancelPortDisabled = ko.observable(false);
        self.cancelPortDisabledHoverText = "Tresta accounts must have at least one</br> phone number on the account. If a customer</br> wishes to cancel a port and close their account,</br> please put in a Tresta Port Cancellation ticket</br> with Number Services or update the existing</br>ticket for the port. Afterwards, cancel their </br> account per normal procedures";
        self.retirePhoneNumber = ko.observable("");
        self.retireButtonText = "Submit";
        self.retireNumberResponseMessage = ko.observable("");
        self.retirePhoneNumber.subscribe(function() {
            self.retireNumberResponseMessage("");
        });

        self.showNoActiveFlagsMessage = ko.pureComputed(function () {
            return self.activeAccountFlags().length === 0;
        });

        self.displayFraudStatus = ko.pureComputed(function () {
            return self.fraudStatus() !== _accountStatusEnumerations.fraudStatus.ok;
        });

        self.displayReasonForCancellation = ko.pureComputed(function () {
            return self.accountStatus() === _accountStatusEnumerations.status.closed && self.reasonForCancellation() !== 'None';
        });

        self.accountStatusStyle = ko.pureComputed(function () {
            switch (self.accountStatus()) {
                case undefined:
                    return "";
                case _accountStatusEnumerations.status.active:
                    return "status-success";
                case _accountStatusEnumerations.status.closed:
                    return "status-error";
                default:
                    throw new Error("Invalid account status.");
            }
        });

        self.billingStatusStyle = ko.computed(function () {
            switch (self.billingStatus()) {
                case undefined:
                    return "";
                case _accountStatusEnumerations.billingStatus.ok:
                    return "status-success";
                case _accountStatusEnumerations.billingStatus.ccDeclined:
                    return "status-warning";
                case _accountStatusEnumerations.billingStatus.nonPayment:
                case _accountStatusEnumerations.billingStatus.badDebt:
                case _accountStatusEnumerations.billingStatus.collections:
                    return "status-error";
                default:
                    throw new Error("Invalid billing status.");
            }
        });

        self.fraudStatusStyle = ko.computed(function () {
            switch (self.fraudStatus()) {
                case undefined:
                    return "";
                case _accountStatusEnumerations.fraudStatus.ok:
                    return "status-success";
                case _accountStatusEnumerations.fraudStatus.fraudReview:
                    return "status-warning";
                case _accountStatusEnumerations.fraudStatus.abusiveCustomer:
                case _accountStatusEnumerations.fraudStatus.creditCardFraud:
                case _accountStatusEnumerations.fraudStatus.other:
                    return "status-error";
                default:
                    throw new Error("Invalid fraud status.");
            }
        });

        self.editAccountStatus = function () {
            return _promiseFactory.defer(function (onLoadCompleteDeferredObject) {
                _getSidebarData()
                    .fail(onLoadCompleteDeferredObject.reject)
                    .done(function() {
                        var onModalCloseCompleteDeferredObject = _promiseFactory.deferIndefinitely();
                        var editAccountStatusModal = new EditAccountStatusModalViewModelConstructor(
                            _accountNumber,
                            self.accountStatus(),
                            self.billingStatus(),
                            self.fraudStatus(),
                            self.reasonForCancellation(),
                            self.cancellationComment(),
                            onLoadCompleteDeferredObject,
                            onModalCloseCompleteDeferredObject);

                        onModalCloseCompleteDeferredObject
                            .fail(onLoadCompleteDeferredObject.reject)
                            .done(function () {
                                _getSidebarData();
                            });

                        _modalService.showModal(editAccountStatusModal);
                    });
            });
        };

        self.editAccountFlags = function () {
            return _promiseFactory.defer(function (onLoadCompleteDeferredObject) {
                var onModalCloseCompleteDeferredObject = _promiseFactory.deferIndefinitely();
                var editAccountFlagsModal = new EditAccountFlagsModalConstructor(_accountNumber, onLoadCompleteDeferredObject, onModalCloseCompleteDeferredObject);

                onModalCloseCompleteDeferredObject
                    .fail(onLoadCompleteDeferredObject.reject)
                    .done(function (result) {
                        if (result !== 'cancel') {
                            self.activeAccountFlags.removeAll();
                            result.accountFlags.forEach(function (accountFlag) {
                                if(_isActiveAccountFlag(accountFlag)) {
                                    self.activeAccountFlags.push(accountFlag);
                                }
                            });
                        }
                    });

                _modalService.showModal(editAccountFlagsModal);
            });
        };

        self.editPatliveAccountNumberModal = function () {
            return _promiseFactory.deferIndefinitely(function (onLoadCompletePromise) {
                var onModalCloseCompletePromise = _promiseFactory.deferIndefinitely();
                var editPatliveAccountNumberModal = new EditPatliveAccountNumberModalConstructor(_accountNumber, onLoadCompletePromise, onModalCloseCompletePromise);

                onModalCloseCompletePromise
                    .fail(onLoadCompletePromise.reject)
                    .done(function () {
                        _getSidebarData();
                    });

                _modalService.showModal(editPatliveAccountNumberModal);
            });
        };

        self.openVanityAddModal = function () {
            return _promiseFactory.deferIndefinitely(function (onLoadCompletePromise) {
                var onModalCloseCompletePromise = _promiseFactory.deferIndefinitely();
                var vanityAddModal = new VanityAddModalConstructor(_accountNumber, onLoadCompletePromise, onModalCloseCompletePromise);

                onModalCloseCompletePromise
                    .fail(onLoadCompletePromise.reject)
                    .done(function () {
                        _getSidebarData();
                    });

                _modalService.showModal(vanityAddModal);
            });
        };

        self.cancelPortInProgress = function(phoneNumber) {
            return _promiseFactory.deferIndefinitely(function (promise) {
                _showCancelPortInProgressDialog(phoneNumber.formattedPhoneNumber)
                    .fail(promise.reject)
                    .done(function() {
                        if (_promptDialog.userSelection !== "confirm") {
                            _promptDialog.complete();
                            promise.resolve();

                        } else {
                            _vanityAddFacade.cancelPort(_accountNumber, phoneNumber.phoneNumber)
                                .done(function(result) {
                                    _promptDialog.complete();
                                    _getSidebarData();
                                    promise.resolve();
                                });
                        }
                    });
            });
        };

        self.finishPort = function(phoneNumber) {
            return _promiseFactory.deferIndefinitely(function (promise) {
                _showConfirmPortConfirmationDialog(phoneNumber.formattedPhoneNumber)
                    .fail(promise.reject)
                    .done(function() {
                        if (_promptDialog.userSelection !== "confirm") {
                            _promptDialog.complete();
                            promise.resolve();
                        } else {
                            _vanityAddFacade.finishPort(_accountNumber, phoneNumber.phoneNumber)
                                .done(function(result) {
                                    _promptDialog.complete();
                                    if (result.status && result.status === "success") {
                                        _getSidebarData();
                                        promise.resolve();
                                    } else {
                                        _showPortConfirmationErrorDialog(phoneNumber.phoneNumber, result.status)
                                            .fail(promise.reject)
                                            .done(function() {
                                                _promptDialog.complete();
                                                promise.resolve();
                                            });
                                    }
                                });
                        }
                    });
            });
        };

        self.launchDeleteCapturedPhoneNumberConfirmation = function(capturedPhoneNumber) {
            return _promiseFactory.deferIndefinitely(function (deferredObject) {
                _buildDeleteCapturedPhoneConfirmationModal(capturedPhoneNumber.formattedPhoneNumber);
                _promptDialog.showDialog()
                    .done(function() {
                        if (_promptDialog.userSelection === 'delete') {
                            _facade.deleteCapturedPhoneNumber(_accountNumber, capturedPhoneNumber.id)
                                .done(function() {
                                    _getSidebarData();
                                    _promptDialog.complete();
                                    deferredObject.resolve();
                                })
                                .fail(function(error) {
                                    error.accountNumber = _accountNumber;
                                    error.capturedPhoneNumberId = capturedPhoneNumber.id;
                                    deferredObject.resolve();
                                    throw error;
                                });
                        } else {
                            _promptDialog.complete();
                            deferredObject.resolve();
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.launchDeleteCapturedEmailAddressConfirmation = function(capturedEmailAddress) {
            return _promiseFactory.deferIndefinitely(function (deferredObject) {
                _buildDeleteCapturedEmailAddressConfirmationModal(capturedEmailAddress.capturedEmailAddress);
                _promptDialog.showDialog()
                    .done(function() {
                        if (_promptDialog.userSelection === 'delete') {
                            _facade.deleteCapturedEmailAddress(_accountNumber, capturedEmailAddress.id)
                                .done(function() {
                                    _getSidebarData();
                                    _promptDialog.complete();
                                    deferredObject.resolve();
                                })
                                .fail(function(error) {
                                    error.accountNumber = _accountNumber;
                                    error.capturedEmailAddressId = capturedEmailAddress.id;
                                    deferredObject.resolve();
                                    throw error;
                                });
                        } else {
                            _promptDialog.complete();
                            deferredObject.resolve();
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.retireNumber = function() {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                var phoneNumber = self.retirePhoneNumber();
                self.retireNumberResponseMessage("");
                _retireNumberValidator.validate()
                    .fail(deferredObject.reject)
                    .done(function (isValid) {
                        if (isValid) {
                            _facade.retirePhoneConnector(phoneNumber)
                                .done((result) => {
                                    switch (result.status){
                                        case "success":
                                            self.retireNumberResponseMessage("Success!");
                                            deferredObject.resolve();
                                            break;
                                        case "not_ready":
                                            self.retireNumberResponseMessage("Number is not ready to be retired");
                                            deferredObject.resolve();
                                            break;
                                        default:
                                            self.retireNumberResponseMessage("Oh no, something went wrong!");
                                            deferredObject.resolve();
                                            break;
                                    }
                                })
                                .fail((error) => {
                                    deferredObject.reject(error);
                                });
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };

        self.activate = function () {
            var FacadeConstructor = require('presentation/account/sidebar/facades/accountSidebarFacade');

            _facade = new FacadeConstructor();
            _facade.init();

            _accountNumber = accountNumber;

            var ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            EditAccountFlagsModalConstructor = require('presentation/account/sidebar/viewModels/editAccountFlagsModalViewModel');
            EditAccountStatusModalViewModelConstructor = require('presentation/account/sidebar/viewModels/editAccountStatusModalViewModel');
            EditPatliveAccountNumberModalConstructor = require('presentation/account/sidebar/viewModels/editPatliveAccountNumberModalViewModel');
            VanityAddModalConstructor = require('presentation/account/sidebar/viewModels/vanityAddModalViewModel');

            _windowControl = require("presentation/common/window/windowControl");

            var PromptsDialogViewModelConstructor = require('presentation/promptDialog/viewModels/promptDialogViewModel');
            _promptDialog = new PromptsDialogViewModelConstructor();

            _eventManager = require('businessServices/events/eventManager');

            VanityAddFacadeConstructor = require('presentation/account/sidebar/facades/vanityAddFacade');
            _vanityAddFacade = new VanityAddFacadeConstructor();
            _vanityAddFacade.init();

            var ValidatorConstructor = require('presentation/account/sidebar/validators/retirePhoneNumberValidator');
            _retireNumberValidator = new ValidatorConstructor();

            return _initialize();
        };

        self.activate2 = function (facade, modalService, editAccountFlagsModalConstructor, editAccountStatusModalViewModelConstructor, windowControl, eventManager, EditPatliveAccountNumberModalConstructor, vanityAddModalConstructor, vanityAddFacade) {
            _facade = facade;
            _modalService = modalService;
            EditAccountFlagsModalConstructor = editAccountFlagsModalConstructor;
            EditAccountStatusModalViewModelConstructor = editAccountStatusModalViewModelConstructor;
            EditPatliveAccountNumberModalConstructor = EditPatliveAccountNumberModalConstructor;
            VanityAddModalConstructor = vanityAddModalConstructor;
            _windowControl = windowControl;
            _eventManager = eventManager;
            _vanityAddFacade = vanityAddFacade;

            return _initialize();
        };

        var _initialize = function () {
            _promptDialog.activate();
            _getSidebarData();
            _facade.canEmployeeVanityAdd()
                .done(self.canEmployeeVanityAdd);
            _facade.canEmployeeRetireNumber()
                .done(self.canEmployeeRetireNumber);

            _eventManager.subscribeCapturedPhoneNumberListUpdated(_getSidebarData);
            _retireNumberValidator.registerViewModel(self, _accountNumber);

            return _promiseFactory.wait();
        };
    };
});

