define('presentation/account/sidebar/validators/editAccountStatusModalViewModelValidator',['common/promises/promiseFactory',
        'presentation/common/validation/validationRule',
        'presentation/common/validation/commonValidator',
        'constants/validationMessageEnumerations',
        'constants/accountStatusEnumerations',
        'constants/reasonForCancellationEnumerations'], function () {
    return function () {
        var self = this;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');
        var _accountStatusEnumerations = require('constants/accountStatusEnumerations');
        var _reasonForCancellationEnumerations = require('constants/reasonForCancellationEnumerations');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _commonValidator = null;
        var _facade = null;
        var _editAccountStatusModalViewModel = null;

        self.registerViewModel = function(editAccountStatusModalViewModel, facade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.sidebar.editAccountStatus;
            var validationRules = new ValidationRulesConstructor();

            validationRules.field("accountStatus")
                .addValidation(_validateAccountStatusClosedToActive, validationMessageBase.accountStatusCannotBeChangedToActive);
            validationRules.field("billingStatus")
                .addValidation(_validateBillingStatusActiveAccount, validationMessageBase.billingStatusInvalidForActiveAccount)
                .addValidation(_validateBillingStatusOKToCCDecline, validationMessageBase.billingStatusOKToCCDecline)
                .addValidation(_validateBillingStatusCCDeclineToOK, validationMessageBase.billingStatusCCDeclineToOK);
            validationRules.field("fraudStatus")
                .addValidation(_validateFraudStatusForFraudulentAccount, validationMessageBase.fraudStatusInvalidForFraudulentAccount);

            _editAccountStatusModalViewModel = editAccountStatusModalViewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(editAccountStatusModalViewModel, validationRules);
        };

        self.validate = function () {
            return _commonValidator.validate();
        };

        var _validateAccountStatusClosedToActive = function(accountStatus) {
            return _promiseFactory.defer(function (deferredObject) {
                _facade.getSidebarData(_editAccountStatusModalViewModel.accountNumber)
                    .done(function(result) {
                        var initialAccountStatus = result.accountStatus;
                        if(initialAccountStatus === _accountStatusEnumerations.status.closed && accountStatus === _accountStatusEnumerations.status.active) {
                            deferredObject.resolve(false);
                        } else {
                            deferredObject.resolve(true);
                        }
                    });
            });
        };

        var _validateBillingStatusActiveAccount = function (billingStatus) {
            var accountStatus = _editAccountStatusModalViewModel.accountStatus();
            if(accountStatus === _accountStatusEnumerations.status.active) {
                switch (billingStatus) {
                    case _accountStatusEnumerations.billingStatus.ok:
                    case _accountStatusEnumerations.billingStatus.ccDeclined:
                        return true;
                    case _accountStatusEnumerations.billingStatus.badDebt:
                    case _accountStatusEnumerations.billingStatus.collections:
                    case _accountStatusEnumerations.billingStatus.nonPayment:
                        return false;
                }
            } else {
                return true;
            }
        };

        var _validateBillingStatusOKToCCDecline = function (billingStatus) {
            var initialBillingStatus = _editAccountStatusModalViewModel.initialBillingStatus;
            if(initialBillingStatus === _accountStatusEnumerations.billingStatus.ok && billingStatus === _accountStatusEnumerations.billingStatus.ccDeclined) {
                return false;
            } else {
                return true;
            }
        };

        var _validateBillingStatusCCDeclineToOK = function (billingStatus) {
            var initialBillingStatus = _editAccountStatusModalViewModel.initialBillingStatus;
            if(initialBillingStatus === _accountStatusEnumerations.billingStatus.ccDeclined && billingStatus === _accountStatusEnumerations.billingStatus.ok) {
                return false;
            } else {
                return true;
            }
        };

        var _validateReasonForCancellationActiveAccount = function (reasonForCancellation) {
            var accountStatus = _editAccountStatusModalViewModel.accountStatus();
            if(accountStatus === _accountStatusEnumerations.status.active) {
                return _commonValidator.isEmptyString(reasonForCancellation);
            } else {
                return true;
            }
        };

        var _validateFraudStatusForFraudulentAccount = function (fraudStatus) {
            var reasonForCancellation = _editAccountStatusModalViewModel.reasonForCancellation();
            if(reasonForCancellation === _reasonForCancellationEnumerations.fraudulent) {
                switch(fraudStatus) {
                    case _accountStatusEnumerations.fraudStatus.ok:
                    case _accountStatusEnumerations.fraudStatus.fraudReview:
                        return false;
                    case _accountStatusEnumerations.fraudStatus.abusiveCustomer:
                    case _accountStatusEnumerations.fraudStatus.creditCardFraud:
                    case _accountStatusEnumerations.fraudStatus.other:
                        return true;
                }
            } else {
                return true;
            }
        };
    };
});

