define('presentation/account/activity/notation/viewModels/addNotationViewModel',['common/promises/promiseFactory',
        'presentation/common/modal',
        'presentation/account/activity/notation/presentationObjects/addNotationPresentationObject',
        'presentation/account/activity/notation/facades/addNotationFacade',
        'presentation/account/activity/notation/validators/addNotationViewModelValidator'],
function() {
    return function(accountNumber) {
        var self = this;

        var NotationPresentationObjectConstructor = require('presentation/account/activity/notation/presentationObjects/addNotationPresentationObject');
        var _modalService = null;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _facade = null;
        var _accountNumber = accountNumber;
        var _validator = null;
        var _allNotationEvents = [];

        var _getNotationCategoriesEventsList = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _facade.getNotationCategoriesEventsList(_accountNumber)
                    .done(function(notationCategoriesEventsList) {
                        self.notationCategoriesEventsList(notationCategoriesEventsList);

                        _allNotationEvents = [];
                        notationCategoriesEventsList.forEach(function(notationCategory) {
                            _allNotationEvents = _allNotationEvents.concat(notationCategory.notationEvents);
                        });
                        deferredObject.resolve();
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        var _getNewNotation = function(isRemovable) {
            var notation = new NotationPresentationObjectConstructor();

            notation.notationEventId.subscribe(function(newEventId) {
                var foundEvent = _allNotationEvents.find(function(notationEvent) {
                    return notationEvent.notationEventId === newEventId;
                });
                if (foundEvent === undefined) {
                    return;
                }

                switch (foundEvent.defaultStatus) {
                    case "open":
                        notation.status("open");
                        break;
                    case "closed":
                        notation.status("closed");
                        break;
                }
            });

            notation.startOnIsVisible = ko.computed(function() {
                if (notation.status() === "open") {
                    return true;
                } else {
                    return false;
                }
            });

            notation.dueOnIsVisible = ko.computed(function() {
                if (notation.status() === "open") {
                    return true;
                } else {
                    return false;
                }
            });

            notation.isChanged = ko.computed(function() {
                if (notation.status() === "closed" && notation.comment() === "" && notation.notationEventId() === "") {
                    return false;
                } else {
                    return true;
                }
            });

            var defaultDates = _getDefaultDates();
            notation.startOn(defaultDates.startOn);
            notation.dueOn(defaultDates.dueOn);
            notation.isRemovable = isRemovable;

            return notation;
        };

        var _getDefaultDates = function() {
            var startOnOffsetHours = 24;
            var dueOnOffsetMinutes = 4319;
            var now = new Date();
            var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            var startOn = today.setHours(startOnOffsetHours);
            var dueOn = today.setMinutes(dueOnOffsetMinutes);

            return {
                startOn: new Date(startOn),
                dueOn: new Date(dueOn)
            };
        };

        var _onConfirmCloseModal = function() {
            _modalService.closeModal(self);
        };

        self.notations = ko.observableArray([]);
        self.notationCategoriesEventsList = ko.observableArray([]);

        self.closeModal = function() {
            var changedNotationsExist = false;
            var notations = self.notations();
            notations.forEach(function(notation) {
                if (notation.isChanged() === true) {
                    changedNotationsExist = true;
                }
            });

            if (changedNotationsExist === true) {
                _modalService.confirmMessage(self, "Are you sure you want to close? All changes will be lost.", "Close", "Cancel", _onConfirmCloseModal);
            } else {
                _modalService.closeModal(self);
            }
        };

        self.saveNotations = function() {
            return _promiseFactory.deferIndefinitely(function (deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid === false) {
                            deferredObject.resolve();
                        } else {
                            _facade.saveNotations(_accountNumber, self.notations())
                                .done(function() {
                                    deferredObject.resolve();
                                    _modalService.closeModal(self);
                                })
                                .fail(function(error) {
                                    deferredObject.reject(error);
                                });
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.addAnotherNotation = function() {
            self.notations.push(_getNewNotation(true));
        };

        self.removeNotation = function(notation) {
            if (notation.isChanged() === true) {
                _modalService.confirmMessage(self,
                    "Are you sure you want to remove this notation? Unsaved changes will be lost.",
                    "Remove",
                    "Cancel",
                    function() {
                        self.notations.remove(notation);
                    });
            } else {
                self.notations.remove(notation);
            }
        };

        self.activate = function() {
            var ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            var Facade = require('presentation/account/activity/notation/facades/addNotationFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            var ValidatorConstructor = require('presentation/account/activity/notation/validators/addNotationViewModelValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        self.activate2 = function(modalService, facade, validator) {
            _modalService = modalService;
            _facade = facade;
            _validator = validator;

            return _initialize();
        };

        var _initialize = function() {
            _getNotationCategoriesEventsList();
            self.notations([_getNewNotation(false)]);

            _promiseFactory.wait()
                .done(function() {
                    _validator.registerViewModel(self, _facade);
                })
                .fail(function(error) {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

