define('presentation/settings/validators/potentialCustomerValidator',['common/promises/promiseFactory',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validationRule',
    'constants/validationMessageEnumerations'], function() {
    return function() {
        var self = this;

        var _potentialCustomerViewModel = null;
        var _potentialCustomerFacade = null;
        var _commonValidator = null;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        self.registerViewModel = function(potentialCustomerViewModel, potentialCustomerFacade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.settings.potentialCustomer;
            var validationRules = new ValidationRulesConstructor();
            validationRules.field("accountNumber")
                .addValidation(_validateStringRequired, validationMessageBase.accountNumberRequired)
                .addValidation(_validateAccountNumber, validationMessageBase.accountNumberInvalid);

            _commonValidator = new CommonValidatorConstructor(potentialCustomerViewModel, validationRules);
            _potentialCustomerViewModel = potentialCustomerViewModel;
            _potentialCustomerFacade = potentialCustomerFacade;
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateStringRequired = function(value) {
            return _commonValidator.isStringWithValue(value);
        };

        var _validateAccountNumber= function(accountNumber) {
            return _promiseFactory.defer(function(deferredObject) {
                _potentialCustomerFacade.isAccountNumberValid(accountNumber.trim())
                    .done(function(result) {
                        deferredObject.resolve(result.isValid);
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };
    };
});

