define('presentation/settings/viewModels/notationCategoryManagementViewModel',['common/promises/promiseFactory', 
    'presentation/settings/facades/notationCategoryManagementFacade', 
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'plugins/router'], function() {
    return function(){
        var self = this;
        var _facade = null;
        var _router = null;
        var FilterConstructor = require('common/collections/collectionFilter');
        var SorterConstructor = require('common/collections/collectionSorter');
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _availableNotationCategories = null;
       
        var _notationCategoryFilterOnChange = function() {
            _updateDisplayedNotationCategories();
        };
        
        var NOTATION_CATEGORY_SORT_OPTIONS = [
            { "value": "description",       "displayName":"Notation Category",  "isAscending" : true},
            { "value": "createdDateTime",   "displayName":"Created Date",       "isAscending" : false},
            { "value": "modifiedDateTime",  "displayName":"Modified Date",      "isAscending" : false}
        ];


        self.sortOptions = ko.observableArray(NOTATION_CATEGORY_SORT_OPTIONS);
        self.selectedSort = ko.observable(NOTATION_CATEGORY_SORT_OPTIONS[0]);
        self.displayNotationCategories = ko.observableArray();
        self.notationCategoryFilter = ko.observable('');
        self.notationCategoryFilter.subscribe(_notationCategoryFilterOnChange);
        self.isActive = ko.observable(false);
        self.addNotationCategory = function () {
            _router.navigate("/settings/addNotationCategory");
        };
        
       
        var _updateDisplayedNotationCategories = function() {
            var filteredNotationCategories;
            var filterContent = self.notationCategoryFilter().trim().toLowerCase();
           
            if (filterContent) {
                var notationCategoryFilter = new FilterConstructor();
                 
                notationCategoryFilter.addProperty('description');
                notationCategoryFilter.addProperty('isActive');
                notationCategoryFilter.addProperty('modifiedDateTime.filterValue');
                notationCategoryFilter.addProperty('createdDateTime.filterValue');
                notationCategoryFilter.addValue(filterContent);
                filteredNotationCategories = notationCategoryFilter.filter(_availableNotationCategories);
            }
            else {
                filteredNotationCategories = _availableNotationCategories;
            }

            self.displayNotationCategories.removeAll();

            for (var x = 0; x < filteredNotationCategories.length; x++) {
                 
                var notationCategory = filteredNotationCategories[x];
               
                self.displayNotationCategories.push(notationCategory);
                
            }
             
            _sortDisplayedNotationCategories();
        };

        

        var _sortDisplayedNotationCategories = function() {
            var selectedSort = self.selectedSort();
            var sortField = selectedSort.value;
            var isAscending = selectedSort.isAscending;

            var _sorter = new SorterConstructor();
            _sorter.multiSort(self.displayNotationCategories, ["isActive", sortField], [false, isAscending]);
        };

        var _refreshNotationCategories = function() {
            _facade.getAllNotationCategories()
                .done(function(notationCategories) {
                    
                    _availableNotationCategories = notationCategories;
                    
                    _updateDisplayedNotationCategories();
                    if(notationCategories.length > 0) {
                        
                        self.showWelcomeState(false);
                    } else {
                         
                        self.showWelcomeState(true);
                    }
                });
        };
        self.showEmptyState = ko.computed(function() {
            return self.displayNotationCategories().length <= 0;
        });
        self.showWelcomeState = ko.observable(false);



        self.activate = function() {
            var FacadeConstructor = require('presentation/settings/facades/notationCategoryManagementFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);
            _router = require('plugins/router');
            return _initialize();
        };

        self.activate2 = function(facade, router) {
            _facade = facade;
            _router = router;
            return _initialize();
        };

        var _initialize = function() {
            
            _refreshNotationCategories();
            self.selectedSort.subscribe(_sortDisplayedNotationCategories);
            return _promiseFactory.wait();
        };
    };
});

