define('presentation/developer/viewModels/developerSidebarViewModel',['common/promises/promiseFactory', 'presentation/developer/facades/developerSidebarFacade', 'common/url/urlFormatter'], function() {
    var SidebarItemPresentationObject = function() {
        var self = this;

        self.selection = null;
        self.title = ko.observable();
        self.isSelected = ko.observable(false);
        self.url = ko.observable();
        self.viewModelPath = null;
        self.viewModel = null;
    };

    return function(){
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _facade = null;
        var _urlFormatter = null;

        self.sidebarConfig = null;

        self.sidebarItems = ko.observableArray([]);
        self.contentPane = ko.observable();

        self.activate = function() {
            var FacadeConstructor = require('presentation/developer/facades/developerSidebarFacade');
            _facade = new FacadeConstructor();
            _facade.init(_promiseFactory);

            _urlFormatter = require('common/url/urlFormatter');

            return _initialize();
        };

        self.toolSelectionChanged = function(toolsSelection) {
            var sidebarItems = self.sidebarItems();
            sidebarItems.forEach(function(sidebarItem) {
                if (sidebarItem.selection === toolsSelection) {
                    sidebarItem.isSelected(true);
                } else {
                    if (sidebarItem.childRoutes && sidebarItem.childRoutes.indexOf(toolsSelection) !== -1) {
                        sidebarItem.isSelected(true);
                    } else {
                        sidebarItem.isSelected(false);
                    }
                }
            });
        };

        var loadSidebarItems = function(remainingSidebarItems, validSidebarItems,  allCompletedPromise) {
            try {
                if (remainingSidebarItems.length === 0) {
                    allCompletedPromise.resolve(validSidebarItems);
                    return;
                }

                var sidebarItem = remainingSidebarItems[0];
                var nextSidebarItems = remainingSidebarItems.slice(1);

                _facade.hasPermission(sidebarItem.permissionId)
                    .done(function(result) {
                        if ((result === true) && sidebarItem.showInSidebar) {
                            validSidebarItems.push(sidebarItem);
                        }

                        loadSidebarItems(nextSidebarItems, validSidebarItems, allCompletedPromise);
                    })
                    .fail(function(error) {
                        allCompletedPromise.reject(error);
                    });

            } catch (ex) {
                allCompletedPromise.reject(ex);
            }
        };

        var _initialize = function() {
            var allCompleted = _promiseFactory.defer();
            if (self.sidebarConfig === null) {
                allCompleted.reject(new Error("No sidebar configuration specified"));
            }

            _promiseFactory.defer(function(promise) {
                loadSidebarItems(self.sidebarConfig, [], allCompleted);

                allCompleted.done(function(validSidebarItems) {
                    var sidebarItems = validSidebarItems.map(function(validSidebarItem) {
                        var url = _urlFormatter.buildWorkSessionUrl(validSidebarItem.urlParts);

                        var sidebarItem = new SidebarItemPresentationObject();
                        sidebarItem.selection = validSidebarItem.selection;
                        sidebarItem.title = validSidebarItem.title;
                        sidebarItem.iconClass = validSidebarItem.iconClass;
                        sidebarItem.childRoutes = validSidebarItem.childRoutes;
                        sidebarItem.isSelected(false);
                        sidebarItem.url = url;
                        sidebarItem.viewModelPath = validSidebarItem.viewModelPath;
                        sidebarItem.viewModel = null;

                        return sidebarItem;
                    });

                    self.sidebarItems(sidebarItems);
                    promise.resolve();
                })
                    .fail(function(error) {
                        promise.reject(error);
                    });
            });

            return _promiseFactory.wait();
        };
    };
});

