define('presentation/account/sidebar/viewModels/editPatliveAccountNumberModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/account/sidebar/validators/editPatliveAccountNumberModalValidator',
    'presentation/account/sidebar/facades/accountSidebarFacade'

], function () {
    return function (accountNumber, onLoadCompletePromise, onModalCloseCompletePromise) {
        var self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        var _modalService = null;
        var _onLoadCompletePromise = null;
        var _onModalCloseCompletePromise = null;
        var _facade = null;
        var _validator = null;

        var _accountNumber = accountNumber;

        self.accountNumber = accountNumber;
        self.modalHeaderText = "PATLive Account Number";
        self.patliveAccountNumber = ko.observable(null);
        self.saveButtonText = "Save";

        self.closeModal = function () {
            _onModalCloseCompletePromise.resolve('cancel');
            _modalService.closeModal(self);
        };

        self.saveButton = function() {
            _validator.validate()
                .fail(_onModalCloseCompletePromise.reject)
                .done(function(isValid) {
                    if (isValid) {
                        _facade.savePatliveAccountNumber(_accountNumber, self.patliveAccountNumber())
                            .fail(_onModalCloseCompletePromise.reject)
                            .done(function (result) {
                                _modalService.closeModal(self);
                                _onModalCloseCompletePromise.resolve(result);
                            });
                    }
                });
        };

        self.activate = function () {
            var ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            var FacadeConstructor = require('presentation/account/sidebar/facades/accountSidebarFacade');
            _facade = new FacadeConstructor();

            var ValidatorConstructor = require('presentation/account/sidebar/validators/editPatliveAccountNumberModalValidator');
            _validator = new ValidatorConstructor();

            _onLoadCompletePromise = onLoadCompletePromise;
            _onModalCloseCompletePromise = onModalCloseCompletePromise;

            return _initialize();
        };

        self.activate2 = function (modalService, facade, onLoadCompletePromise, onModalCloseCompletePromise, validator) {
            _modalService = modalService;
            _facade = facade;
            _onLoadCompletePromise = onLoadCompletePromise;
            _onModalCloseCompletePromise = onModalCloseCompletePromise;
            _validator = validator;

            return _initialize();
        };

        var _initialize = function () {
            _validator.registerViewModel(self, _facade);
            _promiseFactory.deferIndefinitely(deferredObject => {
                _facade.getPatliveAccountNumber(_accountNumber)
                    .done(function(result) {
                        self.patliveAccountNumber(result);
                        _onLoadCompletePromise.resolve();
                        deferredObject.resolve();
                    })
                    .fail(function(error) {
                        _onLoadCompletePromise.reject(error);
                        deferredObject.reject();
                    });
            });
            return _promiseFactory.wait();
        };
    };
});
