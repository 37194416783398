define('presentation/developer/facades/sqsQueueDetailsFacade',['common/promises/promiseFactory',
        'externalDependencies/clientApi'],
    function() {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        
        let _promiseFactory = null;
        let _clientApi = null;
        
        const _getQueueMetaData = (queueUrl) => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                const data = {
                    queueUrl: queueUrl
                };
                
                _clientApi.call("crm/sqs/getQueue", "POST", data, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        let sqsQueue = {
                            name: result.data.name,
                            queue_arn: result.data.queueArn,
                            queue_url: result.data.queueUrl,
                            messages: result.data.messages,
                            messages_in_flight:  result.data.messagesInFlight,
                            is_dead_letter_queue: result.data.isDeadLetterQueue,
                        };
                        deferredObject.resolve(sqsQueue);
                    });
            });
        };
        
        const _reprocessMessages = (queueUrl) => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                const data = {
                    queueUrl: queueUrl
                };
    
                _clientApi.call("crm/sqs/reprocessDeadLetterMessages", "POST", data, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        deferredObject.resolve();
                    });
            });
        };
        
        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
    
            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();
        };
        
        return function() {
            const self = this;
            
            self.getQueueMetaData = _getQueueMetaData;
            self.reprocessMessages = _reprocessMessages;
            self.init = _init;
        };
    });

