define('presentation/settings/facades/notationCategoryManagementFacade',['persistence/webSocket/webSocketApp',
    'common/encryption/crypto',
    'presentation/common/dateTimeValue'], function() {

    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        var _webSocketApp = null;

        self.getAllNotationCategories = function() {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {};
                
                _webSocketApp.send("get_notation_categories", webSocketParams, function(notationCategories) {
                    var formattedNotationCategories = _formatNotationCategories(notationCategories);
                    deferredObject.resolve(formattedNotationCategories);
                });
            });
        };
        var _formatNotationCategories = function(notationCategoriesList) {
            var notationCategoriesListFormatted = notationCategoriesList.map(function(category){
                var modifiedDateTime = new DateTimeValueConstructor(category.modifiedDateTime);
                category.modifiedDateTimeDisplayValue = modifiedDateTime.displayValue;
                var createdDateTime = new DateTimeValueConstructor(category.createdDateTime);
                category.createdDateTimeDisplayValue = createdDateTime.displayValue;
                category.editNotationCategoryURL = "/settings/addNotationCategory/" + category.notationCategoryId;
                if(category.isActive === true){
                   category.status = "ACTIVE";
                } else {
                    category.status = "INACTIVE";
                    category.isActive = false;
                }
                return category;
            });
            return notationCategoriesListFormatted;
        };
        self.init = function() {
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("notation_categories");
        };

        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});

