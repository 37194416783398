define('externalDependencies/configuration/clientApiConfiguration',['settings/settings'], function() {
    var ClientApiConfigurationSingleton = function() {
        if (ClientApiConfigurationSingleton.prototype._singletonInstance) {
            return ClientApiConfigurationSingleton.prototype._singletonInstance;
        }
        ClientApiConfigurationSingleton.prototype._singletonInstance = this;

        var settings = require('settings/settings');
        this.apiUrl = settings.externalDependencies.clientApiServerUrl;
    };

    return new ClientApiConfigurationSingleton();
});
