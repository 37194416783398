define('presentation/account/validators/resetVerificationCodeValidator',[
    'constants/validationMessageEnumerations',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validPhoneNumberSpecification'
], function() {
    return function() {
        const self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        let _commonValidator = null;
        let _viewModel = null;

        const ValidPhoneNumberSpecificationConstructor = require('presentation/common/validation/validPhoneNumberSpecification');
        const _validPhoneNumberSpecification = new ValidPhoneNumberSpecificationConstructor();
        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        self.registerViewModel = function(viewModel) {
            const validationMessageBase = _validationMessageEnumerations.webcrm.presentation.sidebar.retireNumber;
            const validationRules = new ValidationRulesConstructor();

            validationRules.field("phoneNumber")
                .addValidation(_isPhoneNumberAStringWithValue, validationMessageBase.phoneNumberRequired)
                .addValidation(_isValidPhoneNumber, validationMessageBase.phoneNumberInvalid);

            _viewModel = viewModel;
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = () => _commonValidator.validate();
        const _isPhoneNumberAStringWithValue = (phoneNumber) => _commonValidator.isStringWithValue(phoneNumber);
        const _isValidPhoneNumber = (phoneNumber) => _validPhoneNumberSpecification.isSatisfiedBy(_phoneNumberFormatter.toNumbers(phoneNumber), "us");
    };
});

