define('presentation/account/viewModels/usersViewModel',[
    'presentation/common/window/windowControl',
    'common/promises/promiseFactory',
    'presentation/account/facades/usersFacade',
    'common/url/urlFormatter',
    'presentation/account/viewModels/resendInviteModalViewModel',
    'presentation/account/viewModels/resetPasswordModalViewModel',
    'presentation/account/viewModels/resetVerificationCodeModalViewModel',
    'presentation/account/activity/email/viewModels/emailViewModel',
    'presentation/common/modal',
    'common/collections/collectionSorter'
], function() {
        return function() {
            let self = this;
            let _accountNumber = null;
            let _facade = null;

            const USERS_DEFAULT_SORT_ORDER = "firstName";
            const USERS_SORT_OPTIONS = [
                { "value": "firstName", "displayName":"First Name" },
                { "value": "lastName",  "displayName":"Last Name" },
            ];

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const ResetPasswordModalViewModelConstructor = require('presentation/account/viewModels/resetPasswordModalViewModel');
            const ResendInviteModalViewModelConstructor = require('presentation/account/viewModels/resendInviteModalViewModel');
            const ModalServiceConstructor = require('presentation/common/modal');
            const SorterConstructor = require('common/collections/collectionSorter');
            const _sorter = new SorterConstructor();

            const _promiseFactory = new PromiseFactoryConstructor();
            const _modalService = new ModalServiceConstructor();
            let _emailViewModel = null;
            let _windowControl = null;
            let _urlFormatter = null;

            self.accountNumber = ko.observable();
            self.users = ko.observableArray([]);
            self.sortOptions = ko.observableArray(USERS_SORT_OPTIONS);
            self.selectedSort = ko.observable(USERS_SORT_OPTIONS[0]);

            const _onSortChange = () => {
                let sortOrder = self.selectedSort().value;
                if (sortOrder !== null) {
                    _sorter.multiSort(self.employeeList, ["isActiveStatus", sortOrder], [false, true]);
                }
            };

            self.loginAsUser = (user) => {
                return _promiseFactory.defer(function(promise) {
                    let accountNumber = self.accountNumber();
                    let userId = user.userId;

                    _facade.loginAsUser(accountNumber, userId)
                        .done((result) => {
                            if (result.success === true) {
                                promise.resolve(result.url);
                            } else {
                                let err = new Error("Failed to login as user");
                                err.result = result;
                                promise.reject(err);
                            }
                        })
                        .fail((error) => {
                            promise.reject(error);
                        });
                });
            };

            self.sendResetPassword = (user) => {
                return _promiseFactory.defer((onLoadCompleteDeferredObject) => {
                    let onModalCloseCompleteDeferredObject = _promiseFactory.deferIndefinitely();
                    let resetPasswordModal = new ResetPasswordModalViewModelConstructor(
                        user.emailAddress,
                        self.accountNumber(),
                        user.userId,
                        onLoadCompleteDeferredObject,
                        onModalCloseCompleteDeferredObject);

                    onModalCloseCompleteDeferredObject
                        .fail((error) => {
                            throw new Error(error);
                        });

                    _modalService.showModal(resetPasswordModal);
                });
            };

            self.resetVerificationCode = (user) => {
                return _promiseFactory.defer((promise) => {
                    let modalClosePromise = _promiseFactory.deferIndefinitely();
                    const ResetVerificationCodeModalViewModelConstructor = require('presentation/account/viewModels/resetVerificationCodeModalViewModel');
                    const modal = new ResetVerificationCodeModalViewModelConstructor(
                        self.accountNumber(),
                        user.userId,
                        promise,
                        modalClosePromise);

                    modalClosePromise
                        .fail((error) => {
                            throw new Error(error);
                        });

                    _modalService.showModal(modal);
                });
            };

            self.resendInvite = (user) => {
                return _promiseFactory.defer((promise) =>{
                    let modalClosePromise = _promiseFactory.deferIndefinitely();
                    let resendInviteModal = new ResendInviteModalViewModelConstructor(
                        user.emailAddress,
                        promise,
                        modalClosePromise);

                    modalClosePromise
                        .fail((error) => {
                            throw new Error(error);
                        });

                    _modalService.showModal(resendInviteModal);
                });
            };

            self.sendEmail = (contactInfo) => {
                if (_emailViewModel.isOpen === true) {
                    _modalService.toggleMinimizeModal(_emailViewModel);
                } else {
                    _emailViewModel.setInitialSendToEmailAddress(contactInfo.emailAddress);
                    _modalService.showModal(_emailViewModel);
                }

            };

            self.activate = function(accountNumber, queryStringArguments, isCalledFromSidebar) {
                if (isCalledFromSidebar !== true) {
                    return;
                }
                _accountNumber = accountNumber;

                _facade = require('presentation/account/facades/usersFacade');
                _windowControl = require('presentation/common/window/windowControl');
                _urlFormatter = require('common/url/urlFormatter');

                _emailViewModel = require('presentation/account/activity/email/viewModels/emailViewModel');
                _emailViewModel.setAccountNumber(_accountNumber);

                return _initialize();
            };

            const _initialize = function() {
                _facade.init();
                self.accountNumber(_accountNumber);
                _facade.getUsers(_accountNumber)
                    .done((users) => {
                        _sorter.multiSort(users, ["isActiveStatus", USERS_DEFAULT_SORT_ORDER], [false, true]);
                        self.selectedSort.subscribe(_onSortChange);
                        self.users(users);
                    })
                    .fail((error) => {
                        throw error;
                    });

                return _promiseFactory.wait();
            };
        };
    });

