define('presentation/developer/viewModels/smsVerificationViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/smsVerificationFacade',
], function() {
    return function() {
        let self = this;
        
        const FacadeConstructor = require('presentation/developer/facades/smsVerificationFacade');
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        
        const _facade = new FacadeConstructor();
        const _promiseFactory = new PromiseFactoryConstructor();
    
        self.smsVerificationNumber = ko.observable('');
        self.message = ko.observable('');
    
        const _getSmsVerificationNumber = () => {
            return _promiseFactory.deferIndefinitely(promise => {
                _facade.getSmsVerificationNumber()
                    .fail(promise.reject)
                    .done(result => {
                        self.smsVerificationNumber(result.data.smsVerificationNumber);
                        self.message(result.data.message);
                        promise.resolve();
                    });
            });
        };
    
        self.setSmsVerificationNumber = () => {
            return _promiseFactory.deferIndefinitely(promise => {
                _facade.setSmsVerificationNumber(self.smsVerificationNumber())
                    .fail(promise.reject)
                    .done(result => {
                        self.message(result.data.message);
                        promise.resolve();
                    });
            });
        };
        
        self.activate = () => {
            _facade.init();
            return _initialize();
        };
        
        const _initialize = () => {
            _getSmsVerificationNumber();
            return _promiseFactory.wait();
        };
    };
});

