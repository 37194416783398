define('presentation/account/sidebar/facades/accountSidebarFacade',[
    'businessServices/accountFlags/accountFlagObjectBuilder',
    'businessServices/authentication/employeeAuthorization',
    'common/authentication/employeeAuthenticationStore',
    'common/collections/collectionSorter',
    'common/converters/phoneNumberFormatter',
    'common/promises/promiseFactory',
    'common/time/date',
    'externalDependencies/clientApi',
    'persistence/webSocket/webSocketApp',
    'presentation/account/sidebar/presentationObjects/capturedEmailAddressPresentationObject',
    'presentation/account/sidebar/presentationObjects/capturedPhoneNumberPresentationObject',
    'presentation/account/sidebar/presentationObjects/paymentMethodPresentationObject',
    'presentation/account/sidebar/presentationObjects/phoneNumberPresentationObject',
    'presentation/account/sidebar/presentationObjects/userWithPendingTicketsPresentationObject'], function () {

    const AccountFlagObjectBuilderConstructor = require('businessServices/accountFlags/accountFlagObjectBuilder');
    const CapturedEmailAddressPresentationObjectConstructor = require('presentation/account/sidebar/presentationObjects/capturedEmailAddressPresentationObject');
    const CapturedPhoneNumberPresentationObjectConstructor = require('presentation/account/sidebar/presentationObjects/capturedPhoneNumberPresentationObject');
    const DateConstructor = require('common/time/date');
    const PaymentMethodPresentationObjectConstructor = require("presentation/account/sidebar/presentationObjects/paymentMethodPresentationObject");
    const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
    const PhoneNumberPresentationObjectConstructor = require('presentation/account/sidebar/presentationObjects/phoneNumberPresentationObject');
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    const SorterConstructor = require('common/collections/collectionSorter');

    let _accountFlagObjectBuilder = new AccountFlagObjectBuilderConstructor();
    let _date = new DateConstructor();
    let _phoneNumberFormatter = new PhoneNumberFormatterConstructor();
    let _promiseFactory = new PromiseFactoryConstructor();
    let _sorter = new SorterConstructor();

    let _clientApi = null;
    let _employeeAuthorization = null;
    let _employeeId = null;
    let _webSocketApp = null;

    const SMS_NO_MESSAGING = 1;
    const SMS_LIMITED_LOCAL_MESSAGING = 2;
    const SMS_STANDARD_LOCAL_MESSAGING = 3;

    const SMS_NO_MESSAGING_COMPLIANCE_INCOMPLETE = 4;
    const SMS_NO_MESSAGING_IN_FREE_TRIAL = 5;
    const SMS_NO_MESSAGING_BRAND_REGISTRATION_FAILED = 6;
    const SMS_NO_MESSAGING_BRAND_REGISTRATION_PENDING = 7;
    const SMS_NO_MESSAGING_BRAND_REGISTRATION_PENDING_OTP_VERIFICATION = 8;
    const BRAND_APPROVED_SOLE_PROPRIETOR_SMS_NOT_ENABLED = 9;
    const BRAND_APPROVED_LOW_VOLUME_MIXED_SMS_NOT_ENABLED = 10;
    const CAMPAIGN_REGISTRATION_FAILED = 11;
    const CAMPAIGN_REGISTRATION_PENDING_SOLE_PROPRIETOR = 12;
    const CAMPAIGN_REGISTRATION_PENDING_LOW_VOLUME_MIXED = 13;
    const CAMPAIGN_REGISTRATION_APPROVED_SOLE_PROPRIETOR = 14;
    const CAMPAIGN_REGISTRATION_APPROVED_LOW_VOLUME_MIXED = 15;
    const CAMPAIGN_REGISTRATION_PENDING_DCA_APPROVAL_SOLE_PROPRIETOR = 16;
    const CAMPAIGN_REGISTRATION_PENDING_DCA_APPROVAL_LOW_VOLUME_MIXED = 17;
    const CAMPAIGN_REGISTRATION_FAILED_DCA_APPROVAL_SOLE_PROPRIETOR = 18;
    const CAMPAIGN_REGISTRATION_FAILED_DCA_APPROVAL_LOW_VOLUME_MIXED = 19;

    const TOLL_FREE_SMS_NO_MESSAGING = 1;
    const TOLL_FREE_LIMITED_MESSAGING = 2;
    const TOLL_FREE_SMS_NO_MESSAGING_COMPLIANCE_INCOMPLETE = 3;
    const TOLL_FREE_SMS_NO_MESSAGING_IN_FREE_TRIAL = 4;
    const TOLL_FREE_SMS_NOT_ENABLED = 5;
    const TOLL_FREE_SMS_PENDING_VERIFICATION = 6;
    const TOLL_FREE_SMS_SUBMITTED_FOR_VERIFICATION = 7;
    const TOLL_FREE_SMS_VERIFICATION_APPROVED = 8;
    const TOLL_FREE_SMS_VERIFICATION_REJECTED = 9;
    const TOLL_FREE_SMS_VERIFICATION_NOT_FOUND = 10;

    const _canEmployeeVanityAdd = () => {
        return _employeeAuthorization.hasPermission("CRM.VanityAdd");
    };

    const _canEmployeeRetireNumber = () => {
        return _employeeAuthorization.hasPermission("CRM.RetireNumber");
    };

    const _getVanityAddStatus = (phoneNumber, isPortReadyToCompleteObservable) => {
        return _promiseFactory.defer((promise) => {
            _canEmployeeVanityAdd()
                .fail(promise.reject)
                .done((hasPermission) => {
                    if (hasPermission) {
                        var webSocketParams = {
                            "phone_number": _phoneNumberFormatter.toEOneSixFour(phoneNumber),
                            "employee_id": _employeeId
                        };
                        _webSocketApp.send("vanity_add_phone_number_status", webSocketParams, function(result) {
                            isPortReadyToCompleteObservable(result.status === "success");
                        });
                    }
                    promise.resolve();
                });
        });
    };

    var _getSidebarData = function (accountNumber) {
        return _promiseFactory.defer(function (deferredObject) {
            var webSocketParams = {
                "account_number": accountNumber,
                "employee_id": _employeeId
            };

            _webSocketApp.send("get_sidebar_data", webSocketParams, function (result) {
                _clientApi.call("account/getSmsStatus", "POST", {accountNumber: accountNumber, employeeId: _employeeId}, 'application/json')
                    .done((response) => {
                        var returnObject = result;
                        var phoneNumbers = result.phoneNumbers;
                        var phoneNumberPresentationObjects = phoneNumbers.map(function (phoneNumber) {
                            var phoneNumberPresentationObject = new PhoneNumberPresentationObjectConstructor();
                            phoneNumberPresentationObject.phoneNumber = phoneNumber.phoneNumber;
                            phoneNumberPresentationObject.formattedPhoneNumber = _phoneNumberFormatter.toInternational(phoneNumber.phoneNumber);
                            if (phoneNumber.isPortPending) {
                                phoneNumberPresentationObject.isPortPending = true;
                                _getVanityAddStatus(phoneNumberPresentationObject.phoneNumber, phoneNumberPresentationObject.isPortReadyToComplete);
                            } else if (phoneNumberPresentationObject.formattedPhoneNumber === phoneNumber.phoneNumberName) {
                                phoneNumberPresentationObject.phoneNumberName = "";
                                phoneNumberPresentationObject.phoneNumberNameCss = "";
                            } else {
                                phoneNumberPresentationObject.phoneNumberName = phoneNumber.phoneNumberName;
                                phoneNumberPresentationObject.phoneNumberNameCss = "nowrap";
                            }
                            return phoneNumberPresentationObject;
                        });
                        var sortedPhoneNumberPresentationObjects = phoneNumberPresentationObjects.sort(function (a, b) {
                            if (a.phoneNumberName > b.phoneNumberName) {
                                return 1;
                            } else if (a.phoneNumberName < b.phoneNumberName) {
                                return -1;
                            } else if (a.phoneNumber > b.phoneNumber) {
                                return 1;
                            } else {
                                return -1;
                            }
                        });
                        returnObject.phoneNumbers = sortedPhoneNumberPresentationObjects;

                        var capturedPhoneNumbers = result.capturedPhoneNumbers;
                        var capturedPhoneNumberPresentationObjects = capturedPhoneNumbers.map(function(capturedPhoneNumber) {
                            var capturedPhoneNumberPresentationObject = new CapturedPhoneNumberPresentationObjectConstructor();
                            capturedPhoneNumberPresentationObject.id = capturedPhoneNumber.capturedPhoneNumberId;
                            capturedPhoneNumberPresentationObject.phoneNumber = capturedPhoneNumber.capturedPhoneNumber;
                            capturedPhoneNumberPresentationObject.formattedPhoneNumber = _phoneNumberFormatter.toInternational(capturedPhoneNumber.capturedPhoneNumber);

                            return capturedPhoneNumberPresentationObject;
                        });

                        var capturedEmailAddresses = result.capturedEmailAddresses;
                        var capturedEmailAddressPresentationObjects = capturedEmailAddresses.map(function(capturedEmailAddress) {
                            var capturedEmailAddressPresentationObject = new CapturedEmailAddressPresentationObjectConstructor();
                            capturedEmailAddressPresentationObject.id = capturedEmailAddress.capturedEmailAddressId;
                            capturedEmailAddressPresentationObject.capturedEmailAddress = capturedEmailAddress.capturedEmailAddress;

                            return capturedEmailAddressPresentationObject;
                        });

                        _sorter.sort(capturedPhoneNumberPresentationObjects, 'phoneNumber');
                        returnObject.capturedPhoneNumbers = capturedPhoneNumberPresentationObjects;

                        _sorter.sort(capturedEmailAddressPresentationObjects, 'capturedEmailAddress');
                        returnObject.capturedEmailAddresses = capturedEmailAddressPresentationObjects;

                        var accountFlagObject = result.accountFlags;
                        returnObject.accountFlags = _convertAccountFlagObjectToArray(accountFlagObject);

                        returnObject.patliveAccountNumber = result.patliveAccountNumber;

                        switch(response.data.tollFreeMessagingStatus) {
                            case TOLL_FREE_SMS_NO_MESSAGING:
                                returnObject.tollFreeMessagingStatus = "No Messaging";
                                break;
                            case TOLL_FREE_SMS_NO_MESSAGING_COMPLIANCE_INCOMPLETE:
                                returnObject.tollFreeMessagingStatus = "Not Eligible - Messaging Compliance Incomplete";
                                break;
                            case TOLL_FREE_SMS_NO_MESSAGING_IN_FREE_TRIAL:
                                returnObject.tollFreeMessagingStatus = "Not Eligible - In Free Trial";
                                break;
                            case TOLL_FREE_SMS_NOT_ENABLED:
                                returnObject.tollFreeMessagingStatus = "No Tollfree Numbers with Messaging Enabled";
                                break;
                            case TOLL_FREE_SMS_PENDING_VERIFICATION:
                                returnObject.tollFreeMessagingStatus = "Pending Verification - No Messaging";
                                break;
                            case TOLL_FREE_SMS_SUBMITTED_FOR_VERIFICATION:
                                returnObject.tollFreeMessagingStatus = "Submitted for Verification - No Messaging";
                                break;
                            case TOLL_FREE_SMS_VERIFICATION_APPROVED:
                                returnObject.tollFreeMessagingStatus = "Approved for Messaging (4 Toll-Free Numbers Limit)";
                                break;
                            case TOLL_FREE_SMS_VERIFICATION_REJECTED:
                                returnObject.tollFreeMessagingStatus = "Not Eligible for Toll-Free Messaging - Rejected by Carriers";
                                break;
                            case TOLL_FREE_SMS_VERIFICATION_NOT_FOUND:
                                returnObject.tollFreeMessagingStatus = "Tollfree Verification not found";
                                break;
                        }

                        switch(response.data.smsStatus) {
                            case SMS_NO_MESSAGING:
                                returnObject.messagingStatus = "No Messaging";
                                break;
                            case SMS_LIMITED_LOCAL_MESSAGING:
                                returnObject.messagingStatus = "Local Messaging (Up to 1 Local Number)";
                                break;
                            case SMS_STANDARD_LOCAL_MESSAGING:
                                returnObject.messagingStatus = "Local Messaging on Unlimited Local Numbers";
                                break;
                            case SMS_NO_MESSAGING_COMPLIANCE_INCOMPLETE:
                                returnObject.messagingStatus = "Not Eligible - Messaging Compliance Incomplete";
                                break;
                            case SMS_NO_MESSAGING_IN_FREE_TRIAL:
                                returnObject.messagingStatus = "Not Eligible - In Free Trial";
                                break;
                            case SMS_NO_MESSAGING_BRAND_REGISTRATION_FAILED:
                                returnObject.messagingStatus = "Not Eligible - Brand Registration Failed";
                                break;
                            case SMS_NO_MESSAGING_BRAND_REGISTRATION_PENDING:
                                returnObject.messagingStatus = "Brand Registration Pending";
                                break;
                            case SMS_NO_MESSAGING_BRAND_REGISTRATION_PENDING_OTP_VERIFICATION:
                                returnObject.messagingStatus = "Brand Registration Pending OTP Verification";
                                break;
                            case BRAND_APPROVED_SOLE_PROPRIETOR_SMS_NOT_ENABLED:
                                returnObject.messagingStatus = "Brand Approved 1 Local Number - Waiting on Customer to Enable Messaging";
                                break;
                            case BRAND_APPROVED_LOW_VOLUME_MIXED_SMS_NOT_ENABLED:
                                returnObject.messagingStatus = "Brand Approved Unlimited Local Number - Waiting on Customer to Enable Messaging";
                                break;
                            case CAMPAIGN_REGISTRATION_FAILED:
                                returnObject.messagingStatus = "Not Eligible - Campaign Registration Failed";
                                break;
                            case CAMPAIGN_REGISTRATION_PENDING_SOLE_PROPRIETOR:
                                returnObject.messagingStatus = "Brand Approved 1 Local Number - Campaign Registration Pending";
                                break;
                            case CAMPAIGN_REGISTRATION_PENDING_LOW_VOLUME_MIXED:
                                returnObject.messagingStatus = "Brand Approved Unlimited Local Numbers - Campaign Registration Pending";
                                break;
                            case CAMPAIGN_REGISTRATION_PENDING_DCA_APPROVAL_SOLE_PROPRIETOR:
                                returnObject.messagingStatus = "Brand Approved 1 Local Number - Campaign Pending DCA Approval";
                                break;
                            case CAMPAIGN_REGISTRATION_PENDING_DCA_APPROVAL_LOW_VOLUME_MIXED:
                                returnObject.messagingStatus = "Brand Approved Unlimited Local Numbers - Campaign Pending DCA Approval";
                                break;
                            case CAMPAIGN_REGISTRATION_FAILED_DCA_APPROVAL_SOLE_PROPRIETOR:
                                returnObject.messagingStatus = "Brand Approved 1 Local Number - Campaign Failed DCA Approval";
                                break;
                            case CAMPAIGN_REGISTRATION_FAILED_DCA_APPROVAL_LOW_VOLUME_MIXED:
                                returnObject.messagingStatus = "Brand Approved Unlimited Local Numbers - Campaign Failed DCA Approval";
                                break;
                            case CAMPAIGN_REGISTRATION_APPROVED_SOLE_PROPRIETOR:
                                returnObject.messagingStatus = "Approved to Message on 1 Local Number";
                                break;
                            case CAMPAIGN_REGISTRATION_APPROVED_LOW_VOLUME_MIXED:
                                returnObject.messagingStatus = "Approved to Message on Unlimited Local Numbers";
                                break;
                        }
                        deferredObject.resolve(returnObject);
                    });
            });
        });
    };

    const _getAccountFlags = (accountNumber) => {
        return _promiseFactory.defer((deferredObject) => {
            const webSocketParams = {
                "account_number": accountNumber,
                "employee_id": _employeeId
            };

            _webSocketApp.send("get_account_flags", webSocketParams, function (result) {
                const returnObject = result;
                let accountFlagObject = result.accountFlags;
                returnObject.accountFlags = _convertAccountFlagObjectToArray(accountFlagObject);

                deferredObject.resolve(returnObject);
            });
        });
    };

    const _getPatliveAccountNumber = (accountNumber) => {
        return _promiseFactory.defer((deferredObject) => {
            const webSocketParams = {
                "account_number": accountNumber,
                "employee_id": _employeeId
            };

            _webSocketApp.send("get_patlive_account_number", webSocketParams, function (result) {
                deferredObject.resolve(result.patliveAccountNumber);
            });
        });
    };

    const _saveStatusData = (statusData, accountNumber) => {
        return _promiseFactory.defer((deferredObject) => {
            const webSocketParams = {
                "account_number": accountNumber,
                "employee_id": _employeeId,
                "billing_status": statusData.billingStatus,
                "fraud_status": statusData.fraudStatus,
            };

            _webSocketApp.send("save_status_data", webSocketParams, function (result) {
                deferredObject.resolve(result);
            });
        });
    };

    const _saveAccountFlags = (accountFlags, accountNumber) => {
        return _promiseFactory.defer((deferredObject) => {
            const webSocketParams = {
                "account_number": accountNumber,
                "employee_id": _employeeId,
                "account_flags": accountFlags
            };

            _webSocketApp.send("save_account_flags", webSocketParams, function (result) {
                let returnObject = {};
                let accountFlagObject = result;
                returnObject.accountFlags = _convertAccountFlagObjectToArray(accountFlagObject);
                deferredObject.resolve(returnObject);
            });
        });
    };

    const _savePatliveAccountNumber = (accountNumber, patliveAccountNumber) => {
        return _promiseFactory.defer((deferredObject) => {
            const webSocketParams = {
                "employee_id": _employeeId,
                "account_number": accountNumber,
                "patlive_account_number": patliveAccountNumber
            };
            _webSocketApp.send("save_patlive_account_number", webSocketParams, function() {
                deferredObject.resolve();
            });
        });
    };

    const _getAllPaymentMethods = () => {
        return _promiseFactory.defer((deferredObject) => {
            const webSocketParams = {
                "employee_id": _employeeId
            };

            _webSocketApp.send("get_all_payment_methods", webSocketParams, function (result) {
                let paymentMethods = result.payment_methods;
                let mappedPaymentMethods = [];
                if (paymentMethods instanceof Array === true) {
                    paymentMethods.map(function (paymentMethod) {
                        const paymentMethodPO = _mapPaymentMethodPresentationObject(paymentMethod);
                        mappedPaymentMethods.push(paymentMethodPO);
                    });
                } else {
                    const paymentMethodPO = _mapPaymentMethodPresentationObject(paymentMethods);
                    mappedPaymentMethods.push(paymentMethodPO);
                }
                result.paymentMethodPOs = mappedPaymentMethods;
                deferredObject.resolve(result);
            });
        });
    };

    const _payFinalBalance = (paymentMethodId, amount) => {
        return _promiseFactory.defer((deferredObject) => {
            const webSocketParams = {
                "employee_id": _employeeId,
                "payment_method_id": paymentMethodId,
                "amount": amount
            };

            _webSocketApp.send("pay_final_balance", webSocketParams, function (result) {
                const processingResult = result.processing_result;
                deferredObject.resolve(processingResult);
            });
        });
    };

    const _convertAccountFlagObjectToArray = (accountFlagObject) => {
        let accountFlagsArray = Object.keys(accountFlagObject).map((accountFlagKey) => {
            const accountFlagPresentationObject = _accountFlagObjectBuilder.buildAccountFlagPresentationObject(accountFlagKey, accountFlagObject[accountFlagKey]);
            return accountFlagPresentationObject;
        });
        const activeAccountFlagsArray = accountFlagsArray.filter((accountFlag) => {
            return accountFlag !== "deprecated";
        });
        return activeAccountFlagsArray;
    };

    const _mapPaymentMethodPresentationObject = (paymentMethod) => {
        let paymentMethodPO = new PaymentMethodPresentationObjectConstructor();
        paymentMethodPO.paymentMethodId = paymentMethod.paymentMethodId;
        paymentMethodPO.cardHolderName = paymentMethod.cardHolderName;
        paymentMethodPO.cardType = paymentMethod.cardType;
        paymentMethodPO.isDefault = paymentMethod.isDefault;
        paymentMethodPO.expirationDate = _date.formatExpirationDate(paymentMethod.expirationDate);
        paymentMethodPO.lastFourDigits = paymentMethod.lastFourDigits;
        paymentMethodPO.isExpired = _isCardExpired(paymentMethod.expirationDate);
        let description;
        if (paymentMethod.isDefault) {
            description = [
                '(Default) ',
                paymentMethodPO.cardType, ' (*',
                paymentMethodPO.lastFourDigits, '), exp. ',
                paymentMethodPO.expirationDate, ' - ',
                paymentMethodPO.cardHolderName
            ].join("");
        } else {
            description = [
                paymentMethodPO.cardType, ' (*',
                paymentMethodPO.lastFourDigits, '), exp. ',
                paymentMethodPO.expirationDate, ' - ',
                paymentMethodPO.cardHolderName
            ].join("");
        }
        paymentMethodPO.description = description;
        return paymentMethodPO;
    };

    const _deleteCapturedPhoneNumber = (accountNumber, capturedPhoneNumberId) => {
        return _promiseFactory.defer((deferredObject) => {
            const webSocketParams = {
                "account_number": accountNumber,
                "employee_id": _employeeId,
                "captured_phone_number_id": capturedPhoneNumberId
            };

            _webSocketApp.send("delete_captured_phone_number", webSocketParams, function (result) {
                deferredObject.resolve();
            });
        });
    };

    const _deleteCapturedEmailAddress = (accountNumber, capturedEmailAddressId) => {
        return _promiseFactory.defer((deferredObject) => {
            const webSocketParams = {
                "account_number": accountNumber,
                "employee_id": _employeeId,
                "captured_email_address_id": capturedEmailAddressId
            };

            _webSocketApp.send("delete_captured_email_address", webSocketParams, function (result) {
                deferredObject.resolve();
            });
        });
    };

    const _isCardExpired = (expirationDate) => {
        return expirationDate < new Date();
    };

    const _retirePhoneConnector = (phoneNumber) => {
        return _promiseFactory.defer((deferredObject) => {
            var webSocketParams = {
                phone_number: _phoneNumberFormatter.toEOneSixFour(phoneNumber)
            };

            _webSocketApp.send("retire_phone_connector", webSocketParams, function (result) {
                deferredObject.resolve(result);
            });
        });
    };

    const _init = () => {
        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("crm_sidebar");

        const _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');
        _employeeId = _employeeAuthenticationStore.getEmployeeId();
        _employeeAuthorization = require('businessServices/authentication/employeeAuthorization');

        const ClientApiConstructor = require('externalDependencies/clientApi');
        _clientApi = new ClientApiConstructor();
        _clientApi.init();
    };

    return function () {
        let self = this;
        self.init = _init;

        self.canEmployeeRetireNumber = _canEmployeeRetireNumber;
        self.canEmployeeVanityAdd = _canEmployeeVanityAdd;
        self.deleteCapturedEmailAddress = _deleteCapturedEmailAddress;
        self.deleteCapturedPhoneNumber = _deleteCapturedPhoneNumber;
        self.getAccountFlags = _getAccountFlags;
        self.getAllPaymentMethods = _getAllPaymentMethods;
        self.getPatliveAccountNumber = _getPatliveAccountNumber;
        self.getSidebarData = _getSidebarData;
        self.payFinalBalance = _payFinalBalance;
        self.retirePhoneConnector = _retirePhoneConnector;
        self.saveAccountFlags = _saveAccountFlags;
        self.savePatliveAccountNumber = _savePatliveAccountNumber;
        self.saveStatusData = _saveStatusData;
    };
});

