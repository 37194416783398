define('presentation/marketing/facades/emailNotificationsFacade',['common/promises/promiseFactory',
        'externalDependencies/clientApi',
        'presentation/common/dateTimeValue'],
    function() {
        var _promiseFactory = null;
        var _clientApi = null;
        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');

        var _getEmailNotifications = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _clientApi.call("marketingRecipient/retrieveAll", "POST", {}, 'application/json')
                    .fail(deferredObject.reject)
                    .done(function(result) {
                        var emailRecipients = result.data;
                        emailRecipients.forEach(function(recipient){
                            var createdDateTime = new DateTimeValueConstructor(recipient.createdDateTime);
                            recipient.createdDateTimeDisplayValue = createdDateTime.displayValue;
                            recipient.emailUrl = "mailto:" + recipient.emailAddress;
                        });
                        deferredObject.resolve(emailRecipients);
                    });
            });
        };

        var _removeEmailRecipient = function(recipient) {
            return _promiseFactory.defer(function(deferredObject) {
                var params = {"marketingEmailRecipientId": recipient.marketingEmailRecipientId};
                _clientApi.call("marketingRecipient/removeRecipient", "POST", params, 'application/json')
                    .fail(deferredObject.reject)
                    .done(function(result) {
                        deferredObject.resolve(result);
                    });
            });
        };

        var _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();
        };

        return function() {
            var self = this;
            self.getEmailNotifications = _getEmailNotifications;
            self.removeEmailRecipient = _removeEmailRecipient;
            self.init = _init;
        };
    });

