define('presentation/account/activity/notation/validators/updateNotationViewModelValidator',['presentation/common/validation/validationRule',
        'presentation/common/validation/commonValidator',
        'constants/validationMessageEnumerations'],
function() {
    return function() {
        var self = this;

        var _viewModel;
        var _facade;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var _commonValidator = null;

        self.registerViewModel = function(viewModel, facade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.account.activity.updateNotation;
            var validationRules = new ValidationRulesConstructor();

            validationRules.field("originalNotationStartOn")
                                .addValidation(_validateOriginalNotationStartOnRequired, validationMessageBase.originalNotationStartOnRequired);
            validationRules.field("originalNotationDueOn")
                                .addValidation(_validateOriginalNotationDueOnRequired, validationMessageBase.originalNotationDueOnRequired);
            validationRules.array("notations")
                                .arrayField("notationEventId")
                                    .addValidation(_validateEventRequired, validationMessageBase.eventRequired)
                                .arrayField("comment")
                                    .addValidation(_validateCommentRequired, validationMessageBase.commentRequired);

            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateOriginalNotationStartOnRequired = function(startOn) {
            var originalNotationStatus = _viewModel.originalNotationStatus();
            if (originalNotationStatus === "closed" || originalNotationStatus === "expired") {
                return true;
            } else {
                return _commonValidator.isDate(startOn);
            }
        };

        var _validateOriginalNotationDueOnRequired = function(dueOn) {
            var originalNotationStatus = _viewModel.originalNotationStatus();
            if (originalNotationStatus === "closed" || originalNotationStatus === "expired") {
                return true;
            } else {
                return _commonValidator.isDate(dueOn);
            }
        };

        var _validateEventRequired = function(notationEventId) {
            return _commonValidator.isStringWithValue(notationEventId);
        };

        var _validateCommentRequired = function(comment) {
            return _commonValidator.isStringWithValue(comment);
        };
    };
});

