define('presentation/common/optionsBox/viewModels/optionsBoxViewModel',['presentation/common/optionsBox/presentationObjects/optionsBoxOptionPresentationObject',
        'common/collections/collectionSorter',
        'constants/systemObjectIconNameConstants'
], function() {
    return function() {
        var self = this;
        var _settings = null;
        var OptionsBoxOptionPresentationObjectConstructor = require('presentation/common/optionsBox/presentationObjects/optionsBoxOptionPresentationObject');
        var SorterConstructor = require('common/collections/collectionSorter');
        
        const _systemObjectIconNames = require('constants/systemObjectIconNameConstants');

        var _updateSelectedValue = function() {
            var options = self.options();
            var selectedValues = null;

            if (_settings.multiSelection === true) {
                selectedValues = [];
            }

            options.forEach(function(option) {
                if (option.isSelected()) {
                    if(_settings.multiSelection) {
                        selectedValues.push(option.value);
                    } else {
                        selectedValues = option.value;
                    }
                }
            });
            self.selectedValue(selectedValues);
        };

        var _clearCurrentOptions = function() {
            var options = self.options();

            options.forEach(function(option) {
                option.isSelected(false);
            });
        };

        var _isOptionSelected = function(optionValue) {
            var selectedValue = ko.unwrap(_settings.value());

            var isSelected = false;

            if (Array.isArray(selectedValue)) {
                isSelected = selectedValue.some(function (individualValue) {
                    return (individualValue === optionValue);
                });
            } else {
                if (selectedValue === optionValue) {
                    isSelected = true;
                } else {
                    isSelected = false;
                }
            }
            return isSelected;
        };

        var _setSelectedItem = function(option) {
            if (self.multiSelection()) {
                if (option.isSelected()) {
                    option.isSelected(false);
                } else {
                    option.isSelected(true);
                }
            } else {
                if (!option.isSelected()) {
                    _clearCurrentOptions();
                    option.isSelected(true);
                } else {
                    return;
                }
            }
        };

        var _onValueChanged = function(newValue) {
            var optionsList = self.options();
            optionsList.forEach(function(option){
                if (option.value === newValue) {
                    _setSelectedItem(option);
                }
            });
        };
        var setIconProperties = function(iconType) {
            var iconProperties = {
                containerWidth: '68',
                containerHeight: '68',
                iconHeight: '32',
                iconWidth: '32',
            };
            switch (iconType) {
                case 'autoAttendant':
                    iconProperties.iconName = _systemObjectIconNames.autoAttendant;
                    break;
                case 'forwardingNumber':
                    iconProperties.iconName = _systemObjectIconNames.forwardingNumber;
                    break;
                case 'landline':
                case 'mobile':
                    iconProperties.iconName = _systemObjectIconNames.device;
                    break;
                case 'phone':
                    iconProperties.iconName = _systemObjectIconNames.hostedNumber;
                    break;
                case 'prompt':
                    iconProperties.iconName = _systemObjectIconNames.prompt;
                    break;
                case 'schedule':
                    iconProperties.iconName = _systemObjectIconNames.schedule;
                    break;
                case 'subMenu':
                    iconProperties.iconName = _systemObjectIconNames.subMenu;
                    break;
                case 'user':
                    iconProperties.iconName = _systemObjectIconNames.user;
                    break;
                case 'userGroup':
                    iconProperties.iconName = _systemObjectIconNames.userGroup;
                    iconProperties.iconHeight = '62';
                    iconProperties.iconWidth = '62';
                    break;
                case 'voicemail':
                    iconProperties.iconName = _systemObjectIconNames.voicemail;
                    break;
            }
            return iconProperties;
        };

        self.multiSelection = ko.observable(false);
        self.options = ko.observableArray([]);
        self.selectedValue = ko.observable();
        self.id = ko.observable();

        self.onOptionClicked = function(option) {
            _setSelectedItem(option);
            _updateSelectedValue();
        };

        self.activate = function(settings) {
            _settings = settings;

            _initalize();
        };

        var _initalize = function() {
            if (_settings.value) {
                self.selectedValue = _settings.value;
                self.selectedValue.subscribe(_onValueChanged);
            }

            if (_settings.multiSelection) {
                self.multiSelection(_settings.multiSelection);
            }

            if (_settings.sortOptions !== false) {
                var sorter = new SorterConstructor();
                sorter.sort(_settings.options, "title");
            }

            if (_settings.id) {
                self.id(_settings.id);
            }

            if (_settings.options) {
                var options = _settings.options.map(function(settingsOption) {
                    var option = new OptionsBoxOptionPresentationObjectConstructor();
                    if (typeof settingsOption === "string") {
                        option.title = settingsOption;
                        option.value = settingsOption;
                        option.isSelected(_isOptionSelected(option.value));
                    } else {
                        if (settingsOption.title) {
                            option.title = settingsOption.title;
                        }

                        if (settingsOption.icon) {
                            option.hasIcon = true;
                            var iconProperties = setIconProperties(settingsOption.icon);
                            option.containerWidth = iconProperties.containerWidth;
                            option.containerHeight = iconProperties.containerHeight;
                            option.iconHeight = iconProperties.iconHeight;
                            option.iconName = iconProperties.iconName;
                            option.iconWidth = iconProperties.iconWidth;
                        }

                        if (settingsOption.value || typeof settingsOption.value === "boolean") {
                            option.value = settingsOption.value;
                            option.isSelected(_isOptionSelected(option.value));
                        }
                    }

                    if (settingsOption.tooltip) {
                        option.hasTooltip = true;
                        option.tooltip = settingsOption.tooltip;
                    }

                    option.isEnabled = settingsOption.isEnabled !== false;

                    return option;
                });
                self.options(options);
            }

            _updateSelectedValue();
        };
    };
});
