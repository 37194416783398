define('presentation/common/modal',['plugins/dialog'], function() {
    return function() {
        var self = this;
        var dialog = require('plugins/dialog');

        self.showModal = function(viewModel){
            dialog.show(viewModel);
        };

        self.closeModal = function(viewModel){
            dialog.close(viewModel);
        };

        self.toggleMinimizeModal = function(viewModel) {
            dialog.toggleMinimize(viewModel);
        };

        self.restoreModal = function(viewModel) {
            dialog.restore(viewModel);
        };

        self.confirmMessage = function(viewModel, confirmationMessage, confirmButtonLabel, cancelButtonLabel, confirmCallback) {
            dialog.confirmMessage(viewModel, confirmationMessage, confirmButtonLabel, cancelButtonLabel, confirmCallback);
        };
    };
});

