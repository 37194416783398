define('presentation/account/facades/usersFacade',['common/promises/promiseFactory',
        'common/storage/workSessionIdStorage',
        'common/converters/phoneNumberFormatter',
        'common/converters/passedTimeSpanFormatter',
        'common/authentication/employeeAuthenticationStore',
        'constants/secretQuestionEnumerations',
        'persistence/webSocket/webSocketApp',
        'presentation/account/presentationObjects/userPresentationObject',
        'presentation/account/presentationObjects/avatarPresentationObject'
    ],
    function() {

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
        const _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

        const PassedTimeSpanFormatterConstructor = require('common/converters/passedTimeSpanFormatter');
        const _passedTimeSpanFormatter = new PassedTimeSpanFormatterConstructor();

        const UserPresentationObjectConstructor = require('presentation/account/presentationObjects/userPresentationObject');
        const AvatarPresentationObjectConstructor = require('presentation/account/presentationObjects/avatarPresentationObject');

        const _verificationQuestionsEnumerations = require('constants/secretQuestionEnumerations');
        const _employeeAuthenticationStore = require('common/authentication/employeeAuthenticationStore');

        let _workSessionIdStorage = null;
        let _webSocketCustomerUserInfo = null;
        let _accountNumber = null;

        const _buildUserPresentationObject = (user) => {
            let userPresentationObject = new UserPresentationObjectConstructor();
            userPresentationObject.userId = user.userId;
            userPresentationObject.emailAddress = user.emailAddress;
            userPresentationObject.emailAddressDescription = "Default, Login";
            userPresentationObject.verificationQuestion = _verificationQuestionsEnumerations[user.verificationQuestion];
            userPresentationObject.verificationAnswer = user.verificationAnswer;
            userPresentationObject.timeZone = user.timeZone;
            userPresentationObject.otherContactInfoEmailAddresses = [];
            userPresentationObject.otherContactInfoPhoneNumbers = [];
            userPresentationObject.status(user.status);
            userPresentationObject.firstName = user.firstName || "Invited";
            userPresentationObject.lastName = user.lastName || "User";
            if (userPresentationObject.isInvitedStatus() || userPresentationObject.isExpiredStatus()) {
                if(user.inviteInformation.status === "pending") {
                    userPresentationObject.inviteStatus("invited");
                } else {
                    userPresentationObject.inviteStatus(user.inviteInformation.status);
                }
                userPresentationObject.inviteExpirationDateTime = _passedTimeSpanFormatter.format(user.inviteInformation.inviteExpirationDateTime);
                userPresentationObject.inviteSentDateTime = _passedTimeSpanFormatter.format(user.inviteInformation.inviteSentDateTime);
            }
            userPresentationObject.userType = user.status + " User";
            userPresentationObject.createdDateTime = _passedTimeSpanFormatter.format(user.createdDateTime);

            let avatarPresentationObject = new AvatarPresentationObjectConstructor();
            if (user.avatar.hasAvatar) {
                avatarPresentationObject.showDefaultImage(false);
                avatarPresentationObject.showImage(true);
                avatarPresentationObject.avatarUrl(user.avatar.url);
                avatarPresentationObject.avatarFileHash(user.avatar.avatarFileHash);
            } else {
                avatarPresentationObject.showDefaultImage(true);
                avatarPresentationObject.showImage(false);
                avatarPresentationObject.avatarUrl("");
                avatarPresentationObject.avatarFileHash("");
            }
            userPresentationObject.hasAvatar = user.avatar.hasAvatar;
            userPresentationObject.userAvatar(avatarPresentationObject);

            userPresentationObject.devices = user.devices.map((device) => ({
                    "devicePhoneNumber": _phoneNumberFormatter.toInternational(device.devicePhoneNumber),
                    "deviceName": device.deviceName,
                    appVersion: device.appVersion,
                    model: device.model,
                    osVersion: device.osVersion,
                })
            );

            if (userPresentationObject.isActiveStatus() && user.userGroups.length > 0) {
                user.userGroups.forEach(userGroup => (userPresentationObject.badges.push({
                        css: `badge-${userGroup.groupType}`,
                        text: userGroup.groupName
                    })
                ));
            }
            if (userPresentationObject.isDeactivatedStatus()) {
                userPresentationObject.badges.push({
                    css: 'badge-inactive',
                    text: 'Inactive'
                });
            }
            if (userPresentationObject.isInvitedStatus() || userPresentationObject.isExpiredStatus()) {
                userPresentationObject.badges.push({
                    css: `badge-${userPresentationObject.inviteStatus()}`,
                    text: userPresentationObject.inviteStatus()
                });
            }


            return userPresentationObject;

        };

        function init() {
            _workSessionIdStorage = require('common/storage/workSessionIdStorage');

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketCustomerUserInfo = new WebSocketAppConstructor();
            _webSocketCustomerUserInfo.init("crm_user");

        }

        function _isUndefinedOrNull(value) {
            return value === undefined || value === null;
        }

        function loginAsUser(accountNumber, userId) {
            if (_isUndefinedOrNull(userId)) {
                throw new Error("userId must have a value");
            }

            return _promiseFactory.defer(function(promise) {
                let params = {
                    accountNumber : accountNumber,
                    userId : userId
                };
                _webSocketCustomerUserInfo.send("login_as_user", params, function(result) {
                    if (result.status === "success") {
                        let loginResult = {
                            success : true,
                            url : result.loginUrl
                        };
                        promise.resolve(loginResult);
                    } else {
                        let unknownError = new Error("Unknown error for loginAsUser");
                        unknownError.result = result;
                        promise.reject(unknownError);
                    }
                });
            });
        }

        function getUsers(accountNumber) {
            return _promiseFactory.defer(function(promise) {
                let webSocketParams = {
                    "employee_id" : _employeeAuthenticationStore.getEmployeeId(),
                    "customer_account_number" : accountNumber
                };
                _accountNumber = accountNumber;
                _webSocketCustomerUserInfo.send("get_all_users", webSocketParams, function(result) {
                    var presentableUsers = result.users.map(_buildUserPresentationObject);
                    promise.resolve(presentableUsers);
                });
            });
        }

        function sendResetPassword(accountNumber, userId) {
            return _promiseFactory.defer(function(promise) {
                let webSocketParams = {
                    "employee_id" : _employeeAuthenticationStore.getEmployeeId(),
                    "customer_account_number" : accountNumber,
                    "user_id" : userId
                };
                _webSocketCustomerUserInfo.send("request_password_reset", webSocketParams, function(result) {
                    promise.resolve(result);
                });
            });
        }

        function resendInvite(emailAddress) {
            return _promiseFactory.defer(function(promise) {
                let webSocketParams = {
                    "employee_id" : _employeeAuthenticationStore.getEmployeeId(),
                    "email_address" : emailAddress
                };
                _webSocketCustomerUserInfo.send("resend_invite", webSocketParams, function(result) {
                    promise.resolve(result);
                });
            });
        }


        const _retrieveVerificationCode = ({accountNumber, phoneNumber}) => _promiseFactory.defer(promise => {
            const webSocketParams = {
                accountNumber,
                phoneNumber: _phoneNumberFormatter.toEOneSixFour(phoneNumber)
            };

            _webSocketCustomerUserInfo.send("retrieve_sms_verification_code", webSocketParams, function (result) {
                promise.resolve(result);
            });
        });

        const _resetVerificationCode = ({accountNumber, phoneNumber, userId, verificationMethod}) => _promiseFactory.defer(promise => {
            const webSocketParams = {
                accountNumber,
                phoneNumber: _phoneNumberFormatter.toEOneSixFour(phoneNumber),
                userId,
                verificationMethod
            };

            _webSocketCustomerUserInfo.send("reset_sms_verification_code", webSocketParams, function (result) {
                promise.resolve(result);
            });
        });

        return {
            init : init,
            loginAsUser : loginAsUser,
            getUsers : getUsers,
            resendInvite: resendInvite,
            sendResetPassword : sendResetPassword,
            retrieveVerificationCode: _retrieveVerificationCode,
            resetVerificationCode: _resetVerificationCode,
        };
    });

