define('presentation/release/viewModels/releaseSidebarViewModel',[
    'common/promises/promiseFactory',
    'common/url/urlFormatter',
    'presentation/release/facades/releaseSidebarFacade'
    ], function() {

        let SidebarItemPresentationObject = function() {
            let self = this;

            self.selection = null;
            self.title = ko.observable();
            self.isSelected = ko.observable(false);
            self.url = ko.observable();
            self.viewModelPath = null;
            self.viewModel = null;
        };

        return function(){
            let self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            const _promiseFactory = new PromiseFactoryConstructor();

            let _facade = null;
            let _urlFormatter = null;

            self.sidebarConfig = null;
            self.sidebarItems = ko.observableArray([]);
            self.contentPane = ko.observable();

            const loadSidebarItems = (remainingSidebarItems, validSidebarItems,  allCompletedPromise) => {
                try {
                    if (remainingSidebarItems.length === 0) {
                        allCompletedPromise.resolve(validSidebarItems);
                        return;
                    }

                    let sidebarItem = remainingSidebarItems[0];
                    let nextSidebarItems = remainingSidebarItems.slice(1);

                    _facade.hasPermission(sidebarItem.permissionId)
                        .done((result) => {
                            if ((result === true) && sidebarItem.showInSidebar) {
                                validSidebarItems.push(sidebarItem);
                            }

                            loadSidebarItems(nextSidebarItems, validSidebarItems, allCompletedPromise);
                        })
                        .fail(allCompletedPromise.reject);

                } catch (ex) {
                    allCompletedPromise.reject(ex);
                }
            };

            self.settingSelectionChanged = (settingsSelection) => {
                let sidebarItems = self.sidebarItems();
                sidebarItems.forEach((sidebarItem) => {
                    if (sidebarItem.selection === settingsSelection) {
                        sidebarItem.isSelected(true);
                    } else {
                        if ((typeof sidebarItem.showSelectedFor !== 'undefined') &&
                            (sidebarItem.showSelectedFor.indexOf(settingsSelection) !== -1)) {
                            sidebarItem.isSelected(true);
                        } else {
                            sidebarItem.isSelected(false);
                        }
                    }
                });
            };

            self.activate = function() {
                const FacadeConstructor = require('presentation/release/facades/releaseSidebarFacade');
                _facade = new FacadeConstructor();
                _facade.init(_promiseFactory);

                _urlFormatter = require('common/url/urlFormatter');

                return _initialize();
            };

            const _initialize = function() {
                if (self.sidebarConfig === null) {
                    throw new Error("No sidebar configuration specified");
                }

                let allCompleted = _promiseFactory.defer();

                _promiseFactory.defer((promise) => {
                    loadSidebarItems(self.sidebarConfig, [], allCompleted);

                    allCompleted.done((validSidebarItems) => {
                        let sidebarItems = validSidebarItems.map((validSidebarItem) => {
                            let url = _urlFormatter.buildWorkSessionUrl(validSidebarItem.urlParts);

                            let sidebarItem = new SidebarItemPresentationObject();
                            sidebarItem.selection = validSidebarItem.selection;
                            sidebarItem.title = validSidebarItem.title;
                            sidebarItem.iconClass = validSidebarItem.iconClass;
                            sidebarItem.showSelectedFor = validSidebarItem.showSelectedFor;
                            sidebarItem.isSelected(false);
                            sidebarItem.url = url;
                            sidebarItem.viewModelPath = validSidebarItem.viewModelPath;
                            sidebarItem.viewModel = null;

                            return sidebarItem;
                        });

                        self.sidebarItems(sidebarItems);
                        promise.resolve();
                    })
                    .fail(promise.reject);
                });

                return _promiseFactory.wait();
            };
        };
    });

