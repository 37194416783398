define('presentation/settings/facades/addNotationEventFacade',['persistence/webSocket/webSocketApp',
    'common/encryption/crypto',
    'common/collections/collectionSorter'
], function() {
    var SorterConstructor = require('common/collections/collectionSorter');
    
    var _promiseFactory = null;
    var _webSocketApp = null;
    var _webSocketAppCategory = null;
    
    var _getAllNotationCategories = function() {
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {};
            _webSocketAppCategory.send("get_notation_categories", webSocketParams, function(notationCategoriesList) {
                _sortDisplayedNotationCategories(notationCategoriesList);
                    deferredObject.resolve(notationCategoriesList);
            });
        });
    };

    var _sortDisplayedNotationCategories = function(notationCategoriesList) {
        var _sorter = new SorterConstructor();
        _sorter.multiSort(notationCategoriesList, ["isActive", "description"], [false, true]);
        return notationCategoriesList;
    };
    
    var _getAllNotationEvents = function() {
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {}; 
            _webSocketApp.send("get_notation_events", webSocketParams, function(result) { 
                deferredObject.resolve(result);
            });
        });
    };
    
    var _findNotationEvent = function(notationEventId){
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {"notationEventId" : notationEventId};
            _webSocketApp.send("get_notation_event", webSocketParams, function(result) { 
                deferredObject.resolve(result);
            });
        });

    };

    var _isNotationEventDescriptionUnique = function(notationEventId, notationCategoryId, notationEventName) {
        return _promiseFactory.defer(function(deferredObject) {
            _getAllNotationEvents().done(function(notationEvents) {
                var formattedExpectedNotationEventName = notationEventName.toLowerCase();

                var isDuplicate = notationEvents.some(function(notationEvent) {
                    var eventNameLowerCase = notationEvent.eventName.toLowerCase();
                    if (eventNameLowerCase === formattedExpectedNotationEventName && notationEvent.notationCategoryId === notationCategoryId) {
                        if (notationEvent.notationEventId !== notationEventId) {
                            // The Name belongs to another Event
                            return true;
                        }
                    }
                    return false;
                });

                if (isDuplicate) {
                    var foundNotationEvent = notationEvents.find(function(notationEvent) {
                        return notationEvent.notationEventId === notationEventId;
                    });

                    if (foundNotationEvent !== undefined) {
                        if (foundNotationEvent.isSystemNotation === true) {
                            // System events can have any name
                            deferredObject.resolve(true);
                            return;
                        }
                    }
                }

                var isUnique = !isDuplicate;
                deferredObject.resolve(isUnique);
            })
                .fail(function(error) {
                    error.notationEventId = notationEventId;
                    error.notationEventName = notationEventName;
                    deferredObject.reject(error);
                });
        });
    };

    var _updateNotationEvent = function(notationEventId, eventName, notationCategoryId, isActiveCode, defaultStatus, isSystemNotation){
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {
                "notationEventId" : notationEventId,
                "eventName" : eventName,
                "notationCategoryId" : notationCategoryId,
                "isActive" : isActiveCode,
                "defaultStatus" : defaultStatus,
                "isSystemNotation" : isSystemNotation};
            
             _webSocketApp.send("update_notation_event", webSocketParams, function(result) {
                 deferredObject.resolve(result);
            });
        });

    };

    var _addNotationEvent = function(eventName, notationCategoryId, isActive, defaultStatus){
        return _promiseFactory.defer(function(deferredObject) {
            var webSocketParams = {"eventName" : eventName,
                "notationCategoryId" : notationCategoryId,
                "isSystemNotation" : false,
                "isActive" : isActive,
                "defaultStatus" : defaultStatus};
             _webSocketApp.send("add_notation_event", webSocketParams, function(result) {
                 deferredObject.resolve(result);
            });
        });
    };

    var _init = function(promiseFactory) {
        _promiseFactory = promiseFactory;

        var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("notation_events");

        
        _webSocketAppCategory = new WebSocketAppConstructor();
        _webSocketAppCategory.init("notation_categories");
        
    };

    var _init2 = function(promiseFactory, webSocketApp) {
        _promiseFactory = promiseFactory;
        _webSocketApp = webSocketApp;
    };

    return {
        isNotationEventDescriptionUnique : _isNotationEventDescriptionUnique,
        addNotationEvent : _addNotationEvent,
        updateNotationEvent : _updateNotationEvent,
        findNotationEvent : _findNotationEvent,
        getAllNotationEvents : _getAllNotationEvents,
        getAllNotationCategories : _getAllNotationCategories, 
        init : _init,
        init2 : _init2
    };
}); 
