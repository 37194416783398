define('presentation/developer/viewModels/rabbitMqViewModel',['common/promises/promiseFactory',
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'plugins/router',
    'presentation/developer/facades/rabbitMqFacade'], function() {
    return function(){
        var self = this;
        var _facade = null;
        var _router = null;
        var _sorter = null;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var FilterConstructor = require('common/collections/collectionFilter');

        var QUEUE_SORT_OPTIONS = [
            { "value": "name",                    "displayName":"Queue Name" },
            { "value": "messages",                "displayName":"Total Messages" },
            { "value": "messages_ready",          "displayName":"Ready Messages" },
            { "value": "messages_unacknowledged", "displayName":"Unacked Messages" }
        ];

        self.queueList = ko.observableArray([]);
        self.queueSearch = ko.observable('');
        self.sortOptions = ko.observableArray(QUEUE_SORT_OPTIONS);
        self.selectedSort = ko.observable(QUEUE_SORT_OPTIONS[1]);

        var _onSortChange = function() {
            var sortOrder = self.selectedSort().value;
            if (sortOrder !== null) {
                _sorter.multiSort(self.queueList, ["isEnabled", sortOrder], [false, (sortOrder === "name")]);
            }
        };

        self.filteredQueueList = ko.computed(function() {
            var searchText = self.queueSearch().trim().toLowerCase();
            var filteredQueueList = [];
            if (searchText) {
                var queueListFilter = new FilterConstructor();
                queueListFilter.addProperty('name');
                queueListFilter.addValue(searchText);
                filteredQueueList = queueListFilter.filter(self.queueList());
            } else {
                filteredQueueList = self.queueList();
            }
            return filteredQueueList;
        });

        self.resetQueueList = function() {
            _getQueues();
        };

        var _getQueues = function() {
            return _promiseFactory.defer(function(promise) {
                _facade.getQueues()
                    .fail(promise.reject)
                    .done(function(result) {
                        self.queueList(result);
                        _onSortChange();
                        promise.resolve();
                    });
            });
        };

        self.activate = function() {
            var Facade = require('presentation/developer/facades/rabbitMqFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);
            _router = require('plugins/router');
            var SorterConstructor = require('common/collections/collectionSorter');
            _sorter = new SorterConstructor();
            return _initialize();
        };

        var _initialize = function () {
            _getQueues();
            self.selectedSort.subscribe(_onSortChange);
            _onSortChange();
            return _promiseFactory.wait();
        };
    };
});

