define('presentation/developer/viewModels/callMonitoringViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/callMonitoringFacade',
], function() {
    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _facade = null;

        self.hostedNumber = ko.observable('');
        self.message = ko.observable('');

        const _getHostedNumber = () => _promiseFactory.deferIndefinitely(promise => {
            _facade.getHostedNumber()
                .fail(promise.reject)
                .done(result => {
                    self.hostedNumber(result.data.hostedNumber);
                    self.message(result.data.message);
                    promise.resolve();
                });
        });

        self.setHostedNumber = () => _promiseFactory.deferIndefinitely(promise => {
            _facade.setHostedNumber(self.hostedNumber())
                .fail(promise.reject)
                .done(result => {
                    self.message(result.data.message);
                    promise.resolve();
                });
        });

        self.activate = () => {
            const Facade = require('presentation/developer/facades/callMonitoringFacade');
            _facade = new Facade();
            _facade.init();

            return _initialize();
        };

        const _initialize = () => {

            _getHostedNumber();

            return _promiseFactory.wait();
        };
    };
});

