define('presentation/settings/facades/addBlacklistPhoneNumberFacade',['persistence/webSocket/webSocketApp',
    'presentation/common/dateTimeValue'], function() {

    return function() {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _webSocketApp = null;
        var _phoneNumberFormatter = null;

        self.addPhoneNumberToBlackList = function(phoneNumber, countryAbbreviation, countryCode) {
            return _promiseFactory.defer(function(deferredObject) {
                var webSocketParams = {
                    phoneNumber: _phoneNumberFormatter.toNumbers(phoneNumber, countryAbbreviation),
                    countryAbbreviation: countryAbbreviation,
                    countryCode: countryCode
                };
                _webSocketApp.send("add_phone_number_to_blacklist", webSocketParams, function() {
                    deferredObject.resolve();
                });
            });
        };

        self.init = function() {
            var PhoneNumberFormatterConstructor = require('common/converters/phoneNumberFormatter');
            _phoneNumberFormatter = new PhoneNumberFormatterConstructor();

            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("blacklist_phone_numbers");
        };

        self.init2 = function(webSocketApp) {
            _webSocketApp = webSocketApp;
        };
    };
});

