define('businessServices/accountFlags/accountFlagObjectBuilder',['presentation/account/sidebar/presentationObjects/accountFlagPresentationObject'], function () {
    return function () {
        var self = this;
        
        var AccountFlagPresentationObjectConstructor = require('presentation/account/sidebar/presentationObjects/accountFlagPresentationObject');
        

        self.buildAccountFlagPresentationObject = function(accountFlagName, accountFlagStatus) {
            if (accountFlagStatus !== null) {
                var _accountFlagPresentationObject = new AccountFlagPresentationObjectConstructor();
                switch (accountFlagName) {
                    case "welcomeInfoCapture":
                        _accountFlagPresentationObject.icon = "icon-account-flag-welcome";
                        _accountFlagPresentationObject.type = "Welcome";
                        _accountFlagPresentationObject.text = "Info Capture";
                        _accountFlagPresentationObject.order = 1;
                        _accountFlagPresentationObject.isHidden = true;
                        break;
                    case "miscProblems":
                        _accountFlagPresentationObject.icon = "icon-account-flag-problems";
                        _accountFlagPresentationObject.type = "Problem";
                        _accountFlagPresentationObject.text = "Miscellaneous";
                        _accountFlagPresentationObject.order = 4;
                        _accountFlagPresentationObject.isHidden = false;
                        break;
                    case "doNotReactivate":
                        _accountFlagPresentationObject.icon = "icon-account-flag-problems";
                        _accountFlagPresentationObject.type = "Problem";
                        _accountFlagPresentationObject.text = "Do Not Reactivate";
                        _accountFlagPresentationObject.order = 5;
                        _accountFlagPresentationObject.isHidden = false;
                        break;
                    case "doNotAutoCharge":
                        _accountFlagPresentationObject.icon = "icon-account-flag-settings";
                        _accountFlagPresentationObject.type = "Setting";
                        _accountFlagPresentationObject.text = "Do Not Auto Charge";
                        _accountFlagPresentationObject.order = 6;
                        _accountFlagPresentationObject.isHidden = false;
                        break;
                    case "doNotAutoClose":
                        _accountFlagPresentationObject.icon = "icon-account-flag-settings";
                        _accountFlagPresentationObject.type = "Setting";
                        _accountFlagPresentationObject.text = "Do Not Auto Close";
                        _accountFlagPresentationObject.order = 7;
                        _accountFlagPresentationObject.isHidden = false;
                        break;
                    case "doNotSendDeclineNotices":
                        _accountFlagPresentationObject.icon = "icon-account-flag-notifications";
                        _accountFlagPresentationObject.type = "Notification";
                        _accountFlagPresentationObject.text = "Suspend Decline Notices";
                        _accountFlagPresentationObject.order = 8;
                        _accountFlagPresentationObject.isHidden = false;
                        break;
                    case "excludeFromReporting":
                        _accountFlagPresentationObject.icon = "icon-account-flag-settings";
                        _accountFlagPresentationObject.type = "Setting";
                        _accountFlagPresentationObject.text = "Exclude From Reporting";
                        _accountFlagPresentationObject.order = 9;
                        _accountFlagPresentationObject.isHidden = false;
                        break;
                    case "signup":
                        _accountFlagPresentationObject.icon = "icon-account-flag-settings";
                        _accountFlagPresentationObject.type = "Setting";
                        _accountFlagPresentationObject.text = "Signup Is Not Complete";
                        _accountFlagPresentationObject.order = 10;
                        _accountFlagPresentationObject.isHidden = true;
                        break;
                    case "isAccountBillingProfileComplete":
                        _accountFlagPresentationObject.icon = "icon-account-flag-settings";
                        _accountFlagPresentationObject.type = "Setting";
                        _accountFlagPresentationObject.text = "Is Billing Profile Complete";
                        _accountFlagPresentationObject.order = 11;
                        _accountFlagPresentationObject.isHidden = true;
                        break;
                }
                _accountFlagPresentationObject.name = accountFlagName;
                _accountFlagPresentationObject.active = ko.observable(accountFlagStatus);
                return _accountFlagPresentationObject;
            } else {
                return "deprecated";
            }
        };
    };
});

