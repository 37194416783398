define('presentation/reports/facades/executeReportFacade',['presentation/common/dateTimeValue',
        'common/converters/accountNumberFormatter',
        'persistence/webSocket/webSocketApp'],
    function() {
        var DateTimeValueConstructor = require('presentation/common/dateTimeValue');
        var AccountNumberFormatterConstructor = require('common/converters/accountNumberFormatter');
        var _accountNumberFormatter = new AccountNumberFormatterConstructor();
        var _promiseFactory = null;
        var _webSocketApp = null;

        var _executeReport = function(reportId) {
            return _promiseFactory.deferIndefinitely(function(deferredObject) {
                _webSocketApp.send("execute_notation_report", {report_id: reportId}, function(result) {
                    var notationsReport = {
                        reportName: result.reportName,
                        totalReportItems: 0,
                        totalPastDueItems: 0,
                        notations: []
                    };
                    var date = new Date();
                    var dateISO  = date.toISOString();
                    result.notations.forEach(function(notationList) {

                        notationList.forEach(function(notationEvent){
                            var accountNumber = notationEvent.accountNumber;
                            notationEvent.formattedAccountNumber = _accountNumberFormatter.formatAccountNumber(accountNumber);

                            var dueByDate = new DateTimeValueConstructor(notationEvent.dueByDate);
                            notationEvent.dueByDateTimeDisplayValue = dueByDate.displayValue;
                            notationEvent.dueByDate = dueByDate;
                            notationEvent.formatRow = ko.observable();
                            if(notationEvent.dueByDate.sortValue <= dateISO) {
                                notationsReport.totalPastDueItems++;
                                notationEvent.formatRow("status-past-due");
                            }
                            var createdDateTime = new DateTimeValueConstructor(notationEvent.createdDateTime);
                            notationEvent.createdDateTimeDisplayValue = createdDateTime.displayValue;
                            notationEvent.createdDateTime = createdDateTime;
                            notationEvent.isDetailsVisible = ko.observable(false);
                            notationsReport.totalReportItems++;
                            notationsReport.notations.push(notationEvent);
                        });

                    });
                    deferredObject.resolve(notationsReport);
                });
            });
        };

        var _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;
            var WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("notation_report");
        };

        var _init2 = function() {

        };

        return function() {
            var self = this;
            self.init = _init;
            self.init2 = _init2;
            self.executeReport = _executeReport;
        };
    });

