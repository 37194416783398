define('businessServices/router/authorizationPlugins/fromClientUiAuthorizationPlugin',['businessServices/authentication/authenticationProvider',
        'constants/statusConstants'], function (AuthenticationProviderConstructor, statusConstants) {

    function isFromClientUi(urlFragment) {
        var urlParts = urlFragment.split("/");
        return urlParts.length === 4 && urlParts[0] === "fromClientUI";
    }

    function fromClientUiAuthorizationPlugin(urlFragment) {
        if(isFromClientUi(urlFragment)) {
            var authenticationProvider = new AuthenticationProviderConstructor();
            authenticationProvider.init();
            return authenticationProvider.validateClientUiUrlNonce(urlFragment);
        }

        return {status: statusConstants.ignored};
    }

    return {
        configureAuthorization: fromClientUiAuthorizationPlugin
    };
});
