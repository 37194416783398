/**
 * @module index
 */
define('presentation/shell/viewModels/indexViewModel',['common/promises/promiseFactory', 'businessServices/router/crmRouter'], function() {

	/**
     * ViewModel for the index
     * @class index
     * @constructor
     */
	return function() {
		var self = this;
		var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        
		/**
         * Activating Router
         * @property router
         * @type {string}
        */
		self.router = null;


		self.activate = function() {
			self.router = require('businessServices/router/crmRouter');
			return _initialize();
		};

		self.activate2 = function(router) {
			self.router = router;
			return _initialize();
		};

		var _initialize = function() {
			return _promiseFactory.wait();
		};
	};
});
