define('presentation/shell/viewModels/templateHeaderContentViewModel',['common/promises/promiseFactory',
        'businessServices/router/crmRouter',
        'settings/navigationConfiguration'
    ],
    function(){
        return function() {
            var self = this;

            var PromiseFactoryConstructor = require('common/promises/promiseFactory');
            var _promiseFactory = new PromiseFactoryConstructor();
            var _navigationConfiguration = null;

            self.router = null;
            self.headerPane = ko.observable();

            var _loadedHeaderViewModelPath = null;

            var _onActiveInstructionChanged = function() {
                var instruction = self.router.activeInstruction();
                var routeId = instruction.config.customSettings.routeId;

                var headerViewModelPath = _navigationConfiguration.routesById[routeId].headerViewModelPath;
                if (headerViewModelPath === undefined) {
                    headerViewModelPath = _navigationConfiguration.defaultHeaderViewModelPath;
                }

                if (_loadedHeaderViewModelPath !== headerViewModelPath) {
                    _loadedHeaderViewModelPath = headerViewModelPath;

                    _promiseFactory.defer(function(promise) {
                        require([headerViewModelPath], function(HeaderViewModelConstructor) {
                            var headerViewModel = new HeaderViewModelConstructor();
                            headerViewModel.activate();
                            headerViewModel.setRouter(self.router);

                            self.headerPane(headerViewModel);
                            promise.resolve();
                        });
                    });
                }
            };

            self.activate = function() {
                self.router = require('businessServices/router/crmRouter');
                self.router.activeInstruction.subscribe(_onActiveInstructionChanged);

                _navigationConfiguration = require('settings/navigationConfiguration');

                return _initialize();
            };

            self.activate2 = function(router, navigationConfiguration) {
                self.router = router;
                _navigationConfiguration = navigationConfiguration;

                return _initialize();
            };

            var _initialize = function() {
                _onActiveInstructionChanged();
                return _promiseFactory.wait();
            };
        };
    });

