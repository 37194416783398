define('presentation/common/wysiwygEditor/viewModels/wysiwygEditorViewModel',[],
function() {
    return function() {
        var self = this;

        var _settings = null;

        self.content = ko.observable();
        self.value = ko.observable();
        self.styles = ko.observable();
        self.enabled = ko.observable(true);

        self.activate = function(settings) {
            _settings = settings;

            return _initialize();
        };

        self.activate2 = function(settings) {
            _settings = settings;

            return _initialize();
        };

        var _initialize = function() {

            if (_settings.value) {
                if (typeof _settings.value === "function") {
                    self.value = _settings.value;
                } else {
                    self.value(_settings.value);
                }
            }

            if (_settings.initialContent) {
                if (typeof _settings.initialContent === "function") {
                    self.content = _settings.initialContent;
                } else {
                    self.content(_settings.initialContent);
                }
            }

            if (_settings.styles) {
                if (typeof _settings.styles === "function") {
                    self.styles = _settings.styles;
                } else {
                    self.styles(_settings.styles);
                }
            }

            if (_settings.enabled) {
                if (typeof _settings.enabled === "function") {
                    self.enabled = _settings.enabled;
                } else {
                    self.enabled(_settings.enabled);
                }
            }
        };
    };
});

