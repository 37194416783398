define('presentation/account/activity/notation/viewModels/updateNotationViewModel',['common/promises/promiseFactory',
        'presentation/common/modal',
        'presentation/account/activity/notation/presentationObjects/updateNotationPresentationObject',
        'presentation/account/activity/notation/facades/updateNotationFacade',
        'presentation/account/activity/notation/validators/updateNotationViewModelValidator'],
function() {
    return function(accountNumber, notationId, modalClosePromise) {
        var self = this;

        var NotationPresentationObjectConstructor = require('presentation/account/activity/notation/presentationObjects/updateNotationPresentationObject');
        var _modalService = null;
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _facade = null;
        var _accountNumber = accountNumber;
        var _notationId = notationId;
        var _validator = null;
        var _modalClosePromise = modalClosePromise;

        var _getNotationCategoriesEventsList = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _facade.getNotationCategoriesEventsList(_accountNumber)
                    .done(function(notationCategoriesEventsList) {
                        self.notationCategoriesEventsList(notationCategoriesEventsList);
                        deferredObject.resolve();
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        var _getNewNotation = function(isRemovable) {
            var notation = new NotationPresentationObjectConstructor();
            notation.isChanged = ko.computed(function() {
                if (notation.comment() === "" && notation.notationEventId() === "") {
                    return false;
                } else {
                    return true;
                }
            });

            notation.isRemovable = isRemovable;

            return notation;
        };

        var _getDefaultDates = function() {
            var startOnOffsetHours = 24;
            var dueOnOffsetMinutes = 1439;
            var now = new Date();
            var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            var startOn = today.setHours(startOnOffsetHours);
            var dueOn = today.setMinutes(dueOnOffsetMinutes);

            return {
                startOn: new Date(startOn),
                dueOn: new Date(dueOn)
            };
        };

        var _onConfirmCloseModal = function() {
            _modalClosePromise.resolve(false);
            _modalService.closeModal(self);
        };

        self.notations = ko.observableArray([]);
        self.notationCategoriesEventsList = ko.observableArray([]);
        self.originalNotationCategory = "";
        self.originalNotationEvent = "";
        self.originalNotationComment = "";
        self.originalNotationStatus = ko.observable();
        self.originalNotationStartOn = ko.observable();
        self.originalNotationDueOn = ko.observable();

        self.originalNotationStartOnIsVisible = ko.computed(function() {
            return self.originalNotationStatus() === "open";
        });

        self.originalNotationDueOnIsVisible = ko.computed(function() {
            return self.originalNotationStatus() === "open";
        });

        self.closeModal = function() {
            var changedNotationsExist = false;
            var notations = self.notations();
            notations.forEach(function(notation) {
                if (notation.isChanged() === true) {
                    changedNotationsExist = true;
                } else {
                    var originalDates = _getDefaultDates();

                    if (self.originalNotationStatus() !== "open") {
                        changedNotationsExist = true;
                    } else if (self.originalNotationStartOn().toString() !== originalDates.startOn.toString()) {
                        changedNotationsExist = true;
                    } else if (self.originalNotationDueOn().toString() !== originalDates.dueOn.toString()) {
                        changedNotationsExist = true;
                    }
                }
            });

            if (changedNotationsExist === true) {
                _modalService.confirmMessage(self, "Are you sure you want to close? All changes will be lost.", "Close", "Cancel", _onConfirmCloseModal);
            } else {
                _modalClosePromise.resolve(false);
                _modalService.closeModal(self);
            }
        };

        self.updateNotation = function() {
            return _promiseFactory.defer(function (deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if (isValid === false) {
                            deferredObject.resolve();
                        } else {
                            _facade.updateNotation(_accountNumber, _notationId, self.originalNotationStatus(), self.originalNotationStartOn(), self.originalNotationDueOn(), self.notations())
                                .done(function() {
                                    deferredObject.resolve();
                                    _modalClosePromise.resolve(true);
                                    _modalService.closeModal(self);
                                })
                                .fail(function(error) {
                                    deferredObject.reject(error);
                                });
                        }
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.addAnotherNotation = function() {
            self.notations.push(_getNewNotation(true));
        };

        self.removeNotation = function(notation) {
            if (notation.isChanged() === true) {
                _modalService.confirmMessage(self,
                    "Are you sure you want to remove this notation? Unsaved changes will be lost.",
                    "Remove",
                    "Cancel",
                    function() {
                        self.notations.remove(notation);
                    });
            } else {
                self.notations.remove(notation);
            }
        };

        self.activate = function() {
            var ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            var Facade = require('presentation/account/activity/notation/facades/updateNotationFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            var ValidatorConstructor = require('presentation/account/activity/notation/validators/updateNotationViewModelValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        self.activate2 = function(modalService, facade, validator) {
            _modalService = modalService;
            _facade = facade;
            _validator = validator;

            return _initialize();
        };

        var _initialize = function() {
            _getNotationCategoriesEventsList();
            _facade.getNotation(_accountNumber, _notationId)
                .done(function(notation) {
                    self.originalNotationCategory = notation.notationCategory;
                    self.originalNotationEvent = notation.notationEvent;
                    self.originalNotationComment = notation.notationComment;

                    var defaultDates = _getDefaultDates();
                    self.originalNotationStartOn(defaultDates.startOn);
                    self.originalNotationDueOn(defaultDates.dueOn);

                })
                .fail(function(error) {
                    throw error;
                });

            var initialFormNotationList = [_getNewNotation(false)];
            self.notations(initialFormNotationList);

            _promiseFactory.wait()
                .done(function() {
                    _validator.registerViewModel(self, _facade);
                })
                .fail(function(error) {
                    throw error;
                });

            return _promiseFactory.wait();
        };
    };
});

