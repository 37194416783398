define('presentation/release/facades/addFeatureToAccountModalFacade',[
    'common/promises/promiseFactory',
    'persistence/webSocket/webSocketApp'
], function() {
    const PromiseFactoryConstructor = require('common/promises/promiseFactory');
    let _promiseFactory = new PromiseFactoryConstructor();
    let _webSocketApp = null;

    const _cleanAccountId = (accountId) => {
        return accountId.replace(/-|\s/g,"");
    };

    const _addAccountsToFeature = (releaseToggle, accountIds) => {
        return _promiseFactory.defer((promise) => {
            let returnReleaseToggle = {
                feature_toggle_id: releaseToggle.featureToggleId,
                account_ids: accountIds,
            };
            _webSocketApp.send("add_accounts_to_feature", returnReleaseToggle, function(result) {
                promise.resolve(result);
            });
        });
    };

    const _isValidAccountId = (accountId) => {
        return _promiseFactory.defer((promise) => {
            let cleanAccountId = _cleanAccountId(accountId);
            let webSocketParams = {
                account_id: cleanAccountId
            };
            _webSocketApp.send("is_valid_account", webSocketParams, function(result) {
                let isValidAccount = result.status;
                promise.resolve(isValidAccount);
            });
        });
    };

    const _init = function() {
        const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
        _webSocketApp = new WebSocketAppConstructor();
        _webSocketApp.init("release_toggles");
    };


    return function() {
        let self = this;
        self.init = _init;
        self.addAccountsToFeature = _addAccountsToFeature;
        self.isValidAccountId = _isValidAccountId;
    };
});

