define('businessServices/router/plugins/fromPccRouterPlugin',[
    'settings/navigationConfiguration',
    'businessServices/authentication/authenticatedEmployee',
    'constants/statusConstants'
], function(
    navigationConfiguration,
    authenticatedEmployee,
    statusConstants
) {

    function isFromPcc(urlFragment) {
        var urlParts = urlFragment.split("/");
        return urlParts.length === 4 && urlParts[0].substr(0, 7) === "fromPcc";
    }

    function fromPccUrlRedirect(urlFragment, results) {
        if ( ! isFromPcc(urlFragment)) {
            return true;
        }

        if (results !== undefined && results.status === statusConstants.success && authenticatedEmployee.isAuthenticated()) {
            var urlParts = urlFragment.split("/");
            switch (urlParts[0]) {
                case "fromPccReports": {
                    return {routeUrl: "reports"};
                }
                case "fromPccOpenEmail": {
                    return {routeUrl: "account/" + results.accountNumber + "/activity/?action=showEmail&callId=" + results.callId};
                }
                case "fromPcc": {
                    if (results.callId && results.phoneNumber) {
                        return {routeUrl: "account/" + results.accountNumber + "/users/?callId=" + results.callId + "&phoneNumber=" + results.phoneNumber};
                    }
                    return {routeUrl: "account/" + results.accountNumber + "/users"};
                }
                default: {
                    var error = new Error("unknown fromPcc url");
                    error.urlFragment = urlFragment;
                    error.results = results;
                    throw error;
                }
            }
        }
        return {routeUrl: navigationConfiguration.loginPageRouteUrl};
    }

    return {
        loadUrlRedirect: fromPccUrlRedirect
    };
});

