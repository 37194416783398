define('presentation/developer/facades/operatorFacade',['common/promises/promiseFactory',
        'externalDependencies/clientApi'
    ],
    function() {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        
        let _promiseFactory = null;
        let _clientApi = null;
    
        const _drainStopAndDetachOperatorInstances = (operatorInstance) => {
            return _promiseFactory.defer((deferredObject) => {
                const data = {
                    serverId: operatorInstance.serverId
                };
                _clientApi.call("crm/operator/drainStopOperatorAndDetachInstance", "POST", data, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        if(result.data.isSuccessful === true)
                        {
                            deferredObject.resolve();
                        } else {
                            deferredObject.reject();
                        }
                    });
            });
        };
        
        const _drainStopAndTerminateOperatorInstances = (operatorInstance) => {
            return _promiseFactory.defer((deferredObject) => {
                const data = {
                    serverId: operatorInstance.serverId
                };
                _clientApi.call("crm/operator/drainStopOperatorAndTerminateInstance", "POST", data, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        if(result.data.isSuccessful === true)
                        {
                            deferredObject.resolve();
                        } else {
                            deferredObject.reject();
                        }
                    });
            });
        };

        const _getOperatorInstances = () => {
            return _promiseFactory.defer((deferredObject) => {
                _clientApi.call("crm/operator/getAllOperatorInstances", "GET", {}, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        let operatorInstances = [];
                        if(result.success === true) {
                            result.data.forEach((instance) => {
                                operatorInstances.push({
                                    instanceId: instance.instanceId,
                                    publicIpAddress: instance.publicIpAddress,
                                    serverId:  instance.serverId,
                                    status: instance.status,
                                    totalChannelCount: instance.totalChannelCount,
                                    inboundChannelCount: instance.inboundChannelCount,
                                    outboundChannelCount: instance.outboundChannelCount
                                });
                            });
                            deferredObject.resolve(operatorInstances);
                        }
                        else {
                            deferredObject.reject();
                        }
                    });
            });
        };

        const _hangupOperatorInstance = (operatorInstance) => {
            return _promiseFactory.defer((deferredObject) => {
                const data = {
                    serverId: operatorInstance.serverId
                };
                _clientApi.call("crm/operator/hangupAllCalls", "POST", data, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        if(result.data.isSuccessful === true)
                        {
                            deferredObject.resolve();
                        } else {
                            deferredObject.reject();
                        }
                    });
            });
        };
        
        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
            
            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();
        };
        
        return function() {
            let self = this;
            
            self.init = _init;
            self.drainStopAndDetachOperatorInstances = _drainStopAndDetachOperatorInstances;
            self.drainStopAndTerminateOperatorInstances = _drainStopAndTerminateOperatorInstances;
            self.getOperatorInstances = _getOperatorInstances;
            self.hangupOperatorInstance = _hangupOperatorInstance;
        };
    });

