define('presentation/developer/facades/fraudSettingsFacade',[
        'externalDependencies/clientApi'
    ],
    function() {
        let _clientApi = null;

        const _getFraudSettings = () => _clientApi.call("ipQualityScore/getFraudSettings", "GET", {}, 'application/json');

        const _updateFraudSettings = (emailFraudScore, ipFraudScore, paymentMethodFraudScore, phoneNumberFraudScore, bypassFraudChecks) => {
            let params = {
                emailFraudScore: emailFraudScore,
                ipFraudScore: ipFraudScore,
                paymentMethodFraudScore: paymentMethodFraudScore,
                phoneNumberFraudScore: phoneNumberFraudScore,
                bypassFraudChecks: bypassFraudChecks
            };
            return _clientApi.call("ipQualityScore/updateFraudSettings", "POST", params, 'application/json');
        };

        const _init = () => {
            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();
        };

        return function() {
            let self = this;
            self.getFraudSettings = _getFraudSettings;
            self.updateFraudSettings = _updateFraudSettings;
            self.init = _init;
        };
    });

