define('presentation/release/facades/releaseSidebarFacade',[
        'common/promises/promiseFactory',
        'businessServices/authentication/employeeAuthorization'
    ], function() {
        return function(){
            let self = this;

            const PromiseFactoryConstructor = require('common/promises/promiseFactory');
            let _promiseFactory = new PromiseFactoryConstructor();
            let _employeeAuthorization = null;

            self.hasPermission = (permissionId) => {
                return _promiseFactory.defer((promise) => {
                    _employeeAuthorization.hasPermission(permissionId)
                        .done(promise.resolve)
                        .fail(promise.reject);
                });
            };

            self.init = function(promiseFactory) {
                _promiseFactory = promiseFactory;
                _employeeAuthorization = require('businessServices/authentication/employeeAuthorization');
            };
        };
    });


