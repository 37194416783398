define('businessServices/validationMessages/validationMessage',['common/promises/promiseFactory'],
    function() {
        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        return {
            get: function(validationMessage) {
                return _promiseFactory.defer(function(deferredObject) {
                    if (validationMessage === undefined) {
                        var error = new Error('the validationMessage passed to validationRule.addValidation was undefined.');
                        deferredObject.reject(error);
                    } else {
                        deferredObject.resolve(validationMessage);
                    }
                });
            },
            init: function() {

            },
            init2: function() {

            }
        };
});

