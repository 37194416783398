define('presentation/account/viewModels/resetVerificationCodeModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/account/facades/usersFacade',
    'presentation/account/validators/resetVerificationCodeValidator',
    'presentation/common/modal',
], function() {
    return function(accountNumber, userId, outerPromise, modalClosePromise) {
        const self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        let _facade = require('presentation/account/facades/usersFacade');

        const ModalServiceConstructor = require('presentation/common/modal');
        let _modalService = new ModalServiceConstructor();

        const ValidatorConstructor = require('presentation/account/validators/resetVerificationCodeValidator');
        let _validator = new ValidatorConstructor();

        const _modalClosePromise = modalClosePromise;

        const _accountNumber = accountNumber;
        const _userId = userId;

        self.phoneNumber = ko.observable('');
        self.validationAttempts = ko.observable('');
        self.verificationCode = ko.observable('');
        self.messagesSent = ko.observable('');
        self.showAskForPhoneNumber = ko.observable(true);
        self.showResetButton = ko.observable(true);
        self.errorMessage = ko.observable('');
        self.cancelButtonText = ko.pureComputed(() => self.showResetButton() ? 'Cancel' : 'Ok');
        self.verificationMethods = ko.observableArray([
            {description: "Text Message", value: "sendTextMessage"},
            {description: "Phone Call", value: "makePhoneCall"}
        ]);
        self.verificationMethod = ko.observable(self.verificationMethods()[0]);

        self.showResults = ko.pureComputed(() => !self.showAskForPhoneNumber());
        self.showErrorMessage = ko.pureComputed(() => self.errorMessage() !== '');

        self.retrieveVerificationCode = () => _promiseFactory.defer(promise => {
            const accountNumber = _accountNumber;
            const phoneNumber = self.phoneNumber();

            _validator.validate()
                .fail(promise.reject)
                .done(isValid => {
                    if (isValid === false) {
                        promise.resolve();
                        return;
                    }
                    _facade.retrieveVerificationCode({accountNumber, phoneNumber})
                        .fail(promise.reject)
                        .done(result => {
                            switch (result.status) {
                                case "hostedNumber":
                                    self.errorMessage("Hosted Number: Customer should not send the SMS to their hosted number. Customer should use the cell phone number of the device on which they are installing the Tresta app.");
                                    break;
                                case "restricted":
                                    self.errorMessage("Restricted number: Customer should not try to use a restricted number. (900, 800, 555-1212, etc). This may be a sign of fraud.");
                                    break;
                                case "notFound":
                                    self.errorMessage('Not Found: Unable to locate any attempts to send an SMS message to that phone number. Confirm number with customer.');
                                    break;
                                case "success":
                                    self.errorMessage('');
                                    self.validationAttempts(result.validationAttempts);
                                    self.verificationCode(result.verificationCode);
                                    self.messagesSent(result.messagesSent);
                                    self.showAskForPhoneNumber(false);
                            }
                            promise.resolve();
                        });
                });
        });

        self.resetVerificationCode = () => _promiseFactory.defer(promise => {
            const accountNumber = _accountNumber;
            const userId = _userId;
            const phoneNumber = self.phoneNumber();
            const verificationMethod = self.verificationMethod().value;

            _facade.resetVerificationCode({accountNumber, userId, phoneNumber, verificationMethod})
                .done(result => {
                    self.validationAttempts(0);
                    self.verificationCode(result.verificationCode);
                    self.messagesSent(1);
                    self.showResetButton(false);
                    promise.resolve();
                })
                .fail(function(error) {
                    promise.reject(error);
                    _modalClosePromise.reject(error);
                });

        });

        self.closeModal = function() {
            _modalClosePromise.resolve("cancel");
            _modalService.closeModal(self);
        };

        self.cancel = function() {
            _modalClosePromise.resolve("cancel");
            _modalService.closeModal(self);
        };

        self.activate = function() {
            return _initialize();
        };

        const _initialize = function() {
            outerPromise.resolve();
            _validator.registerViewModel(self);
            return _promiseFactory.wait();
        };
    };
});
