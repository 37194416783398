define('presentation/developer/viewModels/moveNumbersModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/numberManagementFacade',
    'presentation/developer/validators/moveNumbersValidator'
], function() {
    return function(onModalSavePromise) {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        let _facade = null;
        let _modalService = null;
        let _onModalSavePromise = onModalSavePromise;
        let _validator = null;

        self.cancelButtonText = "Cancel";
        self.closeButtonText = "Ok";
        self.modalTitle = 'Move Phone Numbers';
        self.selectedAll = false;

        self.fromAccountNumber = ko.observable('');
        self.isComplete = ko.observable(false);
        self.moveSuccessfulNumbers = ko.observable('');
        self.moveFailedNumbers = ko.observable('');
        self.moveResultStatus = ko.observable('');
        self.selectedNumbers = ko.observable();
        self.toAccountNumber = ko.observable('');

        self.moveButtonText = ko.computed(() => "Move");

        self.submitForm = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _validator.validate()
                    .done((isValid) => {
                        if (isValid) {
                            let movePromise = new PromiseFactoryConstructor();
                            let moveResult = {
                                status: "",
                                successfulMoved: [],
                                failedMoved: []
                            };
                            if (self.selectedAll) {
                                movePromise.deferIndefinitely((moveNumberDeferredObject) => {
                                    _facade.moveAllNumbers(self.fromAccountNumber(), self.toAccountNumber())
                                        .fail(moveNumberDeferredObject.reject)
                                        .done((result) => {
                                            moveResult.status = result.status;
                                            moveResult.successfulMoved = result.successfulMoved;
                                            moveResult.failedMoved = result.failedMoved;
                                            moveNumberDeferredObject.resolve();
                                        });
                                });
                            } else {
                                movePromise.deferIndefinitely((moveNumberDeferredObject) => {
                                    _facade.movePhoneNumbers(self.selectedNumbers(), self.fromAccountNumber(), self.toAccountNumber())
                                        .fail(moveNumberDeferredObject.reject)
                                        .done((result) => {
                                            moveResult.status = result.status;
                                            moveResult.successfulMoved = result.successfulMoved;
                                            moveResult.failedMoved = result.failedMoved;
                                            moveNumberDeferredObject.resolve();
                                        });
                                });
                            }

                            movePromise.wait()
                                .fail(deferredObject.reject)
                                .done(() => {
                                    deferredObject.resolve();
                                    if (_onModalSavePromise) {
                                        _onModalSavePromise.resolve({action: "submit"});
                                    }
                                    self.isComplete(true);
                                    self.moveResultStatus(moveResult.status);
                                    self.moveSuccessfulNumbers(moveResult.successfulMoved);
                                    self.moveFailedNumbers(moveResult.failedMoved);
                                });
                        } else {
                            deferredObject.resolve();
                        }
                    });
            });
        };

        self.cancelForm = () => {
            if (_onModalSavePromise) {
                _onModalSavePromise.resolve({action: "cancel"});
            }
            _modalService.closeModal(self);
        };

        self.activate = () => {
            const Facade = require('presentation/developer/facades/numberManagementFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            const ValidatorConstructor = require('presentation/developer/validators/moveNumbersValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {

            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();
        };
    };
});


