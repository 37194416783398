define('presentation/developer/facades/numberManagementFacade',['persistence/webSocket/webSocketApp',
        'presentation/common/dateTimeValue'
    ],
    function() {
        let _promiseFactory = null;
        let _webSocketApp = null;

        const DateTimeValueConstructor = require('presentation/common/dateTimeValue');

        const _cleanAccountNumber = (accountNumber) => {
            return accountNumber.replace(/-|\s/g,"");
        };

        const _getAllPhoneNumbers = (accountNumber) => {
            return _promiseFactory.defer(function(deferredObject) {
                let webSocketParams = {
                    account_number: _cleanAccountNumber(accountNumber)
                };
                _webSocketApp.send("get_all_phone_numbers", webSocketParams, function(response) {
                    let phoneNumbersObjects = response.phoneNumbers.map(function(phoneNumber) {
                        let addedDateTime = new DateTimeValueConstructor(phoneNumber.createdDateTime);
                        return {
                            phoneNumberName: phoneNumber.phoneNumberName,
                            phoneNumber: phoneNumber.phoneNumber,
                            addedDateTime: addedDateTime.displayValue,
                            createdDateTime: addedDateTime,
                            isSelected: ko.observable(false),
                            status: status,
                        };
                    });
                    deferredObject.resolve(phoneNumbersObjects);
                });
            });
        };

        const _isValidAccountNumber = (accountNumber) => {
            return _promiseFactory.defer((promise) => {
                let cleanAccountNumber = _cleanAccountNumber(accountNumber);
                let webSocketParams = {
                    account_number: cleanAccountNumber
                };
                _webSocketApp.send("is_valid_account", webSocketParams, function(isValidAccount) {
                    promise.resolve(isValidAccount);
                });
            });
        };

        const _moveAllNumbers = function(fromAccountNumber, toAccountNumber) {
            return _promiseFactory.deferIndefinitely(function(deferredObject) {
                let webSocketParams = {
                    from_account_number: _cleanAccountNumber(fromAccountNumber),
                    to_account_number: _cleanAccountNumber(toAccountNumber)
                };
                _webSocketApp.send("move_all_numbers", webSocketParams, function(moveNumbersResult) {
                    deferredObject.resolve(moveNumbersResult);
                });
            });
        };

        const _movePhoneNumbers = function(phoneNumbers, fromAccountNumber, toAccountNumber) {
            return _promiseFactory.deferIndefinitely(function(deferredObject) {
                let webSocketParams = {
                    from_account_number: _cleanAccountNumber(fromAccountNumber),
                    to_account_number: _cleanAccountNumber(toAccountNumber),
                    phone_numbers: phoneNumbers
                };
                _webSocketApp.send("move_phone_numbers", webSocketParams, function(moveNumbersResult) {
                    deferredObject.resolve(moveNumbersResult);
                });
            });
        };

        const _init = function(promiseFactory) {
            _promiseFactory = promiseFactory;

            const WebSocketAppConstructor = require('persistence/webSocket/webSocketApp');
            _webSocketApp = new WebSocketAppConstructor();
            _webSocketApp.init("crm_phone_number_management");
        };

        return function() {
            let self = this;

            self.getAllPhoneNumbers = _getAllPhoneNumbers;
            self.isValidAccountNumber = _isValidAccountNumber;
            self.moveAllNumbers = _moveAllNumbers;
            self.movePhoneNumbers = _movePhoneNumbers;

            self.init = _init;
        };
    });

