define('presentation/developer/facades/sqsFacade',['common/promises/promiseFactory',
        'externalDependencies/clientApi'
    ],
    function() {
        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        
        let _promiseFactory = null;
        let _clientApi = null;
        
        const _getQueues = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _clientApi.call("crm/sqs/getQueues", "GET", {}, 'application/json')
                    .fail(deferredObject.reject)
                    .done((result) => {
                        let sqsQueues = [];
                        if(result.success === true) {
                            result.data.forEach((sqsQueue) => {
                                sqsQueues.push({
                                    name: sqsQueue.name,
                                    queue_arn: sqsQueue.queueArn,
                                    queue_url: sqsQueue.queueUrl,
                                    messages: sqsQueue.messages,
                                    messages_in_flight:  sqsQueue.messagesInFlight,
                                    is_dead_letter_queue: sqsQueue.isDeadLetterQueue,
                                    queueDetailsUrl: "/developer/sqsQueueDetails/" + encodeURIComponent(sqsQueue.queueUrl),
                                });
                            });
                            deferredObject.resolve(sqsQueues);
                        }
                        else {
                            deferredObject.reject();
                        }
                    });
            });
        };
        
        const _init = (promiseFactory) => {
            _promiseFactory = promiseFactory;
            
            const ClientApiConstructor = require('externalDependencies/clientApi');
            _clientApi = new ClientApiConstructor();
            _clientApi.init();
        };
        
        return function() {
            const self = this;
            
            self.init = _init;
            self.getQueues = _getQueues;
        };
    });

