define('presentation/developer/viewModels/reactivateNumbersViewModel',[
    'common/collections/collectionFilter',
    'common/collections/collectionSorter',
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/reactivateNumbersFacade',
    'presentation/developer/validators/numberManagementValidator',
    'presentation/developer/viewModels/reactivateNumbersModalViewModel',
], function() {
    return function() {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        const _promiseFactory = new PromiseFactoryConstructor();

        const FilterConstructor = require('common/collections/collectionFilter');
        const ReactivateNumbersModalViewModelConstructor = require('presentation/developer/viewModels/reactivateNumbersModalViewModel');

        let _facade = null;
        let _modalService = null;
        let _sorter = null;
        let _validator = null;

        const _sortBy = ko.observable({
            property: "phoneNumber",
            isAscending: true,
        });

        const MAX_NUMBERS_TO_DEACTIVATE = 100;

        const _countOfSelectedPhoneNumbers = () => _availablePhoneNumbers().reduce(
            (sum, v) => v.isSelected() ?
                sum + 1 :
                sum,
            0
        );

        const _selectAllPhoneNumbers = (selectAll) => {
            if (selectAll) {
                let countOfSelectedPhoneNumbers = _countOfSelectedPhoneNumbers();
                let i = 0;
                const availablePhoneNumbers = _availablePhoneNumbers();
                while (i < availablePhoneNumbers.length && countOfSelectedPhoneNumbers < MAX_NUMBERS_TO_DEACTIVATE) {
                    if (availablePhoneNumbers[i].isSelected() === false) {
                        availablePhoneNumbers[i].isSelected(true);
                        countOfSelectedPhoneNumbers++;
                    }
                    i++;
                }
            } else {
                _availablePhoneNumbers.forEach(phoneNumber => phoneNumber.isSelected(false));
            }
        };

        const _availablePhoneNumbers = ko.observableArray([]);

        const _filteredPhoneNumbers = ko.pureComputed(() => {
            const filterContent = self.phoneNumbersFilter().trim().toLowerCase();
            if (filterContent === "") {
                return _availablePhoneNumbers();
            }

            let phoneNumbersFilter = new FilterConstructor();
            const filteredPhoneNumbers = phoneNumbersFilter.addProperties(['phoneNumber', 'phoneNumberName', 'addedDateTime'])
                .addValue(filterContent)
                .filter(_availablePhoneNumbers());

            return filteredPhoneNumbers;
        });

        const displayedPhoneNumbers = ko.pureComputed(() => {
            const displayedPhoneNumbers = _filteredPhoneNumbers();
            _sorter.multiSort(displayedPhoneNumbers, [_sortBy().property], [_sortBy().isAscending]);
            return displayedPhoneNumbers;
        });

        self.dataGridConfig = {};

        self.accountNumber = ko.observable('');
        self.phoneNumbersFilter = ko.observable('');
        self.selectedAll = ko.observable(false);
        self.selectedAll.subscribe(_selectAllPhoneNumbers);
        self.showDataGrid = ko.pureComputed(() => _availablePhoneNumbers().length > 0);
        self.showMaxReactivate = ko.pureComputed(() => _availablePhoneNumbers().length > MAX_NUMBERS_TO_DEACTIVATE);
        self.selectAllText = ko.pureComputed(() => self.showMaxReactivate() ?
            `Select First ${MAX_NUMBERS_TO_DEACTIVATE} Numbers` :
            'Select All Numbers'
        );

        const _refreshDisplayedPhoneNumbers = () => _promiseFactory.deferIndefinitely(function(deferredObject) {
            _facade.getAllDeactivatedPhoneNumbers(self.accountNumber())
                .fail(deferredObject.reject)
                .done(phoneNumbers => {
                    _availablePhoneNumbers(phoneNumbers);
                    self.selectedAll(false);
                    deferredObject.resolve();
                });
        });


        const _selectRow = (row) => {
            let selectedNumber = _availablePhoneNumbers().find(p => p.phoneNumber === row.phoneNumber);
            selectedNumber.isSelected(!selectedNumber.isSelected());

            if (_countOfSelectedPhoneNumbers() > MAX_NUMBERS_TO_DEACTIVATE) {
                selectedNumber.isSelected(false);
            }
        };

        const _sortColumn = (column, isAscending) => {
            const property = column === "addedDateTime" ? "createdDateTime.sortValue" : column;
            const sortBy = _sortBy();
            if (sortBy.property === property && sortBy.isAscending === isAscending) {
                return;
            }

            _sortBy({
                property: property,
                isAscending: isAscending,
            });
        };

        self.launchReactivatePhoneNumbersModal = () => {
            let onModalSavePromise = _promiseFactory.deferIndefinitely();
            let selectedPhoneNumbers = displayedPhoneNumbers().filter(p => p.isSelected());
            let modal = new ReactivateNumbersModalViewModelConstructor(onModalSavePromise);
            modal.accountNumber(self.accountNumber());
            modal.selectedNumbers(selectedPhoneNumbers);

            _modalService.showModal(modal);
            onModalSavePromise
                .done((result) => {
                    if (result.action === "submit") {
                        _refreshDisplayedPhoneNumbers();
                    }
                })
                .fail((error) => {
                    throw error;
                });
        };

        self.refreshResults = () => {
            return _promiseFactory.deferIndefinitely(function (deferredObject) {
                _validator.validate()
                    .fail(deferredObject.reject)
                    .done((isValid) => {
                        if(isValid === false) {
                            deferredObject.resolve();
                            return;
                        }
                        _refreshDisplayedPhoneNumbers()
                            .fail(deferredObject.reject)
                            .done(deferredObject.resolve);
                    });
            });
        };


        self.activate = () => {
            const Facade = require('presentation/developer/facades/reactivateNumbersFacade');
            _facade = new Facade();
            _facade.init();

            const ModalConstructor = require('presentation/common/modal');
            _modalService = new ModalConstructor();

            const SorterConstructor = require('common/collections/collectionSorter');
            _sorter = new SorterConstructor();

            const ValidatorConstructor = require('presentation/developer/validators/numberManagementValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        const _initialize = () => {

            self.dataGridConfig.dataSet = displayedPhoneNumbers;
            self.dataGridConfig.selectRowCallback = _selectRow;
            self.dataGridConfig.sortColumnCallback = _sortColumn;
            self.dataGridConfig.hasRowDetails = false;
            self.dataGridConfig.columns = [
                {"header": "Selected", "key": "isSelected", "isSortable": true, "isSelectable": true},
                {"header": "Phone Number Name", "key": "phoneNumberName", "isSortable": true},
                {"header": "Phone Number", "key": "phoneNumber", "isSortable": true, "sortAsc": true},
                {"header": "Added", "key": "addedDateTime", "isSortable": true},
                {"header": "Deactivated", "key": "deactivatedDateTime", "isSortable": true},
            ];

            _validator.registerViewModel(self, _facade);

            return _promiseFactory.wait();
        };
    };
});

