define('presentation/settings/viewModels/crmOptionsViewModel',['common/promises/promiseFactory',
    'presentation/settings/facades/crmOptionsFacade'], function () {
    return function () {
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _facade = null;

        function _getCrmOptions() {
            return _promiseFactory.defer(function (promise) {
                _facade.getCrmOptions()
                    .done(function (crmOptions) {
                        if(crmOptions){
                            self.environmentLabel(crmOptions.environmentLabel);
                        }
                        promise.resolve();
                    })
                    .fail(function (error) {
                        promise.reject(error);
                    });
            });
        }

        self.environmentLabel = ko.observable();
        self.isSuccessfulSave = ko.observable(false);

        self.saveOptions = function () {
            return _promiseFactory.defer(function (promise) {
                _facade.saveCrmOptions(self.environmentLabel())
                    .done(function () {
                        self.isSuccessfulSave(true);
                        promise.resolve();
                    })
                    .fail(function (error) {
                        promise.reject(error);
                    });
            });
        };

            self.activate = function () {
                var FacadeConstructor = require('presentation/settings/facades/crmOptionsFacade');
                _facade = new FacadeConstructor();
                _facade.init();

                return _initialize();
            };

            self.activate2 = function (facade) {
                _facade = facade;

                return _initialize();
            };

            function _initialize() {
                _getCrmOptions();
                return _promiseFactory.wait();
            }
        };
    }
    )
    ;
