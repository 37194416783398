define('presentation/account/activity/notation/validators/addNotationViewModelValidator',['presentation/common/validation/validationRule',
        'presentation/common/validation/commonValidator',
        'constants/validationMessageEnumerations',
        'common/promises/promiseFactory'], function() {
    return function() {
        var self = this;

        var _viewModel;
        var _facade;

        var CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        var ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        var _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();

        var _commonValidator = null;

        self.registerViewModel = function(viewModel, facade) {
            var validationMessageBase = _validationMessageEnumerations.webcrm.presentation.account.activity.addNotation;
            var validationRules = new ValidationRulesConstructor();
            validationRules.array("notations")
                                .arrayField("notationEventId")
                                    .addValidation(_validateEventRequired, validationMessageBase.eventRequired)
                                .arrayField("startOn")
                                    .addValidation(_validateStartOnRequired, validationMessageBase.startOnRequired)
                                .arrayField("dueOn")
                                    .addValidation(_validateDueOnRequired, validationMessageBase.dueOnRequired)
                                .arrayField("comment")
                                    .addValidation(_validateCommentRequired, validationMessageBase.commentRequired);

            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(_viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        var _validateEventRequired = function(notationEventId) {
            return _commonValidator.isStringWithValue(notationEventId);
        };

        var _validateStartOnRequired = function(startOn, notation) {
            if (notation.status() === "closed" || notation.status() === "expired") {
                return true;
            } else {
                return _commonValidator.isDate(startOn);
            }
        };

        var _validateDueOnRequired = function(dueOn, notation) {
            if (notation.status() === "closed" || notation.status() === "expired") {
                return true;
            } else {
                return _commonValidator.isDate(dueOn);
            }
        };

        var _validateCommentRequired = function(comment) {
            return _commonValidator.isStringWithValue(comment);
        };
    };
});

