define('presentation/account/sidebar/viewModels/editAccountFlagsModalViewModel',['presentation/common/modal',
        'presentation/account/sidebar/facades/accountSidebarFacade'], function () {
    return function (accountNumber, onLoadCompletePromise, onModalCloseCompletePromise) {
        var self = this;

        var _modalService = null;
        var _onLoadCompletePromise = null;
        var _onModalCloseCompletePromise = null;
        var _facade = null;

        var _accountNumber = accountNumber;

        self.accountFlags = ko.observableArray();
        self.modalHeaderText = ko.observable("Edit Account Flags");
        self.isEditing = ko.observable(false);
        self.isReviewing = ko.observable(false);
        self.changedAccountFlags = ko.observableArray([]);
        self.saveButtonText = ko.observable("Save");
        self.showReviewText = ko.observable(false);
        self.showNoChangesText = ko.observable(false);
        self.showCancelButton = ko.observable(true);

        self.closeModal = function () {
            _onModalCloseCompletePromise.resolve('cancel');
            _modalService.closeModal(self);
        };

        self.saveFlags = function() {
            if(self.isEditing() === true) {
                self.isEditing(false);
                self.isReviewing(true);
                if(self.changedAccountFlags().length === 0) {
                    self.modalHeaderText("No Changes Made");
                    self.saveButtonText("OK");
                    self.showCancelButton(false);
                    self.showReviewText(false);
                    self.showNoChangesText(true);
                } else {
                    self.modalHeaderText("Confirm Changes");
                    self.saveButtonText("Save");
                    self.showCancelButton(true);
                    self.showReviewText(true);
                    self.showNoChangesText(false);
                }
            }
            else {
                var accountFlagObject = {};
                self.accountFlags().forEach(function (accountFlag) {
                    accountFlagObject[accountFlag.name] = accountFlag.active();
                });
                _facade.saveAccountFlags(accountFlagObject, _accountNumber)
                    .done(function (result) {
                        self.isReviewing(false);
                        _modalService.closeModal(self);
                        _onModalCloseCompletePromise.resolve(result);
                    })
                    .fail(function (error) {
                        _onModalCloseCompletePromise.reject(error);
                    });
            }
        };

        self.addToChangedArray = function (accountFlag) {
            if(self.changedAccountFlags.indexOf(accountFlag) > -1) {
                self.changedAccountFlags.remove(accountFlag);
            } else {
                self.changedAccountFlags.push(accountFlag);
            }
        };

        self.activate = function () {
            var ModalServiceConstructor = require('presentation/common/modal');
            _modalService = new ModalServiceConstructor();

            var FacadeConstructor = require('presentation/account/sidebar/facades/accountSidebarFacade');
            _facade = new FacadeConstructor();

            _onLoadCompletePromise = onLoadCompletePromise;
            _onModalCloseCompletePromise = onModalCloseCompletePromise;

            return _initialize();
        };

        self.activate2 = function (modalService, facade, onLoadCompletePromise, onModalCloseCompletePromise) {
            _modalService = modalService;
            _facade = facade;
            _onLoadCompletePromise = onLoadCompletePromise;
            _onModalCloseCompletePromise = onModalCloseCompletePromise;

            return _initialize();
        };

        var _initialize = function () {
            self.isEditing(true);
            _facade.getAccountFlags(_accountNumber)
                .done(function(result) {
                    var sortedFlags = result.accountFlags.sort(function (a, b) {
                        if(a.order > b.order) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                    self.accountFlags(sortedFlags);
                    _onLoadCompletePromise.resolve();
                })
                .fail(function(error) {
                    _onLoadCompletePromise.reject(error);
                });
        };
    };
});

