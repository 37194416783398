define('presentation/developer/viewModels/editScheduledJobModalViewModel',[
    'common/promises/promiseFactory',
    'presentation/common/modal',
    'presentation/developer/facades/scheduledJobsFacade'
], function() {
    return function(scheduledJob, onModalSavePromise) {
        let self = this;

        const PromiseFactoryConstructor = require('common/promises/promiseFactory');
        let _promiseFactory = new PromiseFactoryConstructor();

        let _facade = null;
        let _modalService = null;
        let _onModalSavePromise = onModalSavePromise;
        let _scheduledJob = scheduledJob;

        self.saveButtonText = _scheduledJob.isEnabled() === false ? "Enable" : "Disable";
        self.modalTitle = self.saveButtonText + " Scheduled Job";
        self.modalText = "Are you sure you want to " + self.saveButtonText.toLowerCase() + " " + _scheduledJob.displayName + " job?";
        self.cancelButtonText = 'Cancel';

        self.submitForm = () => {
            return _promiseFactory.deferIndefinitely((deferredObject) => {
                _facade.updateScheduledJobStatus(scheduledJob.name, !scheduledJob.isEnabled())
                    .fail(onModalSavePromise.reject)
                    .done((result) => {
                        deferredObject.resolve(result);
                        if(onModalSavePromise) {
                            _modalService.closeModal(self);
                            onModalSavePromise.resolve({action: "save"});
                        }
                    });
            });
        };

        self.cancelForm = () => {
            if (_onModalSavePromise) {
                _onModalSavePromise.resolve({action: "cancel"});
            }
            _modalService.closeModal(self);
        };

        self.activate = () => {
            const Facade = require('presentation/developer/facades/scheduledJobsFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            const Modal = require('presentation/common/modal');
            _modalService = new Modal();

            return _initialize();
        };

        const _initialize = () => {
            return _promiseFactory.wait();
        };
    };
});


