define('presentation/settings/viewModels/potentialCustomerViewModel',['common/promises/promiseFactory',
        'presentation/settings/validators/potentialCustomerValidator',
        'presentation/settings/facades/potentialCustomerFacade'], function() {
    return function(){
        var self = this;

        var PromiseFactoryConstructor = require('common/promises/promiseFactory');
        var _promiseFactory = new PromiseFactoryConstructor();
        var _facade = null;
        var _validator = null;

        var _getPotentialCustomer = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _facade.getPotentialCustomer()
                    .done(function(potentialCustomer) {
                        self.accountNumber(potentialCustomer.account_number);
                        deferredObject.resolve();
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.accountNumber = ko.observable();
        self.isSuccessfulSave = ko.observable(false);

        self.savePotentialCustomer = function() {
            return _promiseFactory.defer(function(deferredObject) {
                _validator.validate()
                    .done(function(isValid) {
                        if(isValid !== true) {
                            self.isSuccessfulSave(false);
                            deferredObject.resolve();
                            return;
                        }

                        _facade.addPotentialCustomer(self.accountNumber())
                            .done(function() {
                                self.isSuccessfulSave(true);
                                deferredObject.resolve();
                            })
                            .fail(function(error) {
                                deferredObject.reject(error);
                            });
                    })
                    .fail(function(error) {
                        deferredObject.reject(error);
                    });
            });
        };

        self.activate = function() {
            var Facade = require('presentation/settings/facades/potentialCustomerFacade');
            _facade = new Facade();
            _facade.init(_promiseFactory);

            var ValidatorConstructor = require('presentation/settings/validators/potentialCustomerValidator');
            _validator = new ValidatorConstructor();

            return _initialize();
        };

        self.activate2 = function() {
            return _initialize();
        };

        var _initialize = function() {
            _getPotentialCustomer()
                .done(function() {
                _validator.registerViewModel(self, _facade);
            });
            return _promiseFactory.wait();
        };
    };
});

