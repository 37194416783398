define('presentation/developer/validators/moveNumbersValidator',[
    'constants/validationMessageEnumerations',
    'presentation/common/validation/validationRule',
    'presentation/common/validation/commonValidator',
    'presentation/common/validation/validPatliveAccountNumberSpecification'
], function() {
    return function() {
        let self = this;

        const CommonValidatorConstructor = require('presentation/common/validation/commonValidator');
        const ValidationRulesConstructor = require('presentation/common/validation/validationRule');
        const _validationMessageEnumerations = require('constants/validationMessageEnumerations');

        let _commonValidator = null;
        let _viewModel = null;
        let _facade = null;

        self.registerViewModel = function(viewModel, facade) {
            const validationMessageBase = _validationMessageEnumerations.webcrm.presentation.developer.numberManagement;
            let validationRules = new ValidationRulesConstructor();

            validationRules.field("toAccountNumber")
                .addValidation(_validateStringRequired, validationMessageBase.accountNumberRequired)
                .addValidation(_validateDifferentAccount, validationMessageBase.accountNumberNotUnique)
                .addValidation(_isValidAccountNumber, validationMessageBase.accountNumberInvalid);

            _viewModel = viewModel;
            _facade = facade;
            _commonValidator = new CommonValidatorConstructor(viewModel, validationRules);
        };

        self.validate = function() {
            return _commonValidator.validate();
        };

        const _validateStringRequired = function(value) {
            return _commonValidator.isStringWithValue(value);
        };

        const _validateDifferentAccount = function(accountNumber) {
            return _viewModel.fromAccountNumber() !== accountNumber;
        };

        const _isValidAccountNumber = function(accountNumber) {
            return _facade.isValidAccountNumber(accountNumber);
        };
    };
});

